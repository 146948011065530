import React from 'react';

import PropTypes from 'prop-types';

import { DropdownDots, MenuItem } from '@components/dmp';

const CheckpointGroupListItem = ({ checkpointGroup, onEdit, confirmDelete }) => {
  const handleAction = (action) => {
    switch (action) {
      case 'edit':
        onEdit(checkpointGroup);
        break;
      case 'delete':
        confirmDelete(checkpointGroup);
        break;
      default:
        break;
    }
  };

  if (!checkpointGroup) return null;

  return (
    <div className="table-inner d-flex justify-content-between">
      <div className="col checkpointGroup-title">
        <span>{checkpointGroup.name}</span>
        <small className="block">{checkpointGroup.description}</small>
      </div>

      <DropdownDots
        pullRight
        onClick={(e) => e.stopPropagation()}
        id={`dd-cg-${checkpointGroup.checkpointGroupKey}`}
        onSelect={handleAction}
      >
        <MenuItem eventKey="edit">Edit</MenuItem>
        <MenuItem eventKey="delete">Delete</MenuItem>
      </DropdownDots>
    </div>
  );
};

CheckpointGroupListItem.propTypes = {
  checkpointGroup: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default CheckpointGroupListItem;
