import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, FormControl } from 'react-bootstrap';

import Branding from '@core/models/Branding';
import Team, { FEATURES, TEAM_ROLES } from '@core/models/Team';
import { isAdmin } from '@core/models/User';
import { USER_SEARCH_LIMIT, UserIndex, dt } from '@core/utils';

import { Breakable, Button, DataTable, DropdownDots, Ellipsis, Loader, MenuItem, ModalConfirm } from '@components/dmp';

import EmptyState from '@components/EmptyState';
import CheckpointGroupListItem from '@components/deal/CheckpointGroupListItem';
import { OBSERVER_ROLES } from '@components/deal/DealUserBlock';
import ThemeListItem from '@components/deal/ThemeListItem';
import WorkflowListItem from '@components/deal/WorkflowListItem';
import TooltipButton from '@components/editor/TooltipButton';
import APIKey from '@components/teams/APIKey';
import CheckpointGroupEditor from '@components/teams/CheckpointGroupEditor';
import DealStyleEditor from '@components/teams/DealStyleEditor';
import FilevineIntegration from '@components/teams/FilevineIntegration';
import MemberInfo from '@components/teams/MemberInfo';
import ScoringEditor from '@components/teams/ScoringEditor';
import ScoringListItem from '@components/teams/ScoringListItem';
import TeamSelector from '@components/teams/TeamSelector';
import ThemeInfo from '@components/teams/ThemeInfo';
import Webhooks from '@components/teams/Webhooks';
import WorkflowEditor from '@components/teams/WorkflowEditor';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

const TeamField = (p) => {
  return (
    <div className="table-inner">
      <div className="s-mob-half s-tab-onethird bold">{p.field}</div>
      <div className="s-mob-half s-tab-twothird">{p.children}</div>
    </div>
  );
};

const TABS = [
  { key: 'details', title: 'Details', test: () => true },
  { key: 'members', title: 'Members', test: () => true },
  {
    key: 'workflows',
    title: 'Workflows',
    test: (team, user) => user.isAdmin || (team.isFeatureActive(FEATURES.WORKFLOWS) && user.canEditTeam(team.teamID)),
  },
  {
    key: 'checkpoints',
    title: 'Checkpoints',
    test: (team, user) =>
      user.isAdmin || (team.isFeatureActive(FEATURES.CHECKPOINT_GROUPS) && user.canEditTeam(team.teamID)),
  },
  {
    key: 'integrations',
    title: 'Integrations',
    test: (team, user) => user.isAdmin || user.teams[team.teamID] === 'owner',
  },
  {
    key: 'themes',
    title: 'Themes',
    test: (team, user) => user.isAdmin || user.canEditTeam(team.teamID),
  },
  {
    key: 'scoring',
    title: 'Scoring',
    test: (team, user) => user.isAdmin || (team.isFeatureActive(FEATURES.VITALS) && user.canEditTeam(team.teamID)),
  },
];

@autoBindMethods
export default class Teams extends Component {
  static defaultProps = {
    team: null,
    teams: null,
    user: null,
  };

  static propTypes = {
    checkSubscription: PropTypes.func.isRequired,
    getTeams: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    og: PropTypes.func.isRequired,
    selectTeam: PropTypes.func.isRequired,
    subscription: PropTypes.object,
    team: PropTypes.object,
    teams: PropTypes.array,
    toggleTeamCreation: PropTypes.func.isRequired,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      deletingTeam: false,
      loading: true,
      loadingMembers: true,
      members: [],
      selectedMembers: [],
      member: null,
      membersTeamID: null,
      showUpgradeTooltip: false,

      newTheme: false,
      deletingTheme: null,
      editingThemeMeta: null,
      editingThemeStyles: null,

      newWorkflow: false,
      deletingWorkflow: false,
      editingWorkflow: null,

      newCheckpointGroup: false,
      deletingCheckpointGroup: false,
      editingCheckpointGroup: null,

      newScoringMatrix: false,
      editingMatrix: null,
      deletingMatrix: null,

      team: null,
      currentPage: 0,
    };

    // Initializing a UserIndex that can contain the current team members
    this.userIndex = new UserIndex();
  }

  async loadTeam(teamID) {
    const { membersTeamID } = this.state;
    const newState = { loading: false };
    const rawTeam = await Fire.getTeam(teamID);

    const team = new Team(rawTeam);

    await this.setState({ team: team });

    if (team && team.info && team.users && (this.selectedTab.key === 'members' || membersTeamID !== teamID)) {
      if (this._isMounted) await this.setState({ loadingMembers: true });
      const members = await API.call('getTeamMembers', { teamID: team.teamID });
      //reset and populate the flex search index.
      this.userIndex.reset();
      this.userIndex.add(members);

      newState.members = members;
      newState.membersTeamID = teamID;
      newState.loadingMembers = false;
    }

    if (this._isMounted) this.setState(newState);
  }

  async reloadTeam() {
    await this.loadTeam(this.state.team.teamID);
    const { editingThemeStyles, team } = this.state;

    // If DealStyleEditor is already open we need to trigger a re-render so that it has the latest style data
    if (editingThemeStyles) {
      const theme = _.get(team, `themes[${editingThemeStyles.themeKey}]`);
      this.setState({ editingThemeStyles: theme });
    }
  }

  get selectedTab() {
    const { match } = this.props;
    const tab = _.get(match, 'params.tab');
    if (tab) {
      return _.find(TABS, { key: tab }) || TABS[0];
    } else {
      return TABS[0];
    }
  }

  async componentDidMount() {
    const { og, team } = this.props;

    this._isMounted = true;

    og({ title: `Outlaw - Teams` });

    if (team) {
      await this.loadTeam(team.teamID);
    }

    if (this._isMounted) this.setState({ loading: false });
  }

  componentDidUpdate(prevProps, prevState) {
    const { members } = this.state;
    const { team } = this.props;

    if (_.get(team, 'teamID') !== _.get(prevProps.team, 'teamID')) {
      this.loadTeam(_.get(team, 'teamID'));
    }

    if (prevState.members !== members) {
      this.setState({ currentPage: 0 });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get isOwner() {
    const { user } = this.props;
    const { team } = this.state;
    if (!team || !user) return false;
    return team.users[user.id] == 'owner' || isAdmin(user);
  }

  async removeMember(member, success) {
    await API.call('removeTeamMember', { teamID: this.state.team.teamID, removeID: member.id }, success);
  }

  async deleteTeam(teamID) {
    API.call('deleteTeam', { teamID }, async () => {
      this.setState({ deletingTeam: false });
      // refresh teams
      await this.props.getTeams(this.props.user);
    });
  }

  async deleteTheme(deletingTheme) {
    const { team } = this.state;
    await Fire.deleteTheme(team, deletingTheme);
    await this.reloadTeam();
    this.setState({ deletingTheme: null });
  }

  async deleteMatrix(deletingMatrix) {
    const { team } = this.state;
    await Fire.deleteScoringMatrix(team.teamID, deletingMatrix);
    await this.reloadTeam();
    this.setState({ deletingMatrix: null });
  }

  async deleteWorkflow(deletingWorkflow) {
    const { team } = this.state;
    await Fire.deleteWorkflow(team, deletingWorkflow);
    await this.reloadTeam();
    this.setState({ deletingWorkflow: null });
  }

  async deleteCheckpointGroup(deletingCheckpointGroup) {
    const { team } = this.state;
    await Fire.deleteCheckpointGroup(team, deletingCheckpointGroup);
    await this.reloadTeam();
    this.setState({ deletingCheckpointGroup: null });
  }

  renderTabs() {
    const { user, history } = this.props;
    const { team } = this.state;
    const tabs = _.filter(TABS, (tab) => tab.test(team, user));

    return (
      <ButtonGroup className="panel-tabs" data-cy="panel-tabs">
        {tabs.map((t) => (
          <Button
            key={t.key}
            size="large"
            dmpStyle="link"
            active={this.selectedTab === t}
            onClick={() => history.push(`/dashboard/team/${t.key}`)}
            data-cy={t.key}
          >
            {t.title}
          </Button>
        ))}
      </ButtonGroup>
    );
  }

  renderDetails() {
    const { user, selectTeam } = this.props;
    const { deletingTeam, team } = this.state;
    const branding = new Branding(team ? team.info : {});

    const role = team ? team.users[user.id] : null;

    // Team owners can delete the team
    const enableDeletion = role == 'owner';

    return (
      <>
        <div className="setting-block">
          <div className="block-header">
            <h4>Team Information</h4>
            <span className="prompt">
              General profile information about your team. This will automatically be included in all of your
              team&rsquo;s {dt}s.
            </span>
          </div>

          <div className="block-content" data-cy="team-block-content">
            <div className="utility">
              {team && user.canEditTeam(team.teamID) && (
                <Button onClick={() => selectTeam(team, true)} data-cy="btn-edit-team-details">
                  Edit details
                </Button>
              )}
            </div>
            <div className="table-outer">
              <TeamField field="Team Name">{team.info.name}</TeamField>
              <TeamField field="Legal Name">{branding.legalName}</TeamField>
              <TeamField field="Address">
                <Breakable>{branding.addressProperties?.fullAddress || branding.address}</Breakable>
              </TeamField>
              <TeamField field="Contract Footer">{branding.license}</TeamField>
              <TeamField field="Logo">
                {branding.logo ? (
                  <img className="team-logo" src={branding.logo} alt={team.info.name} />
                ) : (
                  <div className="upload-logo">
                    <div className="empty-box margin-right-s"></div>
                    <div className="empty-details">
                      <h4 className="lighter">No image uploaded</h4>
                    </div>
                  </div>
                )}
              </TeamField>
              <TeamField field="Letterhead">
                {branding.letterhead ? (
                  <img
                    className="team-letterhead"
                    src={branding.letterhead}
                    alt={team.info.name}
                    data-cy="team-letterhead"
                  />
                ) : (
                  <div className="upload-letterhead" data-cy="upload-letterhead">
                    <div className="empty-box margin-right-s"></div>
                    <div className="empty-details">
                      <h4 className="lighter">No image uploaded</h4>
                    </div>
                  </div>
                )}
              </TeamField>
              {isAdmin(user) && <TeamField field="Salesforce ID">{team.info.salesforceID}</TeamField>}
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'right', paddingBottom: 30 }}>
          {enableDeletion && (
            <Button dmpStyle="danger" onClick={() => this.setState({ editingTeam: team, deletingTeam: true })}>
              Delete Team
            </Button>
          )}
        </div>
        {deletingTeam && this.renderDeleteTeam()}
      </>
    );
  }

  onToggleMember(member) {
    const { selectedMembers } = this.state;
    const idx = _.findIndex(selectedMembers, { id: member.id });

    if (idx > -1) selectedMembers.splice(idx, 1);
    else selectedMembers.push({ ...member });

    this.setState({ selectedMembers });
  }

  toggleAllMembers() {
    let { selectedMembers, members } = this.state;

    if (selectedMembers.length > 0) selectedMembers = [];
    else selectedMembers = [...members];

    this.setState({ selectedMembers });
  }

  filterUsers(e) {
    let results,
      members = [];
    let newState = {};

    if (e.target.value) {
      results = this.userIndex.search(e.target.value, {
        index: ['email', 'fullName'],
        enrich: true,
        USER_SEARCH_LIMIT,
      });
      //this will only return an array with a max size 2 for email and fullName.
      if (results.length > 1) {
        //Break the array up into unique entries by userID.
        members = _.map(_.uniqBy(_.concat(results[0].result, results[1].result), 'id'), 'doc');
        //Notify the user if the limit was hit ( Both fullName and email search can return up to the limit check both )
        if (results[0].result.length === USER_SEARCH_LIMIT || results[1].result.length === USER_SEARCH_LIMIT) {
          newState.limitHit = true;
        } else {
          newState.limitHit = false;
        }
      } else if (results.length === 1) {
        members = _.map(results[0].result, 'doc');
        //Notify the user if the limit was hit
        if (results[0].result.length === USER_SEARCH_LIMIT) {
          newState.limitHit = true;
        } else {
          newState.limitHit = false;
        }
      }
    } else {
      members = this.userIndex.getAll();
      newState.limitHit = false;
    }

    newState.members = members;
    this.setState(newState);
  }

  get memberColumns() {
    const { user } = this.props;

    return [
      // We are hiding it for now since we need to properly define what the batch actions will be
      // This will be the V2 of this Members listing UX transition.
      /* selectorColumn({
        selected: selectedMembers,
        onToggleAll: this.toggleAllMembers,
        onToggle: this.onToggleMember,
      }), */
      {
        accessor: 'fullName',
        Header: 'Name',
        Cell: ({ original: member }) => {
          const isSelf = member.id == user.id;
          const isInvite = member.inviteID != null;
          const { isAdmin, isPartner } = member;
          const customer = member.subscriptionID != null;

          const tagsParams = [
            { key: 'admin', value: isAdmin },
            { key: 'billing', value: !!customer },
            { key: 'partner', value: isPartner },
          ];

          const tags = _.map(tagsParams, (tag) => {
            if (tag.value) {
              if (!isSelf) {
                const tip = isAdmin
                  ? 'This user is affiliated with Outlaw Inc. and therefore does not occupy any seats on your Outlaw subscription'
                  : 'This user is also an Outlaw customer and therefore does not occupy any seats on your Outlaw subscription';
                tag = (
                  <TooltipButton tip={tip} tipID={`team-tag-${member.id}`}>
                    <span className={`tag ${tag.key}`}>{_.upperFirst(tag.key)}</span>
                  </TooltipButton>
                );
              } else {
                tag = <span className={`tag self ${tag.key}`}>{_.upperFirst(tag.key)}</span>;
              }
              return tag;
            }
          });

          return (
            <div className="d-flex">
              {isInvite && <span className="invited">(Invited)</span>}
              {member.fullName && <Ellipsis>{member.fullName}</Ellipsis>}
              <>{tags}</>
            </div>
          );
        },
        minWidth: 100,
      },
      {
        accessor: 'email',
        Header: 'Email',
        minWidth: 100,
      },
      {
        accessor: 'title',
        Header: 'Title',
      },
      {
        accessor: 'role',
        Header: 'Role',
        width: 60,
        resizable: false,
      },
      {
        accessor: 'canCreateNewDoc',
        Header: 'New Docs',
        Cell: ({ original: member }) => (member.canCreateNewDoc === true ? 'Yes' : ''),
        width: 80,
        resizable: false,
      },
      {
        accessor: 'observer',
        Header: 'Observer',
        Cell: ({ original: member }) => (member.observer ? _.get(member, 'observerRole', OBSERVER_ROLES[0].key) : ''),
        width: 80,
        resizable: false,
      },
      this.editColumn,
    ];
  }

  get editColumn() {
    const { team } = this.state;
    const { user } = this.props;

    return {
      accessor: 'id',
      id: 'actions',
      Cell: ({ original: member }) => {
        const role = _.find(TEAM_ROLES, { value: member.role }) || TEAM_ROLES.VIEWER;
        const isSelf = member.id == user.id;
        const isOwner = this.isOwner;
        const showMenu = isSelf || isOwner;
        const isInvite = member.inviteID != null;

        const multiOwners = team.teamOwners.length > 1;
        //if there is only one owner and the user in question is a team owner do not allow that user to be removed, leave, or change his or her status.
        const canRemoveMember = !multiOwners && role === TEAM_ROLES.OWNER;

        return (
          <div>
            {showMenu && (
              <DropdownDots
                pushRight={false}
                id={`dd-member-${member.id}`}
                onSelect={(action) => this.handleMemberAction(member, action)}
              >
                {isOwner && <MenuItem eventKey="edit">Update permissions</MenuItem>}
                {(!canRemoveMember || isInvite) && (
                  <MenuItem eventKey={isSelf ? 'leave' : 'remove'}>{isSelf ? 'Leave' : 'Remove'}</MenuItem>
                )}
              </DropdownDots>
            )}
          </div>
        );
      },
      width: 40,
      className: 'col-actions',
      fixed: 'right',
      sortable: false,
      clickable: false,
      resizable: false,
      style: { overflow: 'visible' }, // Important, otherwise the dropdown won't show
    };
  }

  renderMemberResults(props) {
    const { page, pageSize, pages, data } = props;

    let startDisplay = 0;
    let endDisplay = 0;
    let upperPageEnd = 0;
    let resultSummary = '';

    startDisplay = page * pageSize + 1;
    upperPageEnd = pageSize * (page + 1);
    endDisplay = upperPageEnd < data.length ? upperPageEnd : data.length;

    if (!data.length) {
      resultSummary = 'No members found';
    } else {
      resultSummary =
        pages > 1
          ? `Showing ${startDisplay} - ${endDisplay} of ${data.length} members`
          : `Showing ${data.length} member${data.length > 1 ? 's' : ''}`;
    }

    return (
      <div className="top-of-results" data-cy="top-of-results">
        <div className="results">{resultSummary}</div>
      </div>
    );
  }

  renderMembers() {
    const { members, loadingMembers, limitHit, currentPage } = this.state;

    const alphabeticalMembers = _.sortBy(members, (member) => (member.fullName || member.email).toLowerCase());

    return (
      <div data-cy="setting-block-members" className="setting-block-members">
        <div className="d-flex justify-content-between align-items-center flex-wrap ">
          <h4>Manage members</h4>
          <div className="d-flex">
            <div className="search-users" data-cy="search-users">
              <FormControl type="text" onChange={this.filterUsers} placeholder="Search team member by name or email" />
              {limitHit && <span className="error"> Search limit was hit, refine your search. </span>}
            </div>
            {this.isOwner && (
              <div className="add-button">
                <Button dmpStyle="primary" onClick={() => this.setState({ member: {} })}>
                  Add Member
                </Button>
              </div>
            )}
          </div>
        </div>
        <DataTable
          canScroll={false}
          columns={this.memberColumns}
          data={alphabeticalMembers}
          defaultSorted={[{ id: 'fullName' }]}
          dropshadow
          loading={loadingMembers}
          onPageChange={(pageIndex) => this.setState({ currentPage: pageIndex })}
          page={currentPage}
          pageSizeOptions={[50, 100, 250, 1000, 2500]}
          showPaginationTop={true}
          TopPaginationComponent={this.renderMemberResults}
        />
      </div>
    );
  }

  renderIntegrations() {
    const { user } = this.props;
    const { team } = this.state;
    return (
      <>
        <APIKey user={user} team={team} isOwner={this.isOwner} reloadTeam={this.reloadTeam} />

        <FilevineIntegration user={user} team={team} isOwner={this.isOwner} reloadTeam={this.reloadTeam} />

        <Webhooks user={user} team={team} isOwner={this.isOwner} reloadTeam={this.reloadTeam} />
      </>
    );
  }

  renderWorkflows() {
    const { user } = this.props;
    const { newWorkflow, editingWorkflow, deletingWorkflow, team } = this.state;

    return (
      <>
        <div className="setting-block workflows">
          <div className="block-header">
            <h4>Workflows</h4>
            <span className="prompt">
              Configure workflows for your team. This is useful if your team has a unique way of approving documents.
            </span>
          </div>
          <div className="block-content">
            <div className="utility">
              <Button
                onClick={() => this.setState({ newWorkflow: true, editingWorkflow: null })}
                data-cy="btn-new-workflow"
              >
                New Workflow
              </Button>
            </div>
            <div className="table-outer theme-listing">
              {team.workflows.length === 0 && (
                <div className="table-inner table-inner-disabled">There are no workflows defined yet for this team</div>
              )}
              {_.map(team.workflows, (workflow) => (
                <WorkflowListItem
                  workflow={workflow}
                  key={workflow.workflowKey}
                  onEdit={(editingWorkflow) => this.setState({ editingWorkflow })}
                  confirmDelete={(deletingWorkflow) => this.setState({ deletingWorkflow })}
                />
              ))}
            </div>
          </div>

          <WorkflowEditor
            show={newWorkflow || !!editingWorkflow}
            onHide={() => this.setState({ newWorkflow: false, editingWorkflow: null })}
            onSave={this.reloadTeam}
            workflow={newWorkflow ? null : editingWorkflow}
            user={user}
            team={team}
          />

          <ModalConfirm
            show={!!deletingWorkflow}
            onConfirm={() => this.deleteWorkflow(deletingWorkflow)}
            onHide={() => this.setState({ deletingWorkflow: null })}
            title="Delete workflow?"
            body={
              <>
                <p>
                  <b>{_.get(deletingWorkflow, 'name', '')}</b> will be permanently deleted and all {dt}s and templates
                  using this workflow will revert to default workflow.
                  <br />
                  <b>This action cannot be undone.</b>
                </p>
                <p>Are you sure you want to proceed?</p>
              </>
            }
          />
        </div>
      </>
    );
  }

  renderCheckpointGroups() {
    const { user } = this.props;
    const { newCheckpointGroup, editingCheckpointGroup, deletingCheckpointGroup, team, members, loadingMembers } =
      this.state;

    return (
      <>
        <div className="setting-block checkpointGroupss">
          <div className="block-header" data-cy="block-header">
            <h4>Checkpoint Groups</h4>
            <span className="prompt">
              Manage checkpoint groups that can be applied to custom workflows or run independently.
            </span>
          </div>
          <div className="block-content" data-cy="block-content">
            <div className="utility">
              <Button
                disabled={loadingMembers}
                onClick={() => this.setState({ newCheckpointGroup: true, editingCheckpointGroup: null })}
                data-cy="btn-new-checkpoint-group"
              >
                New checkpoint group
              </Button>
            </div>
            <div className="table-outer theme-listing">
              {team.checkpointGroups.length === 0 && (
                <div className="table-inner table-inner-disabled">
                  There are no checkpoint groups defined yet for this team
                </div>
              )}
              {_.map(team.checkpointGroups, (checkpointGroup) => (
                <CheckpointGroupListItem
                  checkpointGroup={checkpointGroup}
                  key={checkpointGroup.checkpointGroupKey}
                  onEdit={(editingCheckpointGroup) => this.setState({ editingCheckpointGroup })}
                  confirmDelete={(deletingCheckpointGroup) => this.setState({ deletingCheckpointGroup })}
                />
              ))}
            </div>
          </div>

          <CheckpointGroupEditor
            show={newCheckpointGroup || !!editingCheckpointGroup}
            onHide={() => this.setState({ newCheckpointGroup: false, editingCheckpointGroup: null })}
            onSave={this.reloadTeam}
            checkpointGroup={newCheckpointGroup ? null : editingCheckpointGroup}
            user={user}
            team={team}
            members={members}
          />

          <ModalConfirm
            show={!!deletingCheckpointGroup}
            onConfirm={() => this.deleteCheckpointGroup(deletingCheckpointGroup)}
            onHide={() => this.setState({ deletingCheckpointGroup: null })}
            title="Delete checkpoint group?"
            body={
              <>
                <p>
                  <b>{_.get(deletingCheckpointGroup, 'name', '')}</b> will be permanently deleted from all the
                  corresponding workflow steps.
                  <br />
                  <b>This action cannot be undone.</b>
                </p>
                <p>Are you sure you want to proceed?</p>
              </>
            }
          />
        </div>
      </>
    );
  }

  renderThemes() {
    const { user } = this.props;
    const { newTheme, deletingTheme, editingThemeMeta, editingThemeStyles, team } = this.state;
    const themes = _.sortBy(team.themes, 'name');

    return (
      <>
        <div className="setting-block themes">
          <div className="block-header" data-cy="block-header">
            <h4>Themes</h4>
            <span className="prompt">Themes allow your documents to have consistent formatting.</span>
          </div>
          <div className="block-content" data-cy="block-content">
            <div className="utility">
              <Button onClick={() => this.setState({ newTheme: true })} data-cy="btn-new-theme">
                New Theme
              </Button>
            </div>
            <div className="table-outer theme-listing" data-cy="theme-listing">
              {themes.length === 0 && (
                <div className="table-inner table-inner-disabled">There are no themes defined yet for this team</div>
              )}
              {_.map(themes, (theme) => (
                <ThemeListItem
                  theme={theme}
                  key={theme.themeKey}
                  editMeta={(editingThemeMeta) => this.setState({ editingThemeMeta })}
                  editStyles={(editingThemeStyles) => this.setState({ editingThemeStyles })}
                  confirmDelete={(deletingTheme) => this.setState({ deletingTheme })}
                />
              ))}
            </div>
          </div>
        </div>

        <ThemeInfo
          show={!!newTheme || !!editingThemeMeta}
          onHide={() => this.setState({ newTheme: false, editingThemeMeta: null })}
          onSave={this.reloadTeam}
          theme={newTheme ? null : editingThemeMeta}
          user={user}
          team={team}
        />

        <DealStyleEditor
          show={!!editingThemeStyles}
          team={team}
          user={user}
          theme={editingThemeStyles}
          onHide={() => this.setState({ editingThemeStyles: null })}
          onSave={this.reloadTeam}
        />

        <ModalConfirm
          show={!!deletingTheme}
          onConfirm={() => this.deleteTheme(deletingTheme)}
          onHide={() => this.setState({ deletingTheme: null })}
          title="Delete theme?"
          body={
            <>
              <p>
                <b>{_.get(deletingTheme, 'name', '')}</b> will be permanently deleted and all {dt}s and templates using
                this theme will revert to default Outlaw styles.
                <br />
                <b>This action cannot be undone.</b>
              </p>
              <p>Are you sure you want to proceed?</p>
            </>
          }
        />
      </>
    );
  }

  renderScoring() {
    const { deletingMatrix, newMatrix, editingMatrix, team } = this.state;
    const { scoringMatrices } = team;

    return (
      <>
        <div className="setting-block scoring">
          <div className="block-header" data-cy="block-header">
            <h4>Scoring</h4>
            <span className="prompt">Set up custom scoring matrix to support vitals.</span>
          </div>
          <div className="block-content" data-cy="block-content">
            <div className="utility">
              <Button onClick={() => this.setState({ newMatrix: true })} data-cy="btn-new-scoring">
                New scoring matrix
              </Button>
            </div>
            <div className="table-outer scoring-listing" data-cy="scoring-listing">
              {!scoringMatrices && (
                <div className="table-inner table-inner-disabled">
                  There are no Scoring Matrices defined yet for this team
                </div>
              )}
              {_.map(scoringMatrices, (matrix, index) => (
                <ScoringListItem
                  matrix={matrix}
                  key={index}
                  editMatrix={(editingMatrix) => this.setState({ editingMatrix })}
                  confirmDelete={(deletingMatrix) => this.setState({ deletingMatrix })}
                  data-cy="matrix-item"
                />
              ))}
            </div>
          </div>
        </div>

        <ScoringEditor
          show={!!newMatrix || !!editingMatrix}
          onHide={() => this.setState({ newMatrix: false, editingMatrix: null })}
          onSave={this.reloadTeam}
          matrix={newMatrix ? null : editingMatrix}
          team={team}
        />

        <ModalConfirm
          show={!!deletingMatrix}
          onConfirm={() => this.deleteMatrix(deletingMatrix)}
          onHide={() => this.setState({ deletingMatrix: null })}
          title="Delete matrix?"
          body={
            <>
              <p>
                <b>{_.get(deletingMatrix, 'displayName', '')}</b> will be permanently deleted and all {dt}s and
                templates using this theme will revert to default Outlaw styles.
                <br />
                <b>This action cannot be undone.</b>
              </p>
              <p>Are you sure you want to proceed?</p>
            </>
          }
        />
      </>
    );
  }

  renderTeam() {
    const { team } = this.state;

    if (!team) return null;

    const selectedTab = this.selectedTab;

    return (
      <div className="team-admin">
        {this.renderTabs()}

        {selectedTab.key === 'details' && this.renderDetails()}
        {selectedTab.key === 'members' && this.renderMembers()}
        {selectedTab.key === 'integrations' && this.renderIntegrations()}
        {selectedTab.key === 'workflows' && this.renderWorkflows()}
        {selectedTab.key === 'checkpoints' && this.renderCheckpointGroups()}
        {selectedTab.key === 'themes' && this.renderThemes()}
        {selectedTab.key === 'scoring' && this.renderScoring()}
      </div>
    );
  }

  renderEmpty() {
    const empty = {
      title: 'Setup your team',
      subtitle: `Teams let you share your standardized ${dt} templates across your organization`,
    };

    return <EmptyState {...empty} />;
  }

  handleMemberAction(member, action) {
    const { checkSubscription, getTeams, user } = this.props;
    const { team } = this.state;
    switch (action) {
      case 'edit':
        this.setState({ member });
        break;
      case 'remove':
        //if user is removed from team, refresh team (which will refresh members)
        //also refresh subscription as removing a member may put account back in good standing
        //(i.e., no more need for roadblock)
        this.removeMember(member, () => {
          this.loadTeam(team.teamID);
          checkSubscription(user);
        });
        break;
      case 'leave':
        //if user leaves team, refresh teams list (which will no longer have this team)
        this.removeMember(member, () => getTeams(user));
        break;
      default:
        break;
    }
  }

  selectTeam = (teamID) => {
    const { selectTeam, teams } = this.props;
    const team = _.find(teams, { teamID });
    selectTeam(team);
  };

  renderDeleteTeam() {
    const { deletingTeam, team } = this.state;

    if (!team) {
      return null;
    }

    return (
      <ModalConfirm
        show={deletingTeam}
        onHide={() => this.setState({ deletingTeam: false })}
        onConfirm={() => this.deleteTeam(team.teamID)}
        confirmText="Delete Team"
        title={
          <span className="headline">
            Really delete <strong>{team.info.name}</strong>?
          </span>
        }
        body={
          <>
            <p>
              All Members will be removed from this team, and all of this team's Templates and Themes will be
              permanently deleted.
              <br />
              <b>This action cannot be undone.</b>
            </p>
            <p>Are you sure you want to proceed?</p>
          </>
        }
      />
    );
  }

  render() {
    const { user, teams, toggleTeamCreation, subscription, checkSubscription, history } = this.props;
    const { loading, member, team } = this.state;
    const multiOwners = team ? team.teamOwners.length > 1 : false;

    if (!user) return null;

    return (
      <div className="team wrapper">
        <div className="title-bar">
          <h1>Team</h1>
          <TeamSelector
            pullRight
            user={user}
            teamID={team?.teamID || null}
            teams={teams}
            clearable={false}
            onSelect={this.selectTeam}
            toggleTeamCreation={toggleTeamCreation}
            width={180}
            canCreate
            createLabel="New Team"
          />
        </div>

        {loading || !teams ? <Loader centered size="large" /> : !teams.length ? this.renderEmpty() : this.renderTeam()}

        {!!team && (
          <MemberInfo
            multiOwners={multiOwners}
            subscription={subscription}
            checkSubscription={checkSubscription}
            history={history}
            user={user}
            team={team}
            member={member}
            onSave={() => this.setState({ member: null }, () => this.loadTeam(team.teamID))}
            onHide={() => this.setState({ member: null })}
          />
        )}
      </div>
    );
  }
}
