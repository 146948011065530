import React, { Component } from 'react';

import axios from 'axios';
import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, InputGroup, Modal, ProgressBar } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import Attachment, { ACCEPTED_TYPES, ATTACHMENT_TYPE } from '@core/models/Attachment';
import Deal, { DEAL_TYPE } from '@core/models/Deal';
import DealVersion, { strip } from '@core/models/DealVersion';
import User from '@core/models/User';
import { Dt, Stopwatch, dt } from '@core/utils';

import { Alert, Button, Dropdown, Loader, MenuItem } from '@components/dmp';

import ChangeList from '@components/deal/DealHeader/ChangeList';
import DealStatusSelector from '@components/deal/DealStatusSelector';
import Fire from '@root/Fire';

@autoBindMethods
export default class CommitVersion extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal),
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      progress: null,
      title: '',
      dealStatus: null,
      description: '',
      du: null,
      error: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  populate() {
    const { deal } = this.props;
    this.setState({
      dealStatus: deal.info.status,
      du: deal.currentDealUser,
      progress: null,
      error: null,
      // TODO: use automatic naming convention once we agree on one
      title: strip(deal.currentVersion.filename),
    });
  }

  reset() {
    this.setState({
      saving: false,
      progress: null,
      error: null,
      title: '',
      dealStatus: null,
      description: '',
    });
  }

  get isReady() {
    const { dealStatus, du, title } = this.state;
    return dealStatus && du && title;
  }

  get workflowStep() {
    const { deal } = this.props;
    const { dealStatus } = this.state;
    const idx = _.findIndex(deal.workflow.steps, { key: dealStatus });
    return idx > -1 ? deal.workflow.steps[idx] : deal.workflow.steps[0];
  }

  get canClose() {
    const { saving, error } = this.state;
    return !saving && !error;
  }

  onClose() {
    if (this.canClose) {
      this.props.onClose();
    }
  }

  async commitVersion() {
    const { user, deal, history } = this.props;
    const { du, dealStatus, description, title } = this.state;

    const sw = new Stopwatch('COMMIT');

    const version = deal.currentVersion;

    try {
      // TODO: UI to manage these
      const dateCreated = new Date().getTime().toString();

      await this.setState({
        saving: true,
        progress: { current: 1, total: 3, description: 'Committing changes to PDF' },
      });

      // Download the raw PDF data as an ArrayBuffer
      const pdfPath = version.pdfBucketPath;
      const url = await Fire.storage.ref(pdfPath).getDownloadURL();
      const raw = await axios.get(url, { responseType: 'arraybuffer' });

      // Load in the raw PDF data so we're ready for manipulation
      await deal.loadPDF(raw);
      // Get raw data of manipulated pdf, i.e., with pdfElements rendered in
      const pdfBytes = await deal.applyElements();

      sw.step('Committed changes to PDF');

      // Create attachment (which includes file upload)
      await this.setState({ saving: true, progress: { current: 2, total: 3, description: 'Uploading new PDF' } });
      const pdfAttachment = new Attachment(
        {
          title: strip(title),
          extension: ACCEPTED_TYPES.PDF.extension,
          attachmentType: ATTACHMENT_TYPE.VERSION,
        },
        deal
      );
      await Fire.saveAttachment(pdfAttachment, pdfBytes);

      sw.step('Saved PDF Attachment');

      await this.setState({ saving: true, progress: { current: 3, total: 3, description: 'Finalizing new version' } });

      // Now create DealVersion which references the new Attachment
      const newVersion = await Fire.saveDealVersion(
        new DealVersion(
          {
            owner: du.uid,
            pdfKey: pdfAttachment.key,
            dateCreated,
            dealStatus,
            origin: DEAL_TYPE.NATIVE,
            description: description.trim() || null,
          },
          deal
        )
      );
      sw.step('Saved DealVersion');

      await Fire.flushPDFElements(deal);
      sw.step('Flushed PDF elements');

      await this.setState({ saving: false });
      Fire.addActivity(version.deal, user, DealAction.CREATE_VERSION, newVersion.key);
      history.push(newVersion.deepLink);
      this.onClose();
    } catch (error) {
      console.log('Error saving new version', error);
      this.setState({ saving: false, error: error.toString() });
    }
  }

  render() {
    const { show } = this.props;
    const { saving, error } = this.state;

    return (
      <Modal dialogClassName="version-committer" show={show} onHide={this.onClose}>
        <Modal.Header closeButton={this.canClose}>
          <span className="headline">Save new version</span>
        </Modal.Header>

        {error ? this.renderError() : saving ? this.renderSaving() : this.renderBody()}
      </Modal>
    );
  }

  renderBody() {
    const { deal } = this.props;
    const { du, saving, description, title } = this.state;

    return (
      <>
        <Modal.Body>
          <div className="wrapper">
            <Alert centered>Saving will create a new PDF with these changes permanently applied</Alert>
          </div>
          <div className="wrapper">
            <FormGroup>
              <ControlLabel>Pending Changes</ControlLabel>
              <div className="contents deal-status">
                <ChangeList deal={deal} />
              </div>
            </FormGroup>
          </div>

          <div className="wrapper">
            <FormGroup>
              <ControlLabel>{Dt} Status</ControlLabel>
              <div className="contents deal-status">
                <DealStatusSelector
                  steps={deal.workflow.steps}
                  enableAllSteps
                  onSelect={(step) => this.setState({ dealStatus: step.key })}
                  currentStep={this.workflowStep}
                />
              </div>
            </FormGroup>
          </div>

          <div className="wrapper">
            <FormGroup>
              <ControlLabel>New Filename</ControlLabel>
              <div className="contents filename">
                <InputGroup>
                  <FormControl value={title} onChange={(e) => this.setState({ title: e.target.value })} />
                  <InputGroup.Addon>.pdf</InputGroup.Addon>
                </InputGroup>
              </div>
            </FormGroup>
          </div>

          <div className="wrapper">
            <FormGroup>
              <ControlLabel>Attribution</ControlLabel>
              <div className="contents attribution">
                <Dropdown
                  id="dd-attribution"
                  title={du ? du.get('fullName') : 'Select User'}
                  onSelect={(du) => this.setState({ du })}
                  block
                >
                  {deal.users.map((u) => (
                    <MenuItem key={u.key} eventKey={u}>
                      {u.get('fullName')}
                    </MenuItem>
                  ))}
                </Dropdown>
                <small>
                  Select the user who made these changes. All imported changes will be attributed to this user in the
                  {dt}'s Version History on Outlaw.
                </small>
              </div>
            </FormGroup>
          </div>

          <div className="wrapper">
            <FormGroup>
              <ControlLabel>Description</ControlLabel>
              <div className="contents description">
                <FormControl
                  componentClass="textarea"
                  value={description}
                  onChange={(e) => this.setState({ description: e.target.value })}
                />
              </div>
            </FormGroup>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {saving && <Loader />}
          <div className="spacer" />
          <Button onClick={this.onClose} disabled={saving}>
            Cancel
          </Button>
          <Button
            dmpStyle="primary"
            disabled={saving || !this.isReady}
            onClick={this.commitVersion}
            data-cy="btn-save-version"
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </>
    );
  }

  renderSaving() {
    const progress = _.get(this.state, 'progress', { current: 0, total: 1, description: '' });
    const percent = (progress.current / progress.total) * 100;

    return (
      <Modal.Body>
        <div className="saving">
          <ProgressBar bsStyle="info" now={percent} />
          <div className="details">
            <div className="step">
              {progress.current} of {progress.total}
            </div>
            <div className="description">{progress.description}</div>
          </div>
        </div>
      </Modal.Body>
    );
  }

  renderError() {
    const { error } = this.state;
    return (
      <>
        <Modal.Body>
          <div className="saving error">
            <Alert bsStyle="danger">
              <strong>Saving new version failed</strong> {error}
            </Alert>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.reset}>Try Again</Button>
        </Modal.Footer>
      </>
    );
  }
}
