import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import RJV from 'react-json-view';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, Modal } from 'react-bootstrap';

import AIPrompt, { AI_ENGINES, BLOCK_TYPE, BLOCK_PROMPT_LABEL, STEP_TYPES, RESPONSE_TYPES, DATA_SOURCE_TYPES } from '@core/models/AIPrompt';
import { findVariableSuggestText, rxSuggest } from '@core/models/Content';
import List, { FEATURE_LABEL, LIST_TYPES } from '@core/models/List';
import { CONNECTED_VAR_FIELDS, EXTERNAL_TYPES, VariableType, ValueType } from '@core/models/Variable';
import { setCursor } from '@core/utils';
import { FILEVINE_SERVICE } from '@core/enums/IntegrationServices';

import { Button, Checkbox, Dropdown, MenuItem, Switch, Validator } from '@components/dmp';

import NumberFormatSelector from '@components/NumberFormatSelector';
import PartySelector from '@components/PartySelector';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TableColumnManager from '@components/editor/TableColumnManager';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';
import VariableIndex from '@root/utils/VariableIndex';
import VariableSuggest from '@components/editor/VariableSuggest';

@autoBindMethods
export default class ListSidebar extends Component {
  static propTypes = {
    list: PropTypes.instanceOf(List).isRequired,
    variableIndex: PropTypes.instanceOf(VariableIndex),
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      configDS: false,
      aiPrompt: null,
      previewPrompt: false,
      vsState: null,
    };

    this.refSuggest = createRef();
    this.refVariablesDS = createRef();
  }

  saveName = _.debounce((name) => {
    const { list } = this.props;
    Fire.saveSection(list, { name });
  }, 500);

  saveAIPrompt = _.debounce(() => {
    const { list } = this.props;
    const { aiPrompt } = this.state;
    Fire.saveSection(list, { aiPrompt: aiPrompt.json });
  }, 500);

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props.list);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const { list, variableIndex } = this.props;
    if (!_.isEqual(list, prevProps.list) || !_.isEqual(variableIndex, prevProps.variableIndex)) {
      this.populate(list);
    }
  }

  async populate(list) {
    this.setState({
      name: list.name,
    });

    if (list.isAI) {
      if (!list.aiPrompt) {
        const promptJSON = AIPrompt.buildJSON(list, list.deal.workflow);
        await Fire.saveSection(list, { aiPrompt: promptJSON });
      } else {
        this.setState({
          aiPrompt: list.aiPrompt,
        });
      }
    }
  }

  get titleTip() {
    const { list } = this.props;
    if (list.subType === 'BLOCK') {
      return 'Designed for a single section of user-generated content to be entered in Flow';
    } else {
      return 'Designed for user-generated list content to be authored in Flow';
    }
  }

  get collections() {
    const { variableIndex } = this.props;
    const collections = variableIndex.filter({ externalType: EXTERNAL_TYPES.COLLECTION });
    return collections;
  }

  get connection() {
    const { list } = this.props;
    return _.get(list, 'deal.connections[0]', null);
  }

  get enableContinuous() {
    const { list } = this.props;

    if (list.hideOrder || !!list.conditions.length) return false;

    return list.isRepeater || !list.currentVersion.hasContent;
  }

  updateName(e) {
    const name = e.target.value;
    this.setState({ name });
    this.saveName(name);
  }

  updatePrompt(e) {
    const { aiPrompt } = this.state;
    
    // aiPrompt.prompt[index].content = content;

    const index = _.findIndex(aiPrompt.prompt, {role: STEP_TYPES.USER});
    if (index > -1) {
      aiPrompt.prompt[index].content = e.target.value;
      this.setState({ aiPrompt });
      this.saveAIPrompt();
    }
  }

  updateAIPromptConfig(key, value) {
    const { list } = this.props;
    const { aiPrompt } = this.state;
    aiPrompt[key] = value;

    if (key === 'type') {
      // const newPrompt = togglePromptType(value, aiPrompt.prompt);
      const currentUserPrompt = aiPrompt.userPromptText;
      // console.log(currentUserPrompt);
      
      const promptJSON = AIPrompt.buildJSON(list, list.deal.workflow, value);
      Fire.saveSection(list, { aiPrompt: promptJSON });
      return;
      // console.log(promptJSON);
      // aiPrompt['prompt'] = newPrompt;
    }

    // If switching DS type between Sections and Variables,
    // clear anything that's already set in the other method to avoid ambiguity
    // (This does mean if you switch to the other type and then back again, 
    // you'll need to re-link the target Sections or Variables again)
    if (key === 'dsType') {
      if (value === DATA_SOURCE_TYPES.VARIABLES) aiPrompt.linkedSections = [];
      if (value === DATA_SOURCE_TYPES.SECTIONS) aiPrompt.linkedVariables = [];
    }

    // Clear target outputVariable if one is set and we're switching AWAY from variable output 
    if (key === 'responseType' && value !== 'variable') {
      aiPrompt.outputVariable = null;
    }

    this.setState({ aiPrompt });
    this.saveAIPrompt();
  }

  updateSetting(setting, value) {
    const { list } = this.props;

    if (setting === 'dataSource') {
      const dataSource = value ? _.pick(value, CONNECTED_VAR_FIELDS) : null;
      Fire.saveSection(list, { dataSource });
    } else {
      const updates = { [setting]: value };
      // Turning on continuous numbering forces no indent, so that items will be in line with list-level sibs
      if (setting === 'continuous' && value === true) {
        updates.indent = false;
      }
      Fire.saveSection(list, updates);
    }
  }

  // Lists can have up to 4 levels of internal nesting, which should be WAY more than enough
  // (especially considering the entire List itself can be nested in deal structure)
  // So any time we update any nesting level, be sure to explicitly store all levels
  updateNumbering(updateLevel, numberFormat) {
    const { list } = this.props;

    const numbering = [];

    _.map([0, 1, 2, 3], (level) => {
      if (level === updateLevel) {
        numbering[level] = numberFormat.json;
      } else if (!_.get(list.style, `numbering[${level}]`)) {
        numbering[level] = { type: 'none' };
      } else {
        numbering[level] = list.style.numbering[level].json;
      }
    });

    Fire.saveSectionStyle(list, { numbering });
  }

  ///////////////////////////////////////
  // VariableSuggest Implementation Start
  ///////////////////////////////////////

  updateVariableSuggest(e) {
    const vsState = findVariableSuggestText({ element: e.target, rx: rxSuggest });
    this.setState({ vsState });
  }

  handleVS(e) {
    const { vsState } = this.state;
    const varSuggest = this.refSuggest.current;

    // Allow VariableSuggest to hijack certain keys (enter, escape, arrows) if active
    if (vsState && varSuggest && varSuggest.handleKey(e)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  async commitVariableSuggestion(newText, option) {
    const { list:section } = this.props;
    const { vsState, aiPrompt } = this.state;
    let linkedVariables = _.get(aiPrompt, 'linkedVariables', '');

    // VariableSuggest thinks that we are supporting properties (isFormula = false), but we're not (yet)
    // so we need to replace a "." to complete the variable with its "baseVariable" (default) rendering version
    // If we want to support sub properties and transforms, we need to add an explicit function on Deal 
    // to evaluate a Variable AND target property/transform, because right now Variables only evaluate themselves,
    // so a variable like [#MyVariable.spelled] will not actually exist on the Deal 
    newText = newText.replace(/\.$/, ']');
    
    linkedVariables = linkedVariables.slice(0, vsState.start) + newText + linkedVariables.slice(vsState.start + vsState.input.length);
    aiPrompt.linkedVariables = linkedVariables;

    await this.setState({ aiPrompt, vsState: null });

    // Copied from SectionEditor.commitSuggestion -- we want to apply the same logic here (importing connected vars)
    // as if the user had typed this variable into a Section
    if (
      option.connectType === FILEVINE_SERVICE.key &&
      !section.deal.variables[option.id] &&
      option.valueType !== ValueType.PROJECT
    ) {
      // console.log(option);
      const varDef = _.pick(option, CONNECTED_VAR_FIELDS);
      Fire.saveVariableDefinition(section.deal, varDef);
    }

    if (option.type === VariableType.SIMPLE && option?.isNew) {
      // We will store all NEW simple variables.  The user is able to manually delete from the sidebar
      console.log(option);
      await Fire.saveVariableDefinition(section.deal, { ...option, name: option.name || option.value, value: null });
    }

    const el = ReactDOM.findDOMNode(this.refVariablesDS.current);
    setCursor(el, vsState.start + newText.length);
  }

  ///////////////////////////////////////
  // VariableSuggest Implementation End
  ///////////////////////////////////////

  renderNumbering() {
    const { list } = this.props;
    const levels = list.nesting ? [0, 1, 2, 3] : [0];
    const isRepeater = list.subType === LIST_TYPES.REPEATER;
    return _.map(levels, (level) => (
      <div className="property-row list-numbering" key={level} data-cy="property-row">
        {!isRepeater && <div className="property-row-item">{`Item level ${level + 1}`}</div>}
        <NumberFormatSelector
          id={`${list.id}-nf-selector-${level}`}
          section={list}
          size="small"
          numberFormat={_.get(list.style, `numbering[${level}]`, null)}
          onSelect={(nf) => this.updateNumbering(level, nf)}
          includeUnnumbered
        />
      </div>
    ));
  }

  // TODO: componentize (this is copied from TimelineView)
  renderPromptEditor() {
    const { aiPrompt, previewPrompt } = this.state;

    if (!previewPrompt) return null;

    const preview = aiPrompt.buildAPIPrompt(aiPrompt.dataSourceAI);

    const text = _.map(preview.messages, ({role, content}) => `[${role}]: ${content}`).join('\n\n');

    const closeModal = () => this.setState({ previewPrompt: false });

    return (
      <Modal
        dialogClassName="timeline-prompt-editor"
        show={true}
        onHide={closeModal}
        // onMouseDown={this.stop}
        // onMouseUp={this.stop}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <span className="headline">Preview Prompt</span>
        </Modal.Header>

        <Modal.Body>
          <div className="wrapper">

            <FormControl
              componentClass="textarea"
              className="txt-prompt"
              value={text}
              disabled
              // onChange={(e) => this.setState({ aiPrompt: e.target.value })}
            />

            {/* <RJV
              theme="ocean"
              name="Messages"
              displayObjectSize={false}
              displayDataTypes={false}
              enableClipboard={false}
              src={preview.messages}
            /> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Done</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { list, variableIndex, user } = this.props;
    const { subType } = list;
    const { name, configDS, aiPrompt, previewPrompt, vsState } = this.state;
    const connection = this.connection;
    const isRepeater = subType === LIST_TYPES.REPEATER;
    const isList = subType === LIST_TYPES.LIST;
    const isAI = list.isAI;

    const linkedSectionsDisplayText = _.map(aiPrompt?.linkedSections, (id, index) => {
      const section = list.deal.sections[id];

      if (section?.displayname) return `(${index + 1}) ${section.displayname}`;
      else return `(${index + 1}) ${section?.displayBody.substring(0, 15)}...`;
    });

    const linkedVariables = _.get(aiPrompt, 'linkedVariables', '');

    const outputVariable = aiPrompt?.outputVariable ? list.deal.variables[aiPrompt.outputVariable] : null;

    return (
      <div className="list-sidebar" data-cy="list-sidebar">
        <DealPanelItem borderBottom className={`list-${subType}`}>
          <TooltipButton tip={this.titleTip}>
            <div className="property-title" data-cy="property-title-list">
              {FEATURE_LABEL[subType]}
            </div>
          </TooltipButton>

          <div className="item-label">Name</div>
          <FormGroup className="dmp-validator-container">
            <FormControl
              placeholder="e.g., Block 1"
              type="text"
              value={name}
              onChange={this.updateName}
              bsSize="small"
              data-cy="name-input"
            />
            <Validator
              validateEmpty
              value={name}
              validate={(n) => n && n.trim()}
              onResult={_.noop}
              validTip="Valid block name"
              invalidTip="Block name is required"
            />
          </FormGroup>

          {isRepeater && connection && (
            <>
              <div className="item-label">Connection</div>
              <Dropdown
                id="dd-repeater-connection"
                title={_.get(connection, 'service.name', 'Select')}
                onSelect={_.noop}
                size="small"
                block
                disabled //to be enabled once more than filevine is supported
              >
                <MenuItem>{_.get(connection, 'service.name')}</MenuItem>
              </Dropdown>

              <div className="item-label data-source">
                <span>Data source</span>
                {list.dataSource && (
                  <Button
                    dmpStyle="link"
                    size="small"
                    onClick={() => this.setState({ configDS: true })}
                    data-cy="btn-config-ds"
                  >
                    Configure
                  </Button>
                )}
              </div>
              <Dropdown
                id="dd-repeater-source"
                title={_.get(list, 'dataSource.displayName', 'Select')}
                onSelect={(dataSource) => this.updateSetting('dataSource', dataSource)}
                size="small"
                block
              >
                {_.map(this.collections, (collection, idx) => (
                  <MenuItem key={idx} eventKey={collection}>
                    {collection.displayName}
                  </MenuItem>
                ))}
                {list.dataSource && <MenuItem divider />}
                {list.dataSource && <MenuItem eventKey={null}>Clear</MenuItem>}
              </Dropdown>
            </>
          )}
          {!isRepeater && (
            <>
              {!isAI && (
                <>
                  <div className="item-label">Assigned to</div>
                  <PartySelector
                    id={`${list.id}-party-selector`}
                    size="small"
                    block
                    deal={list.deal}
                    assigned={list.assigned}
                    onSelect={(partyID) => this.updateSetting('assigned', partyID)}
                  />
                  <Checkbox
                    id="chk-list-required"
                    checked={list.required}
                    onChange={() => this.updateSetting('required', !list.required)}
                    className="item-label"
                  >
                    {subType === 'LIST' ? 'Require at least one list item' : 'Required'}
                  </Checkbox>
                </>
              )}
              {isAI && aiPrompt && (
                <>
                  <div className="item-label ai-engine">AI Engine</div>
                  <FormGroup>
                    <Dropdown
                      id="dd-ai-engine"
                      title={`${aiPrompt.engine.title} [${aiPrompt.engine.model}]`}
                      onSelect={(engine) => this.updateAIPromptConfig('engine', engine)}
                      size="small"
                      block
                    >
                      {_.map(AI_ENGINES, (engine, idx) => (
                        <MenuItem key={idx} eventKey={engine}>
                          {engine.title} [{engine.model}]
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </FormGroup>

                  {user.isAdmin && (
                    <>
                      <div className="item-label ai-engine">AI Prompt Template</div>
                      <FormGroup>
                        <Dropdown
                          id="dd-ai-block-type"
                          title={BLOCK_PROMPT_LABEL[aiPrompt.type]}
                          onSelect={(type) => this.updateAIPromptConfig('type', type)}
                          size="small"
                          block
                        >
                          {_.map(BLOCK_TYPE, (type, idx) => (
                            <MenuItem key={idx} eventKey={type}>
                              {BLOCK_PROMPT_LABEL[type]}
                            </MenuItem>
                          ))}
                        </Dropdown>
                      </FormGroup>{' '}
                    </>
                  )}

                  {/*
                    ES: I'm commenting this out because it's not actually used anywhere yet 
                    API.summarize only gets/returns the first option regardless of how many are generated

                  user.isAdmin && (
                    <>
                      <div className="item-label ai-engine">Response Options</div>
                      <FormGroup>
                        <Dropdown
                          id="dd-ai-response-options"
                          title={aiPrompt.responseOptions}
                          onSelect={(option) => this.updateAIPromptConfig('responseOptions', option)}
                          size="small"
                          block
                        >
                          {_.map(RESPONSE_OPTIONS, (option, idx) => (
                            <MenuItem key={idx} eventKey={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Dropdown>
                      </FormGroup>
                      </>
                    )  
                  */}

                  <div className="row-group ai-autorun">
                    <div className="item-label">Action Name</div>
                    <div className="spacer" />
                    <Switch
                      id="chk-ai-autorun"
                      checked={aiPrompt.autorun}
                      onChange={(e) => this.updateAIPromptConfig('autorun', e || null)}
                      size="small"
                    >
                      Autorun
                    </Switch>
                  </div>
                  <FormGroup>
                    <FormControl
                      placeholder="e.g., Generate"
                      value={aiPrompt.actionName}
                      onChange={(e) => this.updateAIPromptConfig('actionName', e.target.value)}
                      bsSize="small"
                      data-cy="AI-action-name"
                    />
                  </FormGroup>

                  <div className="item-label">Block-Specific Prompt</div>
                  <FormGroup>
                    <FormControl
                      placeholder="e.g., Summarize this content"
                      componentClass="textarea"
                      value={aiPrompt.userPromptText}
                      onChange={(e) => this.updatePrompt(e)}
                      bsSize="small"
                      data-cy="AI-instructions"
                    />
                  </FormGroup>
                                    
                  {/* 
                    ES: Commenting this out to in order to simplify
                    This text box should always only be the user-specific prompt
                    ie this is not the place to allow for System-level prompt editing

                  {aiPrompt.prompt.map(({ role, content, step, adminOnly }, index) => {
                    //The adminOnly -> isSuper due to changing fv requirements. We will change this to AI permission once we have time to implement.
                    if (!adminOnly || (adminOnly && user.isSuper))
                      return (
                        <React.Fragment key={`${role}-${index}`}>
                          <div className="item-label">{step}</div>
                          <FormGroup>
                            <FormControl
                              placeholder="e.g., Summarize this content"
                              componentClass="textarea"
                              value={content}
                              onChange={(e) => this.updatePrompt(e, index)}
                              bsSize="small"
                              data-cy="AI-instructions"
                              disabled={adminOnly}
                            />
                          </FormGroup>
                        </React.Fragment>
                      );
                  })} */}

                  <div className="item-label">Data Source Type</div>
                  <FormGroup>
                    <Dropdown
                      id="dd-ai-ds-type"
                      title={aiPrompt.dsType}
                      onSelect={(option) => this.updateAIPromptConfig('dsType', option)}
                      size="small"
                      block
                    >
                      {_.map(DATA_SOURCE_TYPES, (option, idx) => (
                        <MenuItem key={idx} eventKey={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </FormGroup>
                  
                  {aiPrompt?.dsType === DATA_SOURCE_TYPES.SECTIONS && 
                    <>
                      <div className="item-label">Source Sections ({linkedSectionsDisplayText.length})</div>
                      <FormGroup className="display">
                        <FormControl
                          bsSize="small"
                          componentClass="textarea"
                          disabled
                          value={linkedSectionsDisplayText.join('\n')}
                          placeholder="Select sections from left-hand side to feed into AI Prompt"
                          data-cy="AI-linked-sections"
                        />
                      </FormGroup>
                    </>
                  }
                  {aiPrompt?.dsType === DATA_SOURCE_TYPES.VARIABLES && 
                    <>
                      <div className="item-label">Source Variables ({aiPrompt.dataSourceVariables.length})</div>
                      <FormGroup className="display">
                        
                        <FormControl
                          ref={this.refVariablesDS}
                          componentClass="textarea"
                          bsSize="small"
                          type="text"
                          value={linkedVariables}
                          placeholder="Type '[' to select Variables to feed into AI Prompt"
                          onChange={(e) => this.updateAIPromptConfig('linkedVariables', e.target.value)}
                          onKeyDown={this.handleVS}
                          onKeyUp={this.updateVariableSuggest}
                          data-cy="linked-variables"
                        />

                        {vsState && (
                          <VariableSuggest
                            ref={this.refSuggest}
                            variableIndex={variableIndex}
                            deal={list.deal}
                            input={vsState.input}
                            onSelect={this.commitVariableSuggestion}
                            target={this.refVariablesDS}
                          />
                        )}
                      </FormGroup>
                    </>
                  }

                  <FormGroup className="display">
                    <Button
                      // dmpStyle="default"
                      size="small"
                      onClick={() => this.setState({previewPrompt: true})}
                      data-cy="btn-preview-prompt"
                    >
                      Preview Prompt
                    </Button>
                  </FormGroup>

                  <div className="item-label">Temperature</div>
                  <FormGroup className="display">
                    <input
                      style={{ padding: 0, marginRight: '5px' }}
                      type="range"
                      min={0.0}
                      max={1.0}
                      value={aiPrompt.temperature}
                      step={0.1}
                      onChange={(e) => this.updateAIPromptConfig('temperature', e.target.value)}
                    />
                    {aiPrompt.temperature}
                  </FormGroup>

                  <FormGroup>
                    <div className="row-group">
                      <div className="ai-response-type">
                        <div className="item-label">Output Target</div>
                        <Dropdown
                          id="dd-ai-response-type"
                          title={(_.find(RESPONSE_TYPES, {key: aiPrompt.responseType}) || RESPONSE_TYPES[0]).title}
                          onSelect={(key) => this.updateAIPromptConfig('responseType', key)}
                          size="small"
                          block
                          menuWidth={240}
                        >
                          {_.map(RESPONSE_TYPES, (option, idx) => (
                            <MenuItem key={idx} eventKey={option.key} info={option.description}>
                              {option.title}
                            </MenuItem>
                          ))}
                        </Dropdown>
                      </div>
                    
                      {aiPrompt?.responseType === 'variable' &&
                        <div className="ai-output-variable">
                          <div className="item-label">Select Variable</div>
                          <Dropdown
                            id="dd-ai-output-var"
                            title={outputVariable?.name || ''}
                            onSelect={(name) => this.updateAIPromptConfig('outputVariable', name)}
                            size="small"
                            block
                            pullRight
                            menuWidth={240}
                          >
                            {_.map(list.deal.simpleVariables, (v, idx) => (
                              <MenuItem key={idx} eventKey={v.name} info={v.displayName || null}>
                                {`[${v.type}${v.name}]`}
                              </MenuItem>
                            ))}
                          </Dropdown>
                        </div>
                      }
                      </div>
                  </FormGroup>

                </>
              )}
              {!isAI && (
                <Checkbox
                  id="chk-list-redline"
                  checked={list.redlining}
                  onChange={() => this.updateSetting('redlining', !list.redlining)}
                  className="item-label"
                >
                  Enable redlining
                </Checkbox>
              )}
              {isList && (
                <Checkbox
                  id="chk-list-clear"
                  checked={list.clearing}
                  onChange={() => this.updateSetting('clearing', !list.clearing)}
                  className="item-label"
                >
                  Enable users to clear entire {FEATURE_LABEL.LIST.toLowerCase()}
                </Checkbox>
              )}
              {isList && (
                <Checkbox
                  id="chk-list-titles"
                  checked={list.titles}
                  onChange={() => this.updateSetting('titles', !list.titles)}
                  className="item-label"
                >
                  Enable list item titles
                </Checkbox>
              )}
            </>
          )}
        </DealPanelItem>
        {isList && (
          <DealPanelItem borderBottom className="number-formats" data-cy="number-formats">
            <div className="property-title" data-cy="property-title-numbering">
              List item numbering
            </div>

            <TooltipButton
              tip="Delete contents in List title and/or body to enable"
              placement="left"
              disabled={this.enableContinuous}
            >
              <Switch
                id="chk-list-continuous"
                checked={list.continuous}
                onChange={() => (!this.enableContinuous ? null : this.updateSetting('continuous', !list.continuous))}
                className={cx('item-label', 'chk-continuous', { 'dmp-switch-disabled': !this.enableContinuous })}
                size="small"
              >
                Continuous numbering
              </Switch>
            </TooltipButton>

            {!list.continuous && (
              <Switch
                id="chk-nest-items"
                checked={list.indent}
                onChange={() => this.updateSetting('indent', !list.indent)}
                className="item-label chk-indent"
                size="small"
              >
                Indent list items
              </Switch>
            )}

            {!list.continuous && this.renderNumbering()}
          </DealPanelItem>
        )}

        <TableColumnManager
          show={configDS}
          dataSource={list.dataSource}
          variableIndex={variableIndex}
          close={() => this.setState({ configDS: false })}
        />

        {previewPrompt && this.renderPromptEditor()}
      </div>
    );
  }
}
