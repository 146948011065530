import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, Radio } from 'react-bootstrap';

import { rxVariableReplace } from '@core/models/Content';
import Deal from '@core/models/Deal';
import TableColumn from '@core/models/TableColumn';
import { ELEMENT_TYPES, ValueType, VariableType, isNumeric } from '@core/models/Variable';
import { getUniqueKey } from '@core/utils';

import { Ellipsis, Icon, MenuItem } from '@components/dmp';
import Button from '@components/dmp/Button';
import Dropdown from '@components/dmp/Dropdown';

import VariableIndex, { generatePropertyOptions } from '@root/utils/VariableIndex';

const OPTION_TYPE = {
  ELEMENT_TYPES: 'elementTypes',
  VARIABLES: 'variables',
  SECRETS: 'secrets',
  CONNECTED: 'connected',
  PARTIES: 'parties',
  DATES: 'dates',
  PARTY_FIELDS: 'partyFields',
  VAR_FIELDS: 'varFields',
  COLLECTION_FIELDS: 'collectionFields',
  FOOTNOTES: 'footnotes',
  CALCULATED: 'calculated',
};

const CONNECT_TYPE_ICONS = {
  filevine: 'logoFilevine',
};

export function getMenuOptions(deal) {
  const items = [];
  const { filterableVariables, parties } = deal;

  if (filterableVariables.length > 0) {
    items.push(
      <MenuItem header key="header-vars">
        Variable fields
      </MenuItem>
    );
  }

  _.forEach(filterableVariables, (variable) => {
    items.push(
      <MenuItem key={variable.name} eventKey={variable} ellipsis>
        {variable.displayName || variable.name}
      </MenuItem>
    );
  });

  if (filterableVariables.length > 0 && parties.length > 0) {
    items.push(<MenuItem divider key="div-vars" />);
  }

  const partyProps = ['fullName', 'email', 'org', 'title', 'address', 'phone'];

  _.forEach(parties, (party, idx) => {
    items.push(
      <MenuItem header key={party.partyID}>
        {party.displayName} fields
      </MenuItem>
    );
    _.forEach(partyProps, (prop) => {
      const key = `${party.partyID}.${prop}`;
      const variable = party.generatePartyVariable(prop);
      items.push(
        <MenuItem key={key} eventKey={variable}>
          {variable.displayName}
        </MenuItem>
      );
    });

    if (idx + 1 < parties.length) {
      items.push(<MenuItem divider key={`div-parties-${idx}`} />);
    }
  });

  return items;
}

@autoBindMethods
export default class VariableSuggest extends Component {
  static defaultProps = {
    variableTypes: [
      VariableType.SIMPLE,
      VariableType.CALCULATED,
      VariableType.PARTY,
      VariableType.PROTECTED,
      VariableType.CONNECTED,
      VariableType.FOOTNOTE,
      VariableType.PAGE_COUNT,
    ],
    minFilter: 2,
    limit: null,
    isFormula: false,
  };

  static propTypes = {
    variableTypes: PropTypes.array,
    externalType: PropTypes.string,
    group: PropTypes.string,
    input: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    deal: PropTypes.instanceOf(Deal).isRequired,
    target: PropTypes.object,
    minFilter: PropTypes.number,
    variableIndex: PropTypes.instanceOf(VariableIndex),
    limit: PropTypes.number,
    isFormula: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      optionType: '',
      activeIndex: -1,
      footnoteIndex: -1,
      footnoteValue: null,
      linking: false,
    };
  }

  componentDidMount() {
    this.generateOptions(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.input !== this.props.input) {
      this.generateOptions(this.props);
    }
    if (prevState.linking !== this.state.linking) {
      this.generateOptions(this.props);
    }
  }

  get footnoteName() {
    return `[${VariableType.FOOTNOTE}ft-${getUniqueKey()}]`;
  }

  generateOptions(props) {
    const { input, variableTypes, minFilter, variableIndex, limit, deal, group, isFormula, section } = props;
    const { linking } = this.state;
    let options = [];

    // No input, do nothing
    if (!input) return;

    if (input === '[') {
      _.forEach(variableTypes, (type) => {
        const elType = _.find(ELEMENT_TYPES, { type });
        if (elType) {
          // Special case for CONNECTED; don't show it as an option if the deal isn't connected!
          if (elType.type === VariableType.CONNECTED && !deal.isConnected) return;
          if (elType.type === VariableType.PAGE_COUNT && section && !section.isTemplateHeaderFooter) return;
          options.push(elType);
        }
      });

      this.setState({
        options,
        optionType: OPTION_TYPE.ELEMENT_TYPES,
        activeIndex: 0,
      });
      return;
    }

    if (input[0] === '{') {
      // If there's no group specified in props, it means the repeater's data source isn't configured yet
      // So this check is necessary to avoid suggesting random special var definitions that have no group

      if (group) {
        // If we're in formula mode and we have a group specified, that group is a table variable
        // and we're using the { } syntax to lookup numeric columns within that table
        if (isFormula) {
          const table = deal.variables[group];
          if (table) {
            options = _.filter(table.columns, (col) => isNumeric(col.valueType));
          }
        }
        // Otherwise, this is for repeater fields which also use { } syntax and is also searching TableColumns
        // Start with the list of available collection fields with all Contact properties flattened into distinct fields
        else {
          options = variableIndex.flattenPropertyOptions(group);
          options = _.map(options, (field) => TableColumn.fromConnectVariable(field));
        }

        // If we have text after the {, further filter properties with simple Regex
        if (input.length > 1) {
          options = _.filter(
            options,
            ({ id = '', displayName = '' }) => `${id} | ${displayName}`.search(new RegExp(input.slice(1), 'i')) > -1
          );
        }
      }

      this.setState({
        options,
        optionType: OPTION_TYPE.COLLECTION_FIELDS,
        activeIndex: 0,
      });
      return;
    }

    // Normal case, we have an input of at least 2 chars or more, so we've got an opening [ bracket and a varType
    const varType = input[1];
    let tag = varType;
    const [varName, varProperty = ''] = input.substr(2).split('.');
    const exactMatch = !!varName && !!variableIndex.contains(varName);
    let baseOption, propertyOptions, optionType;

    if (varType === VariableType.FOOTNOTE) {
      const footnotes = _.filter(deal.variables, ({ value, type }) => {
        return type === VariableType.FOOTNOTE && value;
      });

      options = [...footnotes];
      const footnoteIndex = options.length === 1 && linking ? 0 : -1;
      const footnoteValue = options.length === 1 && linking ? options[0].value : null;

      this.setState({
        options,
        optionType: OPTION_TYPE.FOOTNOTES,
        footnoteIndex,
        footnoteValue,
      });
      return;
    }

    // No exact Variable match; search our VariableIndex based on input to know what to render
    // TODO: recursive property generation isn't working because the property was generated on the fly
    // and is not in the variable index...
    if (!exactMatch) {
      const searchString = varName && varName.length >= minFilter ? varName : '';
      const results = variableIndex.search(searchString, { index: 'searchName', enrich: true, tag, limit });
      const matches = results[0]?.result || [];

      // Add all matches to options set
      _.forEach(matches, ({ doc }) => {
        if (!doc) return;

        const relatedVar = deal.variables[doc.id.split('.')[0]];
        const tableTotalsAvailable =
          relatedVar?.valueType === ValueType.TABLE && relatedVar?.totalEligibleColumns.length > 0;

        // For formula var suggest, we also want to filter out non-numeric variables (e.g. strings, dates etc)
        // But we DO want to include table options if they have 1 or more summable columns
        if (
          isFormula &&
          [VariableType.SIMPLE, VariableType.CONNECTED].includes(varType) &&
          !isNumeric(doc.valueType) &&
          !tableTotalsAvailable
        ) {
          return;
        }

        options.push(doc);
      });

      // Assuming we have a query, we know here that we DON'T have an exact existing match on query
      // So add an option to create a new one, as long as it's not a connected var (those are only defined externally)
      if (varName && varType !== VariableType.CONNECTED) {
        options.push({
          isNew: true,
          type: varType,
          value: varName,
          disabled: exactMatch,
        });
      }

      optionType =
        varType === VariableType.PROTECTED
          ? OPTION_TYPE.SECRETS
          : varType === VariableType.PARTY
          ? OPTION_TYPE.PARTIES
          : varType === VariableType.CALCULATED
          ? OPTION_TYPE.CALCULATED
          : OPTION_TYPE.VARIABLES;

      this.setState({
        options,
        optionType,
        activeIndex: options.length > 0 ? 0 : -1,
      });
      return;
    }

    // Here we do have an exact 1:1 match; see if this option type has sub-properties that need to be displayed
    baseOption = variableIndex.get(varName);
    const isTable = baseOption.valueType === ValueType.TABLE;
    const arg = isTable && deal.variables[baseOption.id] ? deal.variables[baseOption.id] : baseOption;

    // Properties are not allowed in formulas (unless they are table columns)
    if (isFormula && !isTable) {
      propertyOptions = [];
    } else {
      // Second arg here will enable the default table (no column) to be entered in SectionEditor context,
      // but not during formula entry -- which is what we want
      propertyOptions = generatePropertyOptions(arg, !isFormula);
    }

    if (propertyOptions.length > 0) {
      options = propertyOptions;
      // If we have text after the dot, do a simple regex on the available properties
      if (varProperty) {
        options = _.filter(options, ({ searchName }) => searchName?.search(new RegExp(varProperty, 'i')) > -1);
      }
    } else {
      options = [baseOption];
    }

    optionType = [ValueType.CONTACT, ValueType.PROJECT, ValueType.ADDRESS].includes(baseOption.valueType)
      ? OPTION_TYPE.VARIABLES
      : varType === VariableType.PARTY
      ? OPTION_TYPE.PARTY_FIELDS
      : propertyOptions.length > 0
      ? OPTION_TYPE.VAR_FIELDS
      : varType === VariableType.CALCULATED
      ? OPTION_TYPE.CALCULATED
      : OPTION_TYPE.VARIABLES;

    this.setState({
      options,
      optionType,
      activeIndex: options.length > 0 ? 0 : -1,
    });
  }

  handleKey(e) {
    const { activeIndex, options } = this.state;
    const enabledOptions = _.filter(options, (opt) => !opt.disabled);

    switch (e.key) {
      case 'ArrowUp':
        this.setState({ activeIndex: activeIndex <= 0 ? enabledOptions.length - 1 : activeIndex - 1 });
        return true;
      case 'ArrowDown':
        this.setState({ activeIndex: activeIndex + 1 >= enabledOptions.length ? 0 : activeIndex + 1 });
        return true;
      case 'Tab':
      case 'Enter':
      // Manually closing the variable text with ] (or } for fields) should also complete the currently selected var
      // This is especially important for connected vars, where selection triggers a Fire call
      // See SectionEditor.commitSuggestion
      case ']':
      case '}':
        this.selectOption(activeIndex);
        return true;
      default:
        return false;
    }
  }

  selectOption(idx) {
    const { onSelect, variableIndex, isFormula, input } = this.props;
    const { options, activeIndex, optionType } = this.state;

    if (idx + 1 > options.length || activeIndex === -1) return;

    const option = options[idx];

    // Leave here commented, useful for debug
    // console.log(option, variableIndex.contains(option.id), optionType);

    if (option.isNew) {
      return onSelect(`[${option.type}${option.value}] `, option);
    }

    // TABLE vars can be either SIMPLE or CONNECTED, and with the release of table totals, we now need to select a property,
    // so we need this special case to come before the switch below
    if (option.valueType === ValueType.TABLE) {
      if (!input.includes('.')) {
        onSelect(`[${option.type}${option.name}.`, option);
      } else {
        onSelect(`[${option.type}${option.name}] `, option);
      }
      return;
    }

    switch (optionType) {
      case OPTION_TYPE.ELEMENT_TYPES:
        if (option.type === VariableType.PAGE_COUNT) {
          //close call its just a placeholder configured on draft and pdf generation
          onSelect(`[${option.type}PageCount]`, option);
        } else {
          onSelect(`[${option.type}`, option);
        }
        break;
      case OPTION_TYPE.VARIABLES:
        // Several variable types have sub-property options to choose from, so don't complete the selection
        if (
          !isFormula &&
          [
            ValueType.DATE,
            ValueType.NUMBER,
            ValueType.CURRENCY,
            ValueType.CONTACT,
            ValueType.PROJECT,
            ValueType.ADDRESS,
          ].includes(option.valueType)
        ) {
          // Sub-properties are now added on the fly in generateOptions() after their "parent" option is selected
          // But properties of Projects and Contacts are actually treated as distinct variables
          // So if we are selecting a variable that is not yet in the index, we need to add it "just in time"
          // So that we'll hit the "exactMatch" case below and recursively generate sub-properties
          // Example:
          // 1. user selects 'fv_project' variable
          // 2. we see that has properties and generate them dynamically, one of which is the client
          // 3. user selects 'fv_project_client'
          // 4. we see that has properties and generate them dynamically
          // 5. finally, user selects 'fv_client_firstName' --> it has no properties and selection is finally committed
          if (!variableIndex.contains(option.id)) {
            variableIndex.add(option);
          }
          onSelect(`[${option.type}${option.name}.`, option);
        }
        // Normal case; selected variable has no sub-properties, so just commit the selection
        else {
          onSelect(`[${option.type}${option.name}] `, option);
        }
        break;
      case OPTION_TYPE.SECRETS:
        onSelect(`[*${option.name}] `, option);
        break;
      case OPTION_TYPE.CALCULATED:
        if (option.valueType === ValueType.TABLE) {
          onSelect(`[${option.type}${option.name}.`, option);
        } else {
          onSelect(`[%${option.name}] `, option);
        }
        break;
      case OPTION_TYPE.PARTIES:
        onSelect(`[@${option.name}.`, option);
        break;
      case OPTION_TYPE.PARTY_FIELDS:
        onSelect(`[@${option.name}] `, option);
        break;
      case OPTION_TYPE.VAR_FIELDS:
        onSelect(`[${option.type}${option.name}] `, option);
        break;
      case OPTION_TYPE.COLLECTION_FIELDS:
        onSelect(`{${option.id}} `, option);
        break;
      case OPTION_TYPE.FOOTNOTES:
        onSelect(`[${option.type}${option.name}] `, option);
        break;
      default:
        return;
    }
  }

  renderOptions() {
    const { limit, variableIndex } = this.props;
    const { activeIndex, options, optionType } = this.state;

    let elOptions = [];
    switch (optionType) {
      case OPTION_TYPE.ELEMENT_TYPES:
        elOptions = _.map(options, (elType, idx) => (
          <div
            key={idx}
            className={cx('option', 'el-type', { active: activeIndex === idx })}
            onClick={() => this.selectOption(idx)}
            onMouseEnter={() => this.setState({ activeIndex: idx })}
            data-cy="el-options"
          >
            <Icon name={elType.icon} />
            <span className="el-title" data-cy="el-title">
              {elType.title}
            </span>
            <span className="el-char">{elType.type}</span>
          </div>
        ));
        break;
      case OPTION_TYPE.VARIABLES:
      case OPTION_TYPE.SECRETS:
      case OPTION_TYPE.CALCULATED:
        elOptions = _.map(options, (option, idx) => {
          if (option.isNew) {
            return this.renderNewOption(option, 'variable', idx);
          }

          let icon = null;
          if (option.connectType && CONNECT_TYPE_ICONS[option.connectType]) {
            icon = <Icon size="default" className="connect-type" name={CONNECT_TYPE_ICONS[option.connectType]} />;
          }

          const className = cx('option', 'var-select', { active: activeIndex === idx }, { 'option-icon': !!icon });

          return (
            <div
              key={idx}
              className={className}
              onClick={() => this.selectOption(idx)}
              onMouseEnter={() => this.setState({ activeIndex: idx })}
            >
              <div className="var-topline">
                <div className="var-name">
                  <Ellipsis>
                    {option.type}
                    {option.name}
                  </Ellipsis>
                </div>
                {!option.connectType && <div className="var-type">({option.valueType || ValueType.STRING})</div>}
              </div>
              <div className="var-display-name">{option.displayName || option.name}</div>
              {icon}
            </div>
          );
        });
        break;

      case OPTION_TYPE.PARTIES:
        elOptions = _.map(options, (option, idx) => {
          if (option.isNew) {
            return this.renderNewOption(option, 'party', idx);
          }

          return (
            <div
              key={idx}
              className={cx('option', 'var-select', { active: activeIndex === idx })}
              onClick={() => this.selectOption(idx)}
              onMouseEnter={() => this.setState({ activeIndex: idx })}
            >
              <div className="var-topline">
                <div className="var-name">@{option.name}</div>
              </div>
              <div className="var-display-name" data-cy="var-display-name">
                {option.displayName}
              </div>
            </div>
          );
        });
        break;

      case OPTION_TYPE.PARTY_FIELDS:
      case OPTION_TYPE.VAR_FIELDS:
      case OPTION_TYPE.CONTACT_FIELDS:
        elOptions = _.map(options, (field, idx) => {
          return (
            <div
              key={idx}
              className={cx('option', 'var-select', { active: activeIndex === idx })}
              onClick={() => this.selectOption(idx)}
              onMouseEnter={() => this.setState({ activeIndex: idx })}
            >
              <div className="var-topline">
                <div className="var-name">{field.label}</div>
              </div>
              <div className="var-display-name">
                {field.valueType === ValueType.TABLE ? field.tableDisplayName : field.displayName}
              </div>
            </div>
          );
        });

        break;
      case OPTION_TYPE.COLLECTION_FIELDS:
        if (options.length === 0)
          return <div className="option var-select collection-field disabled">No variables found</div>;
        elOptions = _.map(options, (field, idx) => {
          return (
            <div
              key={idx}
              className={cx('option', 'var-select', 'collection-field', { active: activeIndex === idx })}
              onClick={() => this.selectOption(idx)}
              onMouseEnter={() => this.setState({ activeIndex: idx })}
            >
              <div className="var-topline">
                <div className="var-name">{`{${field.id?.replace('_null', '')}}`}</div>
              </div>
              <div className="var-display-name">{field.displayName}</div>
            </div>
          );
        });
        break;
      case OPTION_TYPE.FOOTNOTES:
        return this.footnoteSelection();
      default:
        break;
    }

    const topElOptions = [];
    if (limit && optionType === OPTION_TYPE.VARIABLES) {
      topElOptions.push(
        <div key="info" className="option option-info">
          <div>Type to filter variables</div>
          <div>{variableIndex.count(VariableType.SIMPLE)} indexed</div>
        </div>
      );
    }

    return [...topElOptions, ...elOptions];
  }

  footnoteSelection() {
    const { onSelect } = this.props;
    const { options, footnoteIndex, footnoteValue, linking } = this.state;

    const title = footnoteIndex === -1 ? 'Select footnote' : options[footnoteIndex].value;
    const disabled = footnoteIndex !== -1;

    return (
      <div className="footnote">
        <div className="header">Footnote</div>
        <div className="body">
          {options.length > 0 && (
            <>
              <Radio
                checked={!linking}
                name="isTeamFilter"
                onChange={() => this.setState({ linking: false, footnoteValue: null, footnoteIndex: -1 })}
                value={!linking}
                data-cy="unlink-ft-radio"
              >
                Add new
              </Radio>
              <Radio
                checked={linking}
                name="isTeamFilter"
                onChange={() => this.setState({ linking: true })}
                value={linking}
                data-cy="link-ft-radio"
                size="small"
              >
                Select existing
              </Radio>
            </>
          )}
          {linking && (
            <div className="footnote-dd-container">
              <Dropdown
                id="dd-footnote-selector"
                onSelect={(idx) => this.setState({ footnoteIndex: idx, footnoteValue: options[idx].value })}
                title={title}
                pullRight
                block
                size="small"
                className="footnote-selector"
                data-cy="footnote-selector"
                disabled={options.length === 1}
              >
                {_.map(options, (option, idx) => {
                  return (
                    <MenuItem key={idx} eventKey={idx} active={idx === footnoteIndex} className="footnote-options">
                      <Ellipsis>{option.value}</Ellipsis>
                    </MenuItem>
                  );
                })}
              </Dropdown>
            </div>
          )}
          {!linking && (
            <FormControl
              bsSize="small"
              type="text"
              className="variable-value"
              componentClass="textarea"
              placeholder="Enter Footnote"
              value={footnoteValue}
              onChange={(e) => this.setState({ footnoteValue: e.target.value })}
              data-cy="variable-editor-value"
              disabled={disabled}
            />
          )}
        </div>
        <div className="footer">
          <Button
            className="right"
            dmpStyle="link"
            size="small"
            onClick={() => {
              this.setState({ footnoteValue: null, footnoteIndex: -1 });
              onSelect('[', {});
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={this.confirmFootnote}
            className="right"
            data-cy="confirm-footnote"
            disabled={!footnoteValue}
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  }

  async confirmFootnote() {
    const { onSelect, deal } = this.props;
    const { footnoteIndex, footnoteValue } = this.state;
    if (footnoteIndex !== -1) {
      this.selectOption(footnoteIndex);
    } else {
      const editorText = this.footnoteName;
      const name = editorText.replace(rxVariableReplace, '').split('.')[0];
      const footnote = { type: editorText[1], value: footnoteValue, name };
      onSelect(editorText, footnote);
    }
  }

  renderNewOption(option, type, idx) {
    const { activeIndex } = this.state;
    return (
      <div
        key="add-new"
        className={cx(
          'option',
          'add-new',
          { active: activeIndex === idx && !option.disabled },
          { disabled: option.disabled }
        )}
        onClick={() => (!option.disabled ? this.selectOption(idx) : null)}
        onMouseEnter={() => (!option.disabled ? this.setState({ activeIndex: idx }) : null)}
        data-cy="add-new"
      >
        Add new {type}
      </div>
    );
  }

  //If var type is specified, we now show instructions at top of suggestions
  //This fixes the awkward empty box issue if JUST the type is specified but nothing else, e.g., "[+"
  renderInstructions() {
    const { input } = this.props;
    const { optionType } = this.state;

    switch (optionType) {
      case OPTION_TYPE.CONNECTED:
      case OPTION_TYPE.PARTIES:
      case OPTION_TYPE.VARIABLES:
      case OPTION_TYPE.CALCULATED:
        const char = _.get(input, '[1]', VariableType.SIMPLE);
        const elType = _.find(ELEMENT_TYPES, { type: char }) || _.find(ELEMENT_TYPES, { type: VariableType.SIMPLE });
        const typeName = _.get(elType, 'title', 'variable');
        return (
          <div key="vs-instructions" className={cx('option', 'vs-instructions', 'active', 'disabled')}>
            Type {typeName} element name to filter
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const { target } = this.props;

    const style = {
      position: 'absolute',
      top: _.get(target, 'offsetTop', 0) + 20,
      left: _.get(target, 'offsetLeft', 0),
    };

    return (
      <div className="variable-suggest" style={style} data-cy="var-select">
        <div className="options" data-cy="variable-options">
          {this.renderInstructions()}
          {this.renderOptions()}
        </div>
      </div>
    );
  }
}
