import React, { Component } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

const TYPES = {
  logo: { name: 'LOGO', max_size: 50000 },
  letterhead: { name: 'LETTERHEAD', max_size: 500000 },
  watermark: { name: 'WATERMARK', max_size: 500000 }, //TODO: What is the right max size here?
};

export default class LogoUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      img: null,
      error: null,
      type: 'logo',
    };
  }

  //http://stackoverflow.com/questions/17710147/image-convert-to-base64
  readBase64(file) {
    const reader = new FileReader();

    const onUpload = (image) => this.props.onUpload(image);

    reader.onload = (e) => onUpload(e.target.result);
    reader.onerror = () => onUpload(null);
    reader.readAsDataURL(file);
  }

  onDropAccepted(files) {
    if (files.length > 0) {
      this.setState({ files, error: null });
      this.readBase64(files[0]);
    }
  }
  onDropRejected(rejected) {
    const { type } = this.props;

    if (rejected.length > 0) {
      const file = rejected[0];
      if (file.size > TYPES[type].max_size) {
        this.setState({ error: 'File is too large.' });
      } else if (file.type.split('/')[0].toLowerCase() != 'image') {
        this.setState({ error: 'File must be a valid image.' });
      }
    }

    this.props.onUpload(null);
  }

  remove(e) {
    e.stopPropagation();
    this.props.onUpload(null);
    this.setState({ error: null });
  }

  render() {
    const { error } = this.state;
    const { img, type } = this.props;
    const maxSize = TYPES[type].max_size;
    const disabled = img !== null;
    //https://github.com/okonet/react-dropzone
    return (
      <Dropzone
        className="contents logo-uploader"
        activeClassName="logo-uploader active"
        maxSize={maxSize}
        accept="image/png,image/jpeg"
        disableClick={disabled}
        onDropAccepted={(a) => this.onDropAccepted(a)}
        onDropRejected={(r) => this.onDropRejected(r)}
        data-cy="logo-uploader"
      >
        <div className="logo-uploader-inner">
          {img ? (
            <div className="preview">
              <img src={img} />
            </div>
          ) : (
            <div className="upload">
              <div className="hit-area" />
              <div className="lighter">
                <h4>Upload {type}</h4>
                <small>
                  Drag and drop a {type} or tap to browse
                  <br />
                  PNG max {maxSize / 1000}KB
                </small>
                {this.state.error && <small className="text-danger">{error}</small>}
              </div>
            </div>
          )}
          {img && (
            <span className="remove-logo" onClick={(e) => this.remove(e)}>
              ×
            </span>
          )}
        </div>
      </Dropzone>
    );
  }
}

LogoUploader.propTypes = {
  type: PropTypes.oneOf(Object.keys(TYPES)).isRequired,
};

LogoUploader.defaultProps = {
  type: 'logo',
};
