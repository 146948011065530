import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DealAction from '@core/enums/DealAction';
import List from '@core/models/List';
import { NUMBER_INDENT } from '@core/models/TypeStyle';

import { Loader } from '@components/dmp';

import ColorLabel from '@components/ColorLabel';
import DataSourceBrowser from '@components/deal/DataSourceBrowser';
import ContentSection from '@components/section_types/ContentSection';
import { measure } from '@components/section_types/SectionMeasurer';
import AIBlockRunner from '@core/utils/AIBlockRunner';
import Fire from '@root/Fire';
import trackEvent from '@utils/EventTracking';

@autoBindMethods
export default class ListSection extends Component {
  // ListSection basically wraps ContentSection with some modified UI elements,
  // so has all the same props that we'll need to pass to child instances
  static propTypes = _.merge({}, _.cloneDeep(ContentSection.propTypes), {
    section: PropTypes.instanceOf(List).isRequired,
  });

  static defaultProps = _.cloneDeep(ContentSection.defaultProps);

  constructor(props) {
    super(props);
    this.childRefs = {};
    this.state = {
      selecting: false,
      reselect: false,
    };
    this.sectionRefs = {};
    this.refSelf = createRef();
  }

  componentDidUpdate(prevProps) {
    measure(this);
  }

  async runBlock() {
    const { section, user } = this.props;
    const { aiPrompt, deal } = section;

    await this.setState({ selectedBlockIndex: -1, results: [], error: null });

    await AIBlockRunner.runBlock(section, user);

    const eventData = {
      serviceType: `${_.upperFirst(aiPrompt.type)} AI Block Preview`, //should eventually be enumerated somewhere for additional types
      docID: deal.dealID,
      user: user.email,
      teamID: deal.team,
      engine: aiPrompt.engine.key,
      isTemplate: deal.isTemplate,
      template: deal.info.sourceTemplate,
    };
    await trackEvent('ListSectionSummarize', eventData);
    await this.setState({ selecting: false, reselect: false });
  }

  onCreate(newSectionID) {
    const newSection = _.get(this.childRefs[newSectionID], 'current');
    if (newSection) newSection.focus();
  }

  async addItem(section, atIndex) {
    let newSectionID = null;
    newSectionID = await Fire.addItemSection(section, atIndex);
    this.onCreate(newSectionID);
  }

  async handleDSItems(ds, items) {
    const { section: list, user } = this.props;

    if (list) {
      await Fire.clearList(list);
      const master = list || list.appendix;
      if (master) {
        Fire.addActivity(master, user, DealAction.CLEAR_LIST);
      }
    }

    const sections = _.map(items, (item) => list.generateItem(item));
    if (sections.length > 0) {
      await Fire.addItemBatch(list, sections, null, true);
    }
    await this.setState({ selecting: false, reselect: false });
  }

  handleKey(e, item) {
    switch (e.key) {
      case 'Enter':
        if (e.shiftKey) return false;
        // Only handle enter as adding items if it's a list
        if (_.get(item, 'list.subType') === 'LIST') {
          this.addItem(item.parent, item.sibs.indexOf(item) + 1);
          return true;
        } else {
          return false;
        }
      case 'Tab':
        Fire.moveSection(item, e.shiftKey ? 'left' : 'right');
        return true;
      default:
        return false;
    }
  }

  renderItem(item, idx) {
    const { section: list } = this.props;

    if (!this.childRefs[item.id]) this.childRefs[item.id] = createRef();
    const props = _.merge({}, this.props, {
      section: item,
      editableTitle: list.titles,
      sourceMode: true,
      editable: list.can('edit'),
      hideMenu: !list.can('edit'),
    });

    return (
      <ContentSection
        {...props}
        key={idx}
        ref={this.childRefs[item.id]}
        onCreate={this.onCreate}
        reselectListContent={() => this.setState({ selecting: true, reselect: true })}
        titleKeyHandler={this.handleKey}
        bodyKeyHandler={this.handleKey}
        setQueueFocus={this.onCreate}
      />
    );
  }

  // This is a bit annoying but we need to manually align the "Add item" link style with the list's first-level children
  // That comes from the theme (instead of css) so we need to manually build it here too
  // Also we apply bottom section margin to the button, which is necessary in the event that the list has no items
  get addItemStyle() {
    const { section: list, overviewMode } = this.props;

    const style = {};
    let indent = overviewMode ? 0 : _.get(list, 'webLayout.paddingLeft', 0);

    if (overviewMode) {
      if (list.parent.showOrder) indent += NUMBER_INDENT;
    } else {
      // Add padding if the list itself is numbered
      if (list.showOrder) indent += NUMBER_INDENT;
      // And add more if the list is configured to indent its children (defaults to true)
      if (list.indent) indent += NUMBER_INDENT;
    }

    style.marginLeft = indent;

    // Somewhat of a hack, but empty lists will still render an empty ContentSection
    // But we want the "Add item" link to appear in line with that even though it's technically rendered by this component
    // so setting a negative top on it pulls it up
    if (list.isNumberPlaceholder && !list.isRepeater) {
      style.top = -list.webLayout.marginBottom;
    } else {
      style.marginBottom = list.webLayout.marginBottom;
    }

    return style;
  }

  get isEditing() {
    const focused = _.find(this.childRefs, (ref) => !!_.get(ref, 'current.focused'));
    return !!focused;
  }

  // Whether to show top-level list content (title/body)
  get showListContent() {
    const { section: list, overviewMode } = this.props;

    // Repeater content is actually a template for its children so never show
    if (overviewMode || list.isRepeater) return false;

    // If there is no content, and it's not a placeholder, don't show
    if (!list.currentVersion.hasContent && !list.isNumberPlaceholder) return false;

    // Timeline sections are rendered via 3rd party library (not in react)
    if (list.isTimeline) return false;

    // If we get here, we've got real content to show
    return true;
  }

  render() {
    const { section: list, overviewMode } = this.props;
    const { selecting, reselect } = this.state;
    const dsName = _.get(list, 'dataSource.displayName', '');

    // Repeaters with no data source setup are invalid -- render nothing
    if (list.isRepeater && !list.dataSource) return null;

    // Don't show an empty placeholder section for lists that aren't editable by current user
    if (list.isNumberPlaceholder && !list.can('edit')) return null;

    // WIP, still determining exact scenarios for when an AI Block (or Timeline?) should render children
    const aiRenderList = list.isAI && _.get(list, 'aiPrompt.responseType') === 'list';

    // Don't render Timeline sections in Flow
    // TODO: determine if this is needed here, or whether we remove Timeline sections from source (buildSource)
    // (the latter would be cleaner/preferable, but then we'll need to figure out where to configure them in Draft)
    if (list.isTimeline) return null;

    const canReviseAI = list.can('reviseAI');

    return (
      <div className="list-section" data-cy="list-section" ref={this.refSelf}>
        {/*
          First render the parent (list-level) content, if there is any
          In Flow, the parent itself is uneditable/uncommentable
          REPEATERs have no titles and use the body for the template, so don't render that!
         */}
        {this.showListContent && (
          <ContentSection
            {...this.props}
            sourceMode
            indent
            noActivity={!canReviseAI}
            readonly={!canReviseAI}
            hideMenu={!canReviseAI}
          />
        )}

        {/*
          Now render the list's children. Note, list.items "flattens" ALL children even if the list is hierarchical
          So this will render all of them at appropriate indentLevels
        */}
        {(!list.isAI || aiRenderList) && (
          <div className="items-container" data-cy="items-container">
            {_.map(list.items, this.renderItem)}
          </div>
        )}
        {list.can('add') && !this.isEditing && !['REPEATER', 'AI', 'TIMELINE'].includes(list.subType) && (
          <div
            className="add-item"
            data-cy="add-item"
            onClick={() => this.addItem(list, list.children.length)}
            style={this.addItemStyle}
          >
            <span>
              {list.subType === 'LIST' ? 'Add item' : 'Enter content'} {list.isEmpty && list.required && '(required)'}
            </span>
            {list.isEmpty && list.required && !overviewMode && !list.isNumberPlaceholder && (
              <div className="marker">
                <ColorLabel status="todo" right label=" " />
              </div>
            )}
          </div>
        )}
        {list.can('generate') && !this.isEditing && list.isEmpty && (
          <div
            className="browse-items"
            data-cy="generate"
            onClick={() => this.setState({ selecting: true })}
            style={this.addItemStyle}
          >
            Select {dsName}
          </div>
        )}
        {list.can('ai') && !this.isEditing && !list.aiPrompt?.isRunning && (
          <div className="add-item" data-cy="summarize" onClick={this.runBlock} style={this.addItemStyle}>
            {list.aiPrompt?.actionName || list.actionName}
          </div>
        )}
        {list.aiPrompt?.isRunning && <Loader />}
        {selecting && (
          <DataSourceBrowser
            deal={list.deal}
            show={true}
            multiselect
            variable={list.dataSource}
            onHide={() => this.setState({ selecting: false, reselect: false })}
            onSelect={this.handleDSItems}
            headlineAction={reselect ? 'Re-select' : null}
          />
        )}
      </div>
    );
  }
}
