import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';

import { FEATURES } from '@core/models/Team';

import { Button, Checkbox, Key } from '@components/dmp';

const EDITABLE_FEATURES = _.filter(FEATURES, { isAdminEditable: true });

@autoBindMethods
export default class TeamEditor extends Component {
  static propTypes = {
    team: PropTypes.object,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
  };

  constructor(props) {
    const { team } = props;
    super(props);

    this.state = {
      features: team.features || [],
    };
  }

  componentDidUpdate(prevProps) {
    const { team } = this.props;

    if (!_.isEqual(team, prevProps.team)) {
      this.populate(team);
    }
  }

  populate(team) {
    this.setState({ features: team.features || [] });
  }

  async updateTeam() {
    const { onSave, team } = this.props;

    await API.call('updateTeam', { teamID: team.teamID, features: this.featureChanges });

    onSave();
  }

  toggleFeature(key) {
    const { features } = this.state;
    this.setState({ features: { ...features, [key]: !features[key] } });
  }

  get featureChanges() {
    const { team } = this.props;
    const features = {};
    _.forEach(FEATURES, (feature) => {
      if (this.state.features[feature.key] !== !!team.features[feature.key]) {
        features[feature.key] = this.state.features[feature.key];
      }
    });

    return features;
  }

  render() {
    const { team, onHide } = this.props;
    const disabled = _.size(this.featureChanges) === 0;

    return (
      <Modal dialogClassName="team-editor" onHide={onHide} show>
        <Modal.Header closeButton>
          <span className="headline">Update team</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <FormGroup>
              <ControlLabel>Team</ControlLabel>
              <div className="contents team" data-cy="contents-team">
                <p className="name">{team.name}</p>
                <Key>{team.teamID}</Key>
              </div>
            </FormGroup>
            <hr />
            <FormGroup>
              <ControlLabel>Features</ControlLabel>
              <div className="contents" data-cy="contents-features">
                {_.map(EDITABLE_FEATURES, (feature) => (
                  <Checkbox
                    id={`chk-team-${feature.key}`}
                    checked={this.state.features[feature.key]}
                    onChange={() => this.toggleFeature(feature.key)}
                    data-cy={`chk-team-${feature.key}`}
                    key={feature.key}
                  >
                    {feature.header || feature.key}
                  </Checkbox>
                ))}
              </div>
            </FormGroup>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onHide} data-cy="btn-cancel-feature">
            Cancel
          </Button>
          <Button dmpStyle="primary" onClick={this.updateTeam} disabled={disabled} data-cy="btn-update-feature">
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
