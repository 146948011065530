import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, FormControl } from 'react-bootstrap';

import { rxVariable } from '@core/models/Content';
import Deal from '@core/models/Deal';
import Team from '@core/models/Team';
import { CLAUSE_VITAL_PROMPTS } from '@core/models/Vital';
import { DateFormatter } from '@core/utils';

import { Alert, Button, ButtonIcon, Icon, Loader, Switch } from '@components/dmp';

import VariableView from '@components/VariableView';
import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

import VitalCheck from './deal/VitalCheck';

const showVineLens = false;

//For Testing Calls
const clauseName = 'Terms and Conditions';

const prompt =
  'Does the text in the <records> tag have additional information that is not present in the text found in the <sample> tag? Please give your answer as a boolean value. Please give your confidence interval in your answer on a separate line formatted as a decimal from 0.00 to 1.00.';

const extractPrompt = `Find and return all the paragraphs from the ${clauseName} clause along with any additional amendments for the ${clauseName} clause found in the <records> tags. The clause always starts with "The person signing...". If you do not find anything return Not Found. Do not say anything else.`;

export const PDF_REVIEW_DEFAULTS = {
  showDocTitle: showVineLens,
  showSections: showVineLens,
  showNumbers: showVineLens,
  showTerms: showVineLens,
  showTitles: showVineLens,
  showHeaders: showVineLens,
  showFooters: showVineLens,
  showMargins: showVineLens,
};

const DOCUMENT_ANALYSIS_OPTIONS = [
  {
    key: 'ocr',
    title: 'OCR (included)',
    test: (deal) => true,
    subtext: 'Document is scanned and indexed',
    isDefault: true,
  },
  {
    key: 'variable',
    title: 'Variable analysis',
    test: (deal) => deal.documentAI && !deal.extracted?.error,
    subtext: '(For structured form data) Finds key value pairs and stores them in variables panel',
    isDefault: false,
  },
  {
    key: 'clause',
    title: 'Clause analysis',
    test: (deal) => deal.documentAI && !deal.extracted?.error && deal.extracted?.sections,
    subtext: 'Extracts key clauses from documents and runs prompts, e.g, compare to original.',
    isDefault: false,
  },
  {
    key: 'scoring',
    title: 'Scoring',
    test: (deal) => deal.hasVitals,
    subtext: 'Calculates a score based on variable values and found clauses within a document',
    isDefault: false,
  },
];

//Active set true for releasable pieces of PDF ingestion (Vitals)
export const PDF_REVIEW_TABS = [
  {
    key: 'summary',
    title: 'Summary',
    active: () => true,
  },
  {
    key: 'vitals',
    title: 'Vitals',
    active: (deal, user) => deal.hasVitals && deal.vitalChecks,
  },
  {
    key: 'clauses',
    title: 'Clauses',
    active: (deal, user) => deal.documentAI && deal.extracted && !deal.extracted.error && deal.extracted.sections,
  },
  {
    key: 'terms',
    title: 'Variables',
    active: (deal, user) => deal.documentAI && deal.extracted && !deal.extracted.error,
  },
  {
    key: 'AI',
    title: 'AI',
    active: (deal, user) => user.isSuper,
  },
];

@autoBindMethods
export default class PDFReview extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    dealTeam: PropTypes.instanceOf(Team).isRequired,
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      reviewOptions: this.reviewOptions,
      tab: 'summary',
      selectedDefinitions: {},
      prompt: prompt,
      extractPrompt: extractPrompt,
      results: false,
      selectedClause: null,
      showClauseDifference: false,
      showIssues: false,
      showVariableIssues: false,
      loadingClauseExtraction: false,
      manuallyEditClauseExtraction: false,
      editingClause: null,
      editingVariableValue: null,
    };
  }

  get pdfView() {
    return _.get(this.props, 'pdfView.current', null);
  }

  toggleDefinition(term) {
    const { selectedDefinitions } = this.state;
    selectedDefinitions[term.value] = !selectedDefinitions[term.value];
    this.setState({ selectedDefinitions });
  }

  get showFailedSwitch() {
    const { deal } = this.props;
    const { extracted, vitalChecks } = deal;
    let showFailedSwitch = false;

    if (extracted && extracted.sections) {
      _.forEach(extracted.sections, (clause) => {
        if (clause.vitalIDs) {
          const vitalIDs = clause.vitalIDs.split(',');
          _.forEach(vitalIDs, (id) => {
            const check = _.find(vitalChecks.checks, ({ vital }) => {
              return vital.id === id;
            });
            if (check.failedCheck) {
              showFailedSwitch = true;
            }
          });
        }
      });
    }
    return showFailedSwitch;
  }

  /////////////////////
  //Start For Testing//
  /////////////////////

  //Testing Calls For Admins only
  async AIScoring() {
    const { deal, user } = this.props;
    const { extracted } = deal;

    const clause = extracted.sections['Terms and Conditions'];

    //Transition to sentence checks for standard clauses.
    //1.) break each clause into sentences.
    //2.) check the extracted clause's sentences vrs the standard clauses sentences.
    //3.) If there is content in the extracted clause not in the standard clause we will fail the check.
    const sentenceSplit = /(?<=[.?!]\s+(?=[A-Z]))/;
    const standardContent = clause.standardClause.split(sentenceSplit);
    const extractedContent = clause.extractedClause.split(sentenceSplit);

    let hasAdditionalTerms = false;
    _.forEach(extractedContent, (sentence) => {
      const hasSentence = _.find(standardContent, (standardSentence) => {
        //We have two ways this works.
        //1.) normal clause sentence with no variation.
        //2.) We have a clause with "variation". In this case we take everything on either side of the variable and check if its in a sentence within the clause.
        if (standardSentence.match(rxVariable)) {
          const blocks = standardSentence.split(rxVariable);
          let blockMatch = true;
          _.forEach(blocks, (block) => {
            if (!sentence.toLowerCase().trim().includes(block.toLowerCase().trim())) {
              blockMatch = false;
            }
          });

          if (blockMatch) return standardSentence;
        } else {
          return standardSentence.toLowerCase().trim() === sentence.toLowerCase().trim(); // || standardSentence.trim().includes(sentence.trim());
        }
      });

      if (!hasSentence) {
        hasAdditionalTerms = true;
        return;
      }
    });

    this.setState({ results: hasAdditionalTerms ? 'Has Additional Info' : 'No Additional Info' });
  }

  //Testing Calls For Admins only
  async AIExtraction() {
    const { deal, user } = this.props;
    const { extracted } = deal;

    let text = extracted.form.text;

    const clause = extracted.sections['Terms and Conditions'];
    const results = await API.call('AIClauseExtraction', {
      uid: user.id,
      teamID: deal.team,
      text,
      clauseName: 'Terms and Conditions',
    });

    this.setState({ extractResults: results });
  }

  async rerunClauseExtraction() {
    const { deal, user } = this.props;
    const { selectedClause } = this.state;
    const { extracted, dealID } = deal;

    let text = extracted.form.text;

    this.setState({ loadingClauseExtraction: true });

    const results = await API.call('AIClauseExtraction', {
      uid: user.id,
      teamID: deal.team,
      text,
      clauseName: selectedClause.name,
    });

    await Fire.updateExtractedClause(dealID, results, selectedClause.name);
    this.setState({ loadingClauseExtraction: false });
  }

  async saveManualUpdates() {
    const { deal } = this.props;
    const { selectedClause, editingClause } = this.state;

    await Fire.updateExtractedClause(deal.dealID, editingClause, selectedClause.name);
    this.setState({ editingClause: null, manuallyEditClauseExtraction: false });
  }

  //Testing Calls For Admins only
  async AIVariableExtraction() {
    const { deal, user } = this.props;
    const { extracted } = deal;

    const extractedSection = extracted.sections['Terms and Conditions'].extractedClause;

    const clause = extracted.sections['Terms and Conditions'];
    const results = await API.call('AIClauseVariableExtraction', {
      uid: user.id,
      teamID: deal.team,
      extractedSection,
      prompt: this.state.extractVariablePrompt,
    });

    this.setState({ extractVariableResults: results });
  }
  ///////////////////
  //End For Testing//
  ///////////////////

  renderDefinition(variable, idx) {
    const { editingVariableValue } = this.state;
    const { deal, user } = this.props;
    const { needsReview, extractedValue, variableName } = variable;

    const v = deal.variables[variableName];
    //Two cases to check for
    //1: User reviewed and approved a empty extracted value.
    // This case is true if we have toggled off needsReview and both values are still null or undefined.
    //2: The user reviewed or editied the extracted value.
    //This is a simple value comaprison.
    const humanReviewed = !extractedValue && !v.value && !needsReview ? true : v.value !== extractedValue;

    if (needsReview) {
      return (
        <DealPanelItem borderBottom className="deal-panel-item-type-danger" active={true}>
          <div className="definition" key={idx}>
            <div className={cx('term-definition-title')}>
              <b>{variableName}</b>
            </div>
            <VariableView
              variable={v}
              section={deal.root}
              text={`[#${v.name}]`}
              markReview
              user={user}
              onSave={(variable) => Fire.markVariableReviewed(variable)}
              deal={deal}
            />
          </div>
        </DealPanelItem>
      );
    } else {
      return (
        <DealPanelItem borderBottom>
          <div className="definition" key={idx}>
            <div className={cx('term-definition-title')}>
              <b>{variableName}</b>
            </div>
            <div className="term-info-container">
              {editingVariableValue !== v.name && (
                <div className="term-value">
                  {humanReviewed ? (
                    <TooltipButton tip="Human-reviewed">
                      <Icon name="fieldsEdit" size="default" />
                    </TooltipButton>
                  ) : (
                    <TooltipButton tip="AI extracted">
                      <Icon name="aiAuto" size="default" />
                    </TooltipButton>
                  )}
                  <a
                    onClick={() =>
                      this.setState({
                        editingVariableValue: v.name,
                      })
                    }
                  >
                    {humanReviewed ? (v.value ? `"${v.value}"` : '(empty)') : `"${extractedValue}"`}
                  </a>
                </div>
              )}
            </div>
            {editingVariableValue === v.name && this.editVariable(v, extractedValue)}
          </div>
        </DealPanelItem>
      );
    }
  }

  editVariable(variable, extractedValue) {
    const { deal, user } = this.props;

    return (
      <VariableView
        variable={variable}
        section={deal.root}
        text={`[#${variable.name}]`}
        editExtractedValue
        onCancel={() => this.setState({ editingVariableValue: null })}
        user={user}
        onSave={(variable) => {
          if (variable.value !== extractedValue) Fire.markVariableReviewed(variable);
          this.setState({ editingVariableValue: null });
        }}
        deal={deal}
      />
    );
  }

  renderClauses(clause, idx) {
    const { showIssues } = this.state;
    const { vitalChecks } = this.props.deal;

    //two cases
    //1.) where we are tied to vitals
    //2.) extraction independent of vitals
    if (clause.vitalIDs) {
      const vitalIDs = clause.vitalIDs.split(',');

      return _.map(vitalIDs, (vitalID) => {
        const check = _.find(vitalChecks.checks, ({ vital }) => {
          return vital.id === vitalID;
        });

        const clauseVitalPrompt = check.vital ? _.find(CLAUSE_VITAL_PROMPTS, { key: check.vital.clauseCheck }) : null;

        if ((showIssues && !check.failedCheck) || !clauseVitalPrompt) return;

        return (
          <DealPanelItem
            borderBottom
            key={vitalID}
            className={cx('clause-summary-container', { failedCheck: check.failedCheck })}
          >
            <div
              className="clause-name"
              onClick={() =>
                this.setState({ selectedClause: { name: clause.clauseName, failedCheck: check.failedCheck } })
              }
            >
              {clause.clauseName}
            </div>
            <div className="clause-question">{`Q: ${clauseVitalPrompt.question}`}</div>
            <div className={cx('clause-answer', { failedCheck: check.failedCheck })}>
              <Icon name="aiAuto" />
              A: {clauseVitalPrompt.answer(check.failedCheck)}
            </div>
          </DealPanelItem>
        );
      });
    } else {
      return (
        <DealPanelItem borderBottom key={idx} className="clause-summary-container">
          <div
            className="clause-name"
            onClick={() => this.setState({ selectedClause: { name: clause.clauseName, failedCheck: false } })}
          >
            {clause.clauseName}
          </div>
        </DealPanelItem>
      );
    }
  }

  render() {
    const { deal, container, show, onHide, target, title, user } = this.props;
    const {
      tab,
      selectedClause,
      showClauseDifference,
      showIssues,
      showVariableIssues,
      loadingClauseExtraction,
      manuallyEditClauseExtraction,
      editingClause,
    } = this.state;
    const { vitalChecks, extracted, documentAI, hasVitals, isProcessingAI } = deal;
    const analyzedOn = extracted ? new Date(parseInt(extracted.analyzedOn)) : null;
    const formattedDate = analyzedOn
      ? `${DateFormatter.ymd(analyzedOn, '-')} @ ${DateFormatter.time(analyzedOn)}`
      : null;

    const variablesForReview = extracted?.variables ? _.filter(extracted.variables, { needsReview: true }) : [];

    const terms = showVariableIssues && variablesForReview.length > 0 ? variablesForReview : extracted?.variables;

    return (
      <DealPanel id={'vitals'} onHide={onHide} show={show} target={target} title={title} container={container}>
        <div className="filter-bar review-tabs">
          <ButtonGroup className="tabs">
            {PDF_REVIEW_TABS.map((tabDef) => {
              if (tabDef.active(deal, user))
                return (
                  <Button
                    dmpStyle="link"
                    active={tabDef.key === tab}
                    onClick={() => this.setState({ tab: tabDef.key, selectedClause: null })}
                    data-cy={`${tabDef.key}-tab`}
                    disabled={isProcessingAI}
                    key={tabDef.key}
                  >
                    {tabDef.key === 'terms' && variablesForReview.length > 0 && <div className="needsReview" />}
                    {tabDef.title}
                  </Button>
                );
            })}
          </ButtonGroup>
        </div>
        {isProcessingAI && (
          <div className="panel-scroll">
            <DealPanelItem borderBottom>
              <div className="analysis-loading">
                <Loader /> AI analysis is in progress, please wait...
              </div>
            </DealPanelItem>
          </div>
        )}
        {!isProcessingAI && (
          <div className="panel-scroll">
            {tab === 'summary' && !selectedClause && (
              <>
                {extracted && extracted.error && (
                  <div className="panel-scroll">
                    <DealPanelItem borderBottom>
                      <div className="analysis-loading">
                        <Alert dmpStyle="danger">{extracted.error}</Alert>
                      </div>
                    </DealPanelItem>
                  </div>
                )}
                {extracted && !extracted.error && (
                  <DealPanelItem borderBottom>
                    <>
                      <div className="summary-header">AI analysis</div>

                      <div className="summary-block">
                        Date analyzed
                        <span className="summary-sub-block">{formattedDate}</span>
                      </div>

                      <div className="summary-block">
                        Pages in document
                        <span className="summary-sub-block">{extracted.pages || deal.currentOCR.pages}</span>
                      </div>

                      <div className="summary-block">
                        Variable(s) needing review
                        <span className="summary-sub-block">{variablesForReview.length}</span>
                      </div>

                      {vitalChecks && (
                        <div className="summary-block">
                          Risk score
                          <span className="summary-sub-block">{`+${vitalChecks.totalRisk}`}</span>
                        </div>
                      )}
                    </>
                  </DealPanelItem>
                )}
                <DealPanelItem borderBottom>
                  <>
                    <div className="summary-header">Services used</div>

                    {_.map(DOCUMENT_ANALYSIS_OPTIONS, (option) => {
                      if (option.test(deal)) {
                        return (
                          <div className={cx('analysis-option-container', { isDefault: option.isDefault })}>
                            <Icon name="ai" className={cx('icon-ai', { isDefault: option.isDefault })} />
                            <div className="analysis-summary" data-cy="analysis-summary">
                              <span className="summary-title">{option.title}</span>
                              <div className="summary-subtext">{option.subtext}</div>
                            </div>
                            <div className="spacer" />
                            <div className={cx('active', { isDefault: option.isDefault })}>Active</div>
                          </div>
                        );
                      }
                    })}
                  </>
                </DealPanelItem>
              </>
            )}
            {tab === 'terms' && !selectedClause && documentAI && extracted && (
              <>
                <DealPanelItem borderBottom>
                  <div className="terms-header-container">
                    <div className="terms-count">{`Extracted (${_.size(extracted.variables)})`}</div>
                    {variablesForReview.length > 0 && (
                      <div className="terms-filter">
                        <Switch
                          id="terms-filter-switch"
                          checked={showVariableIssues}
                          onChange={() => this.setState({ showVariableIssues: !showVariableIssues })}
                          className="terms-filter-switch"
                          size="small"
                        >
                          {`Needs review (${variablesForReview.length})`}
                        </Switch>
                      </div>
                    )}
                  </div>
                </DealPanelItem>
                {_.map(terms, (variable, idx) => this.renderDefinition(variable, idx))}
              </>
            )}
            {tab === 'clauses' && !selectedClause && documentAI && (
              <>
                <DealPanelItem borderBottom>
                  <div className="clause-issue-switch-container">
                    <div className="issue-switch-title">{`Extracted (${_.size(extracted.sections)})`}</div>
                    {hasVitals && this.showFailedSwitch && (
                      <Switch
                        id="show-clause-issues"
                        checked={showIssues}
                        onChange={() => {
                          this.setState({ showIssues: !showIssues });
                        }}
                        className="show-clause-issues"
                        size="small"
                      >
                        {`Show failed vitals`}
                      </Switch>
                    )}
                  </div>
                </DealPanelItem>
                {_.map(extracted.sections, (clause, idx) => this.renderClauses(clause, idx))}
              </>
            )}

            {tab === 'vitals' && vitalChecks && !selectedClause && (
              <DealPanelItem borderBottom>
                <VitalCheck vitalChecks={vitalChecks} />
              </DealPanelItem>
            )}

            {tab === 'AI' && user.isAdmin && !selectedClause && (
              <DealPanelItem borderBottom>
                <Button dmpStyle="link" onClick={() => this.AIScoring()} data-cy={`ai-extraction-btn`}>
                  Re-Run AI Scoring
                </Button>

                {this.state.results && (
                  <ul>
                    <li>Value: {this.state.results}</li>
                  </ul>
                )}

                <Button dmpStyle="link" onClick={() => this.AIExtraction()} data-cy={`ai-extraction-btn`}>
                  Re-Run AI Extraction
                </Button>
                {this.state.extractResults && (
                  <div>
                    <b>Result: </b>
                    {this.state.extractResults}
                  </div>
                )}
                <Button dmpStyle="link" onClick={() => this.AIVariableExtraction()} data-cy={`ai-extraction-btn`}>
                  Re-Run AI Value Extraction
                </Button>
                {this.state.extractVariableResults && (
                  <div>
                    <b>Result: </b>
                    {this.state.extractVariableResults}
                  </div>
                )}
              </DealPanelItem>
            )}
            {selectedClause && (
              <>
                <DealPanelItem borderBottom>
                  <div className="clause-comparison-header">
                    <ButtonIcon
                      icon="chevronLeft"
                      onClick={() => this.setState({ selectedClause: null })}
                      data-cy="btn-bundle"
                      size="default"
                    />
                    <span className="compare-clause-title">Back</span>
                  </div>
                </DealPanelItem>
                {/*<DealPanelItem borderBottom>
                  <div className="clause-diff-switch-container">
                    <div className="diff-switch-title">Show differences</div>
                    <Switch
                      id="show-clause-diff"
                      checked={showClauseDifference}
                      onChange={() => {
                        this.setState({ showClauseDifference: !showClauseDifference });
                      }}
                      className="show-clause-diff"
                      size="small"
                    />
                  </div>
                </DealPanelItem>
                    */}
                {!showClauseDifference && (
                  <DealPanelItem borderBottom>
                    <div className="clause-display">
                      <div className="clause-display-container">
                        <div className="clause-title">Extracted clause</div>
                        <div className="action-container">
                          <div className="left">
                            {!loadingClauseExtraction && (
                              <TooltipButton tip="Re-run clause extraction" placement="left">
                                <ButtonIcon
                                  data-cy="undo"
                                  icon="undo"
                                  className="undo"
                                  size="default"
                                  disabled={manuallyEditClauseExtraction}
                                  onClick={this.rerunClauseExtraction}
                                />
                              </TooltipButton>
                            )}
                            {loadingClauseExtraction && (
                              <TooltipButton tip="Re-running clause extraction" placement="left">
                                <span>
                                  <Loader size="default" />
                                </span>
                              </TooltipButton>
                            )}
                          </div>
                          <div className="right">
                            {!manuallyEditClauseExtraction && (
                              <TooltipButton tip="Manually edit clause extraction" placement="left">
                                <ButtonIcon
                                  data-cy="draft"
                                  icon="edit"
                                  className="draft"
                                  size="default"
                                  disabled={loadingClauseExtraction}
                                  onClick={() =>
                                    this.setState({
                                      manuallyEditClauseExtraction: true,
                                      editingClause: extracted.sections[selectedClause.name].extractedClause.trim(),
                                    })
                                  }
                                />
                              </TooltipButton>
                            )}
                            {manuallyEditClauseExtraction && (
                              <TooltipButton tip="Cancel edits" placement="left">
                                <ButtonIcon
                                  data-cy="close"
                                  icon="close"
                                  className="close"
                                  size="default"
                                  onClick={() =>
                                    this.setState({ manuallyEditClauseExtraction: false, editingClause: null })
                                  }
                                />
                              </TooltipButton>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={cx('clause-text-container', { failedCheck: selectedClause.failedCheck })}>
                        {!manuallyEditClauseExtraction && (
                          <div className="clause-text">{extracted.sections[selectedClause.name].extractedClause}</div>
                        )}
                        {manuallyEditClauseExtraction && (
                          <>
                            <FormControl
                              componentClass="textarea"
                              value={editingClause}
                              onChange={(e) => this.setState({ editingClause: e.target.value })}
                            />
                            <div className="edit-actions">
                              <Button
                                className="save-manual-edit"
                                onClick={this.saveManualUpdates}
                                dmpStyle="link"
                                size="small"
                              >
                                Save
                              </Button>
                              <Button
                                className="save-manual-edit"
                                onClick={() =>
                                  this.setState({ manuallyEditClauseExtraction: false, editingClause: null })
                                }
                                dmpStyle="link"
                                size="small"
                              >
                                Cancel
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </DealPanelItem>
                )}
                {showClauseDifference && (
                  <DealPanelItem borderBottom>
                    <div className="clause-display">
                      <div className="clause-text-container">
                        <div className="clause-text">Hello world</div>
                      </div>
                    </div>
                  </DealPanelItem>
                )}
              </>
            )}
          </div>
        )}
      </DealPanel>
    );
  }
}
