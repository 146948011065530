import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, Overlay, Popover } from 'react-bootstrap';

import { Button, Dropdown, Form, Icon, MenuItem } from '@components/dmp';

@autoBindMethods
export default class KeywordFilter extends Component {
  static propTypes = {
    query: PropTypes.string,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      inputQuery: '',
      showPopup: false,
    };

    this.anchor = React.createRef();
  }

  componentDidMount() {
    const { query } = this.props;

    this.setState({ inputQuery: query || '', query: query || '' });
  }

  componentDidUpdate(prevProps) {
    const { query } = this.props;
    if (prevProps.query !== query) this.setState({ query: query || '' });
  }

  get canSubmit() {
    return !!this.state.inputQuery;
  }

  configureFilter() {
    this.setState({ showPopup: true });
  }

  saveFilter() {
    const { onChange } = this.props;
    const { inputQuery } = this.state;

    onChange(inputQuery);

    this.setState({ showPopup: false });
  }

  clearFilter() {
    const { onChange } = this.props;
    this.setState({
      showPopup: false,
      inputQuery: '',
      query: '',
    });

    onChange('');
  }

  render() {
    const { query } = this.props;
    const { showPopup } = this.state;

    return (
      <div className="variable-filter" ref={this.anchor}>
        <div className={cx('filter-name', { active: !!query })} onClick={this.configureFilter}>
          <div>Keyword</div>
          <Icon name="filterExpand" />
        </div>

        {query && (
          <div className="existing" onClick={this.configureFilter}>
            <div className="filter-display" data-cy="filter-display">
              "{query}"
            </div>
          </div>
        )}

        {showPopup && this.renderConfig()}
      </div>
    );
  }

  renderConfig() {
    const { inputQuery } = this.state;

    return (
      <Overlay
        show={true}
        onHide={() => this.setState({ showPopup: false })}
        target={this.anchor.current}
        placement="right"
        rootClose
      >
        <Popover className="keyword-filter popover-keyword-filter" id="pop-keyword-filter">
          <Form className="configuring">
            <FormGroup>
              <FormControl
                type="text"
                bsSize="small"
                value={inputQuery}
                placeholder="Enter Keyword(s)"
                onChange={(e) => this.setState({ inputQuery: e.target.value })}
                data-cy="filter-keyword-input"
              />
            </FormGroup>
            <div className="actions">
              <Button size="small" dmpStyle="link" onClick={this.clearFilter} data-cy="btn-clear-keyword-filter">
                Clear
              </Button>
              <Button
                data-cy="btn-apply-keyword-filter"
                disabled={!this.canSubmit}
                dmpStyle="primary"
                onClick={this.saveFilter}
                size="small"
              >
                Apply
              </Button>
            </div>
          </Form>
        </Popover>
      </Overlay>
    );
  }
}
