import _ from 'lodash';

import API from '@root/ApiClient';
import Fire from '@root/Fire';

class AIBlockRunner {

  autorun(sourceSections) {
    // TODO next: figure out how to encpasulate the API.summarize call into a reusable class
    // that's NOT tied to a component (ie remove from ListSection.jsx)
    // so that we can reuse it both here and in DealView
    // ...maybe follow the VariableIndex paxttern
    const queue = _.filter(sourceSections, (sec) => sec.aiPrompt?.canAutorun);
    
    if (queue.length > 0) {
      const sec = queue[0];
      console.log(`[${sec.name}] - AUTORUN`);
      this.runBlock(sec);
    }
  }

  async runBlock(section) {
    const { aiPrompt } = section;

    let source = aiPrompt.dataSourceAI;

    const useJSON = aiPrompt.responseType === 'list';

    // Store isRunning state in db instead of local component state
    aiPrompt.isRunning = true;
    await Fire.saveSection(section, {aiPrompt: aiPrompt.json});
    
    // This should be the only place in the whole app where the actual AI call gets made
    let skynet = await API.call('summarize', { 
      aiPrompt: aiPrompt.json, 
      source, 
      // TODO: this shouldn't be the full Deal.js json!!! :-(
      deal: section.deal.json,
      json: useJSON 
    });

    console.log(skynet);

    if (skynet?.result[0]) {

      switch (aiPrompt.responseType) {
        // If we're expecting multiple items back, handle it as an array and generate multiple sections
        case 'list':
          // await this.handleJSONItems(section, skynet.result);
          
          // First auto-clear any children in the target list (AIBlock)
          await Fire.clearList(section);
      
          // For now we're not doing any processing on the json array response, 
          // just rendering it as child items for better formatting and subsequent edits/tracking
          const sections = _.map(skynet.result, (item) => {
            switch (typeof item) {
              case 'string':
                return {content: item};
              case 'object':
              default:
                // TODO: some sort of template/Repeater type thing
                return {content: JSON.stringify(item)}
            }
          });
      
          console.log(sections);
      
          if (sections.length > 0) {
            await Fire.addItemBatch(section, sections, null, true);
          }

          break;
        // Or we can store the result inside a variable (once we confirm it's a valid variable)
        case 'variable':
          const targetVariable = section.deal.variables[aiPrompt.outputVariable];
          if (!aiPrompt.outputVariable) {
            console.error(`No target variable specified for AI Block [${section.name}]`);
          }
          else if (!targetVariable) {
            console.error(`Target variable [${aiPrompt.outputVariable}] specified for AI Block [${section.name}] does not exist`);
          }
          // Now we've confirmed we've got a valid target -- save it!
          else {
            const result = skynet.result[0];
            await Fire.saveVariable(section.deal, targetVariable, result);
          }
          break;
        // Default/legacy -- put it in the body of this AI Block Section
        case 'block':
        default:
          await Fire.saveSection(section, { content: skynet.result[0] });
          break;
      }

      // Always store the latest raw response to avoid constantly triggering autorun
      aiPrompt.lastResponse = skynet.result[0];
      aiPrompt.isRunning = false;
      await Fire.saveSection(section, { aiPrompt: aiPrompt.json });
      
    }
  }
  
}

const aiBlockRunner = new AIBlockRunner();

// Export singleton instance so we can use it throughout app
export default aiBlockRunner;
