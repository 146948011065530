import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, Modal } from 'react-bootstrap';

import { Dt, dt } from '@core/utils';

import { Button, Dropdown, Form, Icon, MenuItem } from '@components/dmp';

import API from '@root/ApiClient';

import { DEAL_ROLES } from './DealUserBlock';

const MODES = {
  MAIN: 'main',
  REQUEST: 'request',
  REQUESTED: 'requested',
};

@autoBindMethods
export default class ReadOnlyDealModal extends Component {
  static defaultProps = {
    mode: MODES.MAIN,
    user: null,
  };

  static propTypes = {
    dealID: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mode: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      mode: props.mode,
      fullName: '',
      email: '',
      dealUserType: '',
      isSaving: false,
      selectedRole: null,
    };
  }

  get isRequestingDisabled() {
    const { fullName, email, isSaving } = this.state;
    return isSaving || !fullName || !email;
  }

  onHide() {
    if (this.state.isSaving) return;
    this.props.onHide();
  }

  onLogin() {
    const { location, history } = this.props;
    const returnPath = encodeURIComponent(location.pathname);
    history.push(`/login/?redirect=${returnPath}`);
  }

  handleRequestChange(e) {
    const { target } = e;
    if (!target) return;

    this.setState({ [target.name]: target.value });
  }

  handleRequestRoleChange(key) {
    const selectedRole = _.find(DEAL_ROLES, { key });
    this.setState({ selectedRole });
  }

  async request() {
    const { dealID } = this.props;
    const { email, fullName, selectedRole } = this.state;
    const role = _.get(selectedRole, 'title');

    this.setState({ isSaving: true });

    try {
      const accessResponse = await API.callAnon('requestAccess', {
        dealID,
        requester: { email, info: { fullName } },
        role,
      });
      console.log(accessResponse);
      this.setState({ isSaving: false, mode: MODES.REQUESTED });
    } catch (error) {
      console.log(error);
      this.setState({ isSaving: false, hasRequestError: true });
    }
  }

  renderModeMain() {
    return (
      <div data-cy="read-only-content">
        <div className="icon">
          <Icon name="lockClosed" />
        </div>

        <h3>{Dt} is read-only</h3>
        <p>
          To make edits or to sign this {dt} request an invite from the {dt} owner.
        </p>

        <div>
          <Button
            block
            dmpStyle="primary"
            onClick={() => this.setState({ mode: MODES.REQUEST })}
            data-cy="btn-request-edit"
          >
            Request invite to edit
          </Button>
          <Button block onClick={this.onHide} data-cy="btn-read-only">
            View {Dt} (read-only)
          </Button>
        </div>
      </div>
    );
  }

  renderModeRequest() {
    const { fullName, email, isSaving, selectedRole } = this.state;

    return (
      <div>
        <div className="icon">
          <Icon name="lockClosed" />
        </div>

        <h3>Request to edit {dt}</h3>
        <p>
          Fill out your details so the {dt} owner can invite you to the {dt}.
        </p>

        <Form className="form-request">
          <FormGroup>
            <div className="contents">
              <FormControl
                name="fullName"
                disabled={isSaving}
                value={fullName}
                placeholder="Your full name"
                onChange={this.handleRequestChange}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <div className="contents">
              <FormControl
                name="email"
                disabled={isSaving}
                value={email}
                placeholder="Your email address"
                onChange={this.handleRequestChange}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <div className="contents">
              <Dropdown
                block
                size="small"
                id="dd-role"
                onSelect={this.handleRequestRoleChange}
                title={selectedRole ? selectedRole.title : 'Choose role'}
              >
                {DEAL_ROLES.map((role, idx) => (
                  <MenuItem key={idx} eventKey={role.key}>
                    <div className="title">{role.title}</div>
                    <div className="description">{role.description}</div>
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
          </FormGroup>

          <Button block dmpStyle="primary" onClick={this.request} disabled={this.isRequestingDisabled}>
            Request invitation
          </Button>
        </Form>
      </div>
    );
  }

  renderModeRequested() {
    const { user } = this.props;
    return (
      <div>
        <div className="icon success">
          <Icon name="checkGreen" />
        </div>

        <h3>Invite requested, sit tight</h3>
        <p>The owner of the {dt} has been notified, check your inbox for updates.</p>

        <div>
          <Button block dmpStyle="primary" onClick={this.onHide}>
            Back to {dt}
          </Button>
          {(!user || user.anonymous) && (
            <Button block onClick={this.onLogin}>
              Sign up or log in
            </Button>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { mode } = this.state;

    return (
      <Modal show className="modal-read-only-deal" dialogClassName="dialog-centered">
        <Modal.Header closeButton onHide={this.onHide} />

        <Modal.Body>
          <div className="wrapper text-center">
            {mode === MODES.MAIN && this.renderModeMain()}
            {mode === MODES.REQUEST && this.renderModeRequest()}
            {mode === MODES.REQUESTED && this.renderModeRequested()}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
