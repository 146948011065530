import { assign, find, get, pick } from 'lodash';

import { NUMBER_STYLES, NUMBER_TYPES, discoverNumberType, discoverOrder, formatOrder } from '../utils/OrderFormatter';

export const NUMBER_FINDER = /^\s*\(?([\w]+)[.)]\s*/;

export const MULTIPART_NUMBER_FINDER = /^\s*((\d+\.?)+)\s*/;

export function extractNumber(text) {
  if (!text) return null;
  let num = text.match(NUMBER_FINDER);
  if (!num) return null;

  const type = discoverNumberType(num[1]);
  if (!type) return null;

  const order = discoverOrder(num[1]);
  if (order < 0) return null;

  // Additional case for pdf parsing, where we may get a multipart number, eg 10.7.3
  const multi = text.match(MULTIPART_NUMBER_FINDER);

  // if (multi) console.log(multi[1]);

  //once we get here we know it's a valid number so return
  return { 
    raw: multi ? multi[1] : num[0], 
    type, 
    order, 
    multipart: !!multi ? true : false,
  };
}

export default class DealNumberFormat {
  type = null;
  pre = null;
  post = null;
  case = null;

  static FromText(text) {
    const num = extractNumber(text);
    if (!num) return null;

    const m = text.match(/^\s*([(#])?([\w]+)([:).-])?/);
    if (!m) return null;

    return {
      type: num.type.type,
      case: num.type.case || null,
      pre: m[1] || null,
      post: m[3] || null,
    };
  }

  static FromDocx(fmt, level) {
    let type = find(NUMBER_TYPES, { key: fmt.numFmt });
    type = pick(type, ['type', 'pre', 'post', 'case']);

    // Docx numbering uses a format like this: "%1." or "%1-%2"
    // Where the "%1" represents a 1-based indent level,
    // i.e.,  "%1" means "replace this with the ordinal of the paragraph at indentLevel 0"
    // However, Outlaw's numbering is atomic (indent levels cannot reference other levels)
    // so we want to find the corresponding formatting for the current level
    // Using exec() will ensure that we don't interpret a prior level's "post" as a current level's "pre"
    const DOCX_REG = /([-.:();]*)%([\d])([-.:();]*)/g;
    let match;
    while ((match = DOCX_REG.exec(fmt.lvlText)) !== null) {
      const targetLevel = parseInt(match[2]) - 1;
      if (targetLevel === level) {
        type.pre = match[1] || null;
        type.post = match[3] || null;
      }
    }
    // console.log(`Converting number format @ level [${level}]:`, fmt, type);
    return type;
  }

  constructor(json = {}) {
    assign(this, json);

    // If specified type is alpha or roman, we need to ensure that case is specified as well (default to lower)
    if (['alpha', 'roman'].includes(json.type) && !json.case) {
      this.case = 'lower';
    }
  }

  get json() {
    return pick(this, ['type', 'pre', 'post', 'case']);
  }

  get example() {
    if (this.type === 'unordered') {
      const style = find(NUMBER_STYLES, { pre: this.pre });
      return get(style, 'description', this.pre);
    } else if (this.type === 'none') {
      return 'Unnumbered';
    } else {
      return [0, 1, 2].map((i) => formatOrder(i, this)).join(' ');
    }
  }
}
