import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, FormControl } from 'react-bootstrap';

import { VariableType } from '@core/models/Variable';
import { dt, getSafeKey } from '@core/utils';

import { Alert, Button, Dropdown, MenuItem } from '@components/dmp';

import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import DealPartyBlock from '@components/deal/DealPartyBlock';
import DealUserBlock from '@components/deal/DealUserBlock';
import SendDeal from '@components/deal/SendDeal';
import TeammateSelector from '@components/teams/TeammateSelector';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

const TABS = [
  { key: 'users', title: 'Users' },
  { key: 'parties', title: 'Parties' },
];

@autoBindMethods
export default class DealUserManager extends Component {
  static propTypes = {
    deal: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    team: PropTypes.object,
    teams: PropTypes.array,
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);
    this.state = {
      newUserFullName: '',
      newPartyName: '',
      addingUser: null,
      addingParty: false,
      selectedTab: 'users',
    };
  }

  // Only enable Party mgmt for deal owners on PDF deals
  get canAdminParties() {
    const { deal } = this.props;
    return deal.isOwner && deal.isExternal;
  }

  get panelTitleControls() {
    if (!this.canAdminParties) {
      return this.props.title;
    }

    return (
      <ButtonGroup className="tab-selector">
        {TABS.map((tab) => (
          <Button
            key={tab.key}
            size="small"
            dmpStyle="link"
            active={tab.key === this.state.selectedTab}
            onClick={() => this.setState({ selectedTab: tab.key })}
            data-cy={`tab-selector-${tab.key}`}
          >
            {tab.title}
          </Button>
        ))}
      </ButtonGroup>
    );
  }

  get blocker() {
    const { deal, history } = this.props;

    if (deal.isBundle && deal.bundle && !deal.isBundleParent) {
      const url = `/deals/${deal.bundle.parent.dealID}`;

      return (
        <Alert
          dmpStyle="dark"
          size="small"
          icon="nope"
          inline
          title={`User access for bundled documents is controlled at the parent level (${deal.bundle.parent.name})`}
        >
          Manage users on <a onClick={() => history.push(url)}>parent document</a>
        </Alert>
      );
    } else {
      return null;
    }
  }

  close() {
    const { onHide } = this.props;
    this.setState({ addingUser: null, addingParty: false });
    onHide();
  }

  closeIfDone() {
    const { deal } = this.props;
    if (!deal) return;

    const unsent = _.filter(deal.users, { inviteStatus: 'added' });

    if (unsent.length == 0) this.close();
  }

  handleAddAction(type) {
    switch (type) {
      case 'direct':
      case 'teammates':
        this.setState({ addingUser: type });
        break;
      case 'share':
        this.refs.sendCounsel.show(this.refs.directShare, this.refs.usersWrapper);
        break;
      default:
        break;
    }
  }

  addNewUser(e) {
    const fullName = this.state.newUserFullName.trim();
    if ((!e || e.key === 'Enter') && fullName) {
      Fire.createDealUser(this.props.deal, { fullName }, () => this.setState({ newUserFullName: '' }));
    }
  }

  async addParty() {
    const { selectedTab } = this.state;
    if (selectedTab !== 'parties') {
      await this.setState({ selectedTab: 'parties' });
    }
    await this.setState({ addingParty: true });
    if (this.refNewParty) {
      this.refNewParty.focus();
    }
  }

  async addNewParty(e) {
    const { deal } = this.props;

    const newPartyName = this.state.newPartyName.trim();
    let newPartyID = getSafeKey(newPartyName, false, true);

    // Ensure that this is indeed a NEW party (variable) and that we don't accidentally overwrite an existing one
    while (deal.variables[newPartyID]) newPartyID += '_';

    if ((!e || e.key === 'Enter') && newPartyName) {
      const newVariable = {
        displayName: newPartyName,
        name: newPartyID,
        type: VariableType.PARTY,
      };
      await Fire.saveVariableDefinition(deal, newVariable);
      this.setState({ addingParty: false, newPartyName: '' });
    }
  }

  async addTeammate(tm) {
    const { deal } = this.props,
      { dealID, currentDealUser } = deal;

    this.refs.tm.updateAdding(tm.id);
    await API.call('addTeammatesToDeal', {
      teammates: [tm],
      notify: true,
      dealID,
      fromName: currentDealUser.get('fullName'),
    });
    if (this.refs.tm) {
      this.refs.tm.updateAdding(null);
    }
  }

  renderUsers() {
    const { user, deal, team, teams } = this.props;
    const { addingUser } = this.state;

    const users = _.sortBy(deal.users, 'fullName');

    const actions = [];
    actions.push(
      <MenuItem key="share" eventKey="share">
        Share directly
      </MenuItem>
    );

    if (deal.isOwner) {
      actions.push(
        <MenuItem key="direct" eventKey="direct">
          Add before sharing
        </MenuItem>
      );
    }
    if (user.team) {
      actions.push(
        <MenuItem key="teammates" eventKey="teammates">
          Add teammates
        </MenuItem>
      );
    }

    const blocker = this.blocker;

    return (
      <>
        {blocker}
        {!blocker && (
          <div className="filter-bar" data-cy="filter-bar">
            <div className="user-count">
              {users.length} user{users.length > 1 ? 's' : ''}
            </div>

            {addingUser ? (
              <Button className="add-done" size="small" onClick={() => this.setState({ addingUser: null })}>
                Done
              </Button>
            ) : (
              <Dropdown
                ref="directShare"
                className="add-user-actions"
                pullRight
                id="dd-add-users"
                onSelect={this.handleAddAction}
                size="small"
                title="Add Users"
              >
                {actions}
              </Dropdown>
            )}

            <SendDeal
              deal={deal}
              user={user}
              placement="bottom"
              ref="sendCounsel"
              onSend={() => this.refs.sendCounsel.hide()}
            />
          </div>
        )}

        {addingUser === 'direct' && (
          <DealPanelItem className="adding direct" borderBottom transparent>
            <FormControl
              inputRef={(el) => (this.refNewUser = el)}
              onChange={(e) => this.setState({ newUserFullName: e.target.value })}
              onKeyDown={this.addNewUser}
              placeholder="Enter full name of user"
              type="text"
              bsSize="small"
              value={this.state.newUserFullName}
            />
            <div className="adding-actions actions">
              <div className="spacer" />
              <Button
                size="small"
                dmpStyle="primary"
                disabled={this.state.newUserFullName.trim() == ''}
                onClick={() => this.addNewUser()}
              >
                Add
              </Button>
            </div>
          </DealPanelItem>
        )}

        {addingUser === 'teammates' && (
          <DealPanelItem className="adding teammates" borderBottom transparent>
            <TeammateSelector
              existingUsers={_.map(deal.users, 'uid')}
              onSelect={this.addTeammate}
              ref="tm"
              team={team}
              teams={teams}
              user={user}
            />
          </DealPanelItem>
        )}

        <div className="users-wrapper panel-scroll" data-cy="users-wrapper">
          {users.map((du) => (
            <DealUserBlock {...this.props} key={du.key} du={du} />
          ))}
        </div>
      </>
    );
  }

  renderParties() {
    const { deal } = this.props;
    const { addingParty, newPartyName } = this.state;
    const { parties } = deal;

    const title = !parties.length
      ? 'No parties defined'
      : parties.length === 1
      ? `1 party on ${dt}`
      : `${parties.length} parties on ${dt}`;

    return (
      <div>
        <div className="filter-bar">
          <div className="party-count">{title}</div>
          <Button size="small" disabled={addingParty} onClick={this.addParty} data-cy="btn-add-party">
            Add Party
          </Button>
        </div>
        {addingParty && (
          <DealPanelItem active borderBottom className="deal-party-block" data-cy="deal-party-block">
            <FormControl
              inputRef={(el) => (this.refNewParty = el)}
              onChange={(e) => this.setState({ newPartyName: e.target.value })}
              onKeyDown={this.addNewParty}
              placeholder="e.g., Company"
              type="text"
              value={newPartyName}
              data-cy="input-party"
            />
            <div className="actions">
              <div className="spacer" />
              <Button size="small" dmpStyle="link" onClick={() => this.setState({ addingParty: false })}>
                Cancel
              </Button>
              <Button
                size="small"
                dmpStyle="primary"
                disabled={!newPartyName.trim()}
                onClick={() => this.addNewParty()}
                data-cy="btn-save-party"
              >
                Save
              </Button>
            </div>
          </DealPanelItem>
        )}

        <div className="parties-wrapper">
          {parties.map((party, i) => (
            <DealPartyBlock party={party} key={i} />
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { deal, id, container, show, onHide, target, user } = this.props;
    const { selectedTab } = this.state;
    if (!deal || !user) return null;

    return (
      <DealPanel
        id={id}
        onHide={onHide}
        show={show}
        target={target}
        title={this.panelTitleControls}
        container={container}
      >
        {selectedTab === 'users' && this.renderUsers()}
        {selectedTab === 'parties' && this.renderParties()}
      </DealPanel>
    );
  }
}
