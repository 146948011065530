import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl } from 'react-bootstrap';

import Variable, { CONNECTION_TYPES, EXTERNAL_TYPES } from '@core/models/Variable';

import { Alert, Button, Dropdown, MenuItem } from '@components/dmp';

import { HELP } from '@root/CRM';
import Fire from '@root/Fire';
import { compareConnectedVariableDefinitions } from '@root/utils/VariableIndex';

const VariableConnector = ({
  variable,
  id,
  onUpdate,
  onBrowseDS,
  variableIndex,
  onResolve,
  hideConnectedSyncOptions = false,
}) => {
  const { autoPull, autoPush, writable, externalType } = variable;
  let needsResolution,
    remoteVarNotFound = false;
  let fvDefinition;
  // We need to only expose the types of sync that are appropriate for the context of this connected variable
  // If this variable is not writable, exclude options that would attempt to push value to connected service
  const syncOptions = _.filter(CONNECTION_TYPES, (connectionType) => {
    return writable || !connectionType.autoPush;
  });

  const syncType = _.find(syncOptions, { autoPull, autoPush }) || CONNECTION_TYPES[0];

  if (variableIndex) {
    const comparison = compareConnectedVariableDefinitions(variableIndex, variable);
    fvDefinition = comparison.fvDefinition;
    remoteVarNotFound = comparison.broken;
    needsResolution = comparison.resolve;
  }

  const updateSyncBehavior = (autoPull, autoPush) => {
    variable.autoPull = autoPull;
    variable.autoPush = autoPush;
    Fire.saveVariableDefinition(variable.deal, variable.json);
    onUpdate(variable);
  };

  const updateOLVariableDefinition = async () => {
    const syncedDefinition = _.merge(variable, fvDefinition);
    Fire.saveVariableDefinition(variable.deal, syncedDefinition.json);
    onResolve();
  };

  // Collections can not be set to auto-sync (re-importing data is done manually via UI), so don't even show the dd option
  const canUpdateSyncType = externalType !== EXTERNAL_TYPES.COLLECTION;

  let serviceName = _.get(variable, 'service.name', '');
  if (serviceName && !variable.isBroken) serviceName += '-';
  const docName = variable.deal.isTemplate ? 'template' : 'contract';

  return (
    <div className="variable-connector">
      {variable.isBroken || remoteVarNotFound ? (
        <Alert size="xsmall" dmpStyle="danger" data-cy="alert-broken">
          {serviceName} connection missing. <br />
          <a href={HELP.CONNECTION_MGMT} rel="noreferrer" target="_blank" data-cy="learn-more">
            Learn more.
          </a>
        </Alert>
      ) : needsResolution ? (
        <Alert size="xsmall" className="alert-resolve">
          Filevine connected variable definition mismatch, tap to{' '}
          <a onClick={() => updateOLVariableDefinition()}>auto-resolve</a>
        </Alert>
      ) : (
        <Alert size="xsmall" data-cy="alert">
          This is a {serviceName}connected variable.
          <br />
          <a href={HELP.CONNECTED_VARS} rel="noreferrer" target="_blank" data-cy="learn-more">
            Learn more.
          </a>
        </Alert>
      )}
      {variable.connectedDSID && typeof onBrowseDS === 'function' && (
        <div className="config-ds">
          <div className="control-label">Collection item ID</div>
          <FormControl disabled type="text" value={variable.connectedDSID} bsSize="small" />
          <a className="link-select" onClick={onBrowseDS} data-cy="link-select">
            Select
          </a>
        </div>
      )}
      {canUpdateSyncType && !variable.isBroken && !hideConnectedSyncOptions && (
        <>
          <div className="control-label">Remote sync</div>
          <Dropdown
            size="small"
            block
            id={`${id}-dd`}
            onSelect={({ autoPull, autoPush }) => updateSyncBehavior(autoPull, autoPush)}
            title={syncType.title}
            data-cy="var-sync-dd"
          >
            {_.map(syncOptions, (opt, idx) => (
              <MenuItem key={idx} eventKey={opt} info={opt.description}>
                {opt.title}
              </MenuItem>
            ))}
          </Dropdown>
          <small>{syncType.description}</small>
        </>
      )}
    </div>
  );
};

VariableConnector.propTypes = {
  variable: PropTypes.instanceOf(Variable).isRequired,
  id: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  onBrowseDS: PropTypes.func,
};

VariableConnector.defaultProps = {
  onUpdate: _.noop,
};

export default VariableConnector;
