import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import VariableFilter from '@core/models/VariableFilter';
import { CLAUSE_VITAL_PROMPTS } from '@core/models/Vital';

import { Icon } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

@autoBindMethods
export default class Vital extends Component {
  static propTypes = {
    vitalChecks: PropTypes.object.isRequired,
  };

  renderVitalCheck({ vital, failedCheck, risk }, idx) {
    let checkDescription = null;

    if (vital.valueFilter) {
      vital.valueFilter = new VariableFilter(vital.valueFilter.variable, {
        valueType: vital.valueFilter.valueType,
        operator: vital.valueFilter.operator,
        values: vital.valueFilter.values,
      });
    }

    if (vital.type === 'variable') {
      checkDescription = _.get(vital, 'valueFilter.displayLabel', '');
    } else {
      const clausePrompt = _.find(CLAUSE_VITAL_PROMPTS, { key: vital.clauseCheck });
      if (!clausePrompt) return;
      checkDescription = clausePrompt.answer(failedCheck);
    }

    return (
      <div key={idx} className={cx('vital-check', { pass: !failedCheck }, { fail: failedCheck })}>
        <Icon name="docScan" className="icon-vital" />
        <div className="vital-info">
          <div className="item-label" data-cy="vital-title">
            {vital.title}
          </div>
          <div className="item-sub" data-cy="vital-sub">
            {checkDescription}
          </div>
        </div>
        <div className="spacer" />
        {risk > 0 ? (
          <div className="risk-points" data-cy="vital-risk-point">
            +{risk}
          </div>
        ) : failedCheck ? (
          <>
            <TooltipButton tip="Missing" placement="left">
              <span>
                <Icon name="info" className="icon-result" />
              </span>
            </TooltipButton>
          </>
        ) : (
          <Icon name="check" className="icon-result" />
        )}
      </div>
    );
  }

  render() {
    const { vitalChecks } = this.props;

    return (
      <>
        {_.map(vitalChecks.checks, this.renderVitalCheck)}
        <div className={cx('vitals-summary', { 'has-risk': true })} data-cy="vital-summary-has-risk">
          <div className="item-label risk-points">Risk assessment</div>
          <div className="spacer" />
          <div className="risk-points">{vitalChecks.totalRisk} points</div>
        </div>
        <div className={cx('vitals-summary', { 'has-risk': true })} data-cy="vital-summary-risk-point">
          <div className="item-label risk-points">Grade</div>
          <div className="spacer" />
          <div className="risk-points">{vitalChecks.grade}</div>
        </div>
      </>
    );
  }
}
