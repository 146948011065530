import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Resizable } from 're-resizable';

import { Overlay } from 'react-bootstrap';
import OutsideClickHandler from 'react-outside-click-handler';

import DealAction from '@core/enums/DealAction';
import DealStatus from '@core/enums/DealStatus';
import PDFElement, { ELEMENT_TYPE, FONTS, MIN_ELEMENT_WIDTH, SIGNATURE_HEIGHT_RATIO } from '@core/models/PDFElement';
import { PARTY_PROPERTIES } from '@core/models/Party';
import User from '@core/models/User';
import { VariableType } from '@core/models/Variable';
import { classNamePrefixer, convertPointsToPixels } from '@core/utils';

import { ButtonIcon, Dropdown, Icon, MenuItem, Popover } from '@components/dmp';

import DealUserView from '@components/DealUserView';
import PartySelector from '@components/PartySelector';
import VariableView from '@components/VariableView';
import Siggy from '@components/deal/Siggy';
import SignatureMenu from '@components/deal/SignatureMenu';
import { ElementText } from '@components/pdf-editor';
import { BORDER_SIZE } from '@components/pdf-editor/DraggableElement';
import { getElementStyle } from '@components/pdf-editor/ElementText';
import Dealer, { Category } from '@root/Dealer';
import Fire from '@root/Fire';

const cl = classNamePrefixer('element', 'pdfe');

const RESIZABLE_ENABLE = {
  top: false,
  right: true,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

/*
  Just hardcode in here, there's no need to enforce a list on the database
*/
const FONT_SIZES = [8, 9, 10, 11, 12, 14, 18, 24, 30, 36];

const FONT_SIZE_TYPE = 'pt';

@autoBindMethods
class Element extends Component {
  static defaultProps = {
    draggable: false,
    dragHandleRef: null,
    isDragging: false,
    isNew: false,
    left: null,
    onResize: _.noop,
    onTextChange: _.noop,
    readOnly: false,
    resizable: false,
    scale: 1,
    style: {},
    top: null,
    value: '',
  };

  static propTypes = {
    className: PropTypes.any,
    draggable: PropTypes.bool,
    dragHandleRef: PropTypes.any, // ???
    id: PropTypes.string.isRequired,
    isDragging: PropTypes.bool,
    isNew: PropTypes.bool,
    left: PropTypes.number,
    onChange: PropTypes.func,
    onResize: PropTypes.func,
    pdfElement: PropTypes.instanceOf(PDFElement),
    readOnly: PropTypes.bool,
    resizable: PropTypes.bool,
    scale: PropTypes.number,
    style: PropTypes.any,
    top: PropTypes.number,
    user: PropTypes.instanceOf(User),
    value: PropTypes.string,
    width: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      width: props.width,
      isActive: props.isNew || false,
      isHover: false,
      isResizing: false,
      isPopoverDisabled: false,
      willDrag: false,
      previewOptions: null,
    };

    this.elementRef = createRef();
    this.containerOutsideRef = createRef();
    this.refSiggy = createRef();
    this.refSiggyMenu = createRef();
    this.refVar = createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.width !== nextProps.width) {
      return {
        width: nextProps.width,
      };
    }

    return null;
  }

  get isReadOnly() {
    const {
      pdfElement: { deal },
      readOnly,
    } = this.props;
    if (this.isRequired) return false;
    return deal.status.data === DealStatus.SIGNED.data || readOnly;
  }

  get dealUser() {
    return _.get(this.props, 'pdfElement.dealUser', null);
  }

  // isRequired means required to be filled, AND fillable by the current user
  // For instance, signatures are always required, but they might not be assigned to current user
  get isRequired() {
    const { pdfElement, user } = this.props;
    const { deal, dealVariable, isFilled } = pdfElement;
    const dealUser = this.dealUser;
    const currentPartyID = _.get(deal, 'currentDealUser.partyID');

    if (isFilled || !user) return false;

    switch (pdfElement.elementType) {
      // SIGNATURE elements are assigned to individual specific users (not just parties)
      case ELEMENT_TYPE.SIGNATURE:
        return (
          _.get(dealUser, 'uid') === user.id ||
          // Also show as required if user's name or email is missing (edge case)
          (deal.isOwner && _.get(dealUser, 'status.data') !== DealStatus.COMPLETE.data)
        );

      case ELEMENT_TYPE.VARIABLE:
        if (!dealVariable) return false;

        switch (dealVariable.type) {
          // PARTY VARIABLE elements (aka DealUser properties) can be edited by contract owners,
          // Or by that specific user (just like SIGNATURE elements)
          case VariableType.PARTY:
            return deal.isOwner || _.get(dealUser, 'uid') === user.id;

          // SIMPLE VARIABLE elements can be edited by contract owners,
          // Or by *any* user in assigned party (this aligns with native deal permissions)
          case VariableType.SIMPLE:
            return deal.isOwner || (currentPartyID && dealVariable.assigned === currentPartyID);

          default:
            return false;
        }

      default:
        return false;
    }
  }

  async handleDelete(pdfElement) {
    await Fire.deletePDFElement(pdfElement);
  }

  handleClickOutside() {
    if (!this.state.isActive) return;
    this.setState({ isActive: false });
  }

  handleClickInside() {
    if (this.isReadOnly || this.state.isActive) return;
    this.setState({ isActive: true });
  }

  handleMouseEnter() {
    if (this.isReadOnly) return;
    this.setState({ isHover: true });
  }

  handleMouseLeave() {
    this.setState({ isHover: false });
  }

  handleDragEnter() {
    this.setState({ willDrag: true });
  }

  handleDragLeave() {
    this.setState({ willDrag: false });
  }

  handleResizeStop(e, direction, ref, d) {
    const newWidth = this.state.width + d.width;
    this.setState({ isResizing: false, width: newWidth });
    this.props.onResize(newWidth);
  }

  handleResizeStart(e) {
    // We need to prevent so that the parent react-dnd does not initiate a dragging
    // There might be a better way, but that works for now.
    e.preventDefault();

    this.setState({ isResizing: true });
  }

  async handleFormatChange(option, value) {
    const { pdfElement } = this.props;

    // If the user changed the font size, we should reset the lineHeight to it,
    // otherwise it'll look bad 90% of the time (very accurate statistic).
    if (option === 'size' && value !== pdfElement.options.size) {
      pdfElement.options.lineHeight = value;
    }

    pdfElement.options[option] = value;
    await Fire.savePDFElement(pdfElement);
  }

  async handleClearSignature() {
    const { pdfElement, user } = this.props;
    const { deal, data: signatureData } = pdfElement;
    const intl = Intl.DateTimeFormat().resolvedOptions();
    const { locale, timeZone } = intl;

    // If there was a signature stored, this is actually a deal event
    if (signatureData) {
      Dealer.call({
        category: Category.DEAL,
        action: DealAction.UNSIGN,
        label: deal.info.sourceTemplateKey || null,
      });

      // Signing with null clears signature
      await API.call('signDeal', {
        dealID: deal.dealID,
        objectID: pdfElement.key,
        data: null,
        userOrigin: user.userOrigin,
        locale,
        timeZone,
      });
    }
  }

  async handleSignature() {
    const {
      pdfElement: { deal },
    } = this.props;
    const { isActive, isHover } = this.state;

    if (deal.status.data !== DealStatus.SIGNING.data) {
      await Fire.updateDealInfo(deal.info, { status: DealStatus.SIGNING.data });
    }

    // Prevent display bugs due to modal opening / signing / event not propagating
    if (isHover || isActive) {
      await this.setState({ isHover: false, isActive: false });
    }
  }

  renderElement() {
    const { onChange, pdfElement, isNew, scale, user } = this.props;
    const { deal, width } = pdfElement;

    const { previewOptions } = this.state;

    switch (pdfElement.elementType) {
      case ELEMENT_TYPE.SIGNATURE:
        let activity = {};
        const maxHeight = convertPointsToPixels(SIGNATURE_HEIGHT_RATIO * width, scale);

        return (
          <Siggy
            ref={this.refSiggy}
            partyID={pdfElement.variable}
            disabled={this.isReadOnly}
            user={this.dealUser}
            currentUser={user}
            deal={deal}
            pdfElement={pdfElement}
            activity={activity}
            toggleDealStatus={_.noop}
            onSignature={this.handleSignature}
            onActionClick={() => this.setState({ isPopoverDisabled: true })}
            signatureMaxHeight={maxHeight}
          />
        );

      case ELEMENT_TYPE.SIMPLE:
        return (
          <ElementText
            className={cl('input')}
            value={pdfElement.data}
            onChange={onChange}
            pdfElementOptions={pdfElement.options}
            scale={scale}
            readOnly={this.isReadOnly}
            previewOptions={previewOptions}
            focus={isNew}
          />
        );
      case ELEMENT_TYPE.VARIABLE:
        const variable = pdfElement.dealVariable;
        const style = getElementStyle({ scale, previewOptions, pdfElementOptions: pdfElement.options });

        if (variable.type === VariableType.PARTY) {
          const [partyID, property = null, subProperty = null] = variable.name.split('.');

          return (
            <DealUserView
              container={this.elementRef.current}
              ref={this.refVar}
              deal={deal}
              user={user}
              inline
              text={_.get(variable, 'val', '')}
              dealUserKey={_.get(this.dealUser, 'key', null)}
              partyID={partyID}
              property={property}
              subProperty={subProperty}
              style={style}
            />
          );
        } else {
          return (
            <VariableView
              ref={this.refVar}
              variable={variable}
              section={deal.root}
              text={`[#${variable.name}]`}
              user={user}
              style={style}
            />
          );
        }
    }
  }

  renderConfig() {
    const { pdfElement, user } = this.props;
    const { deal } = pdfElement;
    const dealUser = this.dealUser;

    const party = _.find(deal.parties, { partyID: _.get(pdfElement, 'variable', '').split('.')[0] });
    const selectedParty = _.get(party, 'displayName', 'Select Party');
    const siggy = this.refSiggy.current;
    const canDelete = !pdfElement.signed && !deal.signed && deal.isOwner;

    const DeleteButton = () => <ButtonIcon icon="trash" dark onClick={() => this.handleDelete(pdfElement)} />;

    switch (pdfElement.elementType) {
      case ELEMENT_TYPE.SIGNATURE:
        return (
          <Popover.ListItem>
            <div className="d-flex justify-content-between align-content-center">
              <div className="btn-actions d-flex align-content-center">
                <div className="assigned-party">{selectedParty}</div>
                <SignatureMenu
                  deal={deal}
                  dealUser={dealUser}
                  handleAction={_.get(siggy, 'handleAction', _.noop)}
                  id={`dd-party-user-${pdfElement.key}`}
                  partyID={pdfElement.variable}
                  user={user}
                  ref={this.refSiggyMenu}
                  title={dealUser ? dealUser.fullName || dealUser.email : 'Assign user'}
                  dark
                />

                {canDelete && <DeleteButton />}
              </div>
            </div>
          </Popover.ListItem>
        );
      case ELEMENT_TYPE.VARIABLE:
      case ELEMENT_TYPE.SIMPLE:
        const { bold, italic, size, font } = pdfElement.options;
        const isVar = pdfElement.elementType === ELEMENT_TYPE.VARIABLE;
        let elementTitle;
        const varType = _.get(pdfElement, 'dealVariable.type');
        const isParty = varType === VariableType.PARTY;
        const [, property] = _.get(pdfElement, 'variable', '').split('.');

        if (isParty) {
          elementTitle = `${party.displayName}: ${_.find(PARTY_PROPERTIES, { data: property }).label}`;
        } else {
          elementTitle = _.get(pdfElement, 'dealVariable.displayName') || _.get(pdfElement, 'dealVariable.name', '');
        }

        return (
          <>
            {isVar && (
              <Popover.ListItem>
                <div className="var-selection">
                  <div className="title">{elementTitle}</div>
                  {!isParty && this.renderVarPartySelector()}
                </div>
              </Popover.ListItem>
            )}

            <Popover.ListItem>
              <div className="d-flex justify-content-between">
                <div className="btn-actions d-flex align-content-center" data-cy="btn-actions">
                  <Dropdown
                    dmpStyle="link"
                    size="small"
                    id={`${pdfElement.key}-dd-font-type`}
                    onSelect={(newFont) => this.handleFormatChange('font', newFont)}
                    title={FONTS[font].displayName}
                    noPadding
                    noUnderline
                    dark
                    data-cy="font-type"
                  >
                    {_.map(FONTS, (fontObj) => (
                      <MenuItem key={fontObj.key} eventKey={fontObj.key} data-cy={fontObj.key}>
                        {fontObj.displayName}
                      </MenuItem>
                    ))}
                  </Dropdown>

                  <Dropdown
                    dmpStyle="link"
                    size="small"
                    id={`${pdfElement.key}-dd-font-size`}
                    onSelect={(newSize) => this.handleFormatChange('size', newSize)}
                    title={`${size}${FONT_SIZE_TYPE}`}
                    noPadding
                    noUnderline
                    dark
                    data-cy="font-size"
                  >
                    {_.map(FONT_SIZES, (fs) => (
                      <MenuItem key={fs} eventKey={fs} data-cy={fs}>
                        {fs + FONT_SIZE_TYPE}
                      </MenuItem>
                    ))}
                  </Dropdown>

                  <ButtonIcon
                    icon="bold"
                    checked={bold}
                    checkbox
                    dark
                    onClick={() => this.handleFormatChange('bold', !bold)}
                    data-cy="btn-bold"
                  />
                  <ButtonIcon
                    icon="italic"
                    checked={italic}
                    checkbox
                    dark
                    onClick={() => this.handleFormatChange('italic', !italic)}
                    data-cy="btn-italic"
                  />
                  {canDelete && <DeleteButton />}
                </div>
              </div>
            </Popover.ListItem>
          </>
        );

      default:
        return null;
    }
  }

  assignVarParty(party) {
    const { pdfElement } = this.props;

    const variable = _.pick(pdfElement.dealVariable, ['type', 'name', 'displayName', 'value', 'prompt', 'valueType']);
    if (variable.type !== VariableType.SIMPLE) {
      delete variable.valueType;
    }
    if ([VariableType.PROTECTED, VariableType.SIMPLE].includes(variable.type)) {
      variable.assigned = party;
    }

    Fire.saveVariableDefinition(pdfElement.deal, variable);
  }

  renderVarPartySelector() {
    const { pdfElement } = this.props;
    const { deal } = pdfElement;

    const assigned = _.get(pdfElement, 'dealVariable.assigned');

    return (
      <PartySelector
        id={`${pdfElement.key}-dd-var-party-selector`}
        size="small"
        dmpStyle="link"
        deal={deal}
        assigned={assigned}
        onSelect={this.assignVarParty}
        className="var-party-selector"
        noPadding
        noUnderline
        dark
      />
    );
  }

  renderField() {
    const { className, pdfElement, draggable, isDragging, resizable, scale } = this.props;
    const { isActive, isHover, willDrag, width } = this.state;

    const minWidth = convertPointsToPixels(MIN_ELEMENT_WIDTH[pdfElement.elementType], scale);
    const adjustedWidth = width + BORDER_SIZE * 2;

    // We must define height in here (and not scss) so that it adjust with the scale.
    let height = 'auto';
    if (pdfElement.elementType === ELEMENT_TYPE.SIGNATURE) {
      height = SIGNATURE_HEIGHT_RATIO * width;
    }

    const classNames = cx(
      cl(),
      { [cl('readonly')]: this.isReadOnly },
      { [cl('draggable')]: draggable },
      { [cl('is-dragging')]: isDragging },
      { [cl('is-active')]: isActive },
      { [cl('is-hover')]: isHover },
      { [cl('will-drag')]: willDrag },
      cl([pdfElement.elementType]),
      className
    );

    if (!resizable) {
      return (
        <div className={classNames} style={{ width: adjustedWidth, height }}>
          <div className={cl('content')}>{this.renderElement()}</div>
        </div>
      );
    }

    const resizerProps = {};
    if (pdfElement.elementType === ELEMENT_TYPE.SIGNATURE) {
      resizerProps.lockAspectRatio = true;
    }

    return (
      <Resizable
        className={classNames}
        enable={RESIZABLE_ENABLE}
        handleWrapperClass={cl('resize-handle right')}
        onResizeStart={this.handleResizeStart}
        onResizeStop={this.handleResizeStop}
        size={{ width: adjustedWidth, height }}
        minWidth={minWidth}
        minHeight={20}
        {...resizerProps}
      >
        <div className={cl('content')}>{this.renderElement()}</div>
      </Resizable>
    );
  }

  render() {
    const { isDragging, pdfElement, dragHandleRef, id, left, top, style, className } = this.props;
    const { isActive, isHover, isResizing } = this.state;

    let zIndex = style.zIndex || 1;
    if (isActive) {
      zIndex = zIndex + 50;
    }

    const isPopoverVisible = pdfElement.canConfigure && isActive && !isResizing && !isDragging;
    const clpop = classNamePrefixer('popover-element', 'pdfe');

    const wrapperClassNames = cx(
      'pdfe-wrapper',
      { 'pdfe-wrapper-is-active': isActive },
      { 'pdfe-wrapper-is-hover': isHover },
      {
        unassigned:
          pdfElement.elementType === ELEMENT_TYPE.SIGNATURE && !pdfElement.dealUser && pdfElement.deal.isOwner,
      },
      { required: this.isRequired }
    );

    return (
      <div style={{ left, top, position: 'absolute' }}>
        <OutsideClickHandler onOutsideClick={this.handleClickOutside}>
          <div
            ref={this.elementRef}
            id={id}
            className={wrapperClassNames}
            onClick={this.handleClickInside}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            style={{
              ...style,
              zIndex,
            }}
          >
            {dragHandleRef && (
              <div
                className="drag-handle"
                ref={dragHandleRef}
                onMouseEnter={this.handleDragEnter}
                onMouseLeave={this.handleDragLeave}
                data-cy="drag-handle"
              >
                <Icon name="dragndrop" dark />
              </div>
            )}
            <div className="handle-pusher" data-cy="handle-pusher">
              {this.renderField()}
            </div>
          </div>
          {isPopoverVisible && (
            <Overlay
              placement="top"
              show={true}
              shouldUpdatePosition
              target={this.elementRef.current}
              container={this.elementRef.current}
            >
              <Popover
                id={`${cl()}-${pdfElement.key}`}
                className={cx(clpop(), clpop(`type-${pdfElement.elementType}`), className)}
                size={'small'}
                type={'dark'}
                list
                dmpPlacement="topleft"
              >
                {this.renderConfig()}
              </Popover>
            </Overlay>
          )}
        </OutsideClickHandler>
        <div ref={this.containerOutsideRef} />
      </div>
    );
  }
}

export default Element;
