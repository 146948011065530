import { cloneDeep, find, forEach } from 'lodash';

import { ValueType } from '../Variable';

// https://developer.filevine.io/docs/v2/6b4cf68d0eccc-get-contact-metadata
// There are inconsistencies/omissions in FV's Contact field definition,
// as it excludes a few fields (even though they're standard/supported)
// So we're defining these base fields, but then merging in the rest via API
const baseFields = [
  // Added manually -- no property selection will be transformed to the Contact's fullName
  {
    data: null,
    label: 'Full Name (default)',
    writable: false,
    valueType: 'string',
  },

  // Case issues -- birthdate
  {
    data: 'birthDate',
    label: 'Birthday',
    description: null,
    writable: true,
    valueType: 'date',
  },

  // Not returned in API call
  {
    data: 'deathDate',
    label: 'Deathday',
    description: null,
    writable: true,
    valueType: 'date',
  },

  // Not returned in API call
  {
    data: 'isDeceased',
    label: 'Is Deceased',
    description: null,
    writable: true,
    valueType: 'list',
    prompt: 'Yes\nNo',
  },
];

export const addressFields = [
  {
    data: null,
    label: 'Full Address',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'line1',
    label: 'Line 1',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'line2',
    label: 'Line 2',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'city',
    label: 'City',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'state',
    label: 'State',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'postalCode',
    label: 'Postal Code',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'label',
    label: 'Label',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
];

// This is necessary to still support imports prior to API call returning and merging
let fields = cloneDeep(baseFields);

export const mergeFields = (customFields) => {
  fields = cloneDeep(baseFields);

  forEach(customFields, (field) => {
    // Some legacy issue causes a duplicate "birthdate" field to be returned with the wrong case
    // Ignore this one so that the correct base field we've defined is used. I know. Fuck.
    if (field.data === 'birthdate') return;

    if (field.data === 'addresses') {
      fields.push({
        ...field,
        valueType: ValueType.ADDRESS,
      });
    } else {
      const base = find(baseFields, { data: field.data });
      if (!base) fields.push(field);
    }
  });
};

export const contactFields = () => fields;
