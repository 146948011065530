import { compact, find, get, map, forEach, isNil, cloneDeep } from 'lodash';

import { AI_PROMPT as TIMELINE_AI_PROMPT_TEXT } from './TimelineEvent';
import { EXTERNAL_TYPES, ValueType, VariableType } from './Variable';
import { rxVariable } from '@core/models/Content';

export const DEMANDS_AI_PROMPT = {
  system: `You are an experienced personal injury attorney who is an expert in writing demand letters for law firms. Specifically, you are a specialized drafting consultant for law firms. You are given an example or examples of a law firm's customary language for a section of their demand letters from their past cases. Then, you are given details of a new case, and you use the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples to draft the corresponding section for a demand letter reflecting the details of the new case. The sections that you draft apply the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples but use the details of the new case to create an effective section of the demand letter for the law firm. 
  
When you draft a section, the section should emulate the style, format, tone, voice, purpose, structure, word choice, and types of information included in the example sections, which are placed in the <example> </example> XML tags. If an item of case details begins with "[+fv", consider that entry as indicating that there is no information for that item, and that such item should be omitted from the section and not considered during the drafting of the section. "[+fv" is appearing because there was nothing available to be pulled from the data set for that item of case details.

Read the details and the examples carefully before drafting the section because the section you draft will reflect the style, format, tone, voice, purpose, structure, and word choice of the examples and the facts of the details. Only include the details in your draft which are relevant for the particular section being drafted, which you determine as relevant through a comprehensive review of the examples and the types of information which they discuss. If a type of information is included with the details, but the examples do not reference or discuss that type of information, do NOT include it in your section.`,

  user: `Carefully and thoroughly review the details placed in the <details></details> XML tags and the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples placed in the <example></example> XML tags.
  Referencing the details placed in the <details></details> XML tags and the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples placed in the <example></example> XML tags, draft the corresponding section of the demand letter.
  Think step by step within <thinking></thinking> XML tags. Then, draft the section within <draft></draft> XML tags.
  For the factual details of the incident, use ONLY the details placed in the <details></details> XML tags, and do not use any of the facts from the examples. Refer to the examples ONLY for tailoring the style, format, tone, voice, purpose, structure, word choice, and types of information of the section and NOT for the facts, details, or information of the incident.`,
};

export const AI_ENGINES = [
  {
    title: 'Open AI',
    key: 'openAI_gpt_4',
    description: '',
    model: 'gpt-4',
    systemPrompt: true,
    max_tokens: 8191,
  },
  {
    title: 'Open AI',
    key: 'openAI',
    description: '',
    model: 'gpt-3.5-turbo-16k',
    systemPrompt: true,
    max_tokens: 16384,
  },
  {
    title: 'Anthropic',
    key: 'anthropic',
    description: '',
    model: 'claude-2.1',
    systemPrompt: true,
    max_tokens: 4096,
  },
  {
    title: 'Anthropic',
    key: 'anthropic_opus',
    description: '',
    model: 'claude-3-opus-20240229',
    systemPrompt: true,
    max_tokens: 4096,
  },
  {
    title: 'Anthropic',
    key: 'anthropic_sonnet',
    description: '',
    model: 'claude-3-sonnet-20240229',
    systemPrompt: true,
    max_tokens: 4096,
  },
];

export const STEP_TYPES = {
  SYSTEM: 'system',
  USER: 'user',
  ASSISTANT: 'assistant',
};

export const RESPONSE_TYPES = [
  { 
    key: 'block', 
    title: 'Self - block', 
    description: 'Single paragraph that should populate the body of this AI Block' 
  },
  {
    key: 'list',
    title: 'Self - list',
    description: 'Multiple paragraphs that should populate the children of this AI Block as separate sections'
  },
  {
    key: 'variable',
    title: 'Variable',
    description: 'Data that should populate a target Variable'
  },
];

export const DATA_SOURCE_TYPES = {
  SECTIONS: 'Sections',
  VARIABLES: 'Variables',
};

const TIMELINE_PROMPT = [
  { role: STEP_TYPES.SYSTEM, content: TIMELINE_AI_PROMPT_TEXT, step: 'System Prompt' },
  { role: STEP_TYPES.USER, content: '', step: 'User' },
];

//Outlaw user is the AI instruction step.
//We will use this step to append onto the user step.
const DEMANDS_PROMPT = [
  { role: STEP_TYPES.SYSTEM, content: DEMANDS_AI_PROMPT[STEP_TYPES.SYSTEM], step: 'System Prompt' },
  { role: STEP_TYPES.USER, content: DEMANDS_AI_PROMPT[STEP_TYPES.USER], step: 'User' },
];

//Empty prompt for legacy/total customizable blocks.
const DEFAULT_PROMPT = [
  { role: STEP_TYPES.USER, content: '', step: 'User' },
];

//Will be pulled from DB along with the structure for v2.
export const BLOCK_TYPE = {
  DEFAULT: 'default',
  DEMANDS: 'demands',
  TIMELINE: 'timeline',
};

export const BLOCK_PROMPT = {
  default: DEFAULT_PROMPT,
  demands: DEMANDS_PROMPT,
  timeline: TIMELINE_PROMPT,
};

export const BLOCK_PROMPT_LABEL = {
  default: 'None (default)',
  demands: 'Demands AI',
  timeline: 'Timeline',
};

export const RESPONSE_OPTIONS = [1, 2, 3];

// ES: We're simplifying a lot so this is moot... there's no more 3rd "OUTLAW_USER" type,
// and we're not allowing editing of system prompts on a per-block basis
/*
export const togglePromptType = (type, currentPrompt) => {
  //we want to maintain the current instructions when we toggle.
  const currentInstruction = find(currentPrompt, { role: STEP_TYPES.OUTLAW_USER });

  const newPrompt = map(BLOCK_PROMPT[type], (step) => {
    if (step.role === STEP_TYPES.OUTLAW_USER) step.content = currentInstruction.content;
    return step;
  });

  return newPrompt;
};
*/


//V2
//Outlaw admins will build prompts and save them at a universal level in outlaw. (They will enable prompts based on some criteria)
//Preconfiged Prompts will be selected on the template/deal for use. (Always hidden though)
//

export default class AIPrompt {
  engine = null;
  prompt = [];
  actionName = '';
  linkedSections = [];
  linkedVariables = '';
  type = null;
  responseOptions = 1;
  temperature = 1;
  responseType = 'block';
  outputVariable = null;
  dsType = DATA_SOURCE_TYPES.SECTIONS;
  autorun = false;
  lastResponse = null;
  // Normally we'd track this in an unstored component state var,
  // but because we have multiple sections with AIPrompts potentially auto-running in sequence,
  // and because a lengthy/complex prompt can take a while to complete, 
  // it's safest to actually just store the running state in db while active
  isRunning = false;

  // ES: moved to static class method to follow factory pattern
  // and removed "legacy" from name since this is actually our standard (current) only path to instantiate new AIPrompts
  static buildJSON(section, workflow, type) {
    const aiPrompt = {};
    aiPrompt.actionName = get(section, 'actionName', 'Generate');
    aiPrompt.responseOptions = 1;

    if (workflow) {
      //This means its a demand setup
      aiPrompt.prompt = workflow.serviceProviders ? cloneDeep(DEMANDS_PROMPT) : cloneDeep(DEFAULT_PROMPT);
      aiPrompt.engine = workflow.aiEngine || AI_ENGINES[0].key;
      aiPrompt.type = workflow.serviceProviders ? BLOCK_TYPE.DEMANDS : BLOCK_TYPE.DEFAULT;
    } else {
      aiPrompt.prompt = cloneDeep(DEFAULT_PROMPT);
      aiPrompt.engine = AI_ENGINES[0].key;
      aiPrompt.type = BLOCK_TYPE.DEFAULT;
    }

    //TODO: this is where we'll extend the logic and Workflow models
    //to allow admins to define Workflow-specific AIPrompt templates and apply them to blocks
    //right now we're leaving the above logic to auto-discover type from the Workflow
    //but these will be merged
    if (type) {
      const promptTemplate = BLOCK_PROMPT[type];
      if (promptTemplate) {
        aiPrompt.prompt = cloneDeep(promptTemplate);
        aiPrompt.type = type;
      }
    }

    // Legacy blocks had a simple text field for the AI prompt on the Section
    // if we find that, map it to the STEP_USER piece of the AIPrompt model
    // which is where that block is now editable
    if (section.prompt) {
      const userPrompt = find(aiPrompt, {role: STEP_TYPES.USER});
      if (userPrompt) {
        userPrompt.content = section.prompt;
      }
    }

    // Legacy prompts used section linking to target source data
    // translate these if we find them
    if (section.children?.length > 0) {
      const linkedIDs = map(section.children, 'id');
      aiPrompt.linkedSections = linkedIDs.join('|');
    }

    return aiPrompt;
  };


  constructor(json, deal) {
    this.deal = deal;

    this.actionName = get(json, 'actionName', 'Generate');
    this.engine = find(AI_ENGINES, { key: json.engine }) || AI_ENGINES[0];
    this.prompt = get(json, 'prompt', DEFAULT_PROMPT);
    this.linkedSections = json.linkedSections ? json.linkedSections.split('|') : [];
    this.linkedVariables = get(json, 'linkedVariables', '');
    this.type = get(json, 'type', BLOCK_TYPE.DEFAULT);
    this.responseOptions = get(json, 'responseOptions', 1);
    this.temperature = get(json, 'temperature', 1);
    this.responseType = get(json, 'responseType', 'block');
    this.outputVariable = get(json, 'outputVariable') || null;
    this.dsType = get(json, 'dsType', DATA_SOURCE_TYPES.SECTIONS);
    this.autorun = get(json, 'autorun', false);
    this.lastResponse = get(json, 'lastResponse', null);
    this.isRunning = get(json, 'isRunning', false);
  }

  get json() {
    return {
      engine: this.engine.key,
      actionName: this.actionName,
      prompt: this.prompt,
      linkedSections: this.linkedSections.length > 0 ? this.linkedSections.join('|') : null,
      linkedVariables: this.linkedVariables || null,
      type: this.type,
      responseOptions: this.responseOptions,
      responseType: this.responseType,
      temperature: this.temperature,
      outputVariable: this.outputVariable || null,
      dsType: this.dsType,
      autorun: this.autorun || null,
      lastResponse: isNil(this.lastResponse) ? null : this.lastResponse,
      isRunning: this.isRunning ? true : null,
    };
  }

  get dataSourceVariables() {
    //find variable ranges and add them as styles for flattened rendering
    let variables = [], matchVar;

    while ((matchVar = rxVariable.exec(this.linkedVariables)) !== null) {
      const varName = matchVar[0].slice(2, matchVar[0].length-1).split('.')[0];
      const v = this.deal.variables[varName];
      if (v) variables.push(v);
    }

    return variables;
  }

  get dataSourceAI() {
    
    switch (this.dsType) {
      case DATA_SOURCE_TYPES.VARIABLES:
        const varValues = [];
        const variables = this.dataSourceVariables;

        forEach(variables, (v) => {
          let promptText = `${v.displayName || v.name}: `;
          let val = v.val;
          const emptyValText = `[${v.type}${v.name}]`;
          if (v.valueType === ValueType.TABLE) {
            if (get(v, 'val.length') > 0) {
              promptText += '\n';
              promptText += JSON.stringify(val);
            } else {
              promptText += emptyValText;
            }
          } else {
            if (v.val) promptText += v.val;
            else promptText += emptyValText;
          }
          
          varValues.push(promptText);
        })
        return varValues.join('\n\n');

      case DATA_SOURCE_TYPES.SECTIONS:
      default:
        let table = null,
          text = null,
          source = [];

        const children = compact(map(this.linkedSections, (id) => this.deal.sections[id]));

        find(children, (child) => {
          table = find(child.variables, (v) => {
            return v.valueType === ValueType.TABLE || v.externalType === EXTERNAL_TYPES.COLLECTION;
          });

          if (table) return true;
          return false;
        });

        if (table) {
          return table.val;
        } else {
          source = children;

          // If we're pointing at a LIST, use that list's children as the source content
          if (source.length > 0 && source[0].isList && !source[0].isAI) {
            source = source[0].items;
          }

          text = map(source, (sec) => sec.currentVersion.getText('body', true, this.deal.variables)).join('\n\n');
          return text.trim() || null;
        }
    }
  }

  // This uses the same logic as dataSourceAI() to get the underlying target sections,
  // but the text output is formatted specifically for Timeline generation,
  // which requires explicit Paragraph (Section) IDs to be included for "source" linking
  get timelineSourceAI() {
    let table = null,
      text = null,
      source = [];

    const children = map(this.linkedSections, (id) => this.deal.sections[id]);

    find(children, (child) => {
      table = find(child.variables, (v) => {
        return v.valueType === ValueType.TABLE || v.externalType === EXTERNAL_TYPES.COLLECTION;
      });

      if (table) return true;
      return false;
    });

    if (table) {
      return table.val;
    } else {
      source = children;

      // If we're pointing at a LIST, use that list's children as the source content
      if (source.length > 0 && source[0].isList && !source[0].isAI) {
        source = source[0].items;
      }

      text = map(source, (sec) => {
        let para = `Paragraph ID: ${sec.id}\n`;
        para += `Paragraph Text: \n`;
        para += sec.currentVersion.getText('body', true, this.deal.variables);
        return para; 
      }).join('\n\n');

      return text.trim() || null;
    }
  }


  // TODO: this works, but results in chaotic behavior where multiple blocks are autorunning at once
  // we should move this out of the individual block level (and probably to the server side)
  // to ensure that only 1 AI Block can autorun at a time, even if several are ready to go
  // Also move the "loading" state variable to be saved/stored on the AIPrompt 
  // so that the user can still watch the magic sequentially while the autorun is executing
  get canAutorun() {
    const { autorun, isRunning, lastResponse, dataSourceAI } = this;

    // If autorun is currently running or has already run, stop here
    if (!autorun || isRunning || lastResponse || !dataSourceAI) return false;

    // console.log(`[${this.actionName}]`, 'Passed initial autorun check');

    if (this.dsType === DATA_SOURCE_TYPES.SECTIONS) {
      const linkedSections = map(this.linkedSections, (id) => this.deal.sections[id]);
      const todo = find(linkedSections, (sec) => !sec || !sec.content || sec.todo > 0);
      if (todo) {
        // console.log(`[${this.actionName}]`, todo);
        // console.log(`[${name}]`, 'Autorun cancelled: missing vars in linked sections');
        return false;
      }
    }
    else if (this.dsType === DATA_SOURCE_TYPES.VARIABLES) {
      // TODO: check that all vars are present
    }
    
    // NO NAME/ID specific property available for AIPrompt...
    // console.log(`[${name}]`, 'Autorun running!');
    // console.log('I SHOULD AUTORUN');

    return true;
  }

  get userPromptText() {
    return find(this.prompt, { role: STEP_TYPES.USER })?.content || '';
  }

  get systemPromptText() {
    return find(this.prompt, { role: STEP_TYPES.SYSTEM })?.content || '';
  }

  buildAPIPrompt(source) {
    let messages, system, user, outlaw;
    switch (this.engine.key) {
      case 'anthropic':
      case 'anthropic_opus':
      case 'anthropic_sonnet':
        //Open ai is structured where system is passed in seperately then we have user, assistant, user, assistant...
        //we will always have one system prompt max.
        system = find(this.prompt, { role: STEP_TYPES.SYSTEM })?.content || '';
        //currently we only support
        user = find(this.prompt, { role: STEP_TYPES.USER })?.content || '';
        messages = [{ role: STEP_TYPES.USER, content: `${user}\n\n${source}` }];
        break;
      case 'openAI':
      default:
        //Open ai is an array where we have system, user, assistant, user, assistant...
        //we will always have one system prompt max.
        system = find(this.prompt, { role: STEP_TYPES.SYSTEM })?.content || '';
        user = find(this.prompt, { role: STEP_TYPES.USER })?.content || '';
        messages = [];
        
        if (system) {
          messages.push({ role: STEP_TYPES.SYSTEM, content: system });
        }
        if (user || source) {
          messages.push({ role: STEP_TYPES.USER, content: `${user}\n\n${source}` })
        }
        break;
    }

    return { system, messages };
  }
}
