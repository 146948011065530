import React, { Component } from 'react';

import axios from 'axios';
import autobindMethods from 'class-autobind-decorator';
import { saveAs } from 'file-saver';
import JsZip from 'jszip';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, Modal, Radio } from 'react-bootstrap';

import Deal, { ASPECTS } from '@core/models/Deal';
import { paying } from '@core/models/StripeCustomer';
import User from '@core/models/User';
import { Dt, dt, generateExportURL } from '@core/utils';

import { Button, Checkbox, Dropdown, Loader, MenuItem } from '@components/dmp';

import Fire from '@root/Fire';

const DOC_TYPES = [
  {
    key: 'docx',
    title: 'Microsoft Word Document',
    button: 'Export .docx',
    test: () => true,
  },
  {
    key: 'pdf',
    title: 'PDF Document',
    button: 'Export .pdf',
    test: () => true,
  },
  {
    key: 'vine',
    title: 'Vine Document',
    button: 'Export .vine',
    test: (user) => user.isAdmin,
  },
];

@autobindMethods
export default class ContractExporter extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    deal: PropTypes.instanceOf(Deal).isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    subscription: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      type: 'pdf',
      preserveVariables: false,
      includeCertificate: true,
      appendActivity: false,
      emptyBlocks: false,
      exporting: false,
      downloadURL: '',
      exportBundle: false,
    };
  }

  async generateBundledContracts() {
    const { deal } = this.props;
    const promises = [];
    const children = [];
    const parent = await Fire.getDeal(deal.bundle.parent.dealID);
    const bundleID = deal.bundleID;

    _.map(deal.bundle.children, async (child) => {
      promises.push(
        new Promise(async (resolve) => {
          children.push(await Fire.getDeal(child.dealInfo.dealID));
          resolve();
        })
      );
    });

    await Promise.all(promises);
    return { bundledContracts: [...children, parent], bundleID };
  }

  export() {
    const { type, preserveVariables, includeCertificate, emptyBlocks, appendActivity, exportBundle } = this.state;
    const { deal, close, user } = this.props;
    const userOrigin = user.userOrigin;
    this.setState({ exporting: true });

    const download = async (token, docType, options) => {
      if (deal.isBundle && exportBundle) {
        //https://huynvk.dev/blog/download-files-and-zip-them-in-your-browsers-using-javascript
        //zip the contract and export them using file-saver.
        const { bundledContracts, bundleID } = await this.generateBundledContracts();
        const zip = JsZip();
        const promises = [];
        _.forEach(bundledContracts, async (contract) => {
          promises.push(
            new Promise(async (resolve) => {
              const url = generateExportURL({ deal: contract, token, type: docType, options });
              const blob = await axios.get(url, { responseType: 'blob' });
              zip.file(`${contract.name}.${type}`, blob.data, { binary: true });
              resolve();
            })
          );
        });

        await Promise.all(promises);
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `${bundleID}.zip`);
        });
      } else {
        this.setState({ downloadURL: generateExportURL({ deal, token, type: docType, options }) }, () => {
          // Don't actually follow the download URL in testing mode; just generate it
          if (typeof window.Cypress === 'undefined') {
            this.refs.download.click();
          }
        });
      }

      this.setState({ exporting: false });
      close();
    };

    switch (type) {
      //docx and pdf invoke click() on hidden link to create download action
      case 'docx':
        Fire.token((token) => download(token, 'docx', { preserveVariables }));
        break;
      case 'pdf':
        const aspects = [ASPECTS.CONTRACT];
        if (appendActivity) aspects.push(ASPECTS.ACTIVITY);
        Fire.token((token) => download(token, 'pdf', { aspects: aspects.join(','), emptyBlocks, includeCertificate }));
        break;
      case 'json':
      case 'vine':
        Fire.token((token) => download(token, type));
        break;
      default:
        break;
    }
  }

  render() {
    const { show, close, user, deal } = this.props;
    const { type, includeCertificate, emptyBlocks, appendActivity, downloadURL, exportBundle } = this.state;
    const { title } = _.find(DOC_TYPES, { key: type });

    const types = _.filter(DOC_TYPES, (type) => type.test(user));

    return (
      <Modal dialogClassName="export-contract" show={show} onHide={close}>
        <Modal.Header closeButton>
          <span className="headline">Export as...</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <FormGroup>
              <ControlLabel>Format</ControlLabel>
              <div className="contents">
                <Dropdown id="dd-export" title={title} onSelect={(type) => this.setState({ type })} block>
                  {types.map((t) => (
                    <MenuItem key={t.key} eventKey={t.key}>
                      {t.title}
                    </MenuItem>
                  ))}
                </Dropdown>
              </div>
            </FormGroup>
          </div>

          {type === 'pdf' && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Settings</ControlLabel>
                <div className="export-options">
                  {deal.signed && (
                    <div className="option">
                      <Checkbox
                        id="check-certificate"
                        checked={includeCertificate}
                        onChange={() => this.setState({ includeCertificate: !includeCertificate })}
                      >
                        Include certificate of completion
                      </Checkbox>
                    </div>
                  )}

                  <div className="option">
                    <Checkbox
                      id="check-preserve"
                      checked={appendActivity}
                      onChange={() => this.setState({ appendActivity: !appendActivity })}
                    >
                      Export activity log
                    </Checkbox>
                  </div>

                  {!deal.signed && (
                    <div className="option">
                      <Checkbox
                        id="check-empty"
                        checked={emptyBlocks}
                        onChange={() => this.setState({ emptyBlocks: !emptyBlocks })}
                      >
                        Blank signature fields for wet ink signing
                      </Checkbox>
                      <small>
                        Unsigned signature blocks will be fully empty for parties to manually enter details and sign
                        outside of Outlaw
                      </small>
                    </div>
                  )}
                </div>
              </FormGroup>
            </div>
          )}

          {deal.isBundle && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Bundle</ControlLabel>
                <div className="export-options">
                  <div className="option">
                    <Radio
                      id="check-single-contract"
                      checked={!exportBundle}
                      onChange={() => this.setState({ exportBundle: !exportBundle })}
                    >
                      Export this document only
                    </Radio>
                  </div>

                  <div className="option">
                    <Radio
                      id="check-bundled-contracts"
                      checked={exportBundle}
                      onChange={() => this.setState({ exportBundle: !exportBundle })}
                    >
                      Export all documents in bundle
                    </Radio>
                  </div>
                </div>
              </FormGroup>
            </div>
          )}

          <a ref="download" href={downloadURL} download />
        </Modal.Body>

        <Modal.Footer>
          {this.state.exporting && <Loader />}

          <Button
            dmpStyle="primary"
            className="export"
            disabled={this.state.exporting}
            onClick={() => this.export()}
            data-cy="export"
          >
            {exportBundle ? 'Export files' : 'Export file'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
