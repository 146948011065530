import React, { Component } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import { LETTERHEAD_OPTIONS } from '@core/models/Branding';
import { BEHAVIOR, PDF_CHAR_SETS, PDF_CHAR_SETS_DEFAULT } from '@core/models/Deal';
import Team from '@core/models/Team';
import { Dt, dt } from '@core/utils';

import { Button, Checkbox, Dropdown, Loader, MenuItem, Switch } from '@components/dmp';

import LogoUploader from '@components/LogoUploader';
import ThemeSelector from '@components/teams/ThemeSelector';
import Fire from '@root/Fire';

const TABS = [
  { key: 'general', title: 'General' },
  { key: 'branding', title: 'Page' },
  { key: 'users-guests', title: 'Users & Guests' },
];

export default class DealSettings extends Component {
  static defaultProps = {
    mode: null,
    show: false,
  };

  static propTypes = {
    deal: PropTypes.object.isRequired,
    mode: PropTypes.string,
    team: PropTypes.instanceOf(Team),
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    show: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      legalName: '',
      address: '',
      logo: null,
      license: '',
      pdfCharSet: null,
      theme: null,

      saving: false,
      tab: TABS[0],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this._isMounted) this.populate(props);
  }

  populate(props) {
    const { deal } = props;
    if (!deal) return;

    const { info } = deal;

    this.setState({
      name: info.name,
      legalName: info.legalName || '',
      license: info.license || '',
      address: info.address || '',
      logo: info.logo || null,
      pdfCharSet: deal.pdfCharSet || null,
      theme: deal.theme,
      letterhead: info.letterhead || null,
    });
  }

  async save() {
    const { pdfCharSet, theme } = this.state;
    const { deal, onSave } = this.props;
    const info = _.pick(this.state, ['name', 'legalName', 'license', 'address', 'logo', 'letterhead']);

    this.setState({ saving: true });

    // Must happen first
    if (pdfCharSet && pdfCharSet !== deal.pdfCharSet) {
      await Fire.updateDealPDFCharSet(deal.dealID, pdfCharSet);
    }

    if (theme !== deal.theme) {
      await Fire.updateDealBehavior(deal.dealID, BEHAVIOR.theme.key, theme);
    }

    await Fire.updateDealInfo(deal.info, info);

    this.setState({ saving: false });
    onSave();
  }

  updateBehavior(aspect, value) {
    const { deal } = this.props;
    Fire.updateDealBehavior(deal.dealID, aspect, value);
  }

  handleChange(e, prop) {
    const newState = { saved: false };
    newState[prop] = e.target.value;
    this.setState(newState);
  }

  render() {
    const { deal, show, onHide, mode, team } = this.props;
    const { tab, saving, name, legalName, license, address, logo, pdfCharSet, theme, letterhead } = this.state;

    const currentPdfCharSet = pdfCharSet || PDF_CHAR_SETS_DEFAULT;
    const pdfCharSetTitle = _.get(PDF_CHAR_SETS[currentPdfCharSet], 'displayName');
    const currentCharSetKey = _.get(PDF_CHAR_SETS[currentPdfCharSet], 'key');
    const letterheadTitle = deal.letterheadType
      ? _.find(LETTERHEAD_OPTIONS, (option) => {
          return option.key === deal.letterheadType;
        }).value
      : LETTERHEAD_OPTIONS[0].value;

    return (
      <Modal dialogClassName="deal-settings" show={show} onHide={onHide} enforceFocus={false}>
        <Modal.Header closeButton>
          <span className="headline">{Dt} Settings</span>
        </Modal.Header>

        <Modal.Body>
          <div className="wrapper">
            {/*
              In DRAFT, don't show tabs because only the first tab (title)
              is relevant in this environment; same goes for EXTERNAL deals
            */}
            {mode !== 'draft' && !deal.isExternal && (
              <ButtonGroup className="tab-selector">
                {TABS.map((t) => (
                  <Button key={t.key} active={tab == t} onClick={() => this.setState({ tab: t })} data-cy={t.key}>
                    {t.title}
                  </Button>
                ))}
              </ButtonGroup>
            )}

            {tab.key == 'general' && (
              <div className="fields tab-general">
                <FormGroup>
                  <ControlLabel>Title</ControlLabel>
                  <div className="contents">
                    <FormControl
                      type="text"
                      ref="dealName"
                      disabled={saving}
                      value={name}
                      placeholder={`Enter ${dt} title`}
                      onChange={(e) => this.handleChange(e, 'name')}
                      data-cy="deal-setting-title"
                    />
                  </div>
                </FormGroup>

                {!deal.isExternal && ( // don't show character set options for uploaded (pdf) deals
                  <>
                    <hr />
                    <FormGroup>
                      <ControlLabel>PDF Output</ControlLabel>
                      <div className="contents">
                        <Dropdown
                          id="dd-pdf-char-set"
                          title={pdfCharSetTitle}
                          onSelect={(set) => this.setState({ pdfCharSet: set })}
                          block
                        >
                          {_.map(PDF_CHAR_SETS, (set, idx) => (
                            <MenuItem key={idx} eventKey={set.key} active={set.key === currentCharSetKey}>
                              {set.displayName}
                            </MenuItem>
                          ))}
                        </Dropdown>
                      </div>
                    </FormGroup>
                  </>
                )}
              </div>
            )}

            {tab.key == 'branding' && (
              <div className="fields tab-branding">
                <FormGroup>
                  <ControlLabel>Theme</ControlLabel>
                  <div className="contents">
                    <ThemeSelector
                      id="theme-selector-deal"
                      onSelect={(theme) => this.setState({ theme })}
                      themeKey={theme}
                      team={team}
                      block
                    />
                  </div>
                </FormGroup>
                <hr />

                <FormGroup className="formatting toggle-only">
                  <ControlLabel>Header</ControlLabel>
                  <div className="contents">
                    <div className="toggle-verbose">
                      <Switch
                        checked={deal.showLetterhead}
                        id="chk-letterhead"
                        onChange={() => this.updateBehavior('showLetterhead', !deal.showLetterhead)}
                        size="small"
                      >
                        Show letterhead
                      </Switch>
                      <small className="content-switch">Show team logo and address on header</small>
                      {deal.showLetterhead && (
                        <Dropdown
                          onSelect={(option) => this.updateBehavior('letterheadType', option)}
                          title={letterheadTitle}
                          pullRight
                          block
                          size="small"
                          className="letterhead-selector"
                        >
                          {_.map(LETTERHEAD_OPTIONS, (option, idx) => (
                            <MenuItem eventKey={option.key} key={idx}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </Dropdown>
                      )}
                    </div>
                    <div className="toggle-verbose">
                      <Switch
                        checked={deal.showTitle}
                        id="chk-show-title"
                        onChange={() => this.updateBehavior('showTitle', !deal.showTitle)}
                        size="small"
                      >
                        Show title
                      </Switch>
                      <small className="content-switch">
                        Use {dt} title as first section in {dt}
                      </small>
                    </div>
                  </div>
                </FormGroup>

                {deal.showLetterhead && (
                  <>
                    <FormGroup>
                      <ControlLabel>Logo</ControlLabel>
                      <LogoUploader img={logo} onUpload={(logo) => this.setState({ logo })} />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Letterhead</ControlLabel>
                      <LogoUploader
                        img={letterhead}
                        type="letterhead"
                        onUpload={(letterhead) => this.setState({ letterhead })}
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Legal Name</ControlLabel>
                      <FormControl
                        type="text"
                        value={legalName}
                        placeholder="e.g., Startup Inc."
                        onChange={(e) => this.handleChange(e, 'legalName')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Address</ControlLabel>
                      <FormControl
                        componentClass="textarea"
                        type="text"
                        value={address}
                        placeholder="e.g., Street address, City, State, ZIP"
                        onChange={(e) => this.handleChange(e, 'address')}
                      />
                    </FormGroup>
                  </>
                )}

                <hr />

                <FormGroup>
                  <ControlLabel>Footer</ControlLabel>
                  <FormControl
                    type="text"
                    value={license}
                    placeholder="e.g., Confidential and proprietary."
                    onChange={(e) => this.handleChange(e, 'license')}
                  />
                </FormGroup>
              </div>
            )}

            {tab.key === 'users-guests' && (
              <div className="fields tab-behavior">
                <FormGroup className="guest-signing toggle-only">
                  <ControlLabel>Guest access</ControlLabel>
                  <div className="contents">
                    <div className="toggle-verbose">
                      <Checkbox
                        id="chk-guest"
                        checked={deal.guestSigning}
                        onChange={() => this.updateBehavior('guestSigning', !deal.guestSigning)}
                        placement="right"
                      >
                        Invited users can access {dt} as a guest
                      </Checkbox>
                      <small>Users do not need to an Outlaw account to view, edit and sign a {dt}.</small>
                    </div>
                    <div className="toggle-verbose">
                      <Checkbox
                        disabled={!deal.guestSigning}
                        checked={deal.autoGuest}
                        id="chk-auto-guest"
                        onChange={() => this.updateBehavior('autoGuest', !deal.autoGuest)}
                        placement="right"
                      >
                        Bypass log in page for guests
                      </Checkbox>
                      <small>
                        Guests can save their {dt} to an Outlaw account during their active browser session.
                      </small>
                    </div>
                  </div>
                </FormGroup>

                <hr />

                <FormGroup className="guest-signing toggle-only">
                  <ControlLabel>Sharing</ControlLabel>
                  <div className="contents">
                    <div className="toggle-verbose">
                      <Checkbox
                        id="chk-sharing"
                        checked={deal.readonly}
                        onChange={() => this.updateBehavior('readonly', !deal.readonly)}
                        placement="right"
                      >
                        Allow sharing by URL (read-only)
                      </Checkbox>
                      <small>{Dt} will be visible if accessed by its unique URL.</small>
                      <small className="text-danger">
                        This setting is not recommended for {dt}s containing sensitive information, use with caution.
                      </small>
                    </div>
                  </div>
                </FormGroup>

                <hr />

                <FormGroup className="guest-signing toggle-only">
                  <ControlLabel>Commenting</ControlLabel>
                  <div className="contents">
                    <div className="toggle-verbose">
                      <Checkbox
                        id="chk-commenting"
                        checked={deal.commenting}
                        onChange={() => this.updateBehavior('commenting', !deal.commenting)}
                        placement="right"
                      >
                        User Commenting
                      </Checkbox>
                      <small>Enable commenting on this {dt}</small>
                    </div>
                  </div>
                </FormGroup>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          {saving && <Loader />}
          <Button dmpStyle="primary" disabled={saving} onClick={() => this.save()} data-cy="btn-update">
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
