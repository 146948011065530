import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup } from 'react-bootstrap';

import Deal from '@core/models/Deal';
import Team from '@core/models/Team';
import { VITAL_TYPES } from '@core/models/Vital';

import { Button, ButtonIcon, Dropdown, Ellipsis, MenuItem } from '@components/dmp';

import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import ConditionsView from '@components/editor/ConditionsView';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

import VitalEditor from './VitalEditor';

@autoBindMethods
export default class VitalsSidebar extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    team: PropTypes.instanceOf(Team).isRequired,
    onSelectVital: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { template } = props.deal;

    this.state = {
      creating: false,
      editingVital: null,
      vitalType: 'variable',
    };

    this.refContainer = createRef();
    this.refNewVital = createRef();
    this.refVitals = {};
    this.establishRefs(template.vitals);
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const { editingVital } = this.state;

    if (prevProps.deal !== this.props.deal) {
      if (!editingVital) {
        this.establishRefs(this.props.deal.template.vitals);
      } else {
        const vital = _.get(this.props.deal, `template.vitals[${editingVital.id}]`);
        if (vital && editingVital !== vital) {
          this.setState({ editingVital: vital });
        }
      }
    }
  }

  establishRefs(vitals) {
    _.forEach(vitals, (v) => (this.refVitals[v.id] = createRef()));
  }

  async selectVital(vital = null) {
    const { onSelectVital } = this.props;
    this.setState({ editingVital: vital });
    onSelectVital(vital);
  }

  async deleteVital(vital) {
    const { deal } = this.props;
    await Fire.saveTemplateVital(deal.dealID, vital, true);
  }

  async saveScoringMatrix(matrix) {
    const { deal } = this.props;
    await Fire.saveTemplateScoring(deal.dealID, matrix);
  }

  get activeScoringMatrix() {
    const { deal, team } = this.props;
    const { template } = deal;

    return template.scoring ? team.scoringMatrices[template.scoring] : null;
  }

  renderVital(vital, idx) {
    const { deal } = this.props;
    const { editingVital } = this.state;
    const editing = editingVital === vital;
    const conditions = _.map(vital.conditions);

    return (
      <div className={cx('vital-view', { editing })} key={`${vital.type}-${idx}`} data-cy="vital-view">
        <div className="vital-topline" ref={this.refVitals[vital.id]}>
          <div className={cx('vital-title', { selected: editing })} onClick={() => this.selectVital(vital)}>
            {vital.title}
          </div>
          <div className="spacer" />
          <ButtonIcon
            icon="trash"
            size="default"
            className="delete-vital"
            onClick={() => this.deleteVital(vital)}
            data-cy="delete-vital"
          />
        </div>
        <div className="vital-details">
          {vital.valueFilter && (
            <span className="vital-filter">
              <Ellipsis>{vital.valueFilter.displayLabel}</Ellipsis>
            </span>
          )}
        </div>
        <div className="vital-details conditional" onClick={() => this.selectVital(vital)}>
          {conditions.length > 0 && <ConditionsView deal={deal} conditions={conditions} />}
        </div>
      </div>
    );
  }

  render() {
    const { deal, team } = this.props;
    const { creating, vitalType, editingVital } = this.state;

    const { variableVitals, clauseVitals } = deal.template;

    const typeDef = _.find(VITAL_TYPES, { key: vitalType });
    const vitals = vitalType === 'variable' ? variableVitals : clauseVitals;

    return (
      <div className="vitals-sidebar" data-cy="vitals-sidebar">
        <div className="filter-bar">
          <ButtonGroup className="panel-tabs vital-types">
            {VITAL_TYPES.map((typeDef, idx) => (
              <TooltipButton tipID={`tip-vital-${idx}`} key={idx} tip={typeDef.tip} placement="top">
                <Button
                  dmpStyle="link"
                  active={typeDef.key === vitalType}
                  onClick={() => this.setState({ vitalType: typeDef.key, creating: false })}
                >
                  {typeDef.plural}
                </Button>
              </TooltipButton>
            ))}
          </ButtonGroup>
        </div>

        <DealPanelItem borderBottom className="scoring">
          <div className="item-label">Scoring Matrix</div>
          <Dropdown
            className="scoring-selector"
            pullRight
            size="small"
            title={this.activeScoringMatrix ? this.activeScoringMatrix.displayName : 'Select Scoring Matrix'}
            block
            id="scoring-selector"
            onSelect={(matrixID) => this.saveScoringMatrix(matrixID)}
          >
            {_.map(team.scoringMatrices, (matrix, idx) => (
              <MenuItem key={idx} eventKey={matrix.id} active={this.activeScoringMatrix?.id === matrix.id}>
                {matrix.displayName}
              </MenuItem>
            ))}
          </Dropdown>
        </DealPanelItem>

        <DealPanelItem borderBottom className="add-vital">
          <div className="vital-count" data-cy="vital-count">
            {vitals.length} {typeDef.title} {vitals.length === 1 ? 'Vital' : 'Vitals'}
          </div>

          <div className="spacer" />

          <Button
            ref={this.refNewVital}
            // className="add-entity"
            size="small"
            icon="docScan"
            onClick={() => this.setState({ creating: true })}
            data-cy="new-vital"
          >
            New
          </Button>
        </DealPanelItem>

        <div className="vitals-list panel-scroll" ref={this.refContainer} data-cy="vitals-list">
          {vitals.length > 0 ? (
            _.map(_.sortBy(vitals, ['relatedVariable']), this.renderVital)
          ) : (
            <small>No {typeDef.title} Vitals defined</small>
          )}
        </div>

        {editingVital && (
          <VitalEditor
            target={this.refVitals[editingVital.id].current}
            deal={deal}
            vital={editingVital}
            onHide={() => this.selectVital()}
          />
        )}
        {creating && !editingVital && (
          <VitalEditor
            target={this.refNewVital.current}
            deal={deal}
            newType={vitalType}
            onHide={() => this.setState({ editingVital: null, creating: false })}
          />
        )}
      </div>
    );
  }
}
