const SectionError = {
  ORPHANED_SOURCE: {
    key: 'ORPHANED_SOURCE',
    tip: `This section can not be positioned below a Special section (Appendix or Signature) 
          without being a child of that section. To fix, indent this section to make it a child 
          of the preceeding Special section.`,
  },
  // This error state can occur if a top-level SUMMARY section is deleted,
  // thereby orphaning its children
  ORPHANED_SUMMARY: {
    key: 'ORPHANED_SUMMARY',
    tip: `Top-level Overview sections must have titles. Add at title to this section or indent it 
    to make it a child of the previous Overview section.`,
  },
  NESTED_APPENDIX: {
    key: 'NESTED_APPENDIX',
    tip: `Special sections (Appendix or Signature) can not be nested under other 
    Special sections. To fix, outdent this section.`,
  },
  LEGACY_PAYMENT: {
    key: 'LEGACY_PAYMENT',
    tip: `Payment sections are no longer supported. Delete this section 
    and use Blocks or Lists instead.`,
  },
  LEGACY_SCOPE: {
    key: 'LEGACY_SCOPE',
    tip: `Scope sections are no longer supported. Delete this section 
    and use Blocks or Lists instead.`,
  },
  VARIABLE_ALIGNMENT: {
    key: 'VARIABLE_ALIGNMENT',
    tip: `Sections containing Image or Table variables must be left-aligned.`,
  },
  DC_REPEATER: {
    key: 'DC_REPEATER',
    tip: `Repeater template content will not show up until a data source is configured.`,
  },
  DC_AI: {
    key: 'DC_AI',
    tip: `Link to one or more Sections or Variables to provide source material for AI generation.`,
  }
};

export default SectionError;
