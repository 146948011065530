import { diffWords } from 'diff';
import { ContentState, Modifier, SelectionState, convertFromRaw, convertToRaw } from 'draft-js';
import { assign, filter, forEach, get } from 'lodash';

import Core from '../Core.js';
import DateFormatter from '../utils/DateFormatter';
import { rxVariableReplace } from './Content';
import { ValueType } from './Variable';

export const BASE_ID = 'BASE';
const rxVariable = /\[[!@#$%*+^~][-_\w\d\s.\(\)]+\]/gi;
const rxURL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
export const getVersionID = () => new Date().getTime().toString();

export const MERGE_TYPE = {
  OVERWRITE: 'overwrite', //throw away all existing content, replace with new
  MERGE: 'merge', //merge in new content, keep old, replace base versions
  VERSION: 'version', //match to existing content, create new versions if diffs are found
  REDLINE: 'redline', //match to existing content, create new versions and compute/show diffs
};

// Some customers cleverly try to create tables/layout by inserting special invisible unicode characters -- strip em out!
export const sanitize = (text) => {
  if (!text) return '';
  else return text.replace(/[\u200A-\u200F\u202A-\u202F\u206A-\u206F]/g, ' ');
};

export const getRanges = (cs, clean, includeStyles = true) => {
  //enable null input
  if (!cs) return [];

  // https://stackoverflow.com/questions/39530561/add-empty-block-to-draft-js-without-moving-selection

  const rxBreak = new RegExp('\n', 'g');

  cs.getBlockMap().map((block) => {
    const text = block.getText();

    //find variable ranges and add them as styles for flattened rendering
    let matchVar, matchURL, matchBreak;
    while ((matchVar = rxVariable.exec(text)) !== null) {
      const sel = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: matchVar.index,
        focusOffset: matchVar.index + matchVar[0].length,
      });
      cs = Modifier.applyInlineStyle(cs, sel, 'variable');
    }

    while ((matchURL = rxURL.exec(text)) !== null) {
      const selURL = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: matchURL.index,
        focusOffset: matchURL.index + matchURL[0].length,
      });
      cs = Modifier.applyInlineStyle(cs, selURL, 'url');
    }

    while ((matchBreak = rxBreak.exec(text)) !== null) {
      const selBreak = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: matchBreak.index,
        focusOffset: matchBreak.index + matchBreak[0].length,
      });
      cs = Modifier.applyInlineStyle(cs, selBreak, 'break');
    }

    //also convert diff entities to styles, unless we're looking for a clean copy (no diffs)
    if (!clean) {
      block.findEntityRanges(
        (character) => {
          let key, ent;
          return (
            (key = character.getEntity()) != null &&
            (ent = cs.getEntity(key)) != null &&
            ['added', 'removed'].indexOf(ent.type) > -1
          );
        },
        (start, end) => {
          const ent = cs.getEntity(block.getEntityAt(start));
          if (ent != null) {
            const sel = SelectionState.createEmpty(block.getKey()).merge({
              anchorOffset: start,
              focusOffset: end,
            });
            cs = Modifier.applyInlineStyle(cs, sel, `prior diff ${ent.type}`);
          }
        }
      );
    }
  });

  if (includeStyles) {
    return serializeStyles(cs, includeStyles);
  } else {
    // If we're stripping inline styles (eg, for PDF rendering in certain cases),
    // We want to just create a single block corresponding to the Draft-JS ContentBlock,
    // and containing one range which is the complete block of (unstyled) text
    return cs.getBlockMap().map((block) => {
      const text = sanitize(block.getText());
      const ranges = [
        {
          type: [],
          start: 0,
          end: text.length,
        },
      ];
      return { text, ranges };
    });
  }
};

export const serializeStyles = (cs) => {
  const blocks = [];
  //now comb through styles and convert into serialized array
  //this makes rendering (React, PDF, etc) easier without requiring Draft
  cs.getBlockMap().map((block) => {
    const ranges = [];
    block.findStyleRanges(
      (character) => {
        const type = character.getStyle().toArray();
        ranges.push({ type });
        return true;
      },
      (start, end) => {
        const range = ranges[ranges.length - 1];
        range.start = start;
        range.end = end;
      }
    );

    blocks.push({ text: sanitize(block.getText()), ranges });
  });

  return blocks;
};

export const hasChanges = (cs) => {
  if (!cs) return false;
  let changes = 0;
  cs.getBlockMap().map((block) => {
    block.findEntityRanges((character) => {
      let key, ent;
      if (
        (key = character.getEntity()) != null &&
        (ent = cs.getEntity(key)) != null &&
        ['added', 'removed'].indexOf(ent.type) > -1
      )
        changes += 1;
    });
  });

  return changes > 0;
};

export const hasDiffs = (csOld, csNew) => {
  const newText = typeof csNew == 'string' ? csNew : csNew.getPlainText() || '';
  const oldText = typeof csOld == 'string' ? csOld : csOld.getPlainText() || '';
  const ranges = diffWords(oldText, newText);
  return filter(ranges, (r) => r.added || r.removed).length > 0;
};

// Note, this is not currently used because we deprecated redlining,
// but leaving here for the time being in case we bring it back
export const redline = (csOld, csNew, newEntityData) => {
  const oldText = typeof csOld.getPlainText === 'function' ? csOld.getPlainText() || '' : csOld.toString();
  const newText = typeof csNew.getPlainText === 'function' ? csNew.getPlainText() || '' : csNew.toString();
  const ranges = diffWords(oldText, newText);

  let csMerged = csNew;
  let entityKey, sel;
  let cursor = 0;

  //diff ranges are global to the entire contents because we generated them from plain text
  //however we need to localize range indices relative to individual ContentBlocks for DraftJS text insertion and entity application
  //so we need a helper function to compute this localization
  //this enables diff compare to work properly when there are multiple content blocks (i.e., line breaks) in one Section
  const localize = (start, end) => {
    //now localize the corrected indices to the right target block
    const blocks = csMerged.getBlocksAsArray();
    let key;
    blocks.map((b, idx) => {
      if (key) return; //if we already found our target, we're good to go

      if (start > b.text.length || end > b.text.length) {
        start -= b.text.length;
        end -= b.text.length;
      } else {
        //now we're in the correct block. however, for blocks beyond the first, there's an extra whitespace character somewhere
        //haven't been able to figure out where, but decrementing the range by the block index seems to work
        if (idx > 0) {
          start -= idx;
          end -= idx;
        }

        key = b.key;
      }
    });

    // Edge case when we can't find the key
    // Not sure wether it's the best fix for now, but at least it's not crashing.
    if (!key) {
      console.log('blocks key not found, use the first one.');
      key = blocks[0].key;
    }

    return { key, start, end };
  };

  ranges.map((range) => {
    let local;
    //added ranges will already be in the ContentState so we just need to create and apply the entity
    if (range.added) {
      //create the entity
      csMerged = csMerged.createEntity('added', 'MUTABLE', newEntityData);
      entityKey = csMerged.getLastCreatedEntityKey();
      //then create the selection and apply entity
      local = localize(cursor, cursor + range.value.length);
      sel = SelectionState.createEmpty(local.key).merge({
        anchorOffset: local.start,
        focusOffset: local.end,
      });
      csMerged = Modifier.applyEntity(csMerged, sel, entityKey);
    }
    //removed ranges need to be inserted into the ContentState
    else if (range.removed) {
      //create the entity
      csMerged = csMerged.createEntity('removed', 'MUTABLE', newEntityData);
      entityKey = csMerged.getLastCreatedEntityKey();
      //collapse selection at cursor for insertion
      local = localize(cursor, cursor);
      sel = SelectionState.createEmpty(local.key).merge({
        anchorOffset: local.start,
        focusOffset: local.end,
      });
      //and insert the removed text
      csMerged = Modifier.insertText(csMerged, sel, range.value, null, entityKey);
    }
    cursor += range.value.length;
  });

  return csMerged;
};

export default class Version {
  id = null;
  user = null;
  title = null;
  body = null;
  date = null;

  constructor({ id, user, title, body }) {
    assign(this, { id, user, title });
    if (this.id == null) this.id = getVersionID();
    else if (this.id != BASE_ID) this.date = new Date(parseInt(this.id));

    let csBody, csTitle;

    if (!body) csBody = ContentState.createFromText('');
    else if (typeof body == 'string') csBody = Core.functions.stateFromHTML(body);
    else if (typeof body == 'object') {
      if (!body.entityMap) body.entityMap = {};
      csBody = convertFromRaw(body);
    }

    if (!title) csTitle = ContentState.createFromText('');
    else if (typeof title == 'string') csTitle = Core.functions.stateFromHTML(title);
    else if (typeof title == 'object') {
      if (!title.entityMap) title.entityMap = {};
      csTitle = convertFromRaw(title);
    }

    this.body = csBody;
    this.title = csTitle;
  }

  get displayDate() {
    return `${DateFormatter.mdy(this.date)} @ ${DateFormatter.time(this.date)}`;
  }

  get json() {
    return {
      id: this.id,
      user: this.user,
      title: sanitize(this.title.getPlainText().trim()) || null,
      body: this.body.hasText() ? convertToRaw(this.body) : null,
    };
  }

  get hasContent() {
    return this.has('title') || this.has('body');
  }

  // See Section.constructor; this getter supports a special case for empty (new) ITEM sections;
  // Once we have a real first Version saved by user, we no longer need to create a placeholder Version
  get isPlaceholder() {
    return this.id === BASE_ID && !this.hasContent;
  }

  getDisplayName(dealUser, currentUID) {
    if (get(dealUser, 'uid') === currentUID) {
      return 'You';
    } else if (dealUser) {
      return dealUser.fullName || dealUser.email;
    } else {
      return 'A deleted user';
    }
  }

  //get a clean version of the body with all "added" diffs applied and all "removed" diffs left out
  clean(field) {
    let cs = field == 'body' ? this.body : this.title;
    cs.getBlockMap().map((block) => {
      let removals = [];

      block.findEntityRanges(
        (character) => {
          let key, ent;
          return (
            (key = character.getEntity()) != null &&
            (ent = cs.getEntity(key)) != null &&
            ['added', 'removed'].indexOf(ent.type) > -1
          );
        },
        (start, end) => {
          let key, ent;
          if ((key = block.getEntityAt(start)) != null && (ent = cs.getEntity(key)) != null) {
            //we don't want to change the actual contents of the ContentState while iterating through
            //because this will mess up indices -- so instead just gather the ranges that need to be subsequently removed
            if (ent.type == 'removed') removals.push({ start, end });
          }
        }
      );

      //now reverse them in order to do the last removals first
      //this way each removal doesn't change indices of other (prior) removals
      removals = removals.reverse();
      removals.map((rng) => {
        //create selection for entity and use it to remove the actual text from the block
        const sel = SelectionState.createEmpty(block.getKey()).merge({
          anchorOffset: rng.start,
          focusOffset: rng.end,
        });
        cs = Modifier.removeRange(cs, sel, 'backward');
      });
    });

    return cs;
  }

  getRanges(field, clean, includeStyles = true) {
    let cs = clean ? this.clean(field) : this[field];
    return getRanges(cs, clean, includeStyles);
  }

  getDiff(field, version) {
    const csNew = this.clean(field),
      csOld = version.clean(field);
    const newText = csNew.getPlainText() || '',
      oldText = csOld.getPlainText() || '';

    const diffs = diffWords(oldText, newText);
    let text = '',
      ranges = [],
      changes = 0;
    diffs.map((piece) => {
      if (piece.added || piece.removed) {
        ranges.push({
          type: piece.added ? ['diff', 'added'] : ['diff', 'removed'],
          start: text.length,
          end: text.length + piece.value.length,
        });
        changes += 1;
      } else {
        ranges.push({
          start: text.length,
          end: text.length + piece.value.length,
          type: [],
        });
      }
      text += piece.value;
    });

    //now use Draft API to convert to a similar format to what is returned by getRanges()
    let breaks = [],
      nextBreak,
      reg = /\n/g;

    //we need to convert any line breaks to plain spaces so that creating a ContentState will only have one block
    //otherwise the range indices from diffWords will be wrong
    while ((nextBreak = reg.exec(text)) != null) breaks.push(nextBreak.index);
    if (breaks.length > 0) {
      text = text.replace(/\n/g, ' ');
    }

    let csDiff = ContentState.createFromText(text);

    let block = csDiff.getFirstBlock();

    //first add in diffs as styles
    ranges.map((r) => {
      const sel = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: r.start,
        focusOffset: r.end,
      });
      csDiff = Modifier.applyInlineStyle(csDiff, sel, r.type.join(' '));
    });

    //then add back in styles to represent the breaks we found above
    breaks.map((br) => {
      const sel = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: br,
        focusOffset: br + 1,
      });
      csDiff = Modifier.applyInlineStyle(csDiff, sel, 'break');
    });

    //finally, find variable ranges and convert those to styles too
    let matchVar;
    while ((matchVar = rxVariable.exec(text)) !== null) {
      const sel = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: matchVar.index,
        focusOffset: matchVar.index + matchVar[0].length,
      });
      csDiff = Modifier.applyInlineStyle(csDiff, sel, 'variable');
    }

    //now we've got everything needed to serialize ranges into styles just like in getRanges
    return serializeStyles(csDiff);
  }

  //get plain unstyled text but still with variable replacement
  getText(field, clean, vars = {}) {
    let s = '',
      blocks = this.getRanges(field, clean);

    forEach(blocks, (block, idx) => {
      forEach(block.ranges, (range) => {
        const text = block.text.slice(range.start, range.end);
        if (range.type.includes('variable')) {
          const varName = text.replace(rxVariableReplace, '');
          const variable = vars[varName];
          if (variable && variable.val) {
            if (variable.valueType === ValueType.TABLE) {
              s += variable.val
                .map((row) =>
                  Object.entries(row)
                    .map((col, val) => `${col}: ${val}`)
                    .join(', ')
                )
                .join(' | ');
            } else {
              s += variable.val;
            }
          } else {
            s += varName;
          }
        } else {
          s += text;
        }
      });
      //retain line breaks between blocks in plain text (for pdf rendering)
      if (idx + 1 < blocks.length) s += '\n';
    });

    return sanitize(s);
  }

  hasChanges(field) {
    return hasChanges(this[field]);
  }

  has(field) {
    return sanitize(this[field].getPlainText()).trim() != '';
  }
}
