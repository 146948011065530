import { observer } from 'mobx-react';
import React, { Component, createRef, useState } from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { DataSet, Timeline } from '@anderson-fv/vis-timeline/standalone';
import '@anderson-fv/vis-timeline/styles/vis-timeline-graph2d.css';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import { autorun, makeAutoObservable } from 'mobx';
import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import PropTypes from 'prop-types';

import { ButtonGroup, FormControl, Modal, ProgressBar } from 'react-bootstrap';

import TimelineEvent, { AI_PROMPT, EVENT_TYPES } from '@core/models/TimelineEvent';
import { ValueType, VariableType } from '@core/models/Variable';
import { getSafeKey } from '@core/utils';
import { generateExportURL } from '@core/utils';
import DateFormatter from '@core/utils/DateFormatter';
import { base64ToArray } from '@core/utils/Generators';

import { Button, ButtonClose, Loader, Swatch } from '@components/dmp';

import TableView from '@components/TableView';
import TimelineFilter from '@components/TimelineFilter';
import { measure } from '@components/section_types/SectionMeasurer';
import API from '@root/ApiClient';
import Fire from '@root/Fire';
import trackEvent from '@utils/EventTracking';

const TL_HEIGHT = 8.5 * 96 - 2 * 96;
const TL_WIDTH = 11 * 96 - 2 * 96;

const getPaddedRange = (start, end) => {
  const diffTime = Math.abs(end - start);

  // TODO: Box width is set in CSS, so either need to manually keep these
  // in sync, or do something more clever? Currently not in sync, so events
  // don't get framed perfectly right now.

  // 1 box = 200px width; full width is 864
  // so we need to add 200/864 to get to the right side of the last box
  // plus 20/864 on each side to get a 20px margin
  const boxWidthMS = diffTime * (275 / TL_WIDTH); // TODO: where did 275 come from?
  const marginMS = diffTime * (20 / TL_WIDTH);
  const newEndDate = new Date().setTime(end.getTime() + boxWidthMS + marginMS);
  const newStartDate = new Date().setTime(start.getTime() - marginMS);
  return [newStartDate, newEndDate];
};

function customOrder(a, b) {
  // order by order property
  return a.order - b.order;
}

class TimelineStore {
  selectedItemID = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  selectItem(itemID) {
    this.selectedItemID = itemID;
  }
  clearSelection() {
    this.selectItem(undefined);
  }
}

@autoBindMethods
export default class TimelineView extends Component {
  // static propTypes = _.merge({}, _.cloneDeep(ContentSection.propTypes), {
  //   deal: PropTypes.instanceOf(Deal).isRequired,
  // });

  // static defaultProps = _.cloneDeep(ContentSection.defaultProps);

  constructor(props) {
    super(props);
    this.childRefs = {};
    this.state = {
      loading: false,
      editingTimeline: false,
      editingPrompt: false,
      aiPrompt: '',
      exportProgress: null,
    };
    this.sectionRefs = {};
    this.refSelf = createRef();
    this.refFilters = createRef();

    this.timeline = null;

    this.store = new TimelineStore();
    this.disposer = null;
  }

  async summarize() {
    const { deal, user } = this.props;
    const section = deal.timeline;
    const { aiPrompt } = section;
    const { engine, prompt } = aiPrompt;

    // TODO: make more generic. For now just override with the standard full prompt for testing/debug
    // work with Leo to fix the hard-coded stuff in API.summarize also
    // _.last(prompt).content = AI_PROMPT;

    await this.setState({
      loading: true,
      editingTimeline: true,
      selectedBlockIndex: -1,
      results: [],
      error: null,
    });

    console.log('Calling summarize with prompt', prompt);

    const source = aiPrompt.timelineSourceAI;
    let skynet = await API.call('summarize', { aiPrompt: aiPrompt.json, source, deal: deal.json, json: true });

    this.setState({ loading: false });

    console.log('AI RESPONSE:', skynet);

    // TODO: for timeline, result is an array of data, not options
    // figure out how to separate
    if (skynet?.result[0]) {
      const varDef = {
        name: this.timelineVarName,
        type: VariableType.SIMPLE,
        valueType: ValueType.TABLE,
        value: JSON.stringify(skynet.result),
        columns: _.map(['date', 'type', 'summary'], (id) => {
          return {
            id,
            displayName: id,
            valueType: ValueType.STRING,
            editable: true,
            multiline: id === 'summary',
          };
        }),
      };
      console.log('varDef=', varDef);

      await Fire.saveSection(section, { content: `[#${this.timelineVarName}]` });
      await Fire.saveVariableDefinition(deal, varDef);

      const eventData = {
        serviceType: `${_.upperFirst(aiPrompt.type)} AI Block Preview`, //should eventually be enumerated somewhere for additional types
        docID: deal.dealID,
        user: user.email,
        teamID: deal.team,
        engine: engine.key,
        isTemplate: deal.isTemplate,
        template: deal.info.sourceTemplate,
      };
      await trackEvent('TimelineSectionSummarize', eventData);
    }
  }

  async resetTimeline() {
    const { deal } = this.props;
    const tlVar = deal.variables[this.timelineVarName];
    const section = deal.timeline;

    // TODO: delete table var also

    await Fire.saveSection(section, { content: null });
    await Fire.saveVariable(deal, tlVar, null);

    if (this.timeline) {
      this.timeline.destroy();
      this.timeline = null;
    }
  }

  componentDidMount() {
    this.disposer = autorun(() => {
      //console.log('Selected item ID:', this.store.selectedItemID);
      if (this.store.selectedItemID === undefined && this.timeline) {
        this.timeline.setSelection([]);
      }
    });

    if (this.shouldAutoGenerate) {
      console.log('Auto-generating Timeline events');
      this.summarize();
    }
    if (this.timelineData.length) {
      this.renderTimeline();
    }
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  componentDidUpdate(prevProps) {
    const { editingTimeline } = this.state;
    measure(this);

    if (this.timelineData.length && !this.timeline && !editingTimeline) {
      this.renderTimeline();
      measure(this);
    }
  }

  async toggleEditingTimeline() {
    const { editingTimeline } = this.state;
    const newState = !editingTimeline;

    await this.setState({ editingTimeline: newState });

    if (!newState) {
      if (this.timeline) {
        this.timeline.destroy();
        this.timeline = null;
      }
      this.renderTimeline();
    }
  }

  async toggleEditingPrompt() {
    const { deal } = this.props;
    const section = deal.timeline;
    const { aiPrompt } = section;
    const { prompt } = aiPrompt;

    const txtPrompt = _.get(prompt, '[1].content', '');

    this.setState({
      editingPrompt: true,
      aiPrompt: txtPrompt,
    });
  }

  async savePrompt() {
    const { aiPrompt } = this.state;
    const { deal } = this.props;
    const section = deal.timeline;

    const json = section.aiPrompt.json;

    // This is awful and must be fixed to not hardcode the [2]
    json.prompt[1].content = aiPrompt;
    console.log(json);

    // Calling reset clears existing data (section content and variable)
    await this.resetTimeline();

    // Save the prompt in the section config data
    await Fire.saveSection(section, { aiPrompt: json });
    // Close the modal
    await this.setState({ editingPrompt: false });
    // Call summarize again which will make the API call and open the event editing modal too
    this.summarize();
  }

  renderTimeline() {
    const { editingTimeline } = this.state;

    // Fixed landscape dimensions with one inch margins
    const height = TL_HEIGHT;
    const width = TL_WIDTH;

    if (editingTimeline) return;

    // First grab the raw (filtered) data
    const sourceData = this.timelineData;

    const data = {
      events: [],
    };

    // https://visjs.github.io/vis-timeline/docs/timeline/#items
    _.forEach(sourceData, (evt, idx) => {
      // Add tz offset like " GMT-400" to ensure that dates aren't changed
      const date = DateFormatter.localizeUTC(new Date(evt.date));
      data.events.push({
        start: date,
        // TODO: which DateFormatter method do we want to use?
        //content: `<div><span>${DateFormatter.mdy(date)}</span>${evt.summary}</div>`,
        content: `${evt.summary}`,
        title: DateFormatter.locale(date, 'en-US'),
        id: idx + 1,
        limitSize: true,
        align: 'left',
        className: evt.type,
        order: idx,
        // editable: true,
        sourceID: evt.source,
      });
    });

    // https://visjs.github.io/vis-timeline/docs/timeline/
    // Only generate a new timeline the first time
    // subsequent updates can use the existing instance
    if (!this.timeline) {
      const el = document.getElementById(this.timelineDivID);
      this.timeline = new Timeline(el);
      this.timeline.on('select', (tl_props) => {
        this.store.selectItem(tl_props.items[0]);

        // TODO: this kinda works, but the item isn't truly centered, but it should be "good enough"
        // to at least ensure it is nicely visible (not hidden by Event Detail panel).
        this.timeline.focus(tl_props.items[0], {
          zoom: false,
          animation: {
            duration: 400,
            easingFunction: 'easeInOutQuad',
          },
          offset: 0.3, //TODO: tweak this as needed
        });
      });
    }

    const itemTemplate = (item, element) => {
      if (!item) {
        return;
      }

      // TODO: The example for using React 16 suggests using portals like the following, however it might
      // cause a memory leak! Also, it doesn't seem like we actually need them (see below).
      // See: https://github.com/visjs/vis-timeline/issues/1211
      // return ReactDOM.createPortal(
      //   ReactDOM.render(<ItemView item={item} timelineStore={timelineStore} />, element),
      //   element
      // );
      //
      // I'm Using this alternative approach that was suggested in the above github conversation as it
      // seems to work. Note that, changes to these props will not trigger these components to re-render
      // as they are not in the "normal" virtual dom hierarchy. However, this is mitigated by our use
      // of mobx for any changing state. Beware that there may be other unintended side-effects of this
      // technique that I'm currently unaware of!
      ReactDOM.render(<ItemView item={item} />, element);
      return undefined;
    };

    // TODO: When using a react component as the item template, we have to manually call timeout.redraw()
    // or the items aren't positioned properly. Further, on first render they still appear wrong, but as soon
    // as you start panning around they "snap" into their correct positions.
    // For now I'm forcing this "proper" re-render by calling .zoomOut(). Earlier I tried to use .fit()
    // but it annoyingly results in a horizontal offset to the right for some unknown reason.
    setTimeout(() => {
      this.timeline.redraw();
      this.timeline.zoomOut(0.001);
    }, 1);

    const opts = {
      width,
      height,
      align: 'left',
      margin: {
        item: 5,
      },
      orientation: {
        axis: 'top',
        item: 'top',
      },
      order: customOrder,
      showTooltips: false,
      template: itemTemplate,
      zoomFriction: 50,
    };

    // TODO: show some kind of info/error message on timeline if there are no events found?
    // (or if all of them are filtered out?)
    if (data.events.length > 0) {
      const [start, end] = getPaddedRange(_.first(data.events).start, _.last(data.events).start);
      opts.start = start;
      opts.end = end;
    }
    this.timeline.setOptions(opts);
    const items = new DataSet(data.events);
    this.timeline.setItems(items);

    // We inject a special Overlay component at just the right spot in the timeline's DOM so that we can
    // use it to 'gray out' the unselected event boxes, but keep the selected one full opacity.
    const visGroup = document.querySelector('.vis-foreground > .vis-group');
    if (visGroup) {
      ReactDOM.render(<ItemSelectionOverlay timelineStore={this.store} />, visGroup);
    }
  }

  async buildPDF() {
    const { deal } = this.props;

    let currentStep = 1;
    const totalSteps = 2 + deal.attachedFiles.length;

    // 1. Start with export of prose doc
    // this will fetch the generated doc into memory for further editing/compilation
    await this.setState({ exportProgress: { currentStep, totalSteps, description: 'Exporting main document...' } });
    const token = await Fire.token();
    const url = generateExportURL({ deal, token });
    const bufferDoc = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(bufferDoc);

    // 2. Add the Timeline export
    currentStep++;
    await this.setState({ exportProgress: { currentStep, totalSteps, description: 'Adding Timeline...' } });
    const el = document.getElementById(this.timelineDivID);
    const canvas = await html2canvas(el);
    const dataURL = canvas.toDataURL('image/png', 1);
    const b64 = dataURL.replace('data:image/png;base64,', '');
    const bytes = base64ToArray(b64);

    const pngImage = await pdfDoc.embedPng(bytes);
    const timelinePage = pdfDoc.addPage();
    timelinePage.setSize(11 * 96, 8.5 * 96);

    const [pw, ph] = [timelinePage.getWidth(), timelinePage.getHeight()];
    const [scaledWidth, scaledHeight] = [pngImage.width / 2, pngImage.height / 2];

    timelinePage.drawImage(pngImage, {
      x: (pw - scaledWidth) / 2,
      y: (ph - scaledHeight) / 2,
      width: scaledWidth,
      height: scaledHeight,
    });

    // 3. loop through attachments and load all, pulling out target pages of each
    let attachments = deal.attachedFiles;
    attachments = _.sortBy(attachments, (att) => att.title.toLowerCase());

    for (let i = 0; i < attachments.length; i++) {
      const attachment = attachments[i];

      // Temporarily using the Attachment.description field to designate array of target pages
      // this needs to be converted into real numbers, and also accommodate for a zero-based array
      const pages = _.map(attachment.description.split(','), (page) => parseInt(page) - 1);
      console.log(pages);

      currentStep++;
      await this.setState({
        exportProgress: {
          currentStep,
          totalSteps,
          description: `Attaching ${pages.length} pages from ${attachment.title}...`,
        },
      });
      // Load a PDFDocument in memory from each of the existing PDFs
      const downloadURL = await Fire.storage.ref(attachment.bucketPath).getDownloadURL();
      const buffer = await fetch(downloadURL).then((res) => res.arrayBuffer());
      const excerptPDF = await PDFDocument.load(buffer);
      // console.log('Excerpt', excerptPDF);

      // Copy pages from the doc
      const excerptPages = await pdfDoc.copyPages(excerptPDF, pages);
      _.forEach(excerptPages, (page) => pdfDoc.addPage(page));
    }

    // TODO: for adding internal links to target pages in doc
    // https://github.com/Hopding/pdf-lib/issues/123#issuecomment-568804606

    const pdfBytes = await pdfDoc.save();
    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });

    // Create an anchor, and set the href value to our data URL
    const createEl = document.createElement('a');
    // createEl.href = dataURL;
    createEl.href = pdfDataUri;

    // This is the name of our downloaded file
    createEl.download = deal.info.name;

    // Click the download button, causing a download, and then remove it
    createEl.click();
    createEl.remove();

    // Close modal
    this.setState({ exportProgress: null });
  }

  renderExportProgress() {
    const { exportProgress } = this.state;

    const percent = (exportProgress.currentStep / exportProgress.totalSteps) * 100;

    return (
      <Modal dialogClassName="pdf-compiler" show={true} onHide={_.noop} data-cy="pdf-compiler">
        <Modal.Header closeButton={false}>
          <span className="headline">Compiling PDF</span>
        </Modal.Header>

        <Modal.Body>
          <div className="saving">
            <ProgressBar bsStyle="info" now={percent} />
            <div className="details">
              <div className="step">
                {exportProgress.currentStep} of {exportProgress.totalSteps}
              </div>
              <div className="description">{exportProgress.description}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  get timelineDivID() {
    const { deal } = this.props;
    const section = deal.timeline;
    return `ai-timeline-${section.id}`;
  }

  get timelineVarName() {
    const { deal } = this.props;
    const section = deal.timeline;
    return 'TL-' + getSafeKey(section.name, false, true);
  }

  get timelineData() {
    const { deal } = this.props;
    const tlVar = deal.variables[this.timelineVarName];
    let data = tlVar?.tableValueFormatted || [];

    const activeTypes = this.refFilters?.current?.state.activeTypes;

    if (activeTypes) {
      data = _.filter(data, (evt) => activeTypes.includes(evt.type));
    }

    return data;
  }

  get shouldAutoGenerate() {
    const { deal } = this.props;
    const section = deal.timeline;
    const { loading } = this.state;

    const source = section.aiPrompt?.dataSourceAI;

    // console.log(section.isTimeline, loading, source, this.timelineData);

    if (section.isTimeline && !loading && source && !this.timelineData.length) {
      return true;
    }
    return false;
  }

  // Prevent mouse events from taking whole section into editing when invoked in Flow (ContentSection)
  stop(e) {
    e.stopPropagation();
  }

  renderEventEditor() {
    const { deal } = this.props;
    const { loading } = this.state;
    const tlVar = deal.variables[this.timelineVarName];

    return (
      <Modal
        dialogClassName="timeline-event-editor"
        show={true}
        onHide={this.toggleEditingTimeline}
        onMouseDown={this.stop}
        onMouseUp={this.stop}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <span className="headline">Edit Timeline Events</span>
        </Modal.Header>

        <Modal.Body>
          <div className="wrapper">
            {/* TODO: error states? */}
            {loading && (
              <div className="summarizing">
                <Loader inline /> Generating Timeline events. This may take up to 30 seconds.
              </div>
            )}
            {this.timelineData.length > 0 && <TableView section={deal.timeline} variable={tlVar} />}
          </div>
        </Modal.Body>

        <Modal.Footer>{!loading && <Button onClick={this.toggleEditingTimeline}>Done</Button>}</Modal.Footer>
      </Modal>
    );
  }

  renderPromptEditor() {
    const { aiPrompt } = this.state;

    return (
      <Modal
        dialogClassName="timeline-prompt-editor"
        show={true}
        onHide={() => this.setState({ editingPrompt: false })}
        onMouseDown={this.stop}
        onMouseUp={this.stop}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <span className="headline">Edit Timeline Prompt</span>
        </Modal.Header>

        <Modal.Body>
          <div className="wrapper">
            <FormControl
              componentClass="textarea"
              className="txt-prompt"
              value={aiPrompt}
              onChange={(e) => this.setState({ aiPrompt: e.target.value })}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.savePrompt}>Summarize</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { deal } = this.props;
    const timeline = deal.timeline;
    const { loading, editingTimeline, editingPrompt, exportProgress } = this.state;
    const events = this.timelineData;
    const hasEvents = events.length > 0;

    return (
      <div className="timeline-view" data-cy="timeline-view" ref={this.refSelf}>
        {hasEvents && (
          <div className="timeline-actions">
            <Button size="small" data-cy="btnEditPrompt" onClick={this.toggleEditingPrompt}>
              Edit Prompt
            </Button>

            {this.timelineData.length > 0 && (
              <Button size="small" data-cy="btnEditEvents" onClick={this.toggleEditingTimeline}>
                {editingTimeline ? 'View Timeline' : 'Edit Events'}
              </Button>
            )}

            {timeline.can('clear') && this.timelineData.length > 0 && (
              <Button size="small" data-cy="btnReset" onClick={this.resetTimeline}>
                Reset
              </Button>
            )}
            <Button size="small" data-cy="btnCompilePDF" onClick={this.buildPDF}>
              Compile PDF
            </Button>
          </div>
        )}

        <div id={this.timelineDivID} className="ai-timeline" />

        {hasEvents && <TimelineFilter ref={this.refFilters} onChange={this.renderTimeline} events={events} />}

        {editingTimeline && this.renderEventEditor()}
        {editingPrompt && this.renderPromptEditor()}
        {exportProgress && this.renderExportProgress()}

        <EventBlockerOverlay timelineStore={this.store} />

        <div class="timeline-view-cropper">
          <SidePanelView timelineStore={this.store} timelineData={this.timelineData} deal={this.props.deal} />
        </div>
      </div>
    );
  }
}

const ItemView = observer((props) => {
  const { item } = props;
  const typeLabel = EVENT_TYPES[item.className].title;
  return (
    <>
      <div className="tl-item-title">{item.title}</div>
      <div className="tl-item-type">{typeLabel}</div>
      <div className="tl-item-content">{item.content}</div>
    </>
  );
});

const ItemSelectionOverlay = observer((props) => {
  const { timelineStore } = props;
  const selected = Boolean(timelineStore.selectedItemID);
  return <div className={`timeline-selection-overlay ${selected ? 'active' : 'inactive'}`}></div>;
});

const EventBlockerOverlay = observer(({ timelineStore }) => {
  const handleClick = () => {
    timelineStore.clearSelection();
  };

  return timelineStore.selectedItemID === undefined ? null : (
    <div className="timeline-event-blocker-overlay" onClick={handleClick}></div>
  );
});

const SidePanelView = observer(({ timelineStore, timelineData, deal }) => {
  const tabs = [
    {
      key: 'summary',
      text: 'Summary',
    },
    {
      key: 'source',
      text: 'Prose Source',
    },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0].key);

  useEffect(() => {
    return autorun(() => {
      if (timelineStore.selectedItemID) {
        setCurrentTab(tabs[0].key);
      }
    });
  }, []);

  const id = timelineStore.selectedItemID;
  const data = !id || id < 1 ? null : timelineData[id - 1];
  //console.log('data=', data);

  const handleClose = () => {
    timelineStore.clearSelection();
  };

  return (
    <div className={`timeline-view-sidepanel ${data ? 'visible' : ''}`}>
      <div className="title-row">
        <div className="title">Event detail</div>
        <ButtonClose onClick={handleClose} />
      </div>

      <div className="tabs-row">
        <ButtonGroup className="panel-tabs">
          {tabs.map((tab) => (
            <Button
              key={tab.key}
              dmpStyle="link"
              active={currentTab === tab.key}
              onClick={() => setCurrentTab(tab.key)}
            >
              {tab.text}
            </Button>
          ))}
        </ButtonGroup>
      </div>

      {/* <div className="separator-row"></div> */}

      {currentTab === 'summary' && data ? <SidePanelSummary data={data} /> : null}
      {currentTab === 'source' && data ? <SidePanelSource data={data} deal={deal} /> : null}
    </div>
  );
});

const SidePanelSummary = ({ data }) => {
  const eventType = EVENT_TYPES[data.type];

  const formatDate = (date_str) => {
    // eg. January 31, 2020
    return moment(date_str).format('LL');
  };

  return (
    <>
      <div className="details-row">
        <div className="event-date">{formatDate(data.date)}</div>
        <div className="event-type">
          <Swatch color={eventType.color} />
          <span>{eventType.title}</span>
        </div>
      </div>

      <div className="content">{data.summary}</div>
    </>
  );
};

const SidePanelSource = ({ data, deal }) => {
  return (
    <>
      <div className="content">{getSourceText(data.source, deal)}</div>
    </>
  );
};

const getSourceText = (sourceID, deal) => {
  const linkedSectionIDs = deal.timeline.aiPrompt.linkedSections;
  const linkedSections = linkedSectionIDs.map((id) => deal.sections[id]);
  const sourceItems = linkedSections[0].items;
  const targetSection = sourceItems.find((item) => item.id === sourceID);

  if (targetSection) {
    const text = targetSection.currentVersion.getText('body', true, deal.variables);
    return text;
  } else {
    console.error('Failed to find source item:', sourceID);
  }
  return '';
};
