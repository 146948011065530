import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup } from 'react-bootstrap';

import Deal from '@core/models/Deal';
import Section from '@core/models/Section';
import Team, { FEATURES } from '@core/models/Team';

import { Button } from '@components/dmp';

import DealPanel from '@components/deal/DealHeader/DealPanel';
import ElementsSidebar from '@components/editor/ElementsSidebar';
import PageSidebar from '@components/editor/PageSidebar';
import SectionSidebar from '@components/editor/SectionSidebar';
import VariableIndex from '@root/utils/VariableIndex';

import VitalsSidebar from './VitalsSidebar';

const TABS = [
  { key: 'section', title: 'Section', test: () => true },
  { key: 'elements', title: 'Elements', test: () => true },
  { key: 'page', title: 'Page', test: (deal) => deal.isTemplate },
  { key: 'vitals', title: 'Vitals', test: (deal, team) => deal.isTemplate && team.isFeatureActive(FEATURES.VITALS) },
];

@autoBindMethods
export default class EditorSidebar extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal),
    team: PropTypes.instanceOf(Team),
    section: PropTypes.instanceOf(Section),
    onSelectCL: PropTypes.func.isRequired,
    onColConfig: PropTypes.func.isRequired,
    onSelectVital: PropTypes.func.isRequired,
    variableIndex: PropTypes.instanceOf(VariableIndex),
    id: PropTypes.string.isRequired,
    target: PropTypes.object,
    container: PropTypes.object,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    // Note, this component will soon get expanded with additional sub-panels (Variables etc)
    this.state = {
      tab: TABS[0],
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  renderTabs() {
    const { deal, team } = this.props;
    const { tab } = this.state;

    const tabs = _.filter(TABS, (tab) => tab.test(deal, team));

    return (
      <ButtonGroup className="panel-tabs">
        {tabs.map((t) => (
          <Button
            key={t.key}
            dmpStyle="link"
            active={tab == t}
            onClick={() => this.setState({ tab: t })}
            data-cy={`${t.key}-tab`}
          >
            {t.title}
          </Button>
        ))}
      </ButtonGroup>
    );
  }

  render() {
    const { target, container, id, deal, section, onSelectCL, team, variableIndex, onColConfig, onSelectVital, user } =
      this.props;
    const { tab } = this.state;

    if (!target.current || !deal) return null;

    return (
      <DealPanel
        id={id}
        onHide={_.noop}
        show={true}
        target={target.current}
        title={this.renderTabs()}
        container={container.current}
        canClose={false}
        className={`${tab.key}-sidebar`}
      >
        {tab.key === 'section' && (
          <SectionSidebar
            section={section}
            onSelectCL={onSelectCL}
            variableIndex={variableIndex}
            team={team}
            user={user}
          />
        )}

        {tab.key === 'elements' && (
          <ElementsSidebar deal={deal} onColConfig={onColConfig} variableIndex={variableIndex} />
        )}

        {tab.key === 'page' && deal.isTemplate && <PageSidebar deal={deal} team={team} />}

        {tab.key === 'vitals' && deal.isTemplate && (
          <VitalsSidebar deal={deal} team={team} onSelectVital={onSelectVital} />
        )}
      </DealPanel>
    );
  }
}
