import React, { Component, Fragment, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { Modifier, SelectionState } from 'draft-js';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Overlay, Popover } from 'react-bootstrap';

import { ENTITY_TYPE } from '@core/models/Content';
import Diff from '@core/models/Diff';
import Section from '@core/models/Section';
import Version from '@core/models/Version';
import { getUniqueKey } from '@core/utils';

import { Button } from '@components/dmp';

import { SectionActions } from '@components/section_types/SectionMenu';

@autoBindMethods
export default class DiffView extends Component {
  static propTypes = {
    // https://github.com/facebook/draft-js/blob/master/src/model/decorators/DraftDecorator.js
    // These give us ability to correctly identify the text region in the ContentState that's being wrapped in a DiffView
    decoratedText: PropTypes.string.isRequired,
    blockKey: PropTypes.string.isRequired,
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    entityKey: PropTypes.string,

    // Container for the Popover
    container: PropTypes.object,
    // Nested content to be rendered in <span>
    children: PropTypes.node.isRequired,

    // Underlying Section being edited
    section: PropTypes.instanceOf(Section).isRequired,
    // callback when user responds (Approve / Reject)
    onReview: PropTypes.func.isRequired,
    // Not every DiffView gets re-rendered when ContentState changes, if the edits (or DiffView reviews) were in a different ContentBlock
    // So this gives us a way to retrieve the true *current* ContentState when applying a review()
    getEditorState: PropTypes.func.isRequired,
    // (Previous) Section.version that the current diffs will be referencing when saved
    version: PropTypes.instanceOf(Version),
    // Still colorize but make inactive for case of stale edits in ContentSection EditorState
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      reviewing: false,
      version: props.version,
    };

    this.diffRef = createRef();
    this.id = getUniqueKey();
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps({ version }) {
    if (version.id != this.props.version.id) {
      this.setState({ version });
    }
  }

  get diff() {
    const { entityKey, getEditorState, section } = this.props;
    if (!entityKey || !getEditorState()) return null;
    const cs = getEditorState().getCurrentContent();
    return Diff.get(section, cs, entityKey);
  }

  review(action) {
    const { section, onReview, start, end, blockKey, getEditorState } = this.props;
    const du = section.deal.currentDealUser;
    const type = this.diff.type;
    let cs = getEditorState().getCurrentContent();
    let block = cs.getBlockForKey(blockKey);
    let newDiff, newType, history, currentIndex;

    const diff = this.diff;

    // Get selection in the editor representing the diff being reviewed
    const selection = SelectionState.createEmpty(block.getKey()).merge({ anchorOffset: start, focusOffset: end });

    switch (action) {
      case SectionActions.UNDO:
        // If user had removed text, remove the entity (text is already still there so just remove the redline)
        if (type === ENTITY_TYPE.DIFF_REMOVED) {
          cs = Modifier.applyEntity(cs, selection, null);
        }
        // If user had added text, just remove it (which removes the entity)
        else {
          cs = Modifier.removeRange(cs, selection, 'forward');
        }
        break;
      case SectionActions.APPROVE:
        // Approving an addition means remove the entity
        if (type === ENTITY_TYPE.DIFF_ADDED) {
          cs = Modifier.applyEntity(cs, selection, null);
        }
        // Approving a rejection means removing the text (and with it, the entity)
        else {
          cs = Modifier.removeRange(cs, selection, 'forward');
        }
        break;
      // IMPORTANT: Outlaw has a deliberately different UI around rejecting diffs,
      // because in a contract scenario, rejecting someone else's changes means you are still not in agreement
      // so it effectively means we want to flip the previous diff to a new one of the opposite type,
      // but still keep it intact as a diff instead of resolving
      case SectionActions.REJECT:
        newType = type === ENTITY_TYPE.DIFF_ADDED ? ENTITY_TYPE.DIFF_REMOVED : ENTITY_TYPE.DIFF_ADDED;
        // Either way, we need to first capture a reference to the *prior* diff's id... which is the one passed in props
        newDiff = Diff.create(section, {
          contentState: cs,
          user: du.uid,
          type: newType,
          priorDiff: diff.id,
        });
        cs = Modifier.applyEntity(cs, selection, newDiff.entityKey);

        break;
      case SectionActions.REVERT:
        history = diff.history;
        currentIndex = history.indexOf(diff);
        if (currentIndex > 0) {
          let prevDiff = history[currentIndex - 1];
          prevDiff = Diff.create(section, { contentState: cs, ...prevDiff.entityData });
          cs = Modifier.applyEntity(cs, selection, prevDiff.entityKey);
        }
        break;
    }

    // ContentState will have been modified in some way above; pass editorState back to parent (ContentSection) and close the popover
    onReview(cs);
    if (this._isMounted) this.setState({ reviewing: false });
  }

  renderHistoryItem(diff, idx) {
    const { displayAction, displayName, displayDate } = diff;

    // If the last Diff is pending and can be reverted, we don't need to show it
    // because a separate message will show instead
    if (diff.isPending && diff.canRevert) return null;

    return (
      <div className="diff-step" key={idx}>
        <div className="name-action">
          <span className="name">{displayName}</span> {displayAction} this text
        </div>
        <div className="date">{displayDate}</div>
      </div>
    );
  }

  render() {
    const { children, container, disabled } = this.props;
    const { reviewing } = this.state;
    const diff = this.diff;
    if (!diff) return null;

    const { isPending, canRevert, canUndo, canApprove, pendingAction } = diff;

    const className = cx(
      diff.type,
      'diff',
      { 'can-review': !disabled },
      { 'can-revert': canRevert },
      { 'can-undo': canUndo },
      { pending: isPending }
    );

    // Rendering the Popover inside the <span> adjacent to {children} was causing some very strange orphaning issues,
    // where the Popover wouldn't hide and then would instead target a different DiffView (!!)
    // Using a fragment fixes this, so that the Popover is no longer a sibling of {children}
    return (
      <>
        <span
          className={className}
          ref={this.diffRef}
          onClick={() => (disabled ? null : this.setState({ reviewing: true }))}
        >
          {children}
        </span>
        {reviewing && (
          <Overlay
            show={reviewing && !disabled}
            onHide={() => this.setState({ reviewing: false })}
            target={this.diffRef.current}
            container={container}
            placement="top"
            rootClose
          >
            <Popover className="pop-diff" id={`pop-diff-${this.id}`}>
              <div className="diff-info">
                <div className="diff-history">{diff.history.map(this.renderHistoryItem)}</div>
                {canRevert && isPending && (
                  <div className="pending">Your {pendingAction} will be saved when you click 'Save'</div>
                )}
              </div>
              {!canUndo && !canRevert && (
                <div className="diff-review">
                  <Button
                    bsClass="redline-diff"
                    dmpStyle="link"
                    className="approve"
                    onClick={() => this.review(SectionActions.APPROVE)}
                  >
                    Approve
                  </Button>
                  <Button
                    bsClass="redline-diff"
                    dmpStyle="link"
                    className="reject"
                    onClick={() => this.review(SectionActions.REJECT)}
                  >
                    Reject
                  </Button>
                </div>
              )}
              {canUndo && (
                <div className="diff-review">
                  <Button
                    bsClass="redline-diff"
                    dmpStyle="link"
                    className="reject"
                    onClick={() => this.review(SectionActions.UNDO)}
                  >
                    Undo
                  </Button>
                  {canApprove && (
                    <Button
                      bsClass="redline-diff"
                      dmpStyle="link"
                      className="approve"
                      onClick={() => this.review(SectionActions.APPROVE)}
                    >
                      Approve
                    </Button>
                  )}
                </div>
              )}
              {canRevert && (
                <div className="diff-review">
                  <Button
                    bsClass="redline-diff"
                    dmpStyle="link"
                    className="reject"
                    onClick={() => this.review(SectionActions.REVERT)}
                  >
                    Cancel {pendingAction}
                  </Button>
                </div>
              )}
            </Popover>
          </Overlay>
        )}
      </>
    );
  }
}
