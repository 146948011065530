import React, { createRef } from 'react';

import cx from 'classnames';
import {
  CompositeDecorator,
  ContentState,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
  convertToRaw,
  getDefaultKeyBinding,
} from 'draft-js';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Overlay } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import DealRole from '@core/enums/DealRole';
import DealStatus from '@core/enums/DealStatus';
import { FILEVINE_SERVICE } from '@core/enums/IntegrationServices';
import SectionType, { HEADER_FOOTER_SUB_SECTION_TYPES } from '@core/enums/SectionType';
import {
  ENTITY_TYPE,
  containsEntity,
  findVariableSuggestTarget,
  isEqual,
  isInsideVariable,
  keepSpaces,
  parseExternalHTML,
  rxSuggest,
  rxVariableReplace,
  strategies,
  titleBindings,
  tokenizeVariables,
} from '@core/models/Content';
import Diff from '@core/models/Diff';
import { ALIGN } from '@core/models/SectionStyle';
import Variable, { CONNECTED_VAR_FIELDS, ValueType, VariableType } from '@core/models/Variable';
import { BASE_ID, sanitize } from '@core/models/Version';
import { dt, timeDifference } from '@core/utils';

import { Button, ButtonIcon, Checkbox, Icon, Loader, Popover } from '@components/dmp';

import ColorLabel from '@components/ColorLabel';
import DealUserView from '@components/DealUserView';
import FootnoteDisplay from '@components/FootnoteDisplay';
import HeaderFooterBreak from '@components/HeaderFooterBreak';
import LinkView from '@components/LinkView';
import PageBreak from '@components/PageBreak';
import TableView from '@components/TableView';
import VariableView from '@components/VariableView';
import DiffView from '@components/deal/DiffView';
import SectionSuggest from '@components/editor/SectionSuggest';
import TooltipButton from '@components/editor/TooltipButton';
import VariableSuggest from '@components/editor/VariableSuggest';
import { measure } from '@components/section_types/SectionMeasurer';
import API from '@root/ApiClient';
import Dealer from '@root/Dealer';
import Fire from '@root/Fire';

import LockTakeover from '../editor/LockTakeover';
import ActivitySection from './ActivitySection';
import SectionMenu, { SectionActions } from './SectionMenu';

const { ONE_COL, TWO_COL } = HEADER_FOOTER_SUB_SECTION_TYPES;

// needed to hardcode button height here to avoid an even worse hack
// because buttons aren't always rendered at the time of resizing so we can't do a measurement
const ACTIONS_HEIGHT = 38;

const getSignatoriesUIDs = (section) => {
  const UIDs = _.flow(
    (signatories) => _.map(signatories, (partyID) => section.deal.getUsersByParty(partyID)),
    (partyUserGroups) => _.map(partyUserGroups, (partyUserGroup) => _.map(partyUserGroup, 'uid'))
  )(section.signatories);

  return _.join(UIDs);
};

export const MESSAGE_TYPE = {
  ERROR: 'error',
  INFO: 'info',
  PENDING: 'pending',
};

export default class ContentSection extends ActivitySection {
  static defaultProps = {
    editable: true,
    skipRerender: false,
    onCreate: _.noop,
    onFocusChange: _.noop,
    cacheContent: _.noop,
    toggleTrack: _.noop,
    keepAlive: _.noop,
    rerender: _.noop,
    notifyChanges: false,
    locks: {},
    stale: false,
    recomputeSectionHeight: _.noop,
  };

  static propTypes = {
    editable: PropTypes.bool,
    skipRerender: PropTypes.bool,
    trackChanges: PropTypes.bool,
    notifyChanges: PropTypes.bool,
    onCreate: PropTypes.func,
    onFocusChange: PropTypes.func,
    cachedTitle: PropTypes.instanceOf(EditorState),
    cachedBody: PropTypes.instanceOf(EditorState),
    cacheContent: PropTypes.func,
    titleKeyHandler: PropTypes.func,
    bodyKeyHandler: PropTypes.func,
    locks: PropTypes.object,
    stale: PropTypes.bool,
    keepAlive: PropTypes.func,
    recomputeSectionHeight: PropTypes.func,
    reselectListContent: PropTypes.func,
  };

  constructor(props) {
    const { container, section } = props;
    super(props);

    const v = props.section.currentVersion;
    this.state = {
      editorState: null,
      titleState: null,
      source: false,
      accordionStyle: {},
      //only start focus on body if there's only body content and no title
      editorFocus: v.body && !v.title ? 'body' : 'title',
      //used for a redline deleted section to show confirm/restore action
      reviewingDeletion: false,
      currentVersion: v,
      saving: false,
      focused: false,
      editorMessage: null,
      //target element if VariableSuggest is active
      vsTarget: false,
      clauseLookup: false,
    };

    this.refSelf = createRef();
    this.contentRef = createRef();
    this.wrapperRef = createRef();
    this.childrenRef = createRef();
    this.bodyEditorRef = createRef();
    this.titleEditorRef = createRef();
    this.activityRef = createRef();
    this.refSuggest = createRef();
    this.refSuggestTargets = {};
    this.refCL = createRef();

    this.resizeHandler = () => this.toggleExpansion(this.state.source);

    this.compositeDecorator = new CompositeDecorator([
      {
        strategy: strategies.diffFinder,
        component: (p) => (
          <DiffView
            {...p}
            container={container}
            section={section}
            onReview={(cs) => this.applyDiffReview(cs)}
            getEditorState={() => this.state.editorState}
            version={this.state.currentVersion}
            disabled={this.props.stale}
          >
            {p.children}
          </DiffView>
        ),
      },
      {
        strategy: strategies.variable,
        component: (p) => <span className="variable-defining">{p.children}</span>,
      },
      {
        strategy: strategies.variableSuggest,
        component: (p) => {
          // We need to create a ref specific to THIS instance of the potential variable
          // so we can use the blockKey and start as a unique ref id
          const refKey = `${p.blockKey}|${p.start}`;
          this.refSuggestTargets[refKey] = createRef();
          return (
            <span className="var-suggest" ref={this.refSuggestTargets[refKey]}>
              {p.children}
            </span>
          );
        },
      },
    ]);
  }

  onChange(editorState) {
    this.applyChanges({ editorState });
  }

  onTitleChange(titleState) {
    this.applyChanges({ titleState });
  }

  componentDidMount() {
    this.toggleExpansion();
    window.addEventListener('resize', this.resizeHandler);

    // When ContentSections get re-mounted, if they were previously being edited, keep 'em that way!
    // What we have for both title and body is a cached EditorState, which makes it possible to get both the ContentState and SelectionState
    // To be safe, we want to re-create new EditorStates for both
    // This is necessary because we need to ensure that this.compositeDecorator gets called for the *current* (re-mounted) component
    // Which forces all DiffViews to be re-instantiated with updated refs as well
    // Without that, the DiffViews stop working properly after unmount/remount,
    // because they call applyReview on a parent ContentSection that is no longer mounted
    this.loadEditorsFromCache();
  }

  componentDidUpdate(prevProps, prevState) {
    measure(this);
  }

  loadEditorsFromCache() {
    const { cachedTitle, cachedBody } = this.props;
    if (cachedTitle || cachedBody) {
      const newState = { focused: true };
      if (cachedTitle) {
        let newTitle = EditorState.createWithContent(cachedTitle.getCurrentContent());
        newTitle = EditorState.acceptSelection(newTitle, cachedTitle.getSelection());
        newState.titleState = newTitle;
      }
      if (cachedBody) {
        let newBody = EditorState.createWithContent(cachedBody.getCurrentContent(), this.compositeDecorator);
        newBody = EditorState.acceptSelection(newBody, cachedBody.getSelection());
        newState.editorState = newBody;
      }
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    const { section, showActivity, toggleActivity, cacheContent } = this.props;
    // Make sure we remove the "now out of view activityView" to avoid a bug when we scroll back.
    if (showActivity && showActivity.includes(section.id)) {
      toggleActivity(false, section.id);
    }

    // ContentSections get unmounted due to "windowing" (see SourceViewVirtual) when user scrolls out of view
    // This way, we can cache their editor state and re-mount them with the same state
    if (this.editing) {
      cacheContent(section.id, this.cacheableState);
    }

    window.removeEventListener('resize', this.resizeHandler);
  }

  get isEmptySource() {
    const { section } = this.props;
    const v = section.currentVersion;

    return !v.hasContent && !v.title.hasText() && section.sectiontype === SectionType.SOURCE;
  }

  get cacheableState() {
    return {
      editorState: this.state.editorState,
      titleState: this.state.titleState,
      priorVersionID: this.props.section.currentVersion.id,
      stale: this.props.stale,
    };
  }

  get hasContent() {
    const { editorState, titleState } = this.state;
    const title = titleState ? titleState.getCurrentContent().getPlainText().trim() : '';
    const body = editorState ? editorState.getCurrentContent().getPlainText().trim() : '';
    return !!title || !!body;
  }

  get track() {
    const { section, trackChanges } = this.props;

    if (!section.canTrack) return false;

    // Force redlining for proposer users
    return (
      trackChanges ||
      section.currentVersion.hasChanges('body') ||
      _.get(section, 'deal.currentDealUser.role') === DealRole.PROPOSER
    );
  }

  get currentDiffs() {
    const { section } = this.props;
    const { editorState } = this.state;
    const cs = editorState ? editorState.getCurrentContent() : section.currentVersion.body;
    return Diff.getAll(section, cs);
  }

  get hasChanges() {
    const { section } = this.props;
    const { editorState, titleState } = this.state;

    const saved = section.currentVersion;

    // If the saved version is empty (a base version with no content) and the current state is empty, those are equivelent -- stop here
    if (saved.isPlaceholder && !this.hasContent) return false;

    const savedTitle = section.currentVersion.title;
    const savedBody = section.currentVersion.body;

    // Versions always have title/body even if empty/null, so we need to simulate that here for comparison
    const currentBody = editorState ? editorState.getCurrentContent() : ContentState.createFromText('');
    const currentTitle = titleState ? titleState.getCurrentContent() : ContentState.createFromText('');

    return !isEqual(savedBody, currentBody) || !isEqual(savedTitle, currentTitle);
  }

  get editorMessage() {
    const { section, user, stale } = this.props;
    const { editorMessage } = this.state;

    // If there's a message in state (currently only used for showing an error), use that
    if (editorMessage) return editorMessage;

    // If viewing a Caption or column, there's not enough space in UI for messages
    if (section.isCaption) return null;

    // If it's stale, show dead end, only thing to do is cancel out
    if (stale) {
      return {
        message: 'Another user is editing, your changes can not be saved',
        type: MESSAGE_TYPE.INFO,
      };
    }

    // Otherwise, construct dynamically from the 3 potential cases below:
    // 1. There are current pending changes that need to be saved
    if (this.hasChanges) {
      return {
        message: (
          <div>
            <span className="editor-message-save">[Save]</span> <span>to commit your changes</span>
          </div>
        ),
        type: MESSAGE_TYPE.PENDING,
      };
    }
    // 2. Only 1 version means nothing to show
    else if (section.versions.length === 1 && section.versions[0].id === BASE_ID) {
      return {
        message: null,
        type: MESSAGE_TYPE.INFO,
      };
    }
    // 3. Multiple versions means someone must have made an edit beyond the original
    else {
      const version = section.currentVersion;
      const du = section.deal.getUserByID(version.user);
      // ITEM sections get their V1 replaced with the real thing once the first version is saved
      // Meaning there is no "BASE" version because it's not templated
      // So we can still show the date stamp, but as there's only 1 version, disable version history
      const versionLink =
        section.versions.length > 1 ? (
          <span className="version-history" onClick={() => this.handleAction(SectionActions.VERSIONS)}>
            {version.displayDate}
          </span>
        ) : (
          version.displayDate
        );
      return {
        message: (
          <div>
            {version.getDisplayName(du, _.get(user, 'id'))} saved V{section.versions.indexOf(version) + 1} on{' '}
            {versionLink}
          </div>
        ),
        type: MESSAGE_TYPE.INFO,
      };
    }
  }

  placeHolder = (isTitle) => {
    const { section } = this.props;
    const isInAppendix = section.appendix;
    const name = isTitle ? 'Title' : 'Body';

    switch (this.props.section.sectiontype) {
      case SectionType.SOURCE:
        let sourcePlaceholder = `Paragraph ${name}`;
        sourcePlaceholder = isInAppendix ? `Appendix Item ${name}` : sourcePlaceholder;
        if (section.sourceParent) {
          const isInSignature = section.sourceParent.sectiontype === SectionType.SIGNATURE;
          sourcePlaceholder = isInSignature ? `Signature Item ${name}` : sourcePlaceholder;
        }
        return sourcePlaceholder;
      case SectionType.APPENDIX:
        return 'Appendix - Contents';
      case SectionType.SIGNATURE:
        return 'Signature - Contents';
      default:
        const listType = _.get(section, 'list.subType') === 'LIST';
        const checkAppendix = _.get(section, 'parent.sourceParent.sectiontype') === SectionType.APPENDIX;
        const checkSignature = _.get(section, 'parent.sourceParent.sectiontype') === SectionType.SIGNATURE;
        let phBody = '';

        if (checkAppendix) {
          phBody = listType ? `Appendix List Item ${name}` : `Appendix Block Item ${name}`;
        } else if (checkSignature) {
          phBody = listType ? `Signature List Item ${name}` : `Signature Block Item ${name}`;
        } else {
          phBody = listType ? `List Item ${name}` : `Block Item ${name}`;
        }
        return phBody;
    }
  };

  handlePaste(text, html, field) {
    const { section, user } = this.props;

    // Only allow bulk copy/paste for empty, new ITEM sections (in LISTs and legacy SCOPEs)
    if (
      section.isItem &&
      _.get(section, 'list.subType') === 'LIST' &&
      !this.hasContent &&
      section.currentVersion.isPlaceholder
    ) {
      const sections = parseExternalHTML(html);

      // We've should have an array of 1+ json objects to add as sections
      if (sections.length > 0) {
        Fire.addItemBatch(section, sections, (replaced) => {
          //if content was replaced, we need to update state
          if (replaced) {
            const sec = sections[0];
            this.setState({
              editorState: this.createEditorState(sec.content),
              titleState: this.createTitleEditorState(),
            });
            this.cancel();
          }
        });
        const master = section.list || section.appendix;
        if (master) {
          Fire.addActivity(master, user, DealAction.PASTE, sections.length);
        }
        return true;
      }
      // Otherwise (not sure how we'd even get here... empty content?) let default DraftJS handler do the work
      else {
        return false; //default behavior
      }
    } else if (field === 'body') {
      // For body, if redlining is on, let custom body handler do the work
      if (this.track) {
        this.onBodyKeyDown({}, text);
        return true;
      }
      // Otherwise, let DraftJS handle it and insert as new text
      else {
        return false;
      }
    }
    // For title (no redlining) let DraftJS handle it do the work
    else {
      return false;
    }
  }

  showEditingError(editingError, delay = 3000) {
    this.setState({ editorMessage: { message: editingError, type: MESSAGE_TYPE.ERROR } });
    const timer = setTimeout(() => {
      try {
        this.setState({ editorMessage: null });
      } catch (er) {}
      clearTimeout(timer);
    }, delay);
  }

  onTitleKeyDown(e) {
    const { section, keepAlive } = this.props;
    const { titleState } = this.state;
    const body = this.bodyEditorRef.current;

    // EVERY keypress (even if it's rejected) means user is still working,
    // So reset the timeout on the section lock
    keepAlive(section.id);

    switch (e.key) {
      case '/':
        if (
          section.canCL &&
          section?.currentVersion?.isPlaceholder &&
          titleState.getCurrentContent().getPlainText().trim() === ''
        ) {
          this.setState({ clauseLookup: true });
          return true;
        }
        break;
      // Hitting enter on title moves focus to body
      case 'Enter':
        if (body) body.focus();
        return true;
      // Escape with no changes means cancel
      case 'Escape':
        if (!this.hasChanges) {
          this.cancel();
        }
        return true;
      // Tab can indent/outdent, but only for ITEMs
      // (further testing on whether ITEM can actually move is inside Fire.moveSection)
      case 'Tab':
        if (section.isItem) {
          Fire.moveSection(section, e.shiftKey ? 'left' : 'right');
        }
        return true;
      default:
        break;
    }

    return getDefaultKeyBinding(e);
  }

  async clearAndExitClauseLookup() {
    await this.setState({ clauseLookup: false, titleState: EditorState.createEmpty() });
    this.focus();
  }

  onClauseLookupKeyDown(e) {
    const { section, keepAlive } = this.props;
    const { clauseLookup, titleState } = this.state;

    const cl = this.refCL.current;

    keepAlive(section.id);

    // Only do inline CL lookup if there's no body text
    if (!this.hasBody && cl && cl.handleKey(e)) return true;

    if (e.key === 'Backspace') {
      if (clauseLookup && titleState.getCurrentContent().getPlainText().trim() === '') {
        this.setState({ clauseLookup: false });
        return true;
      }
    }

    if (e.key === 'Escape') {
      if (clauseLookup) {
        this.clearAndExitClauseLookup();
        return true;
      }
    }

    // In normal cases, just handle keypress with extra commands (enter, tab, etc)
    return titleBindings(e, titleState);
  }

  onBodyKeyDown(e, pasted) {
    const { vsTarget } = this.state;
    const { section, keepAlive, bodyKeyHandler } = this.props;
    const varSuggest = this.refSuggest.current;

    // EVERY keypress (even if it's rejected) means user is still working,
    // So reset the timeout on the section lock
    keepAlive(section.id);

    // Allow VariableSuggest to hijack certain keys (enter, escape, arrows) if active
    if (vsTarget && varSuggest && varSuggest.handleKey(e)) return true;

    // Allow a parent Component to specify custom key bindings, as in ListSection
    if (typeof bodyKeyHandler === 'function') {
      const handled = bodyKeyHandler(e, section);
      // Special case for hitting enter to sequentially add list items;
      // The addition and focus of a new item is delegated, but we ALSO need to save (and blur) *this* item
      if (e.key === 'Enter' && !e.shiftKey && _.get(section, 'list.subType') === 'LIST') {
        this.save();
      }
      if (handled) return true;
    }

    if (e.key === 'Escape' && !this.hasChanges) {
      this.cancel();
      return true;
    }

    // We're not yet using proper key bindings in the way we are for SectionEditor (see bodyBindings in Content.js)
    // So this is a special case fix to ensure that "cut" command doesn't circumvent redlining (https://trello.com/c/maoVQmqG)
    // Eventually this should be refactored to use bodyBindings and make "cut" a custom Outlaw command
    const isCut = (e.ctrlKey || e.metaKey) && e.key === 'x';

    if (
      ((e.ctrlKey || e.metaKey) && !isCut) ||
      !this.track ||
      ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(e.key)
    ) {
      return getDefaultKeyBinding(e);
    }

    const du = section.deal.currentDealUser;

    let es = this.state.editorState;
    let cs = es.getCurrentContent();
    let sel = es.getSelection();
    let block = cs.getBlockForKey(sel.getStartKey());
    let cursor, newText, newCursor, diffAtCursor, diffBack1, diffAtStart, diffAtEnd, diff;

    if (e.key === 'Backspace' || isCut) {
      // Pre-calculate where cursor will be after deletion event occurs
      cursor = sel.getFocusOffset();
      newCursor = sel.isCollapsed() ? cursor - 1 : cursor;

      // Gather awareness of surrounding Diffs that we'll use in various cases below
      diffAtCursor = Diff.get(section, cs, block.getEntityAt(cursor));
      diffBack1 = cursor >= 1 ? Diff.get(section, cs, block.getEntityAt(cursor - 1)) : null;

      // No deleting if cursor is at start of editor
      if (cursor == 0 && sel.isCollapsed()) {
        // If first block, nothing to do
        if (block === cs.getFirstBlock()) return true;
        // If not, allow default behavior (join blocks)
        else return getDefaultKeyBinding(e);
      }

      // Collapsed selection is normal backspace scenario
      if (sel.isCollapsed()) {
        // Disallow variable editing
        if (containsEntity(cs, sel, [ENTITY_TYPE.VARIABLE])) {
          this.showEditingError('You can not edit variables while redlining');
          return true;
        }

        // If user is re-deleting something they just added (but didn't save yet), just delete the text and we're done!
        if (_.get(diffBack1, 'type') === ENTITY_TYPE.DIFF_ADDED && diffBack1.isMutable) {
          sel = sel.merge({ anchorOffset: cursor - 1, focusOffset: cursor });
          cs = Modifier.removeRange(cs, sel, 'backward');
          es = EditorState.push(es, cs);
          es = EditorState.forceSelection(es, sel.merge({ anchorOffset: newCursor, focusOffset: newCursor }));
          this.setState({ editorState: es });
          return true;
        }

        // If cursor is moving onto a removed diff, just move the cursor back 1 spot but don't touch the diff
        if (_.get(diffBack1, 'type') === ENTITY_TYPE.DIFF_REMOVED) {
          es = EditorState.forceSelection(es, sel.merge({ anchorOffset: newCursor, focusOffset: newCursor }));
          this.setState({ editorState: es });
          return true;
        }

        // If there's a removed diff at current position, we can potentially extend
        // this covers hitting backspace sequentially, i.e., cursor is always at the beginning of range
        if (!diffBack1 && _.get(diffAtCursor, 'type') === ENTITY_TYPE.DIFF_REMOVED && diffAtCursor.isMutable) {
          diff = diffAtCursor;
        }

        //if cursor is collapsed, we need to create a range starting from the prior character
        sel = sel.merge({ anchorOffset: cursor - 1, focusOffset: cursor });
      }

      // Here the cursor isn't moving because there's a non-collapsed selection, i.e., selected text
      else {
        // If there are ANY entities (diffs or variables) inside selected range; if found, disallow
        if (containsEntity(cs, sel, [ENTITY_TYPE.DIFF_ADDED, ENTITY_TYPE.DIFF_REMOVED])) {
          this.showEditingError('You can not delete text containing existing redlines');
          return true;
        }
        // And again disallow variables
        if (containsEntity(cs, sel, [ENTITY_TYPE.VARIABLE])) {
          this.showEditingError('You can not edit variables while redlining');
          return true;
        }

        // Look to see if range being removed can be combined with another already removed range
        diffAtStart = Diff.get(section, cs, block.getEntityAt(Math.max(0, sel.getStartOffset() - 1)));
        diffAtEnd = Diff.get(section, cs, block.getEntityAt(sel.getEndOffset()));

        if (_.get(diffAtStart, 'type') === ENTITY_TYPE.DIFF_REMOVED && diffAtStart.isMutable) {
          diff = diffAtStart;
        } else if (_.get(diffAtEnd, 'type') === ENTITY_TYPE.DIFF_REMOVED && diffAtEnd.isMutable) {
          diff = diffAtEnd;
        }
      }

      // If no existing removed diff was found to merge with, create a new one
      if (!diff) {
        diff = Diff.create(section, { contentState: cs, type: ENTITY_TYPE.DIFF_REMOVED, user: du.uid });
      }

      // Finally apply entity and selection and push into state
      cs = Modifier.applyEntity(cs, sel, diff.entityKey);
      es = EditorState.push(es, cs);
      es = EditorState.acceptSelection(
        es,
        sel.merge({ anchorOffset: sel.getStartOffset(), focusOffset: sel.getStartOffset() })
      );
      this.applyChanges({ editorState: es });
    } else if (pasted || e.key.length === 1) {
      // If selection not collapsed, first do the routine above to remove what's there
      // this is the case where user selects text and then starts typing to replace,
      // without explicitly hitting backspace
      if (!sel.isCollapsed()) {
        // Again, first check to make sure selection doesn't contain any entities
        if (containsEntity(cs, sel, [ENTITY_TYPE.VARIABLE])) {
          this.showEditingError('You can not edit variables while redlining');
          return true;
        }

        // And again ensure there are no Diffs being replaced
        if (containsEntity(cs, sel, [ENTITY_TYPE.DIFF_ADDED, ENTITY_TYPE.DIFF_REMOVED])) {
          this.showEditingError('You can not replace text containing existing redlines');
          return true;
        }

        // Here it's safe to remove selected text -- create and apply removed entity
        diff = Diff.create(section, { contentState: cs, type: ENTITY_TYPE.DIFF_REMOVED, user: du.uid });
        cs = Modifier.applyEntity(cs, sel, diff.entityKey);
        // Then move selection to end and collapse it there for text addition below
        sel = sel.merge({ anchorOffset: sel.getFocusOffset(), focusOffset: sel.getFocusOffset() });
      }

      // One last time, now on a collapsed selection; make sure we're not trying to type into a variable
      if (isInsideVariable(cs, sel)) {
        this.showEditingError('You can not edit variables while redlining');
        return true;
      }

      // Finally here we're ready to add text, either pasted or a new character
      // Again collect some awareness of surrounding diffs
      newText = pasted || e.key;
      cursor = sel.getEndOffset();
      diffAtCursor = Diff.get(section, cs, block.getEntityAt(cursor));
      diffBack1 = cursor >= 1 ? Diff.get(section, cs, block.getEntityAt(cursor - 1)) : null;

      // Disallow adding text into the middle of a removed entity
      if (
        diffAtCursor &&
        diffBack1 &&
        diffAtCursor.id === diffBack1.id &&
        diffAtCursor.type === ENTITY_TYPE.DIFF_REMOVED
      ) {
        this.showEditingError('You can not edit pre-existing redlines');
        return true;
      }

      // If user is sequentially typing to add new text, combine into one diff
      if (_.get(diffBack1, 'type') === ENTITY_TYPE.DIFF_ADDED && diffBack1.isMutable) {
        diff = diffBack1;
      }
      // Otherwise it's a new diff
      else {
        diff = Diff.create(section, { contentState: cs, user: du.uid, type: ENTITY_TYPE.DIFF_ADDED });
      }

      // Finally, attach the diff to the new text and push into state
      cs = Modifier.insertText(cs, sel, newText, null, diff.entityKey);
      es = EditorState.push(es, cs);
      sel = sel.merge({ anchorOffset: cursor + newText.length, focusOffset: cursor + newText.length });
      es = EditorState.acceptSelection(es, sel);
      this.applyChanges({ editorState: es });
    }
    //prevent default behavior (onChange)
    return true;
  }

  // This updates the content in editors, and then *after* state updates,
  // it pushes the latest content into SourceViewVirtual.editorCache,
  // to ensure that changes persist virtualized windowing or disconnection (unlock/timeout)
  applyChanges(newState) {
    const { cacheContent, section } = this.props;
    this.setState(newState, () => {
      cacheContent(section.id, this.cacheableState);

      if (newState.editorState) {
        const vsTarget = findVariableSuggestTarget({
          editorState: newState.editorState,
          refTargets: this.refSuggestTargets,
          rx: rxSuggest,
        });
        this.setState({ vsTarget });
      }

      return newState;
    });
  }

  // Using Editor.push lets us apply the redlining changes from DiffView while keeping the undo stack intact!
  // Note to self, Evan: RFTM :-) https://draftjs.org/docs/api-reference-editor-state/#push
  applyDiffReview(contentState) {
    const editorState = EditorState.push(this.state.editorState, contentState, 'apply-entity');
    this.applyChanges({ editorState });
  }

  // While I *hate* copy/pasting code (from SectionEditor) and prefer to keep things DRY,
  // The desired behavior here is sufficiently different (and sufficiently small amount of code) that it's warranted
  // Here (in Flow), we DON'T want to auto-save while the user is typing, because we're saving explicit Versions,
  // So all we need to do is insert the text from the VariableSuggest, advance the cursor, and we're done
  commitVariableSuggestion(newText, option) {
    const { vsTarget, editorState } = this.state;
    const { section } = this.props;

    if (!vsTarget) return;

    const { block, start, input } = vsTarget;

    // We will likely have a partial search string already present, e.g., '[#va'
    // so select the whole thing before inserting (replacing) text
    let selection = SelectionState.createEmpty(block.getKey()).merge({
      anchorOffset: start,
      focusOffset: start + input.length,
    });
    let newState = EditorState.forceSelection(editorState, selection);
    const newCS = Modifier.replaceText(newState.getCurrentContent(), selection, newText);

    newState = EditorState.push(newState, newCS, 'insert-characters');
    //move cursor forward by the length of the inserted text
    //and apply to editorState
    selection = selection.merge({ anchorOffset: start + newText.length, focusOffset: start + newText.length });
    newState = EditorState.forceSelection(newState, selection);
    this.applyChanges({ editorState: newState });

    // If we select an external variable (Filevine, maybe others soon) that's not on the Deal yet, add it as a variable definition to the Deal!
    // This will trigger a Deal reload, at which point the external value will be loaded/synced in DealView
    if (option.connectType === FILEVINE_SERVICE.key && !section.deal.variables[option.id]) {
      const varDef = _.pick(option, CONNECTED_VAR_FIELDS);
      Fire.saveVariableDefinition(section.deal, varDef);
    }

    if (option.type === VariableType.FOOTNOTE && !(option instanceof Variable)) {
      Fire.saveVariableDefinition(section.deal, option);
    }
  }

  reviewSectionDeletion(deletionApproved) {
    const { section } = this.props;
    Fire.updateSectionDeletion(section, deletionApproved, deletionApproved, () => {
      //component will no longer be mounted if deletion was approved
      //so we only need to setState if it was rejected
      if (!deletionApproved) this.setState({ reviewingDeletion: false });
    });
  }

  toggleExpansion(expand, animate, buttons) {
    if (
      !_.get(this, 'childrenRef.current') ||
      !_.get(this, 'wrapperRef.current') ||
      this.props.section.sectiontype == 'ITEM'
    )
      return;
    const contentNode = this.wrapperRef.current;
    const childrenNode = this.childrenRef.current;
    const style = {
      height: expand ? contentNode.offsetHeight + childrenNode.offsetHeight : contentNode.offsetHeight,
      overflow: expand ? 'visible' : 'hidden',
    };

    //add height for buttons + margin if we're in editing mode (or coming back out of it via cancel())
    if (expand && buttons) {
      style.height += buttons;
    }

    if (animate) {
      const time = expand ? '.3s ease-out' : '.3s';
      style.WebkitTransition = `height ${time}`;
      style.msTransition = `height ${time}`;
    }
    this.setState({ accordionStyle: style, source: expand });
  }

  createEditorState() {
    const version = this.props.section.currentVersion;
    let cs;
    if (this.track) {
      // When EditorState is created we need to tokenize variables in order to make them un-editable entities
      // If redlining is on this makes them untouchable; if not they get deleted as a whole token (which is good)
      cs = tokenizeVariables(version.body);
    } else {
      cs = tokenizeVariables(version.clean('body'));
    }
    return EditorState.createWithContent(cs, this.compositeDecorator);
  }
  createTitleEditorState() {
    const version = this.props.section.currentVersion;
    const cs = this.track ? version.title : version.clean('title');
    return EditorState.createWithContent(cs);
  }

  handleKeyCommand(command) {
    const newState = RichUtils.handleKeyCommand(this.state.editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    } else {
      switch (command) {
        case 'split-block':
          return false;
        default:
          return false;
      }
    }
  }

  get children() {
    const { section } = this.props;

    // First filter view based on conditions
    const filtered = section.deal.applyConditions(section.children);

    // Now also ignore child LIST sections; they are rendered inline in OverviewSection
    // So we skip them here so as not to render them twice
    return _.filter(filtered, (child) => child.sectiontype !== SectionType.LIST);
  }

  //used to determine whether user can see the cog menu for editing
  get canAdmin() {
    const { section, overviewMode, editable } = this.props;
    const deal = section.deal,
      du = deal.currentDealUser;

    if (deal.signed || section.deleted) return false;

    // If this section is part of an assignable section type (SCOPE / PAYMENT / LIST),
    // The relevant getters will find a reference to it
    const assignable = section.list || section.appendix;

    //no longer using canEdit() because we still want to return true if deal is locked
    //to reveal the option to clear signatures
    return (
      du &&
      //user needs to be owner or editor OR assigned to this appendix section
      ([DealRole.OWNER, DealRole.PROPOSER, DealRole.EDITOR].indexOf(du.role) > -1 ||
        (assignable && assignable.assigned && assignable.assigned == du.partyID)) &&
      //no summary editing -- only scope items, source and appendix
      [SectionType.ITEM, SectionType.SOURCE, SectionType.APPENDIX, SectionType.PAYMENT, SectionType.LIST].indexOf(
        section.sectiontype
      ) > -1 &&
      //only Scope Items and Payment Items can be edited in overview mode (not contract source)
      (!overviewMode || [SectionType.ITEM, SectionType.PAYMENT].indexOf(section.sectiontype) > -1) &&
      //no editing contract sections in preview
      (!section.deal.preview || overviewMode) &&
      editable
    );
  }

  // Handling mouseDown/mouseMove lets us prevent accidentally going into editing mode,
  // If user was just trying to drag to select text
  onMouseDown(e) {
    const { clientX, clientY } = e;
    // Capture the exact location of the click so that we can allow a small margin of error for jitter
    this.clicking = { clientX, clientY };
  }

  onMouseMove(e) {
    const { clientX, clientY } = e;
    if (this.clicking) {
      const moveX = Math.abs(this.clicking.clientX - clientX);
      const moveY = Math.abs(this.clicking.clientY - clientY);

      // If the move exceeds 5px, setting clicking to null will avoid the click behavior
      // This margin still allows for expected click behavior in the event that the mouse moves a tiny bit between mousedown and mouseup
      // Otherwise users will get frustrated because their clicks appear to do nothing :-)
      if (moveX >= 5 || moveY >= 5) {
        this.clicking = null;
      }
    }
  }

  // This had to be moved out of defaultAction() handler because of weird interaction with the way Popover/rootClose works
  // It seems to rely on a proper onClick handler, but we're now using onMouseDown/onMouseUp handling for the click-to-edit
  // but avoid that behavior when selecting text
  showDeleteConfirmation(e) {
    if (this.canEdit && this.props.section.deleted && !this.state.reviewingDeletion) {
      this.setState({ reviewingDeletion: true });
    }
  }

  // Default clicks can either expand section (for overviews) or go into editing (if editable)
  defaultAction(e) {
    // If already editing, or if clicking to select text, do nothing (and don't stop propagation!)
    // This line is super important; otherwise it resets editor focus to 0 and it's impossible to type
    if (this.editing || !this.clicking) return;

    //prevent child (SOURCE) sections from triggering parent clicks too
    e.stopPropagation();

    const { section, lock, readonly } = this.props;
    const show = !this.state.source;

    //if this is a content section (not source), action is to toggle source view
    //also, only Source sections can have focus so null this out in Model to be safe
    switch (this.props.section.sectiontype) {
      //if this is a source section, action is either edit (if owner) or view activity
      case 'SOURCE':
      case 'APPENDIX':
      case 'SIGNATURE':
      case 'ITEM':
        if (this.canEdit && !section.deleted && !Dealer.mobile && !lock) {
          this.handleAction('edit');
        }
        break;
      //special case for AI sections that have already been filled
      //allow user to edit like a normal section
      case 'LIST':
        if (section.can('reviseAI') && !section.deleted && !Dealer.mobile && !lock && !readonly) {
          this.handleAction('edit');
        }
        break;
      //summary action is to toggle source view
      //unless we're in manager mode, in which case it's an edit
      //also we'll only get other sectiontypes in manager mode
      //because in normal summary mode, OverviewSection renders custom components for these latter 4
      case 'SUMMARY':
      case 'CONTENT':
      case 'PAYMENT':
      case 'PARTIES':
      case 'SCOPE':
        //nothing to do on a summary section with no children
        if (section.sectiontype == 'SUMMARY' && section.children.length == 0) return;
        this.setState({ source: show });
        this.toggleExpansion(show, true);
        break;
      default:
        break;
    }
  }

  async handleAction(action) {
    const { section, toggleHistory, setQueueFocus, user, lock, rerender, reselectListContent } = this.props;
    let newSectionID = null;

    switch (action) {
      case SectionActions.COMMENT:
        this.toggleActivity(true);
        break;
      case SectionActions.EDIT:
      case SectionActions.PROPOSE:
        if (!lock) {
          await this.focus();
        }
        break;
      case SectionActions.REJECT:
        break;
      case SectionActions.UP:
      case SectionActions.DOWN:
      case SectionActions.LEFT:
      case SectionActions.RIGHT:
        Fire.moveSection(section, action, section.sectiontype == SectionType.SOURCE);
        break;
      // BEFORE and AFTER actions add sections, which we need to communicate to parent container
      // in order for parent to toggle new sections into editing mode
      case SectionActions.BEFORE:
      case SectionActions.AFTER:
        if (section.sectiontype == SectionType.ITEM) {
          let idx = section.parent.children.indexOf(section);
          if (action == 'after') idx += 1;
          newSectionID = await Fire.addItemSection(section.parent, idx);
          setQueueFocus(newSectionID);
        } else {
          //data method adds section according to hierarchy
          //so adding a section "after" 2 will add it at 3
          //but if the section has children, "after" actually means
          //add it as the first child of this section
          //i.e., before the current first child
          if (action == 'after' && section.sourceChildren.length > 0) {
            Fire.addSection(section.sourceChildren[0], true, (id) => setQueueFocus(id), null, null, true);
          } else {
            Fire.addSection(section, true, (id) => setQueueFocus(id), null, null, action == 'before');
          }
        }
        break;
      case SectionActions.SUB:
        if (section.isItem) {
          newSectionID = await Fire.addItemSection(section, 0);
          setQueueFocus(newSectionID);
        } else {
          await Fire.addSection(section, true, (id) => setQueueFocus(id), { sourceparentid: section.id });
        }
        break;
      case SectionActions.NUMBERING:
        const hideOrder = section.hideOrder == null ? true : null;
        Fire.saveSection(section, { hideOrder });
        break;
      case SectionActions.DELETE:
        // Annoying, tooltips don't play nice with disabled buttons, so technically the button is still clickable
        // but we want to just do nothing if deletion shouldn't be enabled
        if (this.currentDiffs.length > 0) return;
        this.delete();
        break;
      //used for deletion of scope items -- no tracking
      case SectionActions.DELETE_HARD:
        this.delete(true, true);
        break;
      case SectionActions.CLEAR_SIG:
        API.call('clearSignatures', { dealID: section.deal.dealID, userOrigin: user.userOrigin });
        break;
      case SectionActions.CLEAR_LIST:
        await Fire.clearList(section.list);
        const master = section.list || section.appendix;
        if (master) {
          Fire.addActivity(master, user, DealAction.CLEAR_LIST);
        }
        if (typeof rerender === 'function') rerender();
        break;
      case SectionActions.RESELECT_LIST:
        reselectListContent();
        break;
      case SectionActions.VERSIONS:
        if (this.editing && !this.hasChanges) {
          await this.cancel();
        }
        toggleHistory(section.id);
        break;
      case SectionActions.PAGE_BREAK:
        const { isCaption, isColumn } = section;
        if (isCaption) {
          await Fire.saveSection(section.sourceParent, { pageBreak: !section.sourceParent.pageBreak });
        }
        //since each indidual column needs to be in sync with page break, update all when you update one.
        else if (isColumn) {
          const { pageBreak, sections } = section.columnContainer;
          for (let i = 0; i < sections.length; i++) {
            await Fire.saveSection(sections[i], { pageBreak: !pageBreak });
          }
        } else {
          await Fire.saveSection(section, { pageBreak: !section.pageBreak });
        }
        break;
    }
  }

  // Enable parent container (SourceViewVirtual or ContractView) to tell section to go into editing mode
  // and focus appropriate field
  async focus() {
    const { section, onFocusChange } = this.props;
    await this.setState({
      focused: true,
      titleState: this.createTitleEditorState(),
      editorState: this.createEditorState(),
      editorMessage: null,
    });

    const saved = section.currentVersion;
    const title = this.titleEditorRef.current,
      body = this.bodyEditorRef.current;

    // If this is a new section, default to title (if there is one)
    if (saved.isPlaceholder) {
      if (title) title.focus();
      else if (body) body.focus();
    }
    // Otherwise default to body
    else {
      if (body) body.focus();
      else if (title) title.focus();
    }

    onFocusChange(section.id, true, this.cacheableState);
  }

  async delete(force, permanent) {
    const { section, user, onFocusChange } = this.props;

    // This section will disappear after deletion, but take it out of editing mode first
    // so that it doesn't appear to still be focused (this fixes Cypress tests)
    await this.setState({ focused: false });
    onFocusChange(section.id, false);

    //permanent deletion is possible for cancelled additions (e.g., user adds section then cancels out)
    //and for Scope Item sections where tracking is never enabled
    if (permanent) {
      Fire.removeSection(section);
    }
    //for normal contract (Source) sections,
    //even if track changes are off, we want to keep the underlying data (and potential versioning etc) intact
    //so that object still exists for audit log etc
    else {
      Fire.updateSectionDeletion(section, true, force || !this.track);

      //and fire action, which is safe because section object still exists
      const activity = await Fire.addActivity(section, user, DealAction.DELETE, null);
      this.notify(section, activity);
    }
  }

  async onSelectCL(sectionRecord, focusedSection) {
    const { onFocusChange } = this.props;
    const [dealID, sectionID] = sectionRecord.id.split('|');
    const path = `deals/${dealID}/sections/${sectionID}`;
    const cl = await Fire.load(path);

    if (focusedSection) {
      await Fire.saveSection(focusedSection, {
        displayname: cl.displayname || null,
        content: cl.content || null,
        originCL: sectionRecord.id,
      });

      await this.reset();
      onFocusChange(focusedSection.id, false);
      this.resizeHandler();
    }
  }

  async save(e) {
    if (e) e.stopPropagation();

    const { section, editableTitle, user, onFocusChange } = this.props;
    const { editorState, titleState } = this.state;
    let title = null,
      body = null;

    if (editorState) {
      body = editorState.getCurrentContent();
    }
    if (editableTitle && titleState) {
      title = titleState.getCurrentContent().getPlainText().trim() || null;
      // No special unicode character bullshit in titles!
      if (title) title = sanitize(title);
    } else {
      title = section.currentVersion.title.getPlainText().trim() || null;
    }

    // Only save a new version if there are changes. if nothing in editor has changed, just go out of edit mode
    // This shouldn't be possible as Save button is disabled when there are no changes... but... ¯\_(ツ)_/¯
    if (!this.hasChanges) {
      console.log('Nothing ventured, nothing gained! (Nothing changed, nothing to save)');
      await this.reset();
      onFocusChange(section.id, false);
      return;
    }

    // Here, there are changes so save a new version of the section
    const currentDiffs = this.currentDiffs;
    const priorDiffs = Diff.getAll(section, section.currentVersion.body);

    let activity = null,
      rawBody = null;

    if (body) {
      // Ensure that we first re-strip the variable entities
      // We never want to store these anyway, as they are purely used to manage editor behavior
      rawBody = tokenizeVariables(body, true);
      rawBody = convertToRaw(rawBody);
    }

    // No special unicode character bullshit in bodies!
    if (rawBody) _.forEach(rawBody.blocks, (block) => (block.text = sanitize(block.text)));

    await this.setState({ saving: true, editorMessage: null });
    await Fire.saveSectionVersion(section, user, title, rawBody);

    // After saving, if the user really just went in and approved all prior changes, log a different action
    // We don't need to explicitly check text equality here because text changes would show up as new Diffs!
    if (priorDiffs.length > 0 && currentDiffs.length === 0) {
      activity = await Fire.addActivity(section, user, DealAction.APPROVE);
      this.notify(section, activity);
    }

    // Otherwise just log an update action
    else {
      activity = await Fire.addActivity(section, user, DealAction.UPDATE);
      this.notify(section, activity);
    }
    await this.reset();
    onFocusChange(section.id, false);
  }

  async cancel(e) {
    const { section, parent, onFocusChange } = this.props;

    if (e) e.stopPropagation();

    // When a new section is created it starts out empty and in editing mode, but is already saved to db
    // if user "cancels" out of editing mode, we want to actually delete the section rather than end up with an empty placeholder
    // so we need an empty check to delete it
    if (section.currentVersion.isPlaceholder) {
      this.delete(true, true);
    } else {
      await this.reset();
      this.toggleActivity(false);
      if (parent) {
        parent.toggleExpansion(true, true, -ACTIONS_HEIGHT);
      }
      onFocusChange(section.id, false);
    }
  }

  reset() {
    return this.setState({
      editorState: null,
      titleState: null,
      focused: false,
      saving: false,
      editorMessage: null,
      clauseLookup: false,
    });
  }

  notify(section, activity) {
    const { notifyChanges } = this.props;
    if (notifyChanges) {
      const args = { dealID: section.deal.dealID, logID: section.id, activityID: activity.id };
      API.call('sendActivity', args, (r) => console.log(r));
    }
  }

  renderChildren() {
    const { renderChildren, container, user, readonly, hideMenu, reselectListContent } = this.props;
    const { source } = this.state;

    if (!renderChildren || this.children.length === 0) return null;

    return (
      <div ref={this.childrenRef} className={cx('source-children', { active: source })}>
        {_.map(this.children, (src) => (
          <ContentSection
            container={container}
            key={src.id}
            section={src}
            parent={this}
            user={user}
            overviewMode
            indent
            sourceMode
            readonly={readonly}
            hideMenu={hideMenu}
            reselectListContent={reselectListContent}
          />
        ))}
      </div>
    );
  }

  renderSection() {
    const {
      container,
      section,
      sourceMode,
      editableTitle,
      readonly,
      user,
      hideMenu,
      locks,
      stale,
      variableIndex,
      reselectListContent,
    } = this.props;

    const { titleState, editorState, source: expanded, saving, vsTarget, clauseLookup } = this.state;
    const editing = this.editing;
    const { isColumn, isCaption, pageBreak, activeFootnotes, footnoteDiff, isTemplateFooter, isTemplateHeader } =
      section;

    let lock = locks ? locks[section.id] : null;
    if (lock && lock.uid === user.id) lock = null;

    //if we are not editing use active otherwise check diffs
    let footnotes = activeFootnotes;
    if (editorState) {
      footnotes = footnoteDiff(editorState);
    }

    let title = titleState;
    let editorStateProp = editorState;

    const className = cx(
      !sourceMode ? 'summary-words' : 'source-words',
      {
        'scope-words':
          section.sectiontype === SectionType.ITEM && _.get(section.parent, 'sectiontype') === SectionType.SCOPE,
      },
      expanded ? 'selected' : 'unselected',
      { readonly: readonly },
      { 'align-center': _.get(section.style, 'align') === ALIGN.CENTER },
      { 'align-right': _.get(section.style, 'align') === ALIGN.RIGHT }
    );

    const textClassName = cx(
      `${this.classRoot}-text`,
      { editing: editing },
      { 'title-only': section.isHeader },
      { readonly: readonly }
    );

    const titleClassName = cx(
      `${this.classRoot}-title`,
      section.isHeader ? `is-header header-${section.headerType}` : false
    );

    //in a normal case where section is already present and user edits,
    //Draft.js state objects will already be present in state
    //but this is necessary for the "add section" functionality
    //to ensure that editor state objects are created prior to rendering
    //we need to do this check here instead of in componentWillMount
    //because we ONLY want to create Draft.js state if the section is being edited
    if (editing) {
      if (!editorStateProp) editorStateProp = this.createEditorState();
      if (editableTitle && !title) title = this.createTitleEditorState();
    }

    // Text alignment is managed at the Section level, but needs to be merged into each of the title/body
    const styleTitle = section.styleTitle.css;
    const styleBody = section.styleBody.css;

    if (section.style.isAligned) {
      styleTitle.textAlign = section.style.align;
      styleBody.textAlign = section.style.align;
    }

    let containerStyle = {};
    let alignedNumberStyle = {};
    if (section.hasNumberAlignmentOverride) {
      alignedNumberStyle = { width: '100%', 'text-align': section.alignment };
      containerStyle['flex-direction'] = 'column';
      containerStyle['display'] = 'flex';
    }

    return (
      <div className={className} ref={this.wrapperRef} style={containerStyle}>
        {pageBreak && !isCaption && !isColumn && <PageBreak section={section} />}
        {isTemplateFooter && section.subSectionType === ONE_COL && <HeaderFooterBreak section={section} />}
        {section.showOrder && (
          <div className={`${this.classRoot}-number`} style={{ ...section.styleNumber.css, ...alignedNumberStyle }}>
            {section.isNumberPlaceholder ? null : section.displayNumber}
          </div>
        )}
        <div
          className={textClassName}
          ref={this.contentRef}
          onMouseDown={(e) => this.onMouseDown(e)}
          onMouseMove={(e) => this.onMouseMove(e)}
          onMouseUp={(e) => this.defaultAction(e)}
          onClick={(e) => this.showDeleteConfirmation(e)}
          style={styleBody}
          data-cy={textClassName}
        >
          {section.currentVersion.title && (!editableTitle || !editing) && !section.isTemplateHeaderFooter && (
            <div className={titleClassName} style={styleTitle} data-cy={titleClassName}>
              {section.displayTitle}
            </div>
          )}

          {editing && clauseLookup ? (
            this.renderClauseLookup()
          ) : (
            <>
              {editableTitle && editing && !section.isTemplateHeaderFooter && (
                <Editor
                  spellCheck={true}
                  editorState={title}
                  blockStyleFn={() => 'editing-title'}
                  onChange={this.onTitleChange.bind(this)}
                  placeholder={this.placeHolder(true)}
                  readOnly={saving || stale || section.hasNumberAlignmentOverride}
                  keyBindingFn={(e) => this.onTitleKeyDown(e)}
                  handlePastedText={(text, html) => this.handlePaste(text, html, 'title')}
                  ref={this.titleEditorRef}
                />
              )}
              {editing && !lock ? (
                <Editor
                  spellCheck={true}
                  editorState={editorStateProp}
                  onChange={this.onChange.bind(this)}
                  placeholder={this.placeHolder(false)}
                  readOnly={saving || stale || section.hasNumberAlignmentOverride}
                  handleKeyCommand={(c) => this.handleKeyCommand(c)}
                  keyBindingFn={(e) => this.onBodyKeyDown(e)}
                  handlePastedText={(text, html) => this.handlePaste(text, html, 'body')}
                  ref={this.bodyEditorRef}
                />
              ) : (
                this.renderContent()
              )}
            </>
          )}
          {vsTarget && !this.track && (
            <VariableSuggest
              ref={this.refSuggest}
              variableIndex={variableIndex}
              deal={section.deal}
              input={vsTarget.input}
              onSelect={(text, option) => this.commitVariableSuggestion(text, option)}
              target={vsTarget.target}
            />
          )}

          {footnotes.length > 0 && !isCaption && !isColumn && (
            <FootnoteDisplay
              styles={section.footnoteStyle.css}
              section={section}
              container={container}
              activeFootnotes={footnotes}
            />
          )}
        </div>
        {!editing &&
          this.canAdmin &&
          !hideMenu &&
          !lock &&
          !stale &&
          !section.isCaption &&
          !section.hasNumberAlignmentOverride && (
            <SectionMenu
              section={section}
              user={user}
              canReselectListItems={!!reselectListContent}
              handleAction={(action) => this.handleAction(action)}
            />
          )}
        {lock && section.deal.can(user.id, 'edit') && !stale && (
          <div id="dd-lock">
            <LockTakeover
              lock={lock}
              section={section}
              handleLock={this.props.lockSection}
              handleUnlock={() => {
                this.props.unlockSection(section.id);
              }}
              focusSection={() => this.props.focusSection(section.id)}
            />
          </div>
        )}
        {isTemplateHeader && section.subSectionType === ONE_COL && <HeaderFooterBreak section={section} />}
      </div>
    );
  }

  renderClauseLookup() {
    const { titleState } = this.state;

    return (
      <Editor
        editorState={titleState}
        blockStyleFn={() => 'editing-title'}
        onChange={this.onTitleChange.bind(this)}
        placeholder="Enter keywords to search"
        keyBindingFn={(e) => this.onClauseLookupKeyDown(e)}
      />
    );
  }

  render() {
    const { section, noActivity } = this.props;
    const { reviewingDeletion, source: expanded, clauseLookup, focused, titleState } = this.state;
    const { currentDealUser } = section.deal;
    const { isColumn, isCaption, pageBreak, isTemplateHeaderFooter, subSectionType } = section;
    //Do not add margin bottom for two col (capiton) based headers/footers
    const style = isTemplateHeaderFooter && !subSectionType ? null : section.webLayout;

    return (
      <div
        ref={this.refSelf}
        data-sectionid={section.id}
        className={cx(
          this.className,
          { 'clause-lookup': clauseLookup },
          { 'page-break': pageBreak && !isCaption && !isColumn },
          { 'is-empty': this.isEmptySource && !section.hasNumberAlignmentOverride },
          { 'header-footer-break': isTemplateHeaderFooter && subSectionType === ONE_COL }
        )}
        style={style}
        onClick={this.handleClickCL}
      >
        {clauseLookup &&
          focused &&
          section.canCL &&
          !section.shouldSyncWithCL &&
          section.currentVersion.isPlaceholder && (
            <SectionSuggest
              contextual
              ref={this.refCL}
              teamID={section.deal.team}
              input={titleState.getCurrentContent().getPlainText()}
              onSelect={(sectionRecord) => this.onSelectCL(sectionRecord, section)}
              section={section}
            />
          )}
        {this.canExpand && this.children.length > 0 ? (
          <>
            <div className="accordion" style={this.state.accordionStyle}>
              {this.renderSection()}
              {this.renderChildren()}
            </div>
            <TooltipButton tip={`Click to expand and view related ${dt} language`} disabled={expanded}>
              <ButtonIcon
                className={`source-toggle action ${expanded ? 'selected' : 'unselected'}`}
                icon={expanded ? 'minus' : 'plus2'}
                onClick={() => this.toggleExpansion(!expanded, true)}
              />
            </TooltipButton>
          </>
        ) : (
          this.renderSection()
        )}

        {this.editing && !clauseLookup && this.renderEditorActions()}

        {!!currentDealUser && this.renderCommentAnchor()}
        {!noActivity && this.renderActivity(this.activityRef.current)}
        {reviewingDeletion && this.renderDeleteConfirmation()}
        {this.renderMarker()}
      </div>
    );
  }

  renderMarker() {
    const { section, user, overviewMode, readonly } = this.props;

    // Source sections inside accordion don't have markers
    if (overviewMode && SectionType.src(section.sectiontype)) return null;

    const uid = _.get(user, 'id');
    const canFill = section.deal.can(uid, 'fillVariables');

    // Empty lists with continuous numbering get their markers rendered here
    // So that they show up inline with other section markers
    if (section.isNumberPlaceholder && !section.isAI) {
      return (
        <div className="marker">
          <ColorLabel right status="todo" label=" " disabled={!canFill} />
        </div>
      );
    }

    // List sections have their own markers unless this is a numberPlaceholder (see above case)
    if (section.todo > 0 && canFill && !section.isList) {
      return (
        <div className="marker">
          <ColorLabel right status="todo" label=" " disabled={readonly} />
        </div>
      );
    } else if (section.deleted && !section.deletionApproved) {
      return (
        <TooltipButton tipID={`tip-marker-${section.id}`} tip="This section has been marked for deletion">
          <div className="marker">
            <ColorLabel right status="alert" label=" " disabled={readonly} />
          </div>
        </TooltipButton>
      );
    } else if (section.activityStatus.data == DealStatus.REVIEW.data) {
      const du = section.deal.getUserByID(section.currentVersion.user);
      const me = user && du && du.key == user.id;
      const status = me ? 'proposed' : 'review';
      const when = section.currentVersion.date ? timeDifference(section.currentVersion.date) : '';
      const tip = me ? (
        <span>
          <b> You </b> {`proposed changes ${when}`}{' '}
        </span>
      ) : (
        <span>
          <b> {du ? (du.fullName ? du.fullName + ' ' : du.email + ' ') : 'Deleted user '} </b>{' '}
          {` proposed changes ${when}`}
        </span>
      );

      return (
        <TooltipButton tipID={`tip-marker-${section.id}`} tip={tip}>
          <div className="marker">
            <ColorLabel right status={status} label=" " onClick={() => this.handleAction('edit')} disabled={readonly} />
          </div>
        </TooltipButton>
      );
    } else return null;
  }

  renderEditorActions() {
    const { section, notifyChanges, trackChanges, toggleNotify, toggleTrack, stale } = this.props;
    const { saving } = this.state;
    const { proposing, editorMessage, currentDiffs, hasChanges } = this;

    // Change tracking is not enabled for PAYMENT and SCOPE ITEM sections
    const canTrack = section.canTrack;
    // Same with notifications, plus notifications are only relevant if deal has been shared (if there's someone to notify!)
    const canNotify = SectionType.src(section.sectiontype) && section.deal.shared;

    return (
      <div className="editor-actions-section" data-cy="editor-actions-section">
        <div className="editor-actions" data-cy="editor-actions">
          <div className="left-side">
            {canTrack && !stale && (
              <div className="editor-item">
                <Checkbox
                  id={`check-track-${section.id}`}
                  checked={currentDiffs.length > 0 || trackChanges || proposing || stale}
                  disabled={currentDiffs.length > 0 || proposing || saving || stale || hasChanges}
                  onChange={toggleTrack}
                  tip={
                    proposing
                      ? 'Redlining is on: your changes will require approval before signing'
                      : currentDiffs.length > 0
                      ? 'Redlining cannot be disabled while there are pending changes on a section'
                      : trackChanges
                      ? 'Redlining is on: your changes will require approval before signing'
                      : hasChanges
                      ? 'This section has unsaved edits with redlines turned off. To re-enable redlining, [save] current edits or [cancel] to revert to the last saved state.'
                      : 'Redlining is off: you can make changes without requiring approval'
                  }
                  tipPlacement="bottom"
                  data-cy="check-redline"
                >
                  Redline
                </Checkbox>
              </div>
            )}
            {canNotify && !stale && (
              <div className="editor-item">
                <Checkbox
                  id={`check-notify-${section.id}`}
                  checked={notifyChanges}
                  disabled={saving || stale}
                  onChange={toggleNotify}
                  tipPlacement="bottom"
                  tip={
                    notifyChanges
                      ? 'Other users will be notified of changes via email'
                      : 'Other users will not be notified of changes'
                  }
                  data-cy="check-notify"
                >
                  Notify
                </Checkbox>
              </div>
            )}
            {!section.isColumn && this.renderDeleteSectionButton()}
          </div>

          <div className="right-side">
            {section.isColumn && this.renderDeleteSectionButton()}
            {saving && <Loader inline />}
            <Button
              className="cancel btn-sm"
              dmpStyle={stale ? 'default' : 'link'}
              disabled={saving}
              onClick={(e) => this.cancel(e)}
              data-cy="btn-editing-cancel"
            >
              Cancel
            </Button>
            {!stale && (
              <TooltipButton
                placement="bottom"
                tip="You have not made any changes to the current section"
                disabled={this.hasChanges}
              >
                <Button
                  className="save"
                  size="small"
                  disabled={saving || !this.hasChanges || stale}
                  onClick={(e) => this.save(e)}
                  data-cy="btn-editing-save"
                >
                  Save
                </Button>
              </TooltipButton>
            )}
          </div>
        </div>

        {editorMessage?.message && (
          <div className="editor-message-section">
            <div className={cx('editor-message', editorMessage.type)} data-cy="editor-message">
              {editorMessage.message}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderDeleteSectionButton() {
    const { section, stale, trackChanges } = this.props;
    const { saving } = this.state;
    const { proposing, currentDiffs } = this;
    // Change tracking is not enabled for PAYMENT and SCOPE ITEM sections
    const canTrack = section.canTrack;
    // For the case where we users try to delete a empty section while redlining is enabled.
    const version = section.currentVersion;
    const empty =
      version.title.getPlainText().trim() === '' &&
      version.body.getPlainText().trim() === '' &&
      !section.hasNumberAlignmentOverride;
    const canNotDelete = trackChanges && empty;
    //tooltip logic for delete section
    let deleteToolTip = 'Delete entire section';
    if (currentDiffs.length > 0) deleteToolTip = 'You can not delete a section while there are pending changes';
    else if (canNotDelete) deleteToolTip = 'You can not delete an empty section while redlining is enabled';

    return (
      canTrack &&
      !proposing &&
      !stale &&
      !section.isCaption &&
      !section.isItem && (
        <div className="editor-item editor-item-trash">
          <TooltipButton tipID={`tip-delete-${section.id}`} placement="bottom" tip={deleteToolTip}>
            <ButtonIcon
              className={cx('trash', { disabled: saving || currentDiffs.length > 0 })}
              disabled={saving || stale || canNotDelete}
              onClick={() => this.handleAction('delete')}
              icon="trash"
              data-cy="editor-trash"
            />
          </TooltipButton>
        </div>
      )
    );
  }

  renderDeleteConfirmation() {
    const { section } = this.props;

    return (
      <Overlay
        show={true}
        onHide={() => this.setState({ reviewingDeletion: false })}
        target={this.contentRef.current}
        container={this.wrapperRef.current || null}
        placement="top"
        rootClose
      >
        <Popover className="pop-diff dark" id={`pop-delete-confirm-${section.id}`}>
          <div className="diff-info">
            <div className="name-action">Section marked for deletion</div>
          </div>
          <div className="diff-review">
            <Button
              bsClass="redline-diff"
              dmpStyle="link"
              className="approve"
              onClick={() => this.reviewSectionDeletion(true)}
            >
              Confirm
            </Button>
            <Button
              bsClass="redline-diff"
              dmpStyle="link"
              className="reject"
              onClick={() => this.reviewSectionDeletion(false)}
            >
              Restore
            </Button>
          </div>
        </Popover>
      </Overlay>
    );
  }

  blocks({ version, section, compareVersion, showClean }) {
    // Use current version unless another is explicitly specified
    const useVersion = version || section.currentVersion;

    // We want a clean view if either explicitly specified (in VersionHistory viewer)
    // Or if a non-collaborative deal with no version specified
    const clean = showClean || section.deleted || (!this.track && !version);
    // Render with diffs unless we're looking at a clean view
    let blocks;

    if (!clean && compareVersion) {
      blocks = useVersion.getDiff('body', compareVersion);
    } else {
      blocks = useVersion.getRanges('body', clean);
    }

    return blocks;
  }

  renderContent() {
    const { raw, section, container, lock, lockSection, unlockSection, sectionIndex, recomputeSectionHeight } =
      this.props;

    if (this.isEmptySource && !section.isCaption && !section.hasNumberAlignmentOverride) {
      return (
        <div key={sectionIndex} className="empty-text" data-cy="empty-text">
          (empty)
        </div>
      );
    }

    const elements = [];

    const blocks = this.blocks(this.props);

    const childProps = _.merge(
      { deal: section.deal },
      _.pick(this.props, ['section', 'noReplace', 'container', 'user', 'readonly'])
    );

    blocks.map((block, blockIndex) => {
      const text = block.text;

      let isTable = false;

      block.ranges.map((range, idx) => {
        let inner = text.slice(range.start, range.end);
        let breaks = 0;
        let styles = range.type.join(' ').toLowerCase();
        const key = `${blockIndex}-${idx}`;

        // Party is a variable type but we need to use a different component for editing
        // so we need another conditional
        if (styles.includes('variable')) {
          const txt = inner.replace(rxVariableReplace, '');
          const txtArray = txt.split('.');

          if (raw) {
            styles += ' raw';
          } else if (inner.substr(1, 1) == VariableType.PARTY) {
            const variable = section.deal.variables[txt];
            inner = (
              <DealUserView
                {...childProps}
                inline
                key={key}
                text={_.get(variable, 'val', '')}
                partyID={txtArray[0]}
                property={txtArray.length > 1 ? txtArray[1] : null}
                subProperty={txtArray.length > 2 ? txtArray[2] : null}
              />
            );
          } else {
            // Special case for TABLE variables. need to lookup variable and see if it's a table type
            const baseVariable = section.deal.variables[txtArray[0]];
            // Make sure we pass in the actual variable (not the sourced one) (e.g "my-var.spelled" vs "my-var").
            const variable = section.deal.variables[txt];

            if (baseVariable && baseVariable.valueType === ValueType.TABLE && txtArray.length === 1) {
              isTable = true;
              inner = (
                <TableView
                  key={key}
                  lock={lock}
                  lockSection={lockSection}
                  unlockSection={unlockSection}
                  {...childProps}
                  text={inner}
                  variable={baseVariable}
                  recomputeHeight={() => recomputeSectionHeight(sectionIndex)}
                />
              );
            } else {
              inner = (
                <VariableView
                  key={key}
                  lock={lock}
                  lockSection={lockSection}
                  unlockSection={unlockSection}
                  {...childProps}
                  text={inner}
                  variable={variable}
                  recomputeHeight={() => recomputeSectionHeight(sectionIndex)}
                />
              );
            }
          }
        } else if (styles.includes('diff') && this.canEdit) {
          inner = <span className="can-review">{inner}</span>;
        } else if (styles.includes('url')) {
          inner = (
            <LinkView container={container} url={inner}>
              {inner}
            </LinkView>
          );
        }

        //if styles contain a break, each character in the range IS a newline character so add 1 <br> per character
        //this enables breaks to be nested inside of other ranges
        if (styles.includes('break')) {
          for (var i = 0; i < inner.length; i++) {
            elements.push(<br key={`br-${key}-${breaks}`} />);
            breaks += 1;
          }
        } else if (isTable) {
          elements.push(inner);
        } else {
          // Support multiple consecutive spaces in Section content
          if (typeof inner === 'string' && inner.includes('  ')) {
            inner = keepSpaces(inner);
          }

          elements.push(
            <span className={styles || null} key={key}>
              {inner}
            </span>
          );
        }
      });

      //and render a break between blocks
      if (blockIndex + 1 <= blocks.length && blocks.length > 1) {
        elements.push(<br key={`${blockIndex}-br-split`} />);
      }
    });

    return elements;
  }
}
