import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, ButtonToolbar, FormControl } from 'react-bootstrap';

import DealRole from '@core/enums/DealRole';
import { FILEVINE_SERVICE } from '@core/enums/IntegrationServices';
import Section from '@core/models/Section';
import Variable, { EXTERNAL_TYPES, ValueType } from '@core/models/Variable';
import { format } from '@core/utils/ValueTypeFormatter';

import { Button, Dropdown, DropdownDots, Icon, Key, MenuItem, ModalConfirm } from '@components/dmp';

import VariableView from '@components/VariableView';
import DataSourceBrowser from '@components/deal/DataSourceBrowser';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

// Remove empty rows from the value: Array
const cleanValue = (value) => {
  const cleanedMap = _.map(value, (row) => {
    if (!_.map(row).join('').length) {
      return null;
    }

    return row;
  });

  return _.compact(cleanedMap);
};

const getValue = (variable) => {
  const value = variable.val;
  return !_.isArray(value) ? [] : value;
};

@autoBindMethods
class TableView extends Component {
  autosaveTimeout = null;

  static propTypes = {
    section: PropTypes.instanceOf(Section).isRequired,
    readonly: PropTypes.bool,
    recomputeHeight: _.noop,
    text: PropTypes.string,
    variable: PropTypes.instanceOf(Variable).isRequired,
    lock: PropTypes.object,
    lockSection: PropTypes.func,
    unlockSection: PropTypes.func,
  };

  static defaultProps = {
    readonly: false,
    recomputeHeight: _.noop,
    text: '',
    lockSection: _.noop,
    unlockSection: _.noop,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: getValue(props.variable),
      editingRow: -1,
      confirmClear: false,
      confirmPull: false,
      confirmSync: false,
      loading: false,
      // Whether DataSourceBrowser is showing
      browseDS: false,
      // For connected tables, store latest remote data for comparison (and optional sync)
      remoteValue: [],
      dirtyRows: [],
      changedCols: {},
      pendingSave: false,
      syncSuccess: false,
      syncError: false,
      editing: null,
    };

    // We'd normally use createRef(), but react-bootstrap is old and stupid and will throw PropType errors
    // that we can't prevent until we upgrade react-bootstrap or use something else.
    this.firstRowInputRef = null;

    this.wrapperRef = createRef();

    this.autosave = _.debounce(this.save, 1000);
  }

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener('mousedown', this.handleClickOutside);
    this.loadRemote();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.autosaveTimeout) clearInterval(this.autosaveTimeout);

    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const isEditing = this.state.editingRow >= 0;
    const nextValue = getValue(this.props.variable);
    const prevValue = getValue(prevProps.variable);

    if (!isEditing && !_.isEqual(nextValue, prevValue)) {
      if (this._isMounted) {
        this.setState({ value: nextValue }, this.props.recomputeHeight);
      }
    }
  }

  get isEditing() {
    return this.state.editingRow >= 0;
  }

  get canEdit() {
    const { section, variable } = this.props;

    if (section && section.deleted) return false;

    // Now that we allow TableView to be rendered in Templates
    // We must enable edit for anyone who's able to edit the template.
    // However, connected tables (e.g. for Filevine collections) can not have default data
    // because any default data would get immediately overwritten on first import
    if (section.deal.isTemplate) {
      return !variable.isConnected;
    }

    const du = variable.deal.currentDealUser;

    if (variable.deal.locked || !du || variable.name.split('.').length > 1) return false;
    return (
      [DealRole.EDITOR, DealRole.OWNER].indexOf(du.role) > -1 || (variable.assigned && variable.assigned == du.partyID)
    );
  }

  async handleClickOutside(event) {
    const { value } = this.state;
    const { unlockSection, section } = this.props;

    if (this.isEditing && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (this._isMounted) {
        await this.setState({ value: cleanValue(value), editingRow: -1 });
        unlockSection(section.id);
        this.props.recomputeHeight();
      }
    }
  }

  async addRow(rowIndex = null) {
    const { variable } = this.props;
    const { value: stateValue } = this.state;
    const cols = variable.columns;

    // Create a new row object with keys according to TableColumn IDs, starting with empty strings for state
    const row = {};
    _.forEach(cols, ({ id }) => (row[id] = ''));

    let value = cleanValue([...stateValue]);
    const newRowPosition = rowIndex !== null ? rowIndex + 1 : value.length;

    value.splice(newRowPosition, 0, row);

    await this.setState({ value, editingRow: newRowPosition });
    this.focusRow();
    this.props.recomputeHeight();
  }

  focusRow() {
    const { section, lockSection } = this.props;

    if (this.firstRowInputRef) {
      this.firstRowInputRef.focus();
      lockSection(section.id);
    }
  }

  handleChange(rowIndex, col, e) {
    const { value } = this.state;
    const { variable } = this.props;
    const val = e.target.value;

    value[rowIndex][col.id] = val;

    //If value === remote value, then there is no change
    let remoteValue;
    let hasChanged = true;

    if (variable.isConnected) {
      remoteValue = this.state.remoteValue[rowIndex]?.[col.id];
      hasChanged = String(remoteValue) !== String(val); //Cast both to string before comparing to ensure type parity
    }

    this.setState({
      value,
      changedCols: { ...this.state.changedCols, [`${rowIndex}|${col.id}`]: hasChanged },
      pendingSave: true,
    });

    hasChanged && col.valueType !== ValueType.DATE && this.autosave();
  }

  async saveColumn(column) {
    this.setState({ loading: true });
    const { section, variable } = this.props;
    if (!variable) return;

    const idx = _.findIndex(variable.columns, { id: column.id });
    const columns = variable.columns;

    if (idx > -1) {
      columns[idx] = column;
      variable.columns = columns;

      await Fire.saveVariableDefinition(section.deal, variable.json);
    }

    this.setState({ loading: false });
  }

  handleKeyCommand(e) {
    // escape means cancel
    if (e.keyCode == 27) this.cancel();
    // enter means save
    // also add a new row if we're on the last row
    else if (e.keyCode == 13) {
      const { editingRow, value } = this.state;
      const add = editingRow == value.length - 1;
      this.save(add, true);
    }
  }

  async handleRowAction(action, rowIndex) {
    const { recomputeHeight } = this.props;
    let { value } = this.state;

    switch (action) {
      case 'edit':
        value = cleanValue(value);
        await this.setState({ value, editingRow: rowIndex });
        this.focusRow(rowIndex);
        recomputeHeight();
        break;
      case 'delete':
        value.splice(rowIndex, 1);
        value = cleanValue(value);
        await this.setState({ value, editingRow: -1 });
        this.save();
        recomputeHeight();
        break;
      case 'up':
      case 'down':
        // remove row from array an grab reference to it
        const row = value.splice(rowIndex, 1)[0];
        // reinsert at new position (increment or decrement)
        value.splice(action === 'up' ? rowIndex - 1 : rowIndex + 1, 0, row);
        value = cleanValue(value);
        // We must get out of editing mode so that values updates properly w/ state and props + save
        await this.setState({ editingRow: -1 });
        // and save
        await this.setState({ value });
        this.save();
        break;
      case 'before':
        value = cleanValue(value);
        await this.setState({ value, editingRow: -1 });
        this.addRow(rowIndex - 1);
        break;
      case 'after':
        value = cleanValue(value);
        await this.setState({ value, editingRow: -1 });
        this.addRow(rowIndex);
        break;
      case 'clear':
        this.setState({ confirmClear: true });
        break;
      case 'pull':
        this.setState({ browseDS: true });
        break;
      case 'rowSync':
        this.syncRemoteRows([value[rowIndex]]);
        break;
      default:
        break;
    }
  }

  handleTableAction(key) {
    switch (key) {
      case 'add-row':
        this.addRow();
        return;
      case 'delete-rows':
        this.handleRowAction('clear');
        return;
    }

    this.setState({ editing: key });
  }

  async handleDSItems(ds, items) {
    // We're browsing collection items for a single Table var, so we already have what we need
    // just save the full data-set to this one var
    const convertedValue = JSON.stringify(items);

    await Fire.saveVariable(ds.deal, ds, convertedValue);
    await this.loadRemote();

    // Finally, close the browser after all selected date has been applied
    this.setState({ browseDS: false });
  }

  async cancel() {
    const { variable, unlockSection, recomputeHeight, section } = this.props;
    //make sure we don't have any empty rows
    let rows = variable.val || [],
      value = rows,
      changed = false;
    rows.map((row, idx) => {
      const data = _.find(row, (val) => val != '' && val != null);
      if (!data) {
        value.splice(idx, 1);
        changed = true;
      }
    });

    if (changed) {
      await this.setState({ value, editingRow: -1 }, () => this.save());
    } else {
      await this.setState({ value, editingRow: -1 });
      unlockSection(section.id);
    }
    recomputeHeight();
  }

  // If we save w/ submitted=true it means that the user hit return and that they want to get out of editing mode.
  async save(add, submitted = false) {
    const { variable, unlockSection, recomputeHeight, section } = this.props;
    const { value, editingRow } = this.state;

    // If the row that we are editing is empty, do not save it.
    const editingRowData = this.isEditing ? value[editingRow] : null;
    if (editingRowData && !_.map(editingRowData).join('').length) {
      return;
    }

    //Convert the table values to json object to be able to save special characters in column titles.
    const convertedValue = JSON.stringify(value);

    await Fire.saveVariable(variable.deal, variable, convertedValue);

    if (add) {
      this.addRow(editingRow);
    } else if (submitted) {
      this.setState({ editingRow: -1 });
      unlockSection(section.id);
      recomputeHeight();
    }

    this.setState({ hasSaved: true, pendingSave: false });
    if (this.autosaveTimeout) clearTimeout(this.autosaveTimeout);
    this.autosaveTimeout = setTimeout(() => {
      this._isMounted && this.setState({ hasSaved: false });
    }, 1000);

    this.loadRemote();
  }

  async clear() {
    const { variable } = this.props;
    await this.setState({ loading: true });
    await Fire.saveVariable(variable.deal, variable, '[]');
    await this.setState({ confirmClear: false, editingRow: -1, loading: false });
  }

  async import() {
    const { variable: table, lockSection, unlockSection, section } = this.props;

    lockSection(section.id);
    await this.setState({ loading: true });
    if (table.deal.isConnected && Object.keys(table.deal.syncedVariables).length) {
      await API.call('syncConnectVariables', { dealID: table.deal.dealID, varsToLoad: [table.json] });
    }
    await this.setState({ confirmPull: false, editingRow: -1, loading: false });
    unlockSection(section.id);
  }

  async loadRemote() {
    const { variable: table, lockSection, unlockSection, section } = this.props;
    const { value } = this.state;
    const connection = _.find(table.deal.connections, { type: table?.connectType });

    // Only attempt to load a remote value if 1) table is connected, and 2) we have local data, and 3) deal is not locked
    // Putting this check inside this function lets us freely call it from both componentDidMount and save,
    // without worrying about performance or UX issues
    if (!connection || !value.length || table.deal.locked) return;

    const args = {
      teamID: table.deal.team,
      connection: connection.json,
      variable: table.json,
    };

    try {
      lockSection(section.id);
      await this.setState({ loading: true });

      const { items: remoteValue } = await API.call('getCollectionItems', args);
      const dirtyRows = this.getDirtyRows(table, remoteValue);

      await this.setState({ remoteValue, dirtyRows, loading: false });
    } catch (error) {
      this.setState({ remoteValue: [], loading: false });
    }

    unlockSection(section.id);
  }

  async syncRemoteRows(rows) {
    const { dirtyRows } = this.state;
    const { variable, section, lockSection, unlockSection } = this.props;

    const deal = variable.deal;
    const updates = {};

    _.forEach(rows, (localRow) => {
      const dirtyRow = _.find(dirtyRows, { _itemId: localRow._itemId });
      if (dirtyRow) {
        const rowUpdates = {};
        _.forEach(dirtyRow, (val, key) => {
          if (key === '_itemId') return;
          rowUpdates[key] = val.localValue;
        });

        if (_.keys(rowUpdates).length) {
          updates[dirtyRow._itemId] = rowUpdates;
        }
      }
    });

    if (_.keys(updates).length) {
      const connection = _.find(deal.connections, { type: FILEVINE_SERVICE.key });
      if (!connection) return;

      // Here we finally know that we have updates to make (and the ability to make them)
      lockSection(section.id);
      await this.setState({ loading: true });

      try {
        await API.call('syncConnectedTable', {
          dealID: deal.dealID,
          connection: connection.json,
          variable: variable.json,
          updates,
        });

        // Updates are complete; now reload remote data, which should now match
        await this.loadRemote();

        await this.setState({ loading: false, confirmSync: false, syncSuccess: true, syncError: false });
        setTimeout(() => {
          this._isMounted && this.setState({ changedCols: [], dirtyRows: [], syncSuccess: false, syncError: false });
        }, 5000);
      } catch (e) {
        await this.setState({ loading: false, confirmSync: false, syncSuccess: false, syncError: e.error });
      }

      // And finally unlock
      unlockSection(section.id);
    }
  }

  // Prevent mouse events related to showing/hiding popovers from taking whole section into editing
  stop(e) {
    e.stopPropagation();
  }

  // Now we've got both local and remote data; loop through and collect dirty values
  // NB #1: we are only syncing values of EXISTING rows (collection items)
  // Deleting a local row will NOT delete the remote collection item,
  // and adding a local row will NOT create a new remote collection item
  // ...so we should probably disable new row additions for remote connected tables

  // NB #2: table columns support loading properties of linked complex objects (e.g., Contacts)
  // but in an updating/syncing context, we likely don't want to accidentally sync those values
  // e.g., shortening/abbreviating a hospital name for a dispersal doc output,
  // but not wanting to actually change it in Filevine
  // So we need to decide how to handle these cases...
  // For now we're identifying these by checking for isProjectLinkField (Projects),
  // or the presence of underscores (Contacts, Addresses)
  // so that we can then omit them from dirty/sync calls,
  // but TBD whether this is the appropriate behavior
  // and/or whether it's necessary to inform/educate users about this nuance
  getDirtyRows(variable, remoteValue) {
    const dirtyRows = [];
    _.forEach(variable.tableValueFormatted, (localRow) => {
      const remoteRow = localRow._itemId ? _.find(remoteValue, { _itemId: localRow._itemId }) : null;
      if (remoteRow) {
        const dirtyCells = {};
        _.forEach(variable.columns, (col) => {
          let localCell, remoteCell;

          // NB #3: we need to determine the best way to evaluate equivalency between local and remote values
          try {
            localCell = col.calculated ? col.calculate({ row: localRow }) : localRow[col.id];
            remoteCell = remoteRow[col.id];

            if (localCell != remoteCell && !col.isProjectLinkField && !col.id?.includes('_')) {
              dirtyCells[col.id] = { localValue: localCell, remoteValue: remoteCell };
            }
          } catch (e) {
            console.log('error from getDirtyRows', e);
          }
        });
        if (_.keys(dirtyCells).length) {
          dirtyCells._itemId = localRow._itemId;
          dirtyRows.push(dirtyCells);
        }
      }
    });

    return dirtyRows;
  }

  renderConfirmClear() {
    const { loading, confirmClear } = this.state;

    return (
      <ModalConfirm
        show={true}
        title="Confirmation required"
        cancelText="Cancel"
        confirmText="Delete"
        body={
          <>
            <div>Are you sure you want to delete all table rows? This action can not be undone.</div>
          </>
        }
        onHide={() => this.setState({ confirmClear: false, loading: false })}
        onConfirm={this.clear}
        isLoading={confirmClear && loading}
        data-cy="clear-table-modal"
      />
    );
  }

  renderConfirmPull() {
    const { variable } = this.props;
    const { loading, confirmPull } = this.state;
    const service = _.get(variable, 'service.name', null);

    return (
      <ModalConfirm
        show={true}
        title="Import"
        cancelText="Cancel"
        confirmText="Import"
        body={
          <>
            <div>
              Re-import {service} {variable.displayName} data?
            </div>
            <br />
            <div>This will clear and overwrite all existing rows and can not be undone.</div>
          </>
        }
        onHide={() => this.setState({ confirmPull: false })}
        onConfirm={this.import}
        isLoading={confirmPull && loading}
        data-cy="pull-table-modal"
      />
    );
  }

  renderConfirmSync() {
    const { variable } = this.props;
    const { loading, confirmSync } = this.state;
    const service = _.get(variable, 'service.name', null);

    return (
      <ModalConfirm
        show={true}
        title="Sync"
        cancelText="Cancel"
        confirmText="Sync"
        body={
          <>
            <div>Sync all values to {service}?</div>
            <br />
            <div>This will save all row data to {service} and can not be undone.</div>
          </>
        }
        onHide={() => this.setState({ confirmSync: false })}
        onConfirm={() => this.syncRemoteRows(variable.tableValueFormatted)}
        isLoading={confirmSync && loading}
        data-cy="sync-table-modal"
        dmpStyle="primary"
      />
    );
  }

  renderTableActions() {
    const { variable } = this.props;
    const service = _.get(variable, 'service.name', null);

    return (
      <Dropdown
        title="Table options"
        id="dd-table-options"
        size="small"
        dmpStyle="link"
        onSelect={this.handleTableAction}
        data-cy="table-options"
      >
        {this.state.editing !== 'columns' && <MenuItem eventKey="columns">Adjust column widths</MenuItem>}
        <MenuItem eventKey="add-row" data-cy="add-row">
          Add row
        </MenuItem>
        <MenuItem eventKey="delete-rows">Delete all rows</MenuItem>
      </Dropdown>
    );
  }

  async adjustColumn(column, amt) {
    column.width = column.width + amt;

    if (column.width > 4) return;

    await this.saveColumn(column);
  }

  renderColumnControls(col) {
    return (
      <div className="column-controls">
        <ButtonToolbar>
          <ButtonGroup>
            <Button
              iconOnly
              icon="chevronLeft"
              size="small"
              disabled={col.width <= 2}
              onClick={() => this.adjustColumn(col, -1)}
            />
            <Button
              iconOnly
              icon="chevronRight"
              size="small"
              disabled={col.width >= 4}
              onClick={() => this.adjustColumn(col, 1)}
            />
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    );
  }

  render() {
    const { variable, lock, text } = this.props;
    const {
      browseDS,
      dirtyRows,
      confirmClear,
      confirmPull,
      confirmSync,
      hasSaved,
      loading,
      syncError,
      syncSuccess,
      value,
      editing,
    } = this.state;
    const canEdit = this.canEdit && !lock;
    const service = _.get(variable, 'service.name', null);

    if (!variable) {
      return <VariableView {...this.props} />;
    }

    // If we have a connected table that hasn't been populated yet, just show a link to pop the DS browser
    if (
      !variable.deal.isTemplate &&
      variable.externalType === EXTERNAL_TYPES.COLLECTION &&
      !_.get(variable.val, 'length')
    ) {
      return (
        <>
          <div
            className="browse-items"
            onMouseDown={this.stop}
            onMouseUp={this.stop}
            onClick={() => this.setState({ browseDS: true })}
          >
            Select {variable.displayName}
          </div>
          {browseDS && (
            <DataSourceBrowser
              deal={variable.deal}
              multiselect
              show={true}
              variable={variable}
              onHide={() => this.setState({ browseDS: false })}
              onSelect={this.handleDSItems}
            />
          )}
        </>
      );
    }

    const hasColumns = !!variable.columns.length;
    const classNames = cx('variable-table', { 'no-columns': !hasColumns });
    const styleHeader = variable.deal.style.type.TableHeader.css;
    const totalColWidth = variable.columns.reduce((sum, col) => sum + col.width, 0);

    if (!hasColumns) {
      return (
        <div className="variable-table-wrapper" onMouseDown={this.stop} onMouseUp={this.stop}>
          <div className={classNames}>No columns set</div>
        </div>
      );
    }

    return (
      <>
        <div
          className="variable-table-wrapper"
          onMouseDown={this.stop}
          onMouseUp={this.stop}
          ref={this.wrapperRef}
          data-cy="variable-table"
        >
          <div className="table-data">
            <div className={classNames}>
              <div className="column-headers" data-cy="column-headers">
                {variable.columns.map((col, idx) => {
                  return (
                    <div
                      style={{
                        ...styleHeader,
                        width: `${(col.width / totalColWidth) * 100}%`,
                      }}
                      key={idx}
                    >
                      {editing === 'columns' && this.renderColumnControls(col)}
                      <div className={cx('title', { 'has-tooltip': col.id?.includes('_') })}>
                        {col.displayName}
                        {variable.isConnected &&
                          col.id?.includes('_') && ( //There must be a better way to see if this is a local var
                            <TooltipButton tip={`Can be edited locally but cannot be synced to ${service}`}>
                              <div>
                                <Icon name="syncDisabled" />
                              </div>
                            </TooltipButton>
                          )}
                      </div>
                    </div>
                  );
                })}
                {canEdit && value.length > 0 && <div className="dots">&nbsp;</div>}
              </div>

              {value.map((row, idx) => this.renderRow(row, idx))}
              {variable.showTotalRow && variable.columnsToTotal?.length > 0 && this.renderTotalRow()}
            </div>
          </div>

          <div className="variable-table actions">
            {canEdit && (
              <div className="table-footer" data-cy="table-footer">
                {this.renderTableActions()}
                <div className="help-info" data-cy="help-info-message">
                  {this.isEditing && (
                    <>
                      <Key label="Next&nbsp;cell">Tab</Key>
                      <Key label="Cancel">Esc</Key>
                    </>
                  )}
                  <div className={cx('message', { 'hide-message': !hasSaved })} data-cy="table-message">
                    Autosaved
                  </div>

                  {!syncError && !loading && syncSuccess && (
                    <div className="text-success api-status">
                      <Icon name="circleCheck" color="green" />
                      <span>Sync successful</span>
                    </div>
                  )}

                  {!!syncError && !this.isEditing && (
                    <div className="text-danger api-status">
                      <Icon name="close" color="red" />
                      <span>
                        <b>Sync Failed.</b> {syncError}
                      </span>
                    </div>
                  )}

                  {service && (
                    <div className="load-diff">
                      <Button
                        icon="syncEnabled"
                        onMouseDown={this.stop}
                        onMouseUp={this.stop}
                        onClick={() => this.setState({ confirmSync: true })}
                        disabled={_.get(dirtyRows, 'length') === 0 || syncSuccess || loading}
                      >
                        Sync data to {service}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {confirmClear && this.renderConfirmClear()}
          {confirmPull && this.renderConfirmPull()}
          {confirmSync && this.renderConfirmSync()}
        </div>
        {browseDS && (
          <DataSourceBrowser
            deal={variable.deal}
            multiselect
            show={true}
            variable={variable}
            onHide={() => this.setState({ browseDS: false, reConfigure: false })}
            onSelect={this.handleDSItems}
            headlineAction={'Re-select'}
          />
        )}
      </>
    );
  }

  renderTotalRow() {
    const { variable, lock } = this.props;
    const canEdit = this.canEdit && !lock;
    const cols = canEdit ? [...variable.columns, ''] : variable.columns; //canEdit && add an empty pseudo column to allow space for 3 dots from other columns
    const styleHeader = variable.deal.style.type.TableHeader.css;

    return (
      <div className={cx('table-row-wrapper total-row')} key="total-row" data-row="total-row" data-cy="total-row">
        {cols.map((col, colIndex) => {
          let colTotal;
          let error = null;

          try {
            colTotal = col.total;
          } catch (e) {
            error = e;
          }

          if (colIndex === 0 && !col.totalColumn) {
            return (
              <div style={styleHeader} key={colIndex}>
                {variable.totalRowLabel || ''}
              </div>
            );
          }

          return (
            <div style={styleHeader} key={colIndex} className={cx({ error })}>
              {col.totalColumn ? (
                error ? (
                  <TooltipButton tip={error.message}>
                    <span>{error.errorValue}</span>
                  </TooltipButton>
                ) : (
                  format(colTotal, col.valueType, null, col.decimals)
                )
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
    );
  }

  renderRow(row, rowIndex) {
    const { section, variable, lock } = this.props;
    const { changedCols, editingRow, loading, pendingSave, value, dirtyRows, syncError, syncSuccess } = this.state;
    const cols = variable.columns;
    const canEdit = this.canEdit && !lock;
    const styleBody = variable.deal.style.type.TableBody.css;
    const dirtyRow = _.find(dirtyRows, { _itemId: row._itemId });
    const service = _.get(variable, 'service.name', null);

    if (editingRow === rowIndex) {
      const newRow = !Object.hasOwn(row, '_itemId');

      return (
        <div className="table-row-wrapper" key={rowIndex} data-row={rowIndex} data-cy="table-row-editing">
          {cols.map((col, colIndex) => {
            let colValue, cellDirty, cellEdited, error, willNotSync, editedMsg;

            try {
              if (col.calculated) {
                colValue = col.calculate({ row });
              } else {
                colValue = row[col.id];
              }

              cellDirty = !!_.get(dirtyRow, col.id);
              cellEdited = changedCols[`${rowIndex}|${col.id}`];
              editedMsg =
                cellDirty && col.calculated
                  ? `Calculated value, can be synced to ${service}`
                  : cellDirty
                  ? `Value edited, can be synced to ${service}`
                  : false;
              willNotSync = variable.isConnected
                ? newRow
                  ? `Data in added rows cannot be synced to ${service}`
                  : cellEdited && !cellDirty
                  ? `Edited locally but cannot be synced to ${service}`
                  : false
                : false;
            } catch (e) {
              error = e;
              colValue = e.errorValue;
            }

            return (
              <div className="table-col editing" key={colIndex}>
                <div
                  className={cx('input-wrapper', {
                    'is-dirty': cellDirty,
                    'no-sync': !!willNotSync,
                    error,
                  })}
                >
                  <FormControl
                    key={colIndex + 1}
                    type="text"
                    bsSize="small"
                    inputRef={(ref) => (colIndex === 0 ? (this.firstRowInputRef = ref) : null)}
                    value={col.valueType === ValueType.DATE && !newRow ? col.formatValue(colValue) : colValue}
                    placeholder={col.displayName}
                    onChange={(e) => this.handleChange(rowIndex, col, e)}
                    onBlur={() => col.valueType === ValueType.DATE && this.save()}
                    onKeyDown={(e) => this.handleKeyCommand(e)}
                    data-cy="table-row-input"
                    disabled={col.calculated || loading}
                    className={cx({
                      'is-dirty': cellDirty,
                      error,
                    })}
                  />

                  {editedMsg && !loading && (
                    <div className="icons">
                      <TooltipButton tip={editedMsg}>
                        <div>
                          <Icon name="syncEnabled" />
                        </div>
                      </TooltipButton>
                    </div>
                  )}
                  {!!willNotSync && !loading && !pendingSave && (
                    <div className="icons">
                      <TooltipButton tip={willNotSync}>
                        <div>
                          <Icon name="syncDisabled" />
                        </div>
                      </TooltipButton>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div className="table-col editing">&nbsp;</div>
        </div>
      );
    } else {
      const willNotSync = service && !Object.hasOwn(row, '_itemId');

      return (
        <div
          className={cx('table-row-wrapper', { editable: canEdit, 'sync-error': syncError })}
          key={rowIndex}
          data-row={rowIndex}
          data-cy="table-row-wrapper"
        >
          {cols.map((col, colIndex) => {
            let cellDirty;
            let formattedValue;
            let error;
            let cellEdited;
            let localCell;

            try {
              localCell = col.calculated ? col.calculate({ row }) : row[col.id];
              cellDirty = !!_.get(dirtyRow, col.id);
              cellEdited = changedCols[`${rowIndex}|${col.id}`];
              formattedValue = col.formatValue(localCell, row);
            } catch (e) {
              error = e;
            }

            return (
              <div
                style={styleBody}
                key={colIndex}
                className={cx({
                  'is-dirty': cellDirty && !loading,
                  'is-connected': !!variable.connectType,
                  'is-calculated': col.calculated,
                  'is-edited': cellEdited && syncSuccess,
                  error,
                })}
              >
                {error && (
                  <TooltipButton tip={error.message}>
                    <span>{error.errorValue}</span>
                  </TooltipButton>
                )}
                {!error && <span>{!!formattedValue.trim() ? formattedValue : <>&nbsp;</>}</span>}

                {cellDirty ||
                  (willNotSync && (
                    <div className="icons">
                      {cellDirty && (
                        <TooltipButton tip={`Value edited, can be synced to ${service}`}>
                          <div>
                            <Icon name="syncEnabled" />
                          </div>
                        </TooltipButton>
                      )}
                      {willNotSync && (
                        <TooltipButton tip={`Data in added rows cannot be synced to ${service}`}>
                          <div>
                            <Icon name="syncDisabled" />
                          </div>
                        </TooltipButton>
                      )}
                    </div>
                  ))}
              </div>
            );
          })}
          {canEdit && (
            <div>
              <DropdownDots
                className="table-actions"
                id={`dd-table-${section.id}-${rowIndex}`}
                onClick={(e) => e.stopPropagation()}
                onSelect={(action) => this.handleRowAction(action, rowIndex)}
                pullRight
                data-cy="table-actions"
              >
                <MenuItem key="edit" eventKey="edit" data-cy="row-edit">
                  Edit row
                </MenuItem>
                <MenuItem key="delete" eventKey="delete" data-cy="row-delete">
                  Delete row
                </MenuItem>
                <MenuItem key="d1" divider />
                <MenuItem key="before" eventKey="before" data-cy="row-before">
                  Add row before
                </MenuItem>
                <MenuItem key="after" eventKey="after" data-cy="row-after">
                  Add row after
                </MenuItem>
                <MenuItem key="d2" divider />
                <MenuItem key="up" disabled={rowIndex === 0} eventKey="up" data-cy="row-up">
                  Move row up
                </MenuItem>
                <MenuItem key="down" disabled={rowIndex === value.length - 1} eventKey="down" data-cy="row-down">
                  Move row down
                </MenuItem>
                <MenuItem key="d3" divider />
                <MenuItem key="clear" eventKey="clear" data-cy="row-clear">
                  Delete all rows
                </MenuItem>
                {service && (
                  <MenuItem key="rowSync" eventKey="rowSync" data-cy="row-sync" disabled={willNotSync}>
                    Sync this row to {service}
                  </MenuItem>
                )}
                {service && (
                  <MenuItem key="pull" eventKey="pull" data-cy="row-clear">
                    Re-select {variable.displayName} data
                  </MenuItem>
                )}
              </DropdownDots>
            </div>
          )}
        </div>
      );
    }
  }
}

export default TableView;
