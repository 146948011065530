import axios from 'axios';
import CryptoJS from 'crypto-js';
import _ from 'lodash';

import Fire from '@root/Fire';

import APIError from './server/ApiError';

class APIClient {
  path = '/api';

  call(method, args = {}, success, failure) {
    //API is only open to authenticated users (via Firebase)
    //so we wrap all API calls to insert token so that server can verify request is from a valid user
    return new Promise((resolve, reject) => {
      Fire.token(
        (token) => {
          args.token = token;
          axios
            .post(this.path + '/' + method, args)
            .then((response) => {
              // console.log('SUCCESS', response.data);
              if (typeof success == 'function') success(response.data);
              resolve(response.data);
            })
            .catch((error) => {
              const err = _.assign({}, error);
              const data = err && err.response ? err.response.data : null;
              const status = err && err.response ? err.response.status : null;

              if (typeof failure == 'function') failure(data, status);
              //if we recieve a custom APIError return a new instance of that error.
              else if (data?.error) reject(new APIError(err.response.status, data.error));
              else reject(error);
            });
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }

  callAnon(method, args, success, failure) {
    //some calls are anonymously accessible (getPublicDeal); skip auth token piece
    return new Promise((resolve, reject) => {
      axios
        .post(this.path + '/' + method, args)
        .then((response) => {
          if (typeof success == 'function') success(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          const err = _.assign({}, error);
          const data = err && err.response ? err.response.data : null;
          if (typeof failure == 'function') failure(data);
          else console.error(err);
          reject(error);
        });
    });
  }

  // Sign Outlaw API request using Stripe's webhook signing/verification format as a best practice
  // See https://stripe.com/docs/webhooks/signatures for details
  signRequest(args, secret) {
    const headers = {
      'Content-Type': 'application/json',
    };

    const time = new Date().getTime().toString();
    const hash = CryptoJS.HmacSHA256(`${time}.${JSON.stringify(args)}`, secret);
    const b64 = CryptoJS.enc.Base64.stringify(hash);

    headers['outlaw-sig'] = `t=${time},v1=${b64}`;

    return headers;
  }
}

const API = new APIClient();
if (typeof window == 'object') window.API = API;
export default API;
