
const OUTLAW_SERVICE = {
  key: 'outlaw',
  name: 'Outlaw',
  triggers: {
    contractCreated: { key: 'contractCreated', name: 'Contract created' },
    contractCompleted: { key: 'contractCompleted', name: 'Contract completed' },
    // Not implemented yet
    // contractUpdated: {key: 'contractUpdated', name: 'Contract updated', disabled: true},
  },
  actions: {
    createContract: { key: 'createContract', name: 'Create Contract' },
  },
  enableUI: false,
};

const SALESFORCE_SERVICE = {
  key: 'salesforce',
  name: 'Salesforce',
  triggers: {
    outlawCreateContract: {
      key: 'outlawCreateContract',
      name: 'Outlaw App Create Contract',
    },
    // Not implemented yet
    // opportunityStageChange: {key: 'opportunityStageChange', name: 'Opportunity Stage Change', disabled: true},
  },
  actions: {
    updateObject: { key: 'updateObject', name: 'Update Object' },
    uploadAttachmentDOCX: {
      key: 'uploadAttachmentDOCX',
      name: 'Upload Attachment (DOCX)',
    },
    uploadAttachmentPDF: {
      key: 'uploadAttachmentPDF',
      name: 'Upload Attachment (PDF)',
    },
  },
  enableUI: true,
  connectType: { key: 'salesforce', name: 'Salesforce', description: '' },
  objectTypes: [{ key: 'Opportunity', name: 'Opportunity', description: '' }],
  icon: 'logoSalesforce',
  iconClassName: 'blue-icon',
  idFields: [{ key: 'opportunityId', label: 'ID', name: '', required: true }],
};

const HUBSPOT_SERVICE = {
  key: 'hubspot',
  name: 'Hubspot',
  triggers: {
    // none for now...
  },
  actions: {
    uploadContractPDF: {
      key: 'uploadContractPDF',
      name: 'Upload Contract PDF',
    },
  },
  enableUI: false,
};

const FILEVINE_SERVICE = {
  key: 'filevine',
  name: 'Filevine',
  triggers: {},
  actions: {
    addNote: { key: 'addNote', name: 'Create Note' },
  },
  enableUI: true,
  connectType: { key: 'filevine', name: 'Filevine', description: '' },
  objectTypes: [{ key: 'project', name: 'Project', description: '' }],
  icon: 'logoFilevine',
  idFields: [
    { key: 'projectId', label: 'Project ID', name: '', required: true },
    { key: 'sectionId', label: 'Section ID', name: '', required: false },
  ],
};

const CLADOCUMENT_SERVICE = {
  key: 'cladocument',
  name: 'CLA Document',
  triggers: {},
  actions: {
    uploadPDF: { key: 'uploadPDF', name: 'UploadPDF' },
  },
  enableUI: false,
  instance: 'cla',
};

const OFFICERND_SERVICE = {
  key: 'officernd',
  name: 'OfficeR&D',
  enableUI: false,
};

const SERVICES = [
  OUTLAW_SERVICE,
  SALESFORCE_SERVICE,
  OFFICERND_SERVICE,
  HUBSPOT_SERVICE,
  CLADOCUMENT_SERVICE,
  FILEVINE_SERVICE,
];

export {
  OUTLAW_SERVICE,
  SALESFORCE_SERVICE,
  OFFICERND_SERVICE,
  HUBSPOT_SERVICE,
  FILEVINE_SERVICE,
  CLADOCUMENT_SERVICE,
};

export default SERVICES;
