/* eslint-disable react/display-name */
import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import DealRole from '@core/enums/DealRole';
import InviteStatus from '@core/enums/InviteStatus';
import { ATTACHMENT_TYPE } from '@core/models/Attachment';
import Column from '@core/models/Column';
import { DEAL_TYPE } from '@core/models/Deal';
import DealMetaCSV from '@core/models/DealMetaCSV';
import DealRecord from '@core/models/DealRecord';
import { FEATURES as TEAM_FEATURES } from '@core/models/Team';
import { ValueType } from '@core/models/Variable';
import { DateFormatter } from '@core/utils';

import { Ellipsis } from '@components/dmp';
import { Loader } from '@components/dmp';
import Icon from '@components/dmp/Icon';
import StatusLabel from '@components/dmp/StatusLabel';

import BundleIcon from '@components/deal/BundleIcon';
import OriginIcon from '@components/deal/OriginIcon';
import TooltipButton from '@components/editor/TooltipButton';
import CONFIG from '@root/Config';
import IDCopy from '@root/routes/admin/IDCopy';

import AIStatusIcon from './AIStatusIcon';

const { ASSETS_URL } = CONFIG;

const cellPropTypes = {
  tdProps: PropTypes.any,
  value: PropTypes.any,
  original: PropTypes.oneOfType([
    PropTypes.instanceOf(DealRecord),
    PropTypes.instanceOf(DealMetaCSV),
    PropTypes.object,
  ]),
};

export const getPngIcon = (dealType) => {
  const iconStyle = {
    display: 'inline-block',
    height: '16px',
    width: '16px',
    maxHeight: '16px',
    maxWidth: '16px',
    marginTop: '2px',
  };
  switch (dealType) {
    case DEAL_TYPE.INGESTED:
    case DEAL_TYPE.EXTERNAL_WORD:
      return <img src={`${ASSETS_URL}/assets/png/docx.png`} height="16" width="16" style={iconStyle} alt="docx" />;
    case DEAL_TYPE.EXTERNAL:
      return <img src={`${ASSETS_URL}/assets/png/pdf.png`} height="16" width="16" style={iconStyle} alt="pdf" />;
    case DEAL_TYPE.BESPOKE:
      return <img src={`${ASSETS_URL}/assets/png/draft.png`} height="16" width="16" style={iconStyle} alt="draft" />;
    case DEAL_TYPE.NATIVE:
    default:
      return <img src={`${ASSETS_URL}/assets/png/native.png`} height="16" width="16" style={iconStyle} alt="native" />;
  }
};

// Lookup typed version of a Column based on key (url param)
const findColumn = (key, dealTemplate, simpleType = null) => {
  if (simpleType && SIMPLE_TYPES[simpleType]) {
    const defaultSimpleColumn = _.find(SIMPLE_TYPES[simpleType], { key });
    if (defaultSimpleColumn) return new Column(defaultSimpleColumn);
  }

  const defaultColumn = _.find(DEFAULT_COLUMNS, { key });
  if (defaultColumn) return new Column(defaultColumn);

  const vitalColumn = _.find(VITALS_COLUMNS, { key });
  if (vitalColumn) return new Column(vitalColumn);

  const userColumn = _.find(USER_MANAGEMENT_COLUMNS, { key });
  if (userColumn) return new Column(userColumn);

  const teamColumn = _.find(TEAM_MANAGEMENT_COLUMNS, { key });
  if (teamColumn) return new Column(teamColumn);

  let [prefix, varName] = key.split('.');

  if (prefix === 'v' && !!varName) {
    const variable = _.get(dealTemplate, `variables[${varName}]`);
    if (variable) {
      return new Column(
        _.merge(
          {},
          COLUMN_VARIABLE_DEFAULTS, // Default is required for Text vars (valueType: undefined)
          VARIABLE_COLUMNS[variable.valueType],
          {
            key: `v.${varName}`,
            Header: variable.displayName || variable.name,
          }
        )
      );
    }
  }
  return null;
};

const DefaultEllipsisCell = ({ value }) => <Ellipsis>{value}</Ellipsis>;
DefaultEllipsisCell.propTypes = cellPropTypes;

const Empty = () => <span className="empty">--</span>;
Empty.propTypes = cellPropTypes;

// Edge case in CSV import where status columns are missing
const StatusCell = ({ value, original: dealRecord }) => {
  if (!value) value = 'Missing';

  return <StatusLabel status={value} color={dealRecord.statusColor || undefined} />;
};
StatusCell.propTypes = cellPropTypes;

// Edge case in CSV import where status columns are missing
const GradeCell = ({ value, original: dealRecord }) => {
  if (!value) value = 'N/A';

  return <div className="grade-cell">{value}</div>;
};
GradeCell.propTypes = cellPropTypes;

const EmailStatusCell = ({ value, original: dealRecord }) => {
  if (!value) value = 'Missing';

  const id = value.toLowerCase();
  const color = dealRecord.statusColor ? dealRecord.statusColor : '#C3C3C3';

  // return <span className={`statusLabel ${value.toLowerCase()}`}>{value}</span>;
  return (
    <table className={`tag ${id}`} bgcolor={color}>
      <tr>
        <td valign="middle" align="center" bgcolor={color}>
          <span>
            <font size={1}>{value}</font>
          </span>
        </td>
      </tr>
    </table>
  );
};
EmailStatusCell.propTypes = cellPropTypes;

const TitleCell = ({ value, original: dealRecord, tdProps }) => {
  return (
    <div>
      <div className="deal-name">
        {dealRecord.documentAI && <AIStatusIcon dealID={dealRecord.dealID}></AIStatusIcon>}
        <span className="text">{value}</span>
        {dealRecord.parentDealID && <BundleIcon dealRecord={dealRecord} history={tdProps.rest.history}></BundleIcon>}
        {dealRecord.userTags.length > 0 && (
          <div className="deal-tags">
            {dealRecord.userTags.map((tag, i) => (
              <span data-cy="deal-tag" key={i}>
                {tag.tag}
              </span>
            ))}
          </div>
        )}
      </div>
      {dealRecord.public && <span className="statusLabel public">Public</span>}
    </div>
  );
};
TitleCell.propTypes = cellPropTypes;

const OriginCell = ({ original: dealRecord, tdProps }) => {
  const hasAccess = !_.get(tdProps, 'rest.isLocked');
  return <OriginIcon dealRecord={dealRecord} showOrigin={hasAccess} />;
};
OriginCell.propTypes = cellPropTypes;

const PartyCell = ({ value }) => {
  if (!value || !Object.keys(value).length) return <Empty />;

  return (
    <div>
      {_.map(value, (users, party) => (
        <div className="party-item" key={party}>
          {_.map(users, (user, i) => (
            <div
              className={`party-item-inner ${user.inviteStatus === InviteStatus.FAILED ? 'text-danger' : ''}`}
              key={`user-${i}`}
            >
              {user.org && <div>{user.org}</div>}
              <div>{user.fullName || user.email}</div>
              {user.inviteStatus === InviteStatus.FAILED && (
                <>
                  <div>Invitation failed</div>
                  <div>{user.inviteStatusMessage}</div>
                </>
              )}
            </div>
          ))}
          <span className="user-or-party-subtitle">{party}</span>
        </div>
      ))}
    </div>
  );
};
PartyCell.propTypes = cellPropTypes;

const InlinePartyCell = ({ value }) => {
  if (!value || !Object.keys(value).length) return <Empty />;

  const parties = _.map(value, (users) => {
    return _.map(users, (user) => user.org || user.fullName || user.email).join(' - ');
  });

  return (
    <div className="party-inline">
      <Ellipsis>{parties.join(', ')}</Ellipsis>
    </div>
  );
};
InlinePartyCell.propTypes = cellPropTypes;

const OwnersCell = ({ value: dealUsers }) => {
  const owners = _.filter(dealUsers, { role: DealRole.OWNER });

  if (owners.length) {
    return (
      <div>
        {_.map(owners, (owner, idx) => (
          <React.Fragment key={idx}>
            <Ellipsis key={idx}>{owner.fullName || owner.email}</Ellipsis>
            {owner.inviteStatus === InviteStatus.FAILED && <div className="text-danger">Invitation failed</div>}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return <Empty />;
};
OwnersCell.propTypes = cellPropTypes;

const InlineOwnersCell = ({ value: dealUsers }) => {
  const owners = _.filter(dealUsers, { role: DealRole.OWNER });

  if (owners.length) {
    return <Ellipsis>{_.map(owners, (owner) => owner.fullName || owner.email).join(', ')}</Ellipsis>;
  }

  return <Empty />;
};
InlineOwnersCell.propTypes = cellPropTypes;

const AttachmentsCell = ({ original: dealRecord }) => {
  // Only display user facing attachments
  const attachments = _.filter(
    dealRecord.attachments,
    ({ attachmentType }) => !attachmentType || attachmentType === ATTACHMENT_TYPE.STORAGE
  );

  if (attachments.length === 0) {
    return <Empty />;
  }
  const fileNames = attachments.map((attachment) => attachment.filename);
  const tipNames =
    fileNames.length === 1
      ? fileNames[0]
      : fileNames.length === 2
      ? fileNames[0] + ', ' + fileNames[1]
      : fileNames[0] + ', ' + fileNames[1] + ', +' + `${fileNames.length - 2} more`;
  return (
    <TooltipButton tip={tipNames}>
      <span data-cy="attachments">{attachments.length}</span>
    </TooltipButton>
  );
};
AttachmentsCell.propTypes = cellPropTypes;

const COLUMN_VARIABLE_DEFAULTS = {
  minWidth: 150,
  maxWidth: 300,
  isVariable: true,
};

const VARIABLE_COLUMNS = {
  [ValueType.CURRENCY]: { ...COLUMN_VARIABLE_DEFAULTS },
  [ValueType.DATE]: { ...COLUMN_VARIABLE_DEFAULTS },
  [ValueType.IMAGE]: { ...COLUMN_VARIABLE_DEFAULTS },
  [ValueType.LIST]: { ...COLUMN_VARIABLE_DEFAULTS },
  [ValueType.NUMBER]: { ...COLUMN_VARIABLE_DEFAULTS },
  [ValueType.PERCENT]: { ...COLUMN_VARIABLE_DEFAULTS },
  [ValueType.STATE]: { ...COLUMN_VARIABLE_DEFAULTS },
  [ValueType.STRING]: { ...COLUMN_VARIABLE_DEFAULTS },
  [ValueType.TABLE]: { ...COLUMN_VARIABLE_DEFAULTS },
};

const STATUS_COLUMN = {
  key: 'status',
  Header: 'Status',
  accessor: 'status',
  Cell: StatusCell,
  minWidth: 110, //Label width + cell padding
  maxWidth: 110, //Label width + cell padding
  className: 'status',
  sortable: false,
};

const GRADE_COLUMN = {
  key: 'grade',
  Header: 'Risk Score',
  accessor: 'grade',
  Cell: GradeCell,
  minWidth: 110, //Label width + cell padding
  maxWidth: 110, //Label width + cell padding
  className: 'grade',
  sortable: false,
};

const TITLE_COLUMN = {
  key: 'name',
  Header: 'Title',
  accessor: 'name',
  Cell: TitleCell,
  minWidth: 200,
  className: 'title',
};

const SIMPLE_TITLE_COLUMN = {
  key: 'name',
  Header: 'Title',
  accessor: 'name',
  Cell: DefaultEllipsisCell,
  minWidth: 200,
  className: 'title',
};

const ORIGIN_COLUMN = {
  key: 'origin',
  Header: 'Origin',
  accessor: 'dealID',
  Cell: OriginCell,
  minWidth: 60,
  maxWidth: 100,
  className: 'origin',
  sortable: false,
};

const ATTACHMENTS_COLUMN = {
  key: 'attachments',
  Header: 'Attachments',
  accessor: 'dealID',
  Cell: AttachmentsCell,
  minWidth: 100,
  maxWidth: 100,
  className: 'attachments',
  sortable: false,
};

const EMAIL_ORIGIN_COLUMN = {
  key: 'origin',
  Header: 'Origin',
  id: 'dealID',
  accessor: (dealRecord) => {
    const icon = getPngIcon(dealRecord.dealType);
    return icon;
  },
  className: 'origin',
};

const PARTIES_COLUMN = {
  key: 'parties',
  Header: 'Parties',
  accessor: 'usersByParty',
  Cell: PartyCell,
  minWidth: 125,
  maxWidth: 300,
  className: 'parties',
  sortable: false,
};

const SIMPLE_PARTIES_COLUMN = {
  key: 'parties',
  Header: 'Parties',
  accessor: 'usersByParty',
  Cell: InlinePartyCell,
  minWidth: 125,
  maxWidth: 300,
  className: 'parties',
  sortable: false,
};

const UPDATED_COLUMN = {
  key: 'updated',
  Header: 'Last updated',
  id: 'updated',
  accessor: (dealRecord) => <Ellipsis>{_.get(dealRecord, 'displayUpdatedShort', '--')}</Ellipsis>,
  minWidth: 100,
  maxWidth: 120,
  className: 'modified',
};

const SIMPLE_UPDATED_COLUMN = {
  key: 'updated',
  Header: 'Last modified',
  id: 'updated',
  accessor: (dealRecord) => {
    return <Ellipsis>{_.get(dealRecord, 'displayUpdatedShort', '--')}</Ellipsis>;
  },
  className: 'modified',
};

const EMAIL_STATUS_COLUMN = {
  key: 'status',
  Header: 'Status',
  id: 'status',
  Cell: EmailStatusCell,
  accessor: (dealRecord) => {
    return _.get(dealRecord, 'status', '--');
  },
  className: 'status',
};

const OWNERS_COLUMN = {
  key: 'owners',
  Header: 'Owner(s)',
  accessor: 'users',
  Cell: OwnersCell,
  minWidth: 125,
  maxWidth: 300,
  className: 'owner',
  sortable: false,
};

const SIMPLE_OWNERS_COLUMN = {
  key: 'owners',
  Header: 'Owner(s)',
  accessor: 'users',
  Cell: InlineOwnersCell,
  minWidth: 125,
  maxWidth: 300,
  className: 'owner',
  sortable: false,
};

const USER_ID_COLUMN = {
  key: 'id',
  Header: 'ID',
  accessor: 'id',
  Cell: ({ value }) => <IDCopy value={value} name={'User'} />,
  minWidth: 125,
  maxWidth: 300,
  className: 'id',
  sortable: false,
};

const USER_NAME_COLUMN = {
  key: 'fullName',
  Header: 'Name',
  accessor: 'fullName',
  minWidth: 150,
  maxWidth: 250,
  className: 'fullName',
  sortable: true,
};

const USER_EMAIL_COLUMN = {
  key: 'email',
  Header: 'Email',
  accessor: 'email',
  minWidth: 200,
  maxWidth: 300,
  className: 'email',
  sortable: true,
};

const USER_CONNECT_COLUMN = {
  key: 'connect',
  Header: 'Connect',
  accessor: 'features',
  Cell: ({ original }) => {
    return original.features.connect ? <Icon name="check" size="large"></Icon> : '';
  },
  minWidth: 75,
  maxWidth: 75,
  className: 'connect',
  sortable: false,
};

const USER_BATCH_COLUMN = {
  key: 'batch',
  Header: 'Batch',
  accessor: 'features',
  Cell: ({ original }) => {
    return original.features.batch ? <Icon name="check" size="large"></Icon> : '';
  },
  minWidth: 75,
  maxWidth: 75,
  className: 'batch',
  sortable: false,
};

const USER_VINE_IO_COLUMN = {
  key: 'vineIO',
  Header: 'Vine IO',
  accessor: 'features',
  Cell: ({ original }) => {
    return original.features.vineIO ? <Icon name="check" size="large"></Icon> : '';
  },
  minWidth: 75,
  maxWidth: 75,
  className: 'vineIO',
  sortable: false,
};

const USER_WORD_COLUMN = {
  key: 'word',
  Header: 'Word',
  accessor: 'features',
  Cell: ({ original }) => {
    return original.features.word ? <Icon name="check" size="large"></Icon> : '';
  },
  minWidth: 75,
  maxWidth: 75,
  className: 'word',
  sortable: false,
};

const USER_TEAMS_COLUMN = {
  key: 'teamsCount',
  Header: 'Teams',
  id: 'teamsCount',
  accessor: 'teamsCount',
  Cell: ({ original }) => {
    const teams = _.map(original.teams, 'teamID');
    const list = _.join(teams, ', ');
    const cell =
      original.teamsCount > 0 ? (
        <TooltipButton tip={list}>
          <span className="teamsCountTip">{original.teamsCount}</span>
        </TooltipButton>
      ) : (
        ''
      );
    return cell;
  },
  minWidth: 60,
  maxWidth: 80,
  className: 'teamsCount',
  sortable: true,
};

const USER_ROLE_COLUMN = {
  key: 'role',
  Header: 'Role',
  accessor: 'role',
  Cell: ({ original, tdProps }) => {
    //This cell will determine the role to render based off team filters from adminSearchParams
    //it will return various, the users role on a specific team, or the users role accross all teams if its the same.
    const teamFilters = _.get(tdProps, 'rest.teams');
    const { teams, roles } = original;
    let role = '';

    if (teams.length === 0) {
      return role;
    }
    if (teamFilters.length === 1) {
      const teamRole = _.find(teams, ({ teamID }) => {
        return teamID === teamFilters[0];
      });
      role = _.get(teamRole, 'role', '');
    } else {
      if (roles.length > 1) {
        role = 'various';
      } else if (roles.length === 1) {
        role = roles[0];
      }
    }

    return _.capitalize(role);
  },
  minWidth: 75,
  maxWidth: 75,
  className: 'role',
  sortable: false,
};

const USER_ACCESS_COLUMN = {
  key: 'access',
  Header: 'Access',
  accessor: 'access',
  Cell: ({ original }) => {
    const { isBilling, isAdmin, isPartner } = original;

    return (
      <div className="d-flex">
        {isAdmin && <span className="tag admin admin-page-tag">Admin</span>}
        {isBilling && <span className="tag billing admin-page-tag">Billing</span>}
        {isPartner && <span className="tag partner admin-page-tag">Partner</span>}
      </div>
    );
  },
  width: 80,
  className: 'access',
  sortable: false,
};

const USER_OBSERVER_COLUMN = {
  key: 'observer',
  Header: 'Observer',
  accessor: 'observer',
  Cell: ({ original, tdProps }) => {
    //This cell will determine the observer value to render based off team filters from adminSearchParams
    //it will return various, the users observer status on a specific team, or the users observer status accross all teams if its the same.
    const { teams } = original;
    const teamFilters = _.get(tdProps, 'rest.teams');
    let isObserver = false;

    if (teamFilters.length === 1) {
      const teamRole = _.find(teams, ({ teamID }) => {
        return teamID === teamFilters[0];
      });
      isObserver = _.get(teamRole, 'observer', false);
    } else {
      const teamObserver = _.uniqBy(teams, 'observer');
      if (teamObserver.length > 1) {
        isObserver = 'various';
      } else if (teamObserver.length === 1) {
        isObserver = _.get(teamObserver[0], 'observer', false);
      }
    }

    return _.capitalize(isObserver.toString());
  },
  minWidth: 80,
  maxWidth: 100,
  className: 'observer',
  sortable: false,
};

const TEAM_ID_COLUMN = {
  key: 'teamID',
  Header: 'Team ID',
  accessor: 'teamID',
  Cell: ({ value }) => <IDCopy value={value} name={'Team'} />,
  minWidth: 150,
  maxWidth: 350,
  className: 'teamID',
  sortable: false,
};

const TEAM_SALESFORCE_ID_COLUMN = {
  key: 'salesforceID',
  Header: 'Salesforce ID',
  accessor: 'salesforceID',
  Cell: ({ value }) => <IDCopy value={value} name={'Salesforce ID'} />,
  minWidth: 150,
  maxWidth: 350,
  className: 'salesforceID',
  sortable: false,
};

const TEAM_NAME_COLUMN = {
  key: 'name',
  Header: 'Name',
  accessor: 'name',
  Cell: ({ tdProps, value }) => {
    const { rest } = tdProps;

    if (rest.isLocked) {
      return <span className="disabled">{value}</span>;
    }

    return (
      <div>
        <TooltipButton tip="Manage team">
          <span className="enabled" onClick={rest.onClick}>
            {value}
          </span>
        </TooltipButton>
      </div>
    );
  },
  minWidth: 125,
  maxWidth: 350,
  className: 'name',
  sortable: true,
  clickable: true,
};

const TEAM_USER_COUNT_COLUMN = {
  key: 'membersCount',
  Header: 'Members',
  accessor: 'membersCount',
  minWidth: 80,
  maxWidth: 80,
  className: 'membersCount',
  sortable: true,
};

const TEAM_OBSERVER_COUNT_COLUMN = {
  key: 'observerCount',
  Header: 'Observers',
  id: 'observerCount',
  accessor: 'observerCount',
  minWidth: 90,
  maxWidth: 90,
  className: 'observerCount',
  sortable: true,
};

const TEAM_TEMLATE_COUNT_COLUMN = {
  key: 'templates',
  Header: 'Templates',
  accessor: 'templates.length',
  minWidth: 80,
  maxWidth: 80,
  className: 'templates',
  sortable: false,
};

const TEAM_ACTIVE_TEMLATE_COUNT_COLUMN = {
  key: 'activeTemplates',
  Header: 'Active Templates',
  accessor: 'activeTemplates',
  minWidth: 80,
  maxWidth: 80,
  className: 'activeTemplates',
  sortable: false,
};

const TEAM_API_KEY_COLUMN = {
  key: 'apiKey',
  Header: 'API Key',
  accessor: 'apiKey',
  Cell: ({ value }) => {
    return (
      <div>
        {!_.isEmpty(value) && (
          <TooltipButton
            tip={`Created by: ${_.get(value, 'fullName', value.createdBy)} on ${DateFormatter.fromMilliseconds(
              value.dateCreated
            )}`}
          >
            <span className="icon">
              <Icon name="check" size="large" />
            </span>
          </TooltipButton>
        )}
      </div>
    );
  },
  minWidth: 80,
  maxWidth: 80,
  className: 'apiKey',
  sortable: false,
};

const TEAM_THEME_COUNT_COLUMN = {
  key: 'themesCount',
  Header: 'Themes',
  accessor: 'themesCount',
  Cell: ({ original, value }) => {
    const themes = _.map(original.themes, 'name');
    const list = _.join(themes, ', ');
    const cell =
      value > 0 ? (
        <TooltipButton tip={list}>
          <span>{value}</span>
        </TooltipButton>
      ) : (
        ''
      );
    return cell;
  },
  minWidth: 80,
  maxWidth: 80,
  className: 'themesCount',
  sortable: false,
};

const editableTeamFeatures = _.filter(TEAM_FEATURES, { isAdminEditable: true });
const TEAM_FEATURES_COLUMNS = _.map(editableTeamFeatures, (feature) => ({
  id: feature.key,
  key: feature.key,
  Header: feature.header || feature.key,
  accessor: 'features',
  Cell: ({ original }) => {
    return original.features?.[feature.key] ? <Icon name="check" size="large"></Icon> : '';
  },
  minWidth: 100,
  maxWidth: 150,
  className: feature.key,
  sortable: false,
}));

const DEFAULT_COLUMNS = [
  ORIGIN_COLUMN,
  OWNERS_COLUMN,
  PARTIES_COLUMN,
  STATUS_COLUMN,
  TITLE_COLUMN,
  UPDATED_COLUMN,
  ATTACHMENTS_COLUMN,
];

const VITALS_COLUMNS = [GRADE_COLUMN];

/*
  Simpler version (technically inline) of Columns,
  not all requires a simple version.
*/
const DEFAULT_SIMPLE_COLUMNS = [SIMPLE_OWNERS_COLUMN, SIMPLE_PARTIES_COLUMN, SIMPLE_TITLE_COLUMN];

/*
  Simpler version (technically inline) of Columns,
  not all requires a simple version.
*/
const EMAIL_SIMPLE_COLUMNS = [
  EMAIL_ORIGIN_COLUMN,
  SIMPLE_OWNERS_COLUMN,
  SIMPLE_PARTIES_COLUMN,
  SIMPLE_TITLE_COLUMN,
  SIMPLE_UPDATED_COLUMN,
  EMAIL_STATUS_COLUMN,
];

const SIMPLE_TYPES = {
  report: DEFAULT_SIMPLE_COLUMNS,
  email: EMAIL_SIMPLE_COLUMNS,
};

const USER_MANAGEMENT_COLUMNS = [
  USER_NAME_COLUMN,
  USER_EMAIL_COLUMN,
  USER_ID_COLUMN,
  USER_ACCESS_COLUMN,
  USER_TEAMS_COLUMN,
  USER_ROLE_COLUMN,
  USER_OBSERVER_COLUMN,
  USER_BATCH_COLUMN,
  USER_CONNECT_COLUMN,
  USER_VINE_IO_COLUMN,
  USER_WORD_COLUMN,
];

const TEAM_MANAGEMENT_COLUMNS = [
  TEAM_NAME_COLUMN,
  TEAM_ID_COLUMN,
  TEAM_SALESFORCE_ID_COLUMN,
  TEAM_USER_COUNT_COLUMN,
  TEAM_OBSERVER_COUNT_COLUMN,
  TEAM_API_KEY_COLUMN,
  TEAM_THEME_COUNT_COLUMN,
  ...TEAM_FEATURES_COLUMNS,
];

export {
  DEFAULT_COLUMNS,
  VARIABLE_COLUMNS,
  SIMPLE_TITLE_COLUMN,
  SIMPLE_PARTIES_COLUMN,
  SIMPLE_OWNERS_COLUMN,
  STATUS_COLUMN,
  USER_MANAGEMENT_COLUMNS,
  TEAM_MANAGEMENT_COLUMNS,
  VITALS_COLUMNS,
  findColumn,
};
