import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { CompactPicker, GithubPicker } from 'react-color';

import { Dropdown, MenuItem } from '@components/dmp';

@autoBindMethods
export default class ColorPicker extends Component {
  static propTypes = {
    color: PropTypes.string.isRequired,
    colors: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    editableInput: PropTypes.bool,
    size: PropTypes.oneOf(['default', 'small', 'large']),
    style: PropTypes.oneOf(['compact', 'github']),
  };

  static defaultProps = {
    style: 'compact',
    editableInput: true,
    size: 'default',
    colors: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      picking: false,
    };
    this.dd = createRef();
  }

  onChangeComplete(color) {
    const { editableInput, onChange } = this.props;
    onChange(color.hex);
    this.setState({ picking: false });

    // We're kinda hacking the Dropdown component to nest the ColorPicker inside of a MenuItem
    // It works well and we can explicitly close the menu by uncommenting the below,
    // but it can cause selection to happen prematurely (e.g. after typing #000)
    // so we can only enable this if editableInput is disabled
    if (!editableInput) {
      const { onToggle, open } = _.get(this.dd, 'current.inner.props', {});
      if (open && typeof onToggle === 'function') {
        onToggle();
      }
    }
  }

  onPicking() {
    this.setState({ picking: !this.state.picking });
  }

  render() {
    const { color, style, editableInput, size, colors } = this.props;

    return (
      <div className={cx('color-picker', `style-${style}`, { 'editable-input': editableInput })}>
        <Dropdown
          id="color-picker-dd"
          size={size}
          ref={this.dd}
          title={
            <>
              <span className="sample" style={{ backgroundColor: color }} onClick={this.onPicking} />
              {editableInput && <span>{color}</span>}
            </>
          }
        >
          <MenuItem header>
            {style === 'compact' && <CompactPicker color={color} onChangeComplete={this.onChangeComplete} />}
            {style === 'github' && (
              <GithubPicker
                color={color}
                colors={colors || GithubPicker.defaultProps.colors}
                onChangeComplete={this.onChangeComplete}
              />
            )}
          </MenuItem>
        </Dropdown>
      </div>
    );
  }
}
