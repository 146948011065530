import _ from 'lodash';
import VariableFilter from './VariableFilter';

export const VITAL_TYPES = [
  {
    key: 'variable',
    title: 'Variable',
    plural: 'Variables',
    tip: 'Variable Vitals have filters for comparison of values with this template',
  },
  {
    key: 'clause',
    title: 'Clause',
    plural: 'Clauses',
    tip: 'Clause Vitals have linked sections for comparison of language with this template',
  },
];

export const CLAUSE_VITAL_PROMPTS = [
  {
    key: 'exists',
    title: 'Exists',
    prompt: '',
    question: 'Does clause appear in document?',
    answer: (failedCheck) => { return failedCheck ? 'No' : 'Yes'  }
  },
  {
    key: 'not-exist',
    title: 'Does Not Exist',
    prompt: '',
    question: 'Is clause absent from document?',
    answer: (failedCheck) => { return failedCheck ? 'No, clause appears in document.' : 'Yes, clause is absent from document.'  }
  },
  {
    key: 'additional-terms',
    title: 'Has Additional Terms',
    prompt: 'Does the text in the <records> tag have additional information that is not present in the text found in the <sample> tag? Please give your answer as a boolean value. Please give your confidence interval in your answer on a separate line formatted as a decimal from 0.00 to 1.00.',
    question: 'Matches origin clause?',
    answer: (failedCheck) => { return failedCheck ? 'No, clause contains additional terms.' : 'Yes, clause matches origin clause.'  }
  },
]



export default class Vital {
  
  id; 

  type;
  title;
  conditions;

  relatedSections;    // for Clause Vitals
  riskValue;

  relatedVariable;    // for Variable Vitals
  valueFilter = null;        // for Variable Vitals
  clauseCheck = null; //for clauseVitals

  constructor({ 
    id, 
    type, 
    title, 
    conditions,
    relatedSections, 
    valueFilter = null, 
    relatedVariable = null, 
    riskValue = '10',
    clauseCheck = null,
  }) {
    _.assign(this, { id, type, title, relatedVariable, clauseCheck });
    
    // Serialize/deserialize delimited properties (relatedSections)
    this.relatedSections = relatedSections ? relatedSections.split('|') : [];
    this.riskValue = parseInt(riskValue) || 0;

    this.conditions = {};
    
    _.forEach(conditions, (variableFilterJSON) => {
      const { variable, valueType, operator, values } = variableFilterJSON;
      const vf = new VariableFilter(variable, {valueType, operator, values});
      this.conditions[variable] = vf;    
    });

    if (valueFilter) {
      const { variable, valueType, operator, values } = valueFilter;
      this.valueFilter = new VariableFilter(variable, {valueType, operator, values});
    } else {
      this.valueFilter = null;
    }
  }

  get json() {
    const obj = _.pick(this, ['id', 'type', 'title', 'relatedVariable', 'clauseCheck']);

    // Serialize/deserialize delimited properties (relatedSections)
    obj.relatedSections = this.relatedSections.length ? this.relatedSections.join('|') : null;
    obj.riskValue = this.riskValue.toString();
    obj.valueFilter = this.valueFilter ? this.valueFilter.json : null;
    obj.conditions = _.keys(this.conditions).length ? _.mapValues(this.conditions, 'json') : null;
    
    return obj;
  }

}
