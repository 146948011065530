import { assign, find } from 'lodash';

import { STEPS } from '../enums/DealStatus';
import DateFormatter from '../utils/DateFormatter';
import Branding from './Branding';

export default class DealInfo extends Branding {
  dealID = null;
  name = null;
  status = null;
  sourceTeam = null;
  sourceTemplate = null;
  sourceTemplateKey = null;
  created = null;
  updated = null;
  connected = false;
  type = null; // The type of deal: settlement calculator, demand, etc.

  constructor(json) {
    super(json);
    assign(this, json);

    // If no status specified start at the beginning!
    if (!json.status) {
      this.status = STEPS[0].key;
    }
  }
  get displayCreated() {
    return DateFormatter.fromMilliseconds(this.created);
  }
  get displayUpdated() {
    return DateFormatter.fromMilliseconds(this.updated);
  }

  get displayStatus() {
    const stat = find(STEPS, { key: this.status }) || STEPS[0];
    return stat.name;
  }
}
