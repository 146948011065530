import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Dt, dt } from '@core/utils';

import { Button } from '@components/dmp';

import Preloader from '@components/Preloader';
import API from '@root/ApiClient';
import Auth from '@root/Auth';

const MESSAGE = {
  SUCCESS: {
    title: `Access to ${dt} requested, sit tight`,
    description: `The ${dt} owner has been notified by email. You will receive an email when they have granted you access.`,
  },
  ERROR: {
    title: 'Oops! Something went wrong ;(',
    description: `We were unable to process your request for access to this ${dt}. Please try again in a few minutes!`,
  },
};

@autoBindMethods
class NoDealAccess extends Component {
  isGuest = false;

  static defaultProps = {
    user: null,
    history: null,
    match: null,
    dealID: null,
  };
  static propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    dealID: PropTypes.string,
  };

  constructor(props) {
    super(props);

    // check if it's a guest user or not
    this.isGuest = _.get(this.props.user, 'anonymous', false);

    this.state = {
      isAccessRequested: false,
      isLoading: false,
      isError: false,
    };
  }

  componentDidMount() {
    if (!this.props.user) this.otherAccountSignIn();
  }

  async otherAccountSignIn() {
    const { dealID, user, history } = this.props;
    const redirectUrl = `/deals/${dealID}`;

    await this.setState({ isLoading: true });

    if (user) {
      await Auth.logout();
    }

    history.push(`/login/?redirect=${encodeURIComponent(redirectUrl)}`);
  }

  async requestAccess() {
    const { dealID, user } = this.props;

    this.setState({ isLoading: true, isAccessRequested: true });

    try {
      const accessResponse = await API.callAnon('requestAccess', { dealID, requester: user });
      console.log(accessResponse);
      this.setState({ isLoading: false });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false, isError: true });
    }
  }

  render() {
    const { isAccessRequested, isLoading } = this.state;

    if (isLoading) return <Preloader />;

    return isAccessRequested ? this.renderAccessRequested() : this.renderNoAccess();
  }

  renderNoAccess() {
    const { user } = this.props;
    let userClassName = cx('current-user', { small: user && !this.isGuest && user.email.length > 30 });

    return (
      <div className="no-deal-access">
        <h1>Sorry, you do not have access to this {dt}</h1>
        <hr />
        {user ? (
          <div>
            <p>You are currently signed in as:</p>
            <p className={userClassName}>{this.isGuest ? 'Guest' : user.email}</p>
          </div>
        ) : (
          <p>You are currently not signed in.</p>
        )}

        <p>To view this {dt}, choose one of the following:</p>
        <div>
          <Button className="re-login" dmpStyle="primary" onClick={this.otherAccountSignIn} data-cy="btn-other-email">
            Sign in with another email address
          </Button>
          <Button
            className="request-button"
            onClick={this.requestAccess}
            disabled={!user || this.isGuest} // disable the button if it's a guest user
          >
            Request access with current account
          </Button>
        </div>
        <span className="request-terms">{Dt} owner will be notified via email</span>
      </div>
    );
  }

  renderAccessRequested() {
    const { isError } = this.state;
    const message = !isError ? MESSAGE.SUCCESS : MESSAGE.ERROR;

    return (
      <div className="no-deal-access">
        <h1>{message.title}</h1>
        <hr />
        <p>{message.description}</p>
        <div>
          <Button className="exit" dmpStyle="primary" onClick={() => this.props.history.push('/')}>
            Go to dashboard
          </Button>
        </div>
      </div>
    );
  }
}

export default NoDealAccess;
