import React from 'react';

import _ from 'lodash';

import { Radio } from 'react-bootstrap';

import { Checkbox } from '@components/dmp';

/*
  Dynamic Selector column used to select items.
  It does not handle state, it's mostly a structure to avoid repetitive code
*/
const selectorColumn = ({
  accessor = 'id',
  selected,
  selectable = null,
  onToggleAll,
  onToggle,
  multiselect = true,
}) => {
  return {
    Header: ({ data }) => {
      // Single selection is rendered as Radio buttons so no need for a control in the header
      if (!multiselect) return null;

      const selectableArray = selectable || data;
      return (
        <div>
          <Checkbox
            id="chk-select-all"
            checked={selectableArray.length === selected.length}
            onChange={onToggleAll}
            disabled={!selectableArray.length}
          />
        </div>
      );
    },
    headerClassName: 'th-selection',
    accessor,
    id: 'selection',
    Cell: ({ original, tdProps }) => {
      const isLocked = !!_.get(tdProps, 'rest.isLocked');

      const onClick = isLocked
        ? _.noop
        : (e) => {
            // If user clicks the actualy check/radio (instead of row click), prevent a duplicate event
            e.stopPropagation();
            onToggle(original);
          };

      const checked = !!_.find(selected, { [accessor]: original[accessor] });

      return multiselect ? (
        <Checkbox id={`chk-selected-${original[accessor]}`} checked={checked} disabled={isLocked} onChange={onClick} />
      ) : (
        <Radio checked={checked} disabled={isLocked} onChange={onClick} />
      );
    },
    width: 36,
    className: 'col-selection',
    fixed: 'left',
    sortable: false,
    clickable: false,
    resizable: false,
  };
};

export { selectorColumn };
