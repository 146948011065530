import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import StyleFactory, { DEFAULT_THEME } from '@core/models/StyleFactory';
import Team from '@core/models/Team';
import User from '@core/models/User';
import { getSafeKey } from '@core/utils';

import { Button, Checkbox, Loader, Validator } from '@components/dmp';

import ThemeSelector from '@components/teams/ThemeSelector';
import Fire from '@root/Fire';

@autoBindMethods
export default class ThemeInfo extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    team: PropTypes.instanceOf(Team).isRequired,
    show: PropTypes.bool.isRequired,
    theme: PropTypes.object,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      themeKey: '',
      validKey: false,
      isDefault: false,
      description: '',
      basedOn: DEFAULT_THEME,
    };
  }

  componentDidUpdate(prevProps) {
    const { theme, show } = this.props;

    if (!_.isEqual(theme, prevProps.theme) || (!prevProps.show && show)) {
      this.populate(theme);
    }
  }

  get isNew() {
    return !this.props.theme;
  }

  populate(theme) {
    this.setState({
      name: _.get(theme, 'name', ''),
      themeKey: _.get(theme, 'themeKey', ''),
      description: _.get(theme, 'description', ''),
      isDefault: _.get(theme, 'isDefault', false),
      loading: false,
    });
  }

  handleChange(e, prop) {
    this.setState({ [prop]: e.target.value });

    // Updating template title or key on a new template also requires key sanitization,
    // followed by (debounced) validation to ensure uniqueness
    if ((prop === 'name' || prop === 'themeKey') && this.isNew) {
      const themeKey = getSafeKey(e.target.value, false, true, '');
      this.setState({ themeKey });
    }
  }

  focusInput() {
    if (this.refName) {
      this.refName.focus();
    }
  }

  async validateKey(themeKey) {
    const { team } = this.props;
    const valid = !_.get(team.themes, themeKey);
    return valid;
  }

  async save() {
    const { team, onSave, onHide } = this.props;
    const { basedOn } = this.state;
    const theme = _.pick(this.state, ['name', 'themeKey', 'description', 'isDefault']);

    if (this.isNew) {
      const [, customKey] = basedOn.split(':');
      if (customKey) {
        const sourceDS = _.find(team.themes, { themeKey: customKey }).dealStyle;
        theme.dealStyle = StyleFactory.create(basedOn, sourceDS).json;
      } else {
        theme.dealStyle = StyleFactory.create(basedOn).json;
      }
    }

    await this.setState({ loading: true });
    await Fire.saveTheme(team, theme);
    onHide();
    onSave();
  }

  render() {
    const { onHide, show, team } = this.props;
    const { name, themeKey, isDefault, description, loading, validKey, basedOn } = this.state;

    return (
      <Modal dialogClassName="theme-info" show={show} onHide={onHide} onEnter={this.focusInput}>
        <Modal.Header closeButton>
          <span className="headline">{this.isNew ? 'New theme' : 'Update theme info'}</span>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <div className="contents">
              <FormControl
                type="text"
                inputRef={(ref) => (this.refName = ref)}
                disabled={loading}
                value={name}
                placeholder="Enter theme name"
                onChange={(e) => this.handleChange(e, 'name')}
                data-cy="theme-name"
              />
              <Checkbox
                id="chk-theme-default"
                checked={isDefault}
                onChange={() => this.setState({ isDefault: !isDefault })}
              >
                Set as default
              </Checkbox>
            </div>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Theme Key</ControlLabel>
            <div className="contents theme-key dmp-validator-container">
              <FormControl
                readOnly={!this.isNew}
                type="text"
                value={themeKey}
                placeholder="Required"
                onChange={(e) => this.handleChange(e, 'themeKey')}
                data-cy="theme-key"
              />
              {this.isNew && (
                <Validator
                  validate={this.validateKey}
                  value={themeKey}
                  onResult={(validKey) => this.setState({ validKey })}
                  validTip="Available"
                  invalidTip="A theme with this key already exists on this team"
                />
              )}
            </div>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Description</ControlLabel>
            <div className="contents">
              <FormControl
                componentClass="textarea"
                type="text"
                value={description}
                placeholder="Optional"
                onChange={(e) => this.handleChange(e, 'description')}
                data-cy="theme-description"
              />
            </div>
          </FormGroup>

          {this.isNew && (
            <FormGroup>
              <ControlLabel>Based On</ControlLabel>
              <div className="contents" data-cy="contents-theme-selector">
                <ThemeSelector
                  id="theme-selector-new-theme"
                  onSelect={(basedOn) => this.setState({ basedOn })}
                  themeKey={basedOn}
                  team={team}
                  block
                />
              </div>
            </FormGroup>
          )}
        </Modal.Body>

        <Modal.Footer>
          {loading && <Loader />}
          <Button disabled={loading} onClick={onHide}>
            Cancel
          </Button>
          <Button dmpStyle="primary" disabled={loading || (this.isNew && !validKey)} onClick={this.save}>
            {this.isNew ? 'Save' : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
