// Keep this in Outlaw utils, not in Core until we figure out how to get these methods to run on the same thread
import FlexSearch from 'flexsearch';
import _ from 'lodash';

import { PARTY_PROPERTIES } from '@core/models/Party';
import {
  CONNECTED_VAR_COMPARISON_FIELDS,
  DATE_PROPERTIES,
  NUMBER_PROPERTIES,
  ValueType,
  VariableType,
} from '@core/models/Variable';
import { addressFields, contactFields } from '@core/models/filevine/Contact';
import { PROJECT_FIELDS } from '@core/models/filevine/Project';

//Get both the fv and ol variable definitions as well as their statuses following the comparison (broken, resolve)
export const compareConnectedVariableDefinitions = (variableIndex, element) => {
  let fvDefinition;
  let broken,
    resolve = false;

  if (_.isEmpty(variableIndex.get(element.baseVariable.name))) {
    //check to see if they property was a subproperty
    const subproperty = getConnectedSubpropertyDefinition(variableIndex, element);
    fvDefinition = _.pick(subproperty, CONNECTED_VAR_COMPARISON_FIELDS);
  } else {
    fvDefinition = _.pick(variableIndex.get(element.baseVariable.name), CONNECTED_VAR_COMPARISON_FIELDS);
  }
  const olDefinition = _.pick(element, CONNECTED_VAR_COMPARISON_FIELDS);

  //once we have the definitions return the stutus to be used for styling the connected variable.
  if (_.isEmpty(fvDefinition)) broken = true;
  else {
    resolve = !_.isEqual(fvDefinition, olDefinition);
  }

  return { broken, resolve, fvDefinition, olDefinition };
};

//Used to deconstruct a connected variable subproperty and get the base variable name.
//fv connected variables subproperties are just concationated onto their base variable definition name.
//EX: [+fv_draft_recipientInformation], [+fv_draft_recipientInformation_fromCompany].
//WE DO NOT STORE THE BASE VARIABLES SUBPROPERTIES IN THE VARIABLE INDEX.
//It is done in a seperate call (generatePropertyOptions) because a base variable can have 100-1000ish subproperties.
const getConnectedSubpropertyDefinition = (variableIndex, element) => {
  const name = element.baseVariable.name;
  const splitName = name.split('_');

  //project clients needs to processed differenly as the client'a parent acts as a base option name.
  const isProjectClient = element.baseVariable.externalType === 'project' && name.includes('_client_');

  // when project property is it's client's subpropery, we want to first generate the project options
  // if not strip the project field property to generate project option to finc it's subproperty field.
  const projectNameStripIndex = isProjectClient ? splitName.indexOf('client') : splitName.length - 1;
  //all base connected variable options are strucutred the same fv_{sectionName}_{variableName}
  const baseOptionName =
    element.baseVariable.externalType === 'project'
      ? splitName.slice(0, projectNameStripIndex).join('_')
      : splitName.slice(0, 3).join('_');

  const baseOptions = variableIndex.get(baseOptionName);

  const hasAddressSubprop = name.includes('_addresses_');

  if (baseOptions) {
    const propertyOptions = generatePropertyOptions(baseOptions);

    // for project client subproperty value, we have to first generate project options and then from that find the client option
    // and generate property option of that client.
    if (isProjectClient) {
      const [clientBaseOptions] = propertyOptions.filter((option) => option.name === `${baseOptionName}_client`);
      const clientPropertyOptions = generatePropertyOptions(clientBaseOptions);
      if (hasAddressSubprop) {
        const addressPropertyOptions = generatePropertyOptions(
          clientPropertyOptions.find((option) => option.name === `${baseOptionName}_client_addresses`)
        );
        return _.find(addressPropertyOptions, { name: name });
      }
      return _.find(clientPropertyOptions, { name: name });
    }

    if (hasAddressSubprop) {
      const addressPropertyOptions = generatePropertyOptions(
        propertyOptions.find((option) => option.name === `${baseOptionName}_addresses`)
      );
      return _.find(addressPropertyOptions, { name: name });
    }

    return _.find(propertyOptions, { name: name });
  } else {
    //if passed back as empty it means we could not find the fv variable defintion
    return {};
  }
};
// Given a base variable option that can have sub-properties,
// generate property options on the fly (instead of adding to index) once the base option is selected
export const generatePropertyOptions = (baseOption, includeNone = true) => {
  const { type, name, valueType, writable, multiline, prompt, id, externalSelector, displayName } = baseOption;

  const options = [];
  let properties;

  if (valueType === ValueType.TABLE) {
    let option;
    // Add 'none' option for default table display (but not when used in formula...)
    if (includeNone) {
      const label = 'Full table (default)';

      option = _.extend({}, baseOption, {
        data: 'none',
        id,
        name,
        label,
        tableDisplayName: 'Insert the full table showing all data',
        displayName,
        // Note: we're following the existing Outlaw enum definition pattern (data/label/description)
        // But some Filevine fields have data paths which differ from our desired var names
        // so this way we can keep the names pretty (e.g., exclude "custom." -- see Contact.js)
        externalSelector,
        searchName: `${id} | ${label}`,
        valueType,
        writable: writable || false,
        multiline: multiline || false,
        prompt: prompt || null,
      });

      options.push(option);
    }

    _.forEach(baseOption.totalEligibleColumns, (tableColumn) => {
      const propertyName = `${name}.${tableColumn.id}`;
      option = {
        name: propertyName,
        type,
        valueType: tableColumn.valueType,
        searchName: propertyName,
        label: `${propertyName}`,
        displayName: `Column total (${tableColumn.valueType})`,
      };
      options.push(option);
    });
    return options;
  }

  // These two types (which are really Filevine types) are different from Outlaw's types below that support properties
  // Here, we're flatting Filevine complex objects so that each property is its own unique connected var
  // So they're presented in the VariableSuggest as properties, but really it's just a multi-step selection process
  if ([ValueType.CONTACT, ValueType.PROJECT, ValueType.ADDRESS].includes(valueType)) {
    properties = valueType === ValueType.CONTACT ? contactFields() : PROJECT_FIELDS;
    if (ValueType.ADDRESS === valueType) {
      properties = addressFields;
    }
    _.forEach(properties, (field) => {
      const id = field.data ? `${baseOption.id}_${field.data.replace('.', '_')}` : baseOption.id;
      const option = _.extend({}, baseOption, {
        id,
        name: id,
        label: field.label,
        displayName: (baseOption.displayName || baseOption.name).split(' - ').pop() + ` - ${field.label}`,
        // Note: we're following the existing Outlaw enum definition pattern (data/label/description)
        // But some Filevine fields have data paths which differ from our desired var names
        // so this way we can keep the names pretty (e.g., exclude "custom." -- see Contact.js)
        externalSelector: baseOption.externalSelector + `.${field.externalSelector || field.data}`,
        searchName: `${id} | ${field.label}`,
        valueType: field.valueType,
        writable: field.writable || false,
        multiline: field.multiline || false,
        prompt: field.prompt || null,
      });

      if ([ValueType.PROJECT].includes(option.valueType)) {
        options.push(...generatePropertyOptions(option));
      }

      options.push(option);
    });

    return options;
  }

  // If we get here, we're looking at true Outlaw propeties, whose enums all conform to the same data model
  if (type === VariableType.PARTY) properties = PARTY_PROPERTIES;
  else if (valueType === ValueType.DATE) properties = DATE_PROPERTIES;
  else if ([ValueType.NUMBER, ValueType.CURRENCY].includes(valueType)) properties = NUMBER_PROPERTIES;

  if (properties) {
    _.forEach(properties, ({ data, label, description: displayName }) => {
      const propertyName = data === 'none' ? name : `${name}.${data}`;
      const option = _.extend({}, baseOption, {
        name: propertyName,
        searchName: `${data} | ${label}`,
        label,
        displayName,
      });
      options.push(option);
    });
  }
  return options;
};

/*
  VariableIndex is used to search through different variables, tagged by their types.
  The advantage is that it's highly configurable and extract the search mechnism, allowing us
  to re-use the same index in multiple places on the same page.
*/
class VariableIndex {
  index = null;

  constructor() {
    this.initIndex();
  }

  initIndex() {
    this.index = new FlexSearch.Document({
      preset: 'default',
      tokenize: 'full',
      minLength: 3,
      language: 'en',
      document: {
        store: true,
        id: 'id',
        tag: 'tag',
        index: ['id', 'name', 'searchName', 'displayName'],
      },
    });
  }

  count(tag) {
    return _.filter(this.index.store, { tag: tag }).length;
  }

  reset() {
    this.initIndex();
  }

  contains(id) {
    return this.index.contain(id);
  }

  get(id) {
    return this.index.store[id];
  }

  search(...args) {
    return this.index.search(...args);
  }

  filter(docProps) {
    return _.filter(this.index.store, docProps);
  }

  flattenPropertyOptions(group) {
    const fields = this.filter({ group });
    const options = [];

    // For each option that's actually a contact, we need to "flatten" its properties into unique fields
    _.forEach(fields, (field) => {
      if (
        field.valueType === ValueType.CONTACT ||
        field.valueType === ValueType.PROJECT ||
        field.valueType === ValueType.ADDRESS
      ) {
        options.push(...generatePropertyOptions(field));
      } else {
        options.push(field);
      }
    });

    return options;
  }

  remove(...args) {
    return this.index.remove(...args);
  }

  /*
    Add variables to the index
  */
  add(variables) {
    const entries = !_.isArray(variables) ? [variables] : variables;

    _.forEach(
      entries,
      ({
        name,
        displayName,
        type,
        valueType,
        connectType,
        externalType,
        externalSelector,
        prompt,
        group,
        multiline,
        writable,
        autoPull,
        autoPush,
        projectLinkFieldType,
        raw,
      }) => {
        const doc = {
          id: name,
          name,
          searchName: name,
          displayName,
          prompt,
          type,
          tag: type,
          valueType,
          connectType,
          externalType,
          externalSelector,
          multiline,
          writable,
          group,
          autoPull,
          autoPush,
          projectLinkFieldType,
          raw,
        };

        // If we have a display name, include that in the searchName property because that's what our VariableSuggest searches
        // This way arbitrary IDs with pretty titles will still be easily discoverable
        if (displayName) doc.searchName += ` | ${displayName}`;

        this.index.add(doc);
      }
    );
  }
}

export default VariableIndex;
