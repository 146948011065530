import React from 'react';

import PropTypes from 'prop-types';

import { DropdownDots, MenuItem } from '@components/dmp';

const WorkflowListItem = ({ workflow, onEdit, confirmDelete }) => {
  const handleAction = (action) => {
    switch (action) {
      case 'edit':
        onEdit(workflow);
        break;
      case 'delete':
        confirmDelete(workflow);
        break;
      default:
        break;
    }
  };

  if (!workflow) return null;

  let title = workflow.name;
  if (workflow.isDefault) title += ' (Default)';

  return (
    <div className="table-inner d-flex justify-content-between">
      <div className="col workflow-title">
        <span>{title}</span>
        <small className="block">{workflow.description}</small>
      </div>

      <DropdownDots
        pullRight
        onClick={(e) => e.stopPropagation()}
        id={`dd-wf-${workflow.workflowKey}`}
        onSelect={handleAction}
      >
        <MenuItem eventKey="edit">Edit</MenuItem>
        <MenuItem eventKey="delete">Delete</MenuItem>
      </DropdownDots>
    </div>
  );
};

WorkflowListItem.propTypes = {
  workflow: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default WorkflowListItem;
