import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, Overlay, Popover } from 'react-bootstrap';

import DealNumberFormat from '@core/models/DealNumberFormat';
import Section from '@core/models/Section';
import { NUMBER_TYPES } from '@core/utils';

import { Button, Dropdown, MenuItem } from '@components/dmp';

@autoBindMethods
export default class CustomNumbering extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    section: PropTypes.instanceOf(Section),
    target: PropTypes.object,
    container: PropTypes.object,
    hide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const format = props.section.numberFormat;

    this.state = {
      pre: _.get(format, 'pre', ''),
      post: _.get(format, 'post', ''),
      case: _.get(format, 'case', null),
      type: _.get(format, 'type', 'number'),
    };
  }

  focus() {
    const el = this.refPrefix;
    if (el) el.focus();
  }

  close() {
    const { hide } = this.props;

    this.setState({ pre: '', post: '', case: null, type: 'number' });
    hide();
  }

  save() {
    const { onSave } = this.props;
    const newStyle = _.pick(this.state, ['pre', 'post', 'case', 'type']);
    onSave(new DealNumberFormat(newStyle));
    this.close();
  }

  get example() {
    const fmt = new DealNumberFormat(this.state);
    return fmt.example;
  }

  render() {
    const { hide, target, container, section, id } = this.props;
    const { pre, post, type } = this.state;

    let title = `Level ${section.indentLevel + 1} Numbering `;
    if (section.appendix) title += `(${section.appendix.displayname})`;

    // A NUMBER_TYPE should now always be found per fixes in DealNumberFormat.constructor,
    // But fallback to top-level numbering just to be safe and avoid a React error
    const selectedType = _.find(NUMBER_TYPES, { type, case: this.state.case }) || NUMBER_TYPES[0];

    return (
      <Overlay
        onEntered={this.focus}
        onHide={hide}
        placement="bottom"
        rootClose
        show={true}
        target={target}
        container={container}
      >
        <Popover className="popover-custom-numbering" id={`${id}-pop`}>
          <h3 className="popover-title">{title}</h3>
          <div className="number-style">
            <FormControl
              onChange={(e) => this.setState({ pre: e.target.value })}
              placeholder="Prefix"
              inputRef={(r) => (this.refPrefix = r)}
              type="text"
              value={pre || ''}
            />

            <Dropdown
              id={`${id}-dd`}
              noCaret
              onSelect={(t) => this.setState({ type: t.type, case: t.case })}
              title={selectedType.label}
            >
              {NUMBER_TYPES.map((t, idx) => (
                <MenuItem key={idx} eventKey={t}>
                  {t.label}
                </MenuItem>
              ))}
            </Dropdown>

            <FormControl
              onChange={(e) => this.setState({ post: e.target.value })}
              placeholder="Suffix"
              type="text"
              value={post || ''}
            />
          </div>
          <div className="number-example">
            <span>Example:</span>
            <span>{this.example}</span>
          </div>
          <div className="actions">
            <Button size="small" dmpStyle="link" className="cancel" onClick={this.close}>
              Cancel
            </Button>
            <Button className="save" size="small" onClick={this.save}>
              Save
            </Button>
          </div>
        </Popover>
      </Overlay>
    );
  }
}
