import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import DealStatus from '@core/enums/DealStatus';
import InviteStatus from '@core/enums/InviteStatus';
import { NEW_CONTRACT_TYPES } from '@core/models/Attachment';
import { DEAL_TYPE } from '@core/models/Deal';
import DealRecord, { REALTIME_EVENTS } from '@core/models/DealRecord';
import { findFilter } from '@core/models/FilterStore';
import { DEFAULT_COLS } from '@core/models/SearchParams';
import { canHaveTeam } from '@core/models/User';
import { Dt, dt, formatNumber } from '@core/utils';

import { Alert, Button, ButtonIcon, DataTable, Dropper, Icon, ModalConfirm } from '@components/dmp';
import { selectorColumn } from '@components/dmp/DataTableColumns';

import DealFilters from '@components/DealFilters';
import EmptyState from '@components/EmptyState';
import ExportCSV from '@components/ExportCSV';
import Tagger from '@components/Tagger';
import BatchActionDropdown from '@components/deal/BatchActionDropdown';
import BatchUserManager from '@components/deal/BatchUserManager';
import BulkSigner from '@components/deal/BulkSigner';
import { findColumn } from '@components/deal/Columns';
import ContractUploader from '@components/deal/ContractUploader';
import DealEditDropdown from '@components/deal/DealEditDropdown';
import DeleteDeal from '@components/deal/DeleteDeal';
import NewDealButton from '@components/deal/NewDealButton';
import SigPad from '@components/deal/SigPad';
import TooltipButton from '@components/editor/TooltipButton';
import ColumnSettings from '@components/search/ColumnSettings';
import FacetBar from '@components/search/FacetBar';
import API from '@root/ApiClient';
import Dealer from '@root/Dealer';
import Fire from '@root/Fire';

const DEFAULT_SORT = 'updated.desc';

@autoBindMethods
export default class DealsPage extends Component {
  dealEditRefs = [];

  static defaultProps = {
    teams: [],
  };

  static propTypes = {
    og: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    searchParams: PropTypes.object.isRequired,
    teams: PropTypes.array,
    team: PropTypes.object,
    getTeams: PropTypes.func.isRequired,
    selectTeam: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showUpgradeTooltip: false,
      deals: [],
      loading: true,
      deleting: false,
      signing: false,
      managingUsers: false,
      exportingCSV: false,
      dealsToManage: [],
      taggingDealRecord: null,
      taggingDealRef: null,
      selectedDeals: [],
      showSearch: false,
      totalHits: 0,
      currentPage: 0,
      totalPages: 0,
      hitQueryLimit: false,
      userTags: props.user.tags,
      filteredDealTemplate: null,
      showColumnSettings: false,
      showUploader: false,
      droppedFile: null,
      newDocSelectedTeam: null,
      apiArgs: null,
      failedInvites: 0,
      showFailedInvitesAlert: true,
      reviewCount: 0,
      dealLeft: false,
      dealDeleted: false,
    };

    this.refBulk = createRef();
  }

  componentDidMount() {
    const { searchParams, og, user, teams, location } = this.props;

    this._isMounted = true;
    og({ title: `Outlaw - ${Dt}s` });

    if (this.showNeedsReview) {
      this.checkNeedsReview();
    }

    this.getDeals(searchParams);

    if (searchParams.sourceTemplateKey) {
      this.loadTemplate(searchParams.sourceTemplateKey);
    }

    const teamsAllowingNewDocCreation = user.getTeamsAllowingNewDocCreation(teams);

    if (teamsAllowingNewDocCreation.length === 1) {
      this.handleNewDocCreationSelectTeam(teamsAllowingNewDocCreation[0]);
    }

    if (this.showFailedInvites) {
      this.checkFailedInvites();
    }

    if (location.state?.leftDealfromDealView) {
      this.setState({ dealLeft: true });
    }
    if (location.state?.deletedDealFromDealView) {
      this.setState({ dealDeleted: true });
    }
  }

  componentDidUpdate(prevProps) {
    const searchParams = _.get(this.props, 'searchParams');
    const prevParams = _.get(prevProps, 'searchParams');

    // Anytime SearchParams changes, run a new search
    if (!_.isEqual(searchParams, prevParams)) {
      this.getDeals(searchParams);
    }

    if (prevProps.user.tags.length !== this.props.user.tags.length) {
      // Do a second check ONLY if we're not fetching deals
      this.setState({ userTags: this.props.user.tags });
    }

    // Grab current template data anytime it changes in SearchParams (url)
    if (searchParams.sourceTemplateKey !== prevParams.sourceTemplateKey) {
      this.loadTemplate(searchParams.sourceTemplateKey);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get canCreate() {
    const { user, teams } = this.props;
    return canHaveTeam(user) && user.getTeamsAllowingNewDocCreation(teams)?.length > 0;
  }

  get selectableDeals() {
    const { user } = this.props;
    const observerTeamIDs = user.observerTeamIDs;

    return _.filter(this.state.deals, (dealRecord) => {
      // Make sure that the user has a role on the deal (DealUser)
      let canSelect = !!dealRecord.userRole(this.props.user.id);

      // The User can also select if they are an observer on the Team
      if (!canSelect && observerTeamIDs.includes(dealRecord.sourceTeam)) {
        canSelect = true;
      }

      return canSelect;
    });
  }

  get showFailedInvites() {
    const { deals } = this.state;

    return _.filter(deals, (deal) => {
      return _.some(deal.users, (user) => user.inviteStatus === InviteStatus.FAILED);
    });
  }

  get showNeedsReview() {
    const { teams } = this.props;
    return !!_.find(teams, ({ features }) => {
      return features?.pdfExtraction === true;
    });
  }

  get hasCustomColumns() {
    return this.props.searchParams.columns.join(',') !== DEFAULT_COLS.join(',');
  }

  get sorted() {
    const { searchParams } = this.props;
    const sort = _.get(searchParams, 'sort') || DEFAULT_SORT;

    // Sorting by variables has an extra 'v.' prefix which we want on the column name
    let sortBy, sortDir;
    if (sort.startsWith('v.')) {
      // sort format: v.varName.varType.SortDirection
      const parts = sort.split('.');
      sortBy = `v.${parts[1]}.${parts[2]}`;
      sortDir = parts[3] || 'asc';
    } else {
      [sortBy, sortDir] = sort.split('.');
    }
    return [{ id: sortBy, desc: sortDir === 'desc' }];
  }

  get columns() {
    const { searchParams } = this.props;
    const { filteredDealTemplate, selectedDeals } = this.state;
    this.dealEditRefs = [];

    // Build columns based on searchParams.columns
    const columns = _.map(searchParams.columns, (columnName) => {
      let column = findColumn(columnName, filteredDealTemplate);

      // Handle all the dynamic properties for variable columns
      const isVariable = columnName.includes('v.');

      // This allows defining variables to be displayed as columns and bypassing the template variable existence check
      if (isVariable) {
        const parts = columnName.split('.'); // Format: v.varName.varType
        const varName = parts[1];
        const templateVariable = column && column.isVariable && filteredDealTemplate.variables[varName];

        if (!column) column = {};

        return {
          ...column,
          Header: templateVariable?.displayName || varName,
          id: columnName,
          accessor: (row) => _.get(_.find(row.variables, { name: varName }), 'displayValue'),
          className: `variable variable-${varName}`,
        };
      }

      return column;
    });

    return [
      selectorColumn({
        accessor: 'dealID',
        selected: selectedDeals,
        selectable: this.selectableDeals,
        onToggleAll: this.toggleAllDealsSelection,
        onToggle: this.toggleDealSelection,
      }),
      ..._.compact(columns), // Make sure we don't pass in 'undefined' columns
      this.settingsColumn,
    ];
  }

  get settingsColumn() {
    const { history, user, subscription } = this.props;
    const { editingDealID } = this.state;
    let isSettingDisabled = this.selectedFilterTeam ? !user.canEditTeam(this.selectedFilterTeam.teamID) : false;

    return {
      Header: () => (
        <div className="settings">
          <ButtonIcon
            onClick={() => this.setState({ showColumnSettings: true })}
            disabled={isSettingDisabled}
            icon="settings"
            data-cy="btn-setting"
          />
        </div>
      ),
      accessor: 'dealID',
      id: 'actions',
      Cell: ({ original: dealRecord, tdProps }) => {
        const isLocked = !!_.get(tdProps, 'rest.isLocked');

        if (isLocked) {
          return (
            <TooltipButton
              tipID={`tip-no-access-${dealRecord.dealID}`}
              tip={`Tap to request access to this ${dt}`}
              positionLeft={8}
            >
              <Link to={this.rowLocation({ original: dealRecord }, true)}>
                <Icon name="lockGuest" size="large" faded />
              </Link>
            </TooltipButton>
          );
        }

        return (
          <div ref={(editRef) => (this.dealEditRefs[dealRecord.dealID] = editRef)}>
            <DealEditDropdown
              confirmDelete={this.confirmDelete}
              dealRecord={dealRecord}
              editingDealID={editingDealID}
              history={history}
              onShowTagger={this.handleShowTagger}
              realtimeUpdate={this.realtimeUpdate}
              user={user}
              subscription={subscription}
            />
          </div>
        );
      },
      width: 40,
      className: 'col-actions',
      fixed: 'right',
      sortable: false,
      clickable: false,
      resizable: false,
    };
  }

  get selectedFilter() {
    const { searchParams, user, teams } = this.props;
    const { teamID, filterID } = searchParams.params;
    return findFilter({ user, teams, filterID, teamID });
  }

  get selectedFilterTeam() {
    const teamID = _.get(this.selectedFilter, 'teamID', null);
    return teamID ? _.find(this.props.teams, { teamID }) : null;
  }

  handleNewDocCreationSelectTeam(selectedTeam) {
    const { user, teams } = this.props;
    if (user.getTeamsAllowingNewDocCreation(teams)?.length === 1 && !selectedTeam) {
      return;
    }
    if (selectedTeam) {
      this.props.selectTeam(selectedTeam);
    }
    this.setState({ newDocSelectedTeam: selectedTeam });
  }

  handleColumnChange(newColumns) {
    const { searchParams } = this.props;
    searchParams.columns = newColumns;
  }

  handleShowTagger(dealID) {
    const { deals } = this.state;
    const dealRecord = _.find(deals, { dealID });

    if (!dealRecord) return;

    this.setState({ taggingDealRecord: dealRecord });
  }

  getDeals(searchParams) {
    const { user } = this.props;

    // If we're looking at a team filter, pull teamID for search
    const teamID = _.get(this.selectedFilter, 'teamID', null);
    const filterID = _.get(this.selectedFilter, 'filterID', null);
    const apiArgs = _.merge({ teamID, filterID }, searchParams.apiArgs);

    this.setState({ loading: true, hitQueryLimit: false, selectedDeals: [], apiArgs: apiArgs });

    API.call('getDeals', apiArgs, (results) => {
      //When we load in reports with saved filters we run into a timing issue.
      //An empty apiArgs object takes longer to load than the saved filter 99% of the time.
      //by checking we are loading in the correct deals based on the api args we avoid this issue.
      if (!this._isMounted || apiArgs !== this.state.apiArgs) return;

      // Preview deals should never be indexed, so this should essentially never do anything visible
      // But there may be some cases with old/legacy data where it still made its way in
      // So this is just to ensure that we NEVER show preview deals in the deals list
      results.hits = _.filter(results.hits, (dealRecord) => !dealRecord.preview);

      const deals = _.map(results.hits, (record) => new DealRecord(record, user.id));

      // Set deal tags here to avoid doing it in the table rendering (this.columns)
      _.forEach(deals, (deal) => {
        deal.userTags = user.tags.get(deal.tags);
      });

      this.setState({
        deals,
        totalHits: results.nbHits,
        currentPage: results.page,
        totalPages: results.nbPages,
        hitQueryLimit: results.hitQueryLimit,
        loading: false,
      });
    });
  }

  async checkFailedInvites() {
    this.setState({ loading: true, hitQueryLimit: false });

    await API.call('getDeals', { sharingStatus: 'failed' }, (results) => {
      this.setState({ failedInvites: results.nbHits, loading: false });
    });
  }

  checkNeedsReview() {
    const { user } = this.props;

    this.setState({ loading: true, hitQueryLimit: false });

    API.call('getDeals', { needsReview: true }, (results) => {
      //When we load in reports with saved filters we run into a timing issue.
      //An empty apiArgs object takes longer to load than the saved filter 99% of the time.
      //by checking we are loading in the correct deals based on the api args we avoid this issue.
      if (!this._isMounted) return;

      this.setState({ reviewCount: results.nbHits, loading: false });
    });
  }

  async loadTemplate(sourceTemplateKey) {
    if (!sourceTemplateKey) {
      return this.setState({ filteredDealTemplate: null });
    }

    // This is somewhat annoying, but since we only have the sourceTemplateKey (via URL),
    // we first need another (very fast) API call to look up the dealID
    const [teamID, key] = sourceTemplateKey.split(':');

    const dealRecord = await API.call('getTeamTemplates', { teamID, key });
    const dealID = _.get(dealRecord, '[0].dealID');
    if (!dealID) return;

    // Finally, fetch the full Deal object and store in state to pass to children
    const filteredDealTemplate = await Fire.getDeal(dealID);
    this.setState({ filteredDealTemplate });
  }

  onSortChange(newSorted) {
    // Since we're not doing multi-column sorting, let's simply grab the first item in the array
    const sortObject = _.get(newSorted, '[0]', DEFAULT_SORT);
    const { desc, id } = sortObject;
    this.props.searchParams.sort = `${id}.${desc ? 'desc' : 'asc'}`;
  }

  // NB: Because the actual indexing process is unpredictable ,
  // We're not actually blocking on the API response.
  // Instead we send off the call and then immediately update UI for better UX,
  // knowing that the search engine WILL reflect this state within a few milliseconds
  async realtimeUpdate(deal, type, data) {
    const { user } = this.props;
    const { deals, totalHits } = this.state;

    const dealIdx = _.findIndex(deals, { dealID: deal.dealID });

    switch (type) {
      // All 4 of these cases ultimately result in this item being removed from the list, so just remove it manually
      case REALTIME_EVENTS.TAG:
      case REALTIME_EVENTS.UNTAG:
        if (dealIdx > -1) {
          // Treat this as a DELETE
          if (data === 'archived') {
            deals.splice(dealIdx, 1);
            await this.setState({ deals, totalHits: totalHits - 1 });
          } else {
            deals[dealIdx].tags = deal.tags;
            // Need to set again the user Tags to get their display names.
            deals[dealIdx].userTags = user.tags.get(deal.tags);
            await this.setState({ deals });
          }
        }
        break;
      case REALTIME_EVENTS.DELETE:
        this.setState({ dealDeleted: true });
      case REALTIME_EVENTS.LEAVE:
        const { dealDeleted } = this.state;
        if (dealIdx > -1) {
          deals.splice(dealIdx, 1);
          await this.setState({ deals, totalHits: totalHits - 1, dealLeft: !dealDeleted });
        }
        break;
      case REALTIME_EVENTS.ACTIVATE_TEMPLATE:
      case REALTIME_EVENTS.DEACTIVATE_TEMPLATE:
        deal.status = data;
        await this.setState({ deals });
        break;
      default:
        break;
    }
  }

  // Close SigPad and open BulkSigner, and start signing process
  async bulkSign(signatureData) {
    const { dealsToManage } = this.state;
    await this.setState({ signing: false });
    const bulk = this.refBulk.current;
    bulk.start(signatureData, dealsToManage);
  }

  // Close BulkSigner and refresh
  async onBulkSign() {
    await this.setState({ dealsToManage: [] });
    this.getDeals(this.props.searchParams);
  }

  confirmDelete(dealRecords) {
    this.setState({ dealsToManage: dealRecords, deleting: true });
  }

  async confirmExport(dealRecords) {
    this.setState({ dealsToManage: dealRecords, exportingCSV: true });
  }

  openSigPad(dealRecords) {
    this.setState({ dealsToManage: dealRecords, signing: true });
  }

  manageUsers(dealRecords) {
    this.setState({ dealsToManage: dealRecords, managingUsers: true });
  }

  onTagsUpdated(dealRecord, tag, realtimeEvent) {
    this.realtimeUpdate(dealRecord, realtimeEvent, tag);
  }

  hideTagger() {
    this.setState({ taggingDealRecord: null });
  }

  getDealEditRef(dealID) {
    const taggingDealRef = this.dealEditRefs[dealID];
    // eslint-disable-next-line react/no-find-dom-node
    return (taggingDealRef && ReactDOM.findDOMNode(taggingDealRef)) || null;
  }

  hasDealAccess(rowInfo) {
    const { user } = this.props;
    const dealRecord = _.get(rowInfo, 'original', null);

    // Make sure we got a DealRecord and remove click feature for non accessible team deals
    if (!dealRecord) return false;

    const isOnDeal = !!dealRecord.userRole(user.id);
    const canObserve = _.get(user, `teamMemberships[${dealRecord.sourceTeam}].observer`) === true;

    if (!isOnDeal && !canObserve) return false;

    return true;
  }

  rowLocation(rowInfo, allowNoAccess) {
    const dealRecord = _.get(rowInfo, 'original', null);

    // Not clickable for team deals that the user has no access to
    if (!dealRecord || (!allowNoAccess && !this.hasDealAccess(rowInfo))) return null;

    let url = `/deals/${dealRecord.dealID}`;
    if (['signing', 'signed'].indexOf(dealRecord.status) > -1) {
      // if deal is locked (signing or signed) default to source view
      url += '/contract';
    } else if (
      [DEAL_TYPE.INGESTED, DEAL_TYPE.BESPOKE].includes(dealRecord.dealType) &&
      dealRecord.status === DealStatus.DRAFT.title
    ) {
      // if we're viewing an INGESTED/BESPOKE deal, go to Draft mode!
      // (can further update this to also depend on status)
      url += '/draft';
    }

    return url;
  }

  getTdProps(state, rowInfo, column) {
    const { history } = this.props;

    const tdProps = {
      isLocked: !this.hasDealAccess(rowInfo),
      to: this.rowLocation(rowInfo),
      history: history,
    };

    if (column.clickable === false || column.id === '_selector') {
      tdProps.to = null;
    }

    return tdProps;
  }

  getTrProps(state, rowInfo) {
    return {
      to: this.rowLocation(rowInfo),
      isLocked: !this.hasDealAccess(rowInfo),
      needsReview: rowInfo?.original.needsReview,
    };
  }

  toggleDealSelection(dealRecord) {
    const { selectedDeals } = this.state;
    const idx = _.findIndex(selectedDeals, { dealID: dealRecord.dealID });

    if (idx > -1) selectedDeals.splice(idx, 1);
    else selectedDeals.push(dealRecord);

    this.setState({ selectedDeals });
  }

  toggleAllDealsSelection() {
    let { selectedDeals } = this.state;

    if (selectedDeals.length > 0) selectedDeals = [];
    else selectedDeals = this.selectableDeals;

    this.setState({ selectedDeals });
  }

  renderHeader() {
    const { user } = this.props;
    const NewDealButtonProps = {
      ..._.pick(this.props, ['history', 'subscription', 'user', 'teams']),
      selectTeam: this.handleNewDocCreationSelectTeam,
      team: this.state.newDocSelectedTeam,
    };

    let title = `${Dt}s`;
    if (this.selectedFilterTeam) {
      title = (
        <>
          {title} <span>{_.get(this.selectedFilterTeam, 'info.name', '')}</span>
        </>
      );
    }

    return (
      <div className="title-bar">
        <h1>{title}</h1>
        {this.canCreate && (
          <div className="actions">
            {!Dealer.mobile && (
              <Button
                disabled={!user.team}
                icon="upload"
                className="import"
                onClick={() => this.setState({ showUploader: true })}
                data-cy="btn-upload"
              >
                Upload
              </Button>
            )}
            <NewDealButton {...NewDealButtonProps} />
          </div>
        )}
      </div>
    );
  }

  renderResults() {
    const { user, history, location, searchParams } = this.props;
    const { deals, currentPage, totalHits, totalPages, selectedDeals } = this.state;

    let startDisplay = 0;
    let endDisplay = 0;
    let resultSummary = '';

    startDisplay = currentPage * searchParams.hitsPerPage + 1;
    endDisplay = startDisplay + deals.length - 1;

    if (!totalHits) {
      resultSummary = `No ${dt}s found`;
    } else {
      resultSummary =
        totalPages > 1
          ? `Showing ${formatNumber(startDisplay)} - ${formatNumber(endDisplay)} of ${formatNumber(totalHits)} ${dt}s`
          : `Showing ${formatNumber(deals.length)} ${dt}${deals.length > 1 ? 's' : ''}`;
    }

    return (
      <div className="top-of-results" data-cy="top-of-results">
        {selectedDeals.length > 0 ? (
          <BatchActionDropdown
            confirmDelete={this.confirmDelete}
            manageUsers={this.manageUsers}
            onSign={this.openSigPad}
            deals={selectedDeals}
            confirmExport={this.confirmExport}
            realtimeUpdate={this.realtimeUpdate}
            onActionComplete={() => this.setState({ selectedDeals: [] })}
            user={user}
          />
        ) : (
          <div className="results-and-tags">
            <div className="results">{resultSummary}</div>
            <FacetBar history={history} location={location} searchParams={searchParams} tags={user.tags} />
          </div>
        )}
      </div>
    );
  }

  // Note: these all need to render regardless of state, but they will not show if each state var is false
  // This is necessary to support the animation (fade in/out) of the modals
  renderModals() {
    const { user, searchParams, team, teams, history } = this.props;
    const {
      deals,
      deleting,
      dealsToManage,
      managingUsers,
      showColumnSettings,
      filteredDealTemplate,
      droppedFile,
      showUploader,
      newDocSelectedTeam,
      exportingCSV,
      dealLeft,
      dealDeleted,
    } = this.state;

    return (
      <>
        <DeleteDeal
          close={() => this.setState({ deleting: false })}
          deals={dealsToManage}
          onDelete={async (dealRecord) => await this.realtimeUpdate(dealRecord, 'delete')}
          onComplete={() => this.setState({ selectedDeals: [] })}
          show={deleting}
          user={user}
        />

        <ExportCSV
          close={() => this.setState({ exportingCSV: false })}
          deals={this.state.selectedDeals}
          show={exportingCSV}
          searchParams={searchParams}
        />

        <ColumnSettings
          canEditTemplate
          close={() => this.setState({ showColumnSettings: false })}
          onSave={this.handleColumnChange}
          dealTemplate={filteredDealTemplate}
          columns={searchParams.columns}
          searchParams={searchParams}
          show={showColumnSettings}
          teams={teams}
        />

        <BatchUserManager
          close={() => this.setState({ managingUsers: false })}
          deals={dealsToManage}
          key="ManageUsers"
          onUpdate={() => this.setState({ deals })}
          show={managingUsers}
          user={user}
          team={team}
          teams={teams}
        />

        {showUploader && (
          <ContractUploader
            show={showUploader}
            history={history}
            team={newDocSelectedTeam}
            teams={teams}
            selectTeam={this.handleNewDocCreationSelectTeam}
            user={user}
            droppedFile={droppedFile}
            onClose={() => this.setState({ showUploader: false, droppedFile: null, newDocSelectedTeam: null })}
          />
        )}

        {(dealLeft || dealDeleted) && (
          <ModalConfirm
            show={true}
            dmpStyle="primary"
            onConfirm={() => {
              this.setState({ dealLeft: false, dealDeleted: false });
            }}
            confirmText="Close"
            title={dealLeft ? 'Contract departure confirmed' : 'Contract deleted'}
            body={dealLeft ? 'You have successfully left the contract.' : 'The contract has been successfully deleted.'}
            data-cy="delete-deal-confirm-text"
          />
        )}
      </>
    );
  }

  renderDeals() {
    const { location, searchParams, history, user } = this.props;
    const { currentPage, deals, loading, hitQueryLimit, totalPages } = this.state;

    if (!deals.length && !loading) {
      return (
        <div>
          <FacetBar history={history} location={location} searchParams={searchParams} tags={user.tags} />
          <EmptyState
            title="No documents found"
            onAction={() => history.push('/dashboard/contracts')}
            action="Clear search filters"
            buttonStyle="link"
          />
        </div>
      );
    }

    return (
      <>
        {hitQueryLimit && (
          <Alert dmpStyle="warning">
            Too many results to display. For better accuracy, remove filters (status, tag, users, party) and search by
            keywords only.
          </Alert>
        )}
        <div className="deal-listing" data-cy="deal-listing">
          <DataTable
            clickable
            columns={this.columns}
            data={deals}
            dropshadow
            getTdProps={this.getTdProps}
            getTrProps={this.getTrProps}
            hasFixedColumns
            loading={loading}
            loadingText={`Fetching ${dt}s...`}
            manual={true}
            minRows={1}
            onPageChange={(pageIndex) => searchParams.setPage(pageIndex + 1)}
            onPageSizeChange={(pageSize) => (searchParams.hitsPerPage = pageSize)}
            onSortedChange={this.onSortChange}
            page={currentPage}
            pages={totalPages}
            pageSize={searchParams.hitsPerPage}
            showPaginationTop={true}
            sorted={this.sorted}
            TopPaginationComponent={this.renderResults}
          />
        </div>
      </>
    );
  }

  render() {
    const { user, getTeams, history, location, teams, searchParams, team, selectTeam } = this.props;
    const {
      filteredDealTemplate,
      taggingDealRecord,
      showUploader,
      signing,
      failedInvites,
      showFailedInvitesAlert,
      reviewCount,
    } = this.state;

    const newContractTypes = NEW_CONTRACT_TYPES(user);

    return (
      <Dropper
        className="page-deals"
        acceptedTypes={_.map(newContractTypes, 'mime').join(',')}
        onDrop={(droppedFile) => this.setState({ droppedFile, showUploader: true })}
        title={`Drop ${dt} upload here`}
        subtitle={'Accepted: ' + _.map(newContractTypes, 'display').join(', ')}
        suppress={showUploader || !this.canCreate}
      >
        <DealFilters
          dealTemplate={filteredDealTemplate}
          getTeams={getTeams}
          history={history}
          location={location}
          searchParams={searchParams}
          selectedFilter={this.selectedFilter}
          selectTeam={selectTeam}
          team={team}
          teams={teams}
          user={user}
        />
        <div className={cx('contracts', { 'costum-columns': this.hasCustomColumns })} data-cy="contracts">
          {this.renderHeader()}
          {failedInvites > 0 && showFailedInvitesAlert && (
            <Alert dmpStyle="danger" className="alert-failed-invites" data-cy="alert-failed-invites">
              Sharing to recipients has failed ({formatNumber(failedInvites)}).{' '}
              {searchParams.sharingStatus !== InviteStatus.FAILED && (
                <a
                  onClick={() => {
                    searchParams.searchGlobalFailedInvitation();
                  }}
                >
                  Show errors
                </a>
              )}
              <button
                type="button"
                className="close-failed-invites-alert close"
                onClick={() => this.setState({ showFailedInvitesAlert: false })}
                data-cy="alert-close"
              >
                ×
              </button>
            </Alert>
          )}
          {reviewCount > 0 && (
            <Alert dmpStyle="warning">
              Documents in your repository need review.{' '}
              {!searchParams.needsReview && (
                <a
                  onClick={() => {
                    searchParams.searchGlobalNeedsReview();
                  }}
                >
                  Show all ({formatNumber(reviewCount)})
                </a>
              )}
            </Alert>
          )}
          {this.renderDeals()}
          {this.renderModals()}

          {taggingDealRecord && (
            <Tagger
              dealRecord={taggingDealRecord}
              target={this.getDealEditRef}
              user={user}
              onHide={this.hideTagger}
              onTagsUpdated={this.onTagsUpdated}
            />
          )}
        </div>

        <SigPad show={signing} onSignature={this.bulkSign} onHide={() => this.setState({ signing: false })} />

        <BulkSigner ref={this.refBulk} user={user} onHide={this.onBulkSign} />
      </Dropper>
    );
  }
}
