import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Dropdown as BSDropdown } from 'react-bootstrap';

import { classNamePrefixer } from '@core/utils';

import DropdownMenu from '@components/dmp/DropdownMenu';
import DropdownToggle from '@components/dmp/DropdownToggle';

const cl = classNamePrefixer('dd');

const Dropdown = React.forwardRef(
  (
    {
      children,
      className,
      btnClassName,
      icon,
      iconOnly,
      noCaret,
      loading,
      size,
      title,
      dmpStyle,
      width,
      menuWidth,
      noPadding,
      block,
      dark,
      noUnderline,
      tip,
      dataCyToggle,
      ...passingProps
    },
    ref
  ) => {
    const isIconOnly = (icon && !title) || iconOnly;

    const classNames = cx(
      cl(),
      className,
      cl(dmpStyle),
      { [cl(size)]: size !== 'default' },
      { [cl('block')]: block },
      { [cl('icon')]: icon },
      { [cl('icon-only')]: isIconOnly }
    );

    const menuStyle = {};
    if (width) menuStyle.width = width;
    if (menuWidth) menuStyle.width = menuWidth;

    return (
      <BSDropdown className={classNames} ref={ref} {...passingProps}>
        <DropdownToggle
          className={btnClassName}
          size={size}
          dmpStyle={dmpStyle}
          icon={icon}
          iconOnly={isIconOnly}
          loading={loading}
          noCaret={noCaret}
          title={title}
          width={width}
          block={block}
          noPadding={noPadding}
          noUnderline={noUnderline}
          useAnchor={dmpStyle === 'anchor'}
          dark={dark}
          tip={tip}
          data-cy={dataCyToggle}
        />
        <DropdownMenu style={menuStyle}>{children}</DropdownMenu>
      </BSDropdown>
    );
  }
);

Dropdown.displayName = 'Dropdown';

Dropdown.defaultProps = {
  icon: null,
  iconOnly: false,
  noCaret: false,
  size: 'default',
  dmpStyle: 'default',
  block: false,
  noPadding: false,
  noUnderline: false,
  dark: false,
  loading: false,
};

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  btnClassName: PropTypes.string,
  icon: PropTypes.string,
  iconOnly: PropTypes.bool,
  loading: PropTypes.bool,
  noCaret: PropTypes.bool,
  noPadding: PropTypes.bool,
  noUnderline: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default']),
  title: PropTypes.node,
  dmpStyle: PropTypes.oneOf(['default', 'primary', 'link', 'link-primary', 'link-info', 'anchor']),
  width: PropTypes.number,
  menuWidth: PropTypes.number,
  block: PropTypes.bool,
  dark: PropTypes.bool,
  // Copied from <TooltipButton />
  tip: PropTypes.shape({
    delay: PropTypes.number,
    disabled: PropTypes.bool,
    placement: PropTypes.string,
    positionTop: PropTypes.number,
    positionLeft: PropTypes.number,
    tip: PropTypes.node,
    tipID: PropTypes.string,
    trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
};

const DropdownDots = React.forwardRef(({ className, horizontal, faded, pushRight, ...passingProps }, ref) => (
  <Dropdown
    {...passingProps}
    className={cx(cl('dots'), { [cl('dots-faded')]: faded }, { [cl('dots-push-right')]: pushRight }, className)}
    icon={horizontal ? 'dotsHorizontal' : 'dots'}
    title={null}
    dmpStyle="link"
    iconOnly={true}
    noCaret={true}
    width={null}
    block={false}
    pullRight
    ref={ref}
  />
));

DropdownDots.displayName = 'DropdownDots';

DropdownDots.defaultProps = {
  horizontal: false,
  faded: true,
  pushRight: true,
};

DropdownDots.propTypes = {
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  faded: PropTypes.bool,
  pushRight: PropTypes.bool,
};

export { DropdownDots };

export default Dropdown;
