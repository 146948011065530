import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Overlay, Popover, Radio } from 'react-bootstrap';

import DealAction, { CHECKPOINT_ACTIVITY_MESSAGE } from '@core/enums/DealAction';
import DealRole from '@core/enums/DealRole';
import ReadyCheck, { ReadyCheckResult, ReadyCheckStatus, ReadyLabels } from '@core/models/ReadyCheck';
import Vote from '@core/models/Vote';
import { CHECKPOINT_GROUP_APPROVAL_RULE, CHECKPOINT_GROUP_APPROVAL_RULE_DESCRIPTION } from '@core/models/WorkflowStep';
import { dt } from '@core/utils';

import { Button, Loader } from '@components/dmp';
import Dropdown from '@components/dmp/Dropdown';
import Icon from '@components/dmp/Icon';
import MenuItem from '@components/dmp/MenuItem';

import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

import TeammateBlock from '../TeammateBlock';

const VoteHead = ({ vote, isMe, present }) => {
  let tip = isMe ? 'You' : vote.fullName || vote.email;

  switch (vote.response) {
    case DealAction.REJECT:
      tip += ` ${ReadyLabels.VOTED_NO.toLowerCase()} the ${ReadyLabels.EVENT}`;
      break;
    case DealAction.APPROVE:
      tip += ` ${ReadyLabels.VOTED_YES.toLowerCase()} the ${ReadyLabels.EVENT}`;
      break;
    default:
      if (present) {
        tip += isMe ? ' have ' : ' has ';
        tip += ` not ${ReadyLabels.VOTED.toLowerCase()}`;
      } else {
        tip += ` did not ${ReadyLabels.VOTE.toLowerCase()}`;
      }
      break;
  }

  return (
    <TooltipButton tipID={`vote-${vote.uid}`} tip={tip} placement="bottom" key={vote.uid}>
      <div className={`vote${vote.response ? ' voted-' + vote.response : ''}`}>{vote.initials.slice(0, 2)}</div>
    </TooltipButton>
  );
};

@autoBindMethods
export default class Checkpoint extends Component {
  static propTypes = {
    deal: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    team: PropTypes.object,
    teams: PropTypes.array,
    selectTeam: PropTypes.func.isRequired,
    readyCheck: PropTypes.object,
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);
    this.state = {
      templateTeamCandidates: [],
      notify: true,
      loading: true,
      title: ReadyLabels.EVENT,
      showStatus: false,
      selectedCheckpointGroup: null,
      selectedCheckpointApprovalRule: CHECKPOINT_GROUP_APPROVAL_RULE.UNANIMOUS,
    };
  }

  // React Lifecycle Methods
  componentDidMount() {
    this.setState({ loading: true });
    API.call('getTeamMembers', { teamID: this.props.deal?.team }, (members) => {
      this.setState({ templateTeamCandidates: members, loading: false });
    });
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //Data Helpers
  get canCreate() {
    const { readyCheck, deal } = this.props;
    // Only allow new Checkpoints if 1) none in progress, 2) user is owner, and 3) deal not yet signed
    return !readyCheck && _.get(deal, 'currentDealUser.role') === DealRole.OWNER && !deal.signed;
  }

  get isOwner() {
    const { deal } = this.props;
    return _.get(deal, 'currentDealUser.role') === DealRole.OWNER;
  }

  voteIconProperties(memberVote) {
    if (memberVote === ReadyLabels.VOTED_YES) {
      return { name: 'thumbsup', className: 'fill-green' };
    } else if (memberVote === ReadyLabels.VOTED_NO) {
      return { name: 'thumbsdown', className: 'fill-red' };
    } else {
      return { name: 'checkpoint' };
    }
  }

  // Model Actions
  async start(voters, workflowCheckpointKey, checkpointGroupApprovalRule) {
    const { user, deal, onHide } = this.props;
    const { notify, selectedCheckpointGroup } = this.state;
    const checkpointGroup = deal?.currentWorkflowStep?.checkpointGroup || selectedCheckpointGroup;
    const rc = new ReadyCheck({
      action: DealAction.READY_CHECK,
      user: user.id,
      workflowCheckpointKey,
      checkpointGroupApprovalRule,
    });
    voters.forEach((v) => (rc.votes[v.id] = new Vote({ user: v.id })));

    this.setState({ loading: true });
    await API.call('readyCheck', { rc: rc.json, notify, dealID: deal.dealID }, async () => {
      await Fire.addActivity(
        deal,
        user,
        DealAction.CHECKPOINT_ACTIVITY,
        `${CHECKPOINT_ACTIVITY_MESSAGE.RUN.action}:${checkpointGroup?.name}`
      );
      this.setState({ loading: false });
      onHide();
    });
  }

  async reRun(oldRc) {
    const { user, deal, onHide } = this.props;
    const { notify, selectedCheckpointGroup } = this.state;
    const checkpointGroup = deal?.currentWorkflowStep?.checkpointGroup || selectedCheckpointGroup;

    const rc = new ReadyCheck({
      action: DealAction.READY_CHECK,
      user: user.id,
      workflowCheckpointKey: oldRc.workflowCheckpointKey,
      checkpointGroupApprovalRule: oldRc.checkpointGroupApprovalRule,
    });
    Object.keys(oldRc?.votes).forEach((vid) => (rc.votes[vid] = new Vote({ user: vid })));

    this.setState({ loading: true });
    await API.call('readyCheck', { rc: rc.json, notify, dealID: deal.dealID }, async () => {
      await Fire.addActivity(
        deal,
        user,
        DealAction.CHECKPOINT_ACTIVITY,
        `${CHECKPOINT_ACTIVITY_MESSAGE.RE_RUN.action}:${checkpointGroup?.name}`
      );
      this.setState({ loading: false });
      onHide();
    });
  }

  async finish(rc) {
    const { user, onHide, deal } = this.props;
    const checkpointGroup = deal?.currentWorkflowStep?.checkpointGroup || this.state.selectedCheckpointGroup;
    await Fire.updateReadyCheck(
      deal,
      user.id,
      rc,
      ReadyCheckStatus.COMPLETE,
      `${CHECKPOINT_ACTIVITY_MESSAGE.COMPLETE.action}:${checkpointGroup?.name}`
    );
    onHide();
  }

  async skipActive(rc) {
    const { user, onHide, deal } = this.props;
    const checkpointGroup = deal?.currentWorkflowStep?.checkpointGroup || this.state.selectedCheckpointGroup;
    await Fire.updateReadyCheck(
      deal,
      user.id,
      rc,
      ReadyCheckStatus.SKIPPED,
      `${CHECKPOINT_ACTIVITY_MESSAGE.SKIP.action}:${checkpointGroup?.name}`
    );
    onHide();
  }

  async skipInactive(workflowCheckpointKey, checkpointGroupApprovalRule) {
    const { user, deal, onHide } = this.props;
    const { notify, selectedCheckpointGroup } = this.state;
    const checkpointGroup = deal?.currentWorkflowStep?.checkpointGroup || selectedCheckpointGroup;
    const rc = new ReadyCheck({
      action: DealAction.READY_CHECK,
      user: user.id,
      workflowCheckpointKey,
      checkpointGroupApprovalRule,
      votes: [],
    });

    this.setState({ loading: true });
    API.call('readyCheck', { rc: rc.json, notify, dealID: deal.dealID }, async () => {
      await Fire.updateReadyCheck(
        deal,
        user.id,
        rc,
        ReadyCheckStatus.SKIPPED,
        `${CHECKPOINT_ACTIVITY_MESSAGE.SKIP.action}:${checkpointGroup?.name}`
      );
      this.setState({ loading: false });
      onHide();
    });
  }

  async cancel(rc) {
    const { user, deal, onHide } = this.props;
    const checkpointGroup = deal?.currentWorkflowStep?.checkpointGroup || this.state.selectedCheckpointGroup;
    await Fire.updateReadyCheck(
      deal,
      user.id,
      rc,
      ReadyCheckStatus.CANCELLED,
      `${CHECKPOINT_ACTIVITY_MESSAGE.CANCEL.action}:${checkpointGroup?.name}`
    );
    onHide();
  }

  async vote(rc, response) {
    const { deal, user, toggle } = this.props;
    const checkpointGroup = deal?.currentWorkflowStep?.checkpointGroup || this.state.selectedCheckpointGroup;

    // setting rcStatusShown to false before the vote is recorded ensures that the status will show again
    // because props will update via UNSAFE_componentWillReceiveProps() and will be handled similarly to initial contract load
    // this is desired because the message will usually change (e.g., from "you need to vote" to "all set")
    await Fire.voteReadyCheck(deal, user.id, rc, response, checkpointGroup?.name).then(() => {
      toggle(true);
    });

    const votesWithoutResponse = _.filter(rc.votes, (vote) => !vote.response);
    if (
      response === ReadyLabels.VOTE_NO.toLowerCase() ||
      rc.checkpointGroupApprovalRule === CHECKPOINT_GROUP_APPROVAL_RULE.FIRST ||
      votesWithoutResponse.length == 1
    ) {
      await API.call('voteReadyNotification', {
        dealID: deal.dealID,
        team: deal.team,
        response: response,
        checkpointGroupName: checkpointGroup?.name,
      });
    }
  }

  //UI toggles
  async showStatus() {
    // show panel
    await this.props.toggle(true);
    // wait for anchor to be there in order to show popover
    // generally not good to use setTimeout but it's ok in this case

    if (this._isMounted) this.setState({ showStatus: true });
  }

  onCheckpointSelect(checkpointGroup) {
    this.setState({
      selectedCheckpointGroup: checkpointGroup,
    });
  }

  onSelectedCheckpointApprovalRuleChange(e) {
    const selectedCheckpointApprovalRule = e.target.value;
    this.setState({ selectedCheckpointApprovalRule });
  }

  //UI Renders
  render() {
    const { readyCheck, deal, user, id, container, show, onHide, target, title } = this.props;

    // we want to only show the past readychecks to the current user if that user was either owner or voter
    let past = _.filter(deal.checks, (c) => c !== readyCheck && (c.user === user.id || c.votes[user.id] != null));
    // show most recent at top (reverse order)
    past = _.sortBy(past, 'date').reverse();

    const renderPanelItem = this.canCreate || !!readyCheck;

    return (
      <div className="ready-check-wrapper">
        {this.renderVotingAction()}

        <DealPanel id={id} onHide={onHide} show={show} target={target} title={title} container={container}>
          {renderPanelItem ? (
            <div className="panel-scroll">
              {!deal?.workflow?.isOutlaw ? this.renderTemplatedCheckpoint() : this.renderAdHocCheckpoint()}
            </div>
          ) : (
            this.isOwner && <DealPanelItem>No Checkpoints on this workflow step</DealPanelItem>
          )}
        </DealPanel>
      </div>
    );
  }

  renderTemplatedCheckpoint() {
    const { loading, templateTeamCandidates } = this.state;
    const { deal, readyCheck } = this.props;
    const { currentWorkflowStep } = deal;

    if (!currentWorkflowStep?.checkpoint) {
      return <DealPanelItem>No Checkpoints on this workflow step</DealPanelItem>;
    }

    const { participants, checkpointGroupKey } = currentWorkflowStep.checkpointGroup;
    const participantMembers = templateTeamCandidates?.filter((tc) => participants.includes(tc.id));
    const workflowCheckpointKey = `${currentWorkflowStep.key}:${checkpointGroupKey}`;
    const checkpointGroupApprovalRuleText =
      CHECKPOINT_GROUP_APPROVAL_RULE_DESCRIPTION[currentWorkflowStep.checkpointGroupApprovalRule];

    const stepChecks = deal.checks?.filter((check) => check.workflowCheckpointKey === workflowCheckpointKey);

    const [latestCheck] = _.orderBy(stepChecks, ['date'], ['desc']);

    if (readyCheck && workflowCheckpointKey === readyCheck?.workflowCheckpointKey) {
      return this.renderActiveCheckpoint({
        rc: readyCheck,
        workflowStep: currentWorkflowStep,
        checkpointGroupApprovalRuleText,
      });
    }

    if (latestCheck?.status === ReadyCheckStatus.COMPLETE) {
      return this.renderActiveCheckpoint({
        rc: latestCheck,
        isCompleted: true,
        workflowStep: currentWorkflowStep,
        checkpointGroupApprovalRuleText,
      });
    }

    return (
      <DealPanelItem key={currentWorkflowStep.key} borderBottom active className={`template-checkpoint`}>
        <div className="header">
          <div className="title">
            <div className="name">{currentWorkflowStep.name}</div>
            <div className="description">{checkpointGroupApprovalRuleText}</div>
            {latestCheck?.status === ReadyCheckStatus.SKIPPED && (
              <span className={`statusTime skipped`}>
                {latestCheck.result.label} on {latestCheck.date?.toLocaleDateString()} @{' '}
                {latestCheck.date?.toLocaleTimeString()}
              </span>
            )}
          </div>
          {this.isOwner && (
            <div className="admin-actions">
              {loading && <Loader />}
              <div className="spacer" />
              {currentWorkflowStep.checkpointSkippable && !(latestCheck?.status === ReadyCheckStatus.SKIPPED) && (
                <Button
                  size="small"
                  dmpStyle="link"
                  onClick={async () =>
                    await this.skipInactive(workflowCheckpointKey, currentWorkflowStep.checkpointGroupApprovalRule)
                  }
                >
                  {ReadyLabels.SKIP}
                </Button>
              )}
              <Button
                size="small"
                dmpStyle="primary"
                onClick={() =>
                  this.start(participantMembers, workflowCheckpointKey, currentWorkflowStep.checkpointGroupApprovalRule)
                }
              >
                {ReadyLabels.RUN}
              </Button>
            </div>
          )}
        </div>
        <div className="votes">
          {participantMembers.map((participant) => {
            return (
              <div key={participant.id} className="checkpoint-participant">
                <TeammateBlock key={participant.id} teammate={participant} />
                <Icon name={'checkpoint'} faded size={'large'} />
              </div>
            );
          })}
        </div>
      </DealPanelItem>
    );
  }

  renderAdHocCheckpoint() {
    const { loading, selectedCheckpointGroup, templateTeamCandidates, selectedCheckpointApprovalRule } = this.state;
    const { team, readyCheck } = this.props;
    const participantMembers = templateTeamCandidates?.filter((tc) =>
      selectedCheckpointGroup?.participants.includes(tc.id)
    );

    if (readyCheck) {
      const checkpointGroupApprovalRuleText =
        CHECKPOINT_GROUP_APPROVAL_RULE_DESCRIPTION[selectedCheckpointApprovalRule];
      return this.renderActiveCheckpoint({
        rc: readyCheck,
        checkpointGroupApprovalRuleText,
      });
    }

    return (
      this.isOwner && (
        <DealPanelItem className={`checkpoint-group-selector`} borderBottom>
          <span>Checkpoint group</span>
          <Dropdown
            className={'checkpoint-group-dropdown'}
            disabled={loading || team?.checkpointGroups?.length <= 0}
            onSelect={this.onCheckpointSelect}
            id={`checkpoint-group`}
            title={_.get(selectedCheckpointGroup, 'name', 'None Selected')}
            size="small"
          >
            {team?.checkpointGroups?.map((c) => {
              return (
                <MenuItem
                  key={c.checkpointGroupKey}
                  eventKey={c}
                  active={
                    selectedCheckpointGroup && c.checkpointGroupKey === selectedCheckpointGroup?.checkpointGroupKey
                  }
                >
                  {c.name}
                </MenuItem>
              );
            })}
          </Dropdown>
          {participantMembers?.length > 0 && (
            <>
              <span>Participants</span>
              {participantMembers?.map((participant) => {
                return (
                  <div key={participant.id} className="checkpoint-participant" data-cy="checkpoint-participant">
                    <TeammateBlock key={participant.id} teammate={participant} />
                    <Icon name={'checkpoint'} faded size={'large'} />
                  </div>
                );
              })}
              <span>Approval rule</span>
              <div className="radio-button-group">
                <Radio
                  name="approvalRule"
                  checked={selectedCheckpointApprovalRule === CHECKPOINT_GROUP_APPROVAL_RULE.UNANIMOUS}
                  onChange={this.onSelectedCheckpointApprovalRuleChange}
                  value={CHECKPOINT_GROUP_APPROVAL_RULE.UNANIMOUS}
                >
                  Unanimous
                </Radio>
                <Radio
                  name="approvalRule"
                  checked={selectedCheckpointApprovalRule === CHECKPOINT_GROUP_APPROVAL_RULE.FIRST}
                  onChange={this.onSelectedCheckpointApprovalRuleChange}
                  value={CHECKPOINT_GROUP_APPROVAL_RULE.FIRST}
                >
                  First response
                </Radio>
              </div>
              <div className="admin-action">
                <div className="spacer" />
                <Button
                  size="small"
                  dmpStyle="primary"
                  onClick={() => this.start(participantMembers, null, selectedCheckpointApprovalRule)}
                  data-cy="btn-run-checkpoint"
                >
                  {ReadyLabels.RUN}
                </Button>
              </div>
            </>
          )}
        </DealPanelItem>
      )
    );
  }

  renderActiveCheckpoint({ rc, isCompleted = false, workflowStep, checkpointGroupApprovalRuleText }) {
    const { loading, showStatus } = this.state;
    // compile list of users
    const { deal, user } = this.props;
    const votes = rc.getVotes(deal);

    // ReadyCheck tooltip depends on the user's role and the state of the ReadyCheck
    const isVoter = rc.votes[user.id] != null;
    const owner = deal.getUserByID(rc.user);
    const isOwner = user.id === rc.user;
    // show purple status label if ReadyCheck not yet complete
    const stat = rc.result === ReadyCheckResult.INCOMPLETE ? 'setup' : rc.result.label.toLowerCase();

    let headline,
      instructions,
      mask = false;

    headline = `${ReadyLabels.EVENT} ${rc.result.label.toLowerCase()}`;
    if (isOwner) {
      // message to owner depends on whether all votes are in or not
      if (rc.result === ReadyCheckResult.INCOMPLETE)
        instructions = `You've ${ReadyLabels.ACTION_PAST.toLowerCase()} a ${
          ReadyLabels.EVENT
        }. Wait for everyone to respond before continuing.`;
      else instructions = `This ${ReadyLabels.EVENT} has ${rc.result.label.toLowerCase()}.`;
    } else if (isVoter) {
      // message to voters depends on whether THEY have voted or not
      if (rc.votes[user.id].response == null) {
        instructions = `${owner.get(
          'fullName'
        )} has asked you to review this ${dt}. Editing is disabled until all responses are received and this ${
          ReadyLabels.EVENT
        } is complete.`;
      } else {
        headline = 'All set for now';
        instructions = `No further action is required on your part until the ${ReadyLabels.EVENT} is complete.`;
      }
    }
    // Non-voting user is looking at a ReadyCheck. Nothing to do; just tell them to wait
    else {
      mask = true;
      instructions = `${owner.get('fullName')} is running a ${
        ReadyLabels.EVENT
      }. No action is required on your part at this time.`;
    }

    return (
      <DealPanelItem key={rc.id} borderBottom active={!isCompleted}>
        <div className={`active-rc${mask ? ' masked' : ''} template-checkpoint`} ref="activeRC">
          <div className="header" ref="activeTitle">
            <div className="title">
              <div className="name">{workflowStep?.name}</div>
              <div className="description">{checkpointGroupApprovalRuleText}</div>
              {!mask && (rc.status === ReadyCheckStatus.COMPLETE || rc.status === ReadyCheckStatus.SKIPPED) && (
                <span className={`statusTime ${stat}`}>
                  {rc.result.label} on {rc.date?.toLocaleDateString()} @ {rc.date?.toLocaleTimeString()}
                </span>
              )}
            </div>
            {!mask && !isCompleted && (
              <Overlay
                container={this.refs.activeRC}
                onHide={() => this.setState({ showStatus: false })}
                placement="bottom"
                rootClose
                shouldUpdatePosition
                show={showStatus}
                target={this.refs.activeTitle}
              >
                <Popover
                  className="dark small"
                  title={headline}
                  id="pop-active-rc-status"
                  style={{ marginLeft: '-10px' }}
                >
                  <span className="instructions">{instructions}</span>
                </Popover>
              </Overlay>
            )}
            {mask && <span className="statusLabel inactive">{ReadyCheckResult.INCOMPLETE.label}</span>}
            {user.id === rc.user &&
              rc.status !== ReadyCheckStatus.COMPLETE && ( // render administrative actions only if current user is owner
                <div className="admin-actions">
                  {loading && <Loader />}
                  <div className="spacer" />
                  {/* {rc.result === ReadyCheckResult.INCOMPLETE && workflowStep?.checkpointSkippable &&
                <Button size="small" dmpStyle='link' onClick={async () => await this.skipActive(rc)}>
                  {ReadyLabels.SKIP}
                </Button>
              } */}

                  {rc.result === ReadyCheckResult.INCOMPLETE ? (
                    <Button size="small" onClick={async () => await this.cancel(rc)}>
                      Stop
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      dmpStyle="primary"
                      disabled={loading || rc.result === ReadyCheckResult.INCOMPLETE}
                      onClick={async () => await this.finish(rc)}
                    >
                      Stop
                    </Button>
                  )}

                  {isCompleted && rc.result === ReadyCheckResult.FAILED && (
                    <Button size="small" dmpStyle="primary" disabled={loading} onClick={() => this.reRun(rc)}>
                      {ReadyLabels.RE_RUN}
                    </Button>
                  )}
                </div>
              )}
            {user.id === rc.user &&
              rc.status === ReadyCheckStatus.COMPLETE &&
              rc.result === ReadyCheckResult.FAILED && ( // render administrative actions only if current user is owner
                <div className="admin-actions">
                  {loading && <Loader />}
                  <div className="spacer" />
                  <Button size="small" dmpStyle="primary" disabled={loading} onClick={() => this.reRun(rc)}>
                    {ReadyLabels.RE_RUN}
                  </Button>
                </div>
              )}
            {rc.status === ReadyCheckStatus.COMPLETE &&
              rc.result === ReadyCheckResult.PASSED && ( // render administrative actions only if current user is owner
                <div className="admin-actions">
                  <div className="spacer" />
                  <Button size="small" dmpStyle="primary" disabled>
                    {rc.result.label}
                  </Button>
                </div>
              )}
          </div>
          {mask && <div className="instructions">{instructions}</div>}
          {!mask && (
            <div className="votes">
              {votes.map((participant) => {
                return (
                  <div key={participant.uid} className="checkpoint-participant">
                    <TeammateBlock key={participant.uid} teammate={participant} />
                    {!participant.response ? (
                      <Icon name={'checkpoint'} faded size={'large'} />
                    ) : (
                      <Icon
                        name={participant.response === 'approve' ? 'thumbsup' : 'thumbsdown'}
                        faded
                        size={'large'}
                        className={participant.response === 'approve' ? 'thumbsup' : 'thumbsdown'}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </DealPanelItem>
    );
  }

  renderInactiveAction() {
    const { templateTeamCandidates } = this.state;
    const { deal } = this.props;
    const { currentWorkflowStep } = deal;

    if (!currentWorkflowStep?.checkpoint) return null;

    const { participants, checkpointGroupKey } = currentWorkflowStep.checkpointGroup;
    const participantMembers = templateTeamCandidates?.filter((tc) => participants.includes(tc.id));
    const workflowCheckpointKey = `${currentWorkflowStep.key}:${checkpointGroupKey}`;

    const stepChecks = deal.checks?.filter((check) => check.workflowCheckpointKey === workflowCheckpointKey);
    const [latestCheck] = _.orderBy(stepChecks, ['date'], ['desc']);

    const isCompleted = latestCheck?.status === ReadyCheckStatus.COMPLETE;
    const isSkipped = latestCheck?.status === ReadyCheckStatus.SKIPPED;

    if ((isCompleted && latestCheck?.result === ReadyCheckResult.PASSED) || isSkipped) return null;

    return (
      <div className="action-wrapper voting-actions" data-cy="voting-actions">
        {this.isOwner ? (
          <div className="action passive" data-cy="action-passive">
            <div className="info">
              <div className="title">{ReadyLabels.EVENT} is required to progress</div>
              <div className="sub inactive">Editing is disabled</div>
            </div>
            {currentWorkflowStep.checkpointSkippable && (
              <TooltipButton tipID="tip-action-skip" tip={`Tap to ${ReadyLabels.SKIP.toLowerCase()}`}>
                <Button
                  className="skip"
                  dmpStyle="link"
                  onClick={async () =>
                    await this.skipInactive(workflowCheckpointKey, currentWorkflowStep.checkpointGroupApprovalRule)
                  }
                >
                  {`Skip`}
                </Button>
              </TooltipButton>
            )}
            {isCompleted && latestCheck?.result === ReadyCheckResult.FAILED ? (
              <TooltipButton tipID="tip-action-retry" tip={`Tap to ${ReadyLabels.RE_RUN.toLowerCase()}`}>
                <Button className="retry" dmpStyle="primary" onClick={() => this.reRun(latestCheck)}>
                  {`Retry`}
                </Button>
              </TooltipButton>
            ) : (
              <TooltipButton tipID="tip-action-run" tip={`Tap to ${ReadyLabels.RUN.toLowerCase()}`}>
                <Button
                  className="run"
                  dmpStyle="primary"
                  onClick={() =>
                    this.start(
                      participantMembers,
                      workflowCheckpointKey,
                      currentWorkflowStep.checkpointGroupApprovalRule
                    )
                  }
                  data-cy="btn-run-checkpoint"
                >
                  {`Run`}
                </Button>
              </TooltipButton>
            )}
          </div>
        ) : (
          <div className="action passive" data-cy="action-passive">
            <div className="info">
              <div className="title">Document is pending approval</div>
              <div className="sub inactive">Please check back later</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderVotingAction() {
    const { readyCheck, user, deal } = this.props;

    if (!readyCheck) return this.renderInactiveAction();

    const vote = readyCheck?.votes[user.id];
    const owner = deal.getUserByID(readyCheck?.user);
    const voted = vote != null && vote?.response != null;

    return (
      <div className="action-wrapper voting-actions" data-cy="voting-actions">
        {vote /* User has not voted yet */ && (
          <div className="action voting">
            <div className="info">
              <div className="title">
                {vote.response
                  ? `You ${vote.displayResponse.toLowerCase()} this ${ReadyLabels.EVENT}`
                  : `${owner.get('firstName')} is awaiting your review`}
              </div>
              <div className={`sub${voted ? ' inactive' : ''}`} onClick={() => (voted ? null : this.showStatus())}>
                {!voted
                  ? 'Tap for more info'
                  : vote.response === DealAction.REJECT
                  ? 'Comment on relevant sections to explain'
                  : 'No further action required'}
              </div>
            </div>

            {user.id === readyCheck.user && (
              <TooltipButton tipID="tip-vote-cancel" tip={`Tap to ${ReadyLabels.ACTION_CANCEL.toLowerCase()}`}>
                <Button className="stop" onClick={async () => await this.cancel(readyCheck)}>
                  {`Stop`}
                </Button>
              </TooltipButton>
            )}
            <TooltipButton
              tipID="tip-vote-no"
              tip={vote.response === DealAction.REJECT ? 'Tap to reset' : `Tap to ${ReadyLabels.VOTE_NO.toLowerCase()}`}
            >
              <Button
                className={`reject${voted ? ` voted-${vote.response}` : ''}`}
                onClick={async () =>
                  await this.vote(readyCheck, vote.response === DealAction.REJECT ? null : DealAction.REJECT)
                }
                data-cy="btn-voted-reject"
              >
                <span className="icon">
                  <Icon name={'thumbsdown'} faded size={'large'} />
                </span>
              </Button>
            </TooltipButton>

            <TooltipButton
              tipID="tip-vote-yes"
              tip={
                vote.response === DealAction.APPROVE ? 'Tap to reset' : `Tap to ${ReadyLabels.VOTE_YES.toLowerCase()}`
              }
            >
              <Button
                className={`approve${voted ? ` voted-${vote.response}` : ''}`}
                onClick={async () =>
                  await this.vote(readyCheck, vote.response === DealAction.APPROVE ? null : DealAction.APPROVE)
                }
                data-cy="btn-voted-approve"
              >
                <span className="icon">
                  <Icon name={'thumbsup'} faded size={'large'} />
                </span>
              </Button>
            </TooltipButton>
          </div>
        )}

        {!vote && (
          <div className="action passive" data-cy="action-passive">
            <div className="info">
              <div className="title">
                {ReadyLabels.EVENT} {ReadyCheckResult.INCOMPLETE.label.toLowerCase()}
              </div>
              <div className="sub inactive">Editing is disabled</div>
            </div>
            {user.id !== readyCheck.user ? (
              <Button className="locked">
                <span className="icon">
                  <Icon name={'nope'} faded size={'large'} />
                </span>
              </Button>
            ) : (
              <TooltipButton tipID="tip-vote-cancel" tip={`Tap to ${ReadyLabels.ACTION_CANCEL.toLowerCase()}`}>
                <Button
                  className="stop"
                  onClick={async () => await this.cancel(readyCheck)}
                  data-cy="btn-stop-checkpoint"
                >
                  {`Stop`}
                </Button>
              </TooltipButton>
            )}
          </div>
        )}
      </div>
    );
  }
}
