import { get } from 'lodash';

import SectionType from '../enums/SectionType';
import { formatOrder } from '../utils/OrderFormatter';
import { defaultNumberFormat } from './DealStyle';
import Section from './Section';

export default class Source extends Section {
  constructor(json, deal) {
    super(json, deal);
  }

  //return the last child of the most deeply nested section under the current node
  //for example if this is section 4, this might return section 4.11.ix
  //(if that's the last thing before section 5)

  get lastChild() {
    // if (!this.sourceChildren || this.sourceChildren.length == 0) return null;
    let section = this;
    while (section.sourceChildren && section.sourceChildren.length > 0) {
      section = section.sourceChildren[section.sourceChildren.length - 1];
    }
    return section;
  }

  //convenience method to compose a display line for this section's title in a list of sections
  //e.g., "3. Services"
  get displayTOC() {
    if (this.sectiontype == SectionType.APPENDIX) return this.displayTitle;
    let s = '--'.repeat(this.indentLevel) + (this.indentLevel ? ' ' : '');
    const num = this.sourceNumber(true);
    if (num) s += num + ' ';
    s += this.displayname || '(untitled)';
    return s;
  }

  sourceNumber(full = null) {
    if (this.sectiontype == SectionType.APPENDIX) return this.displayTitle;
    if (this.sourceParent == null || !this.showOrder) return '';

    let sec = this;
    let format = this.numberFormat;

    //if the document depth goes beyond the style info, just use number and period, i.e., "3."
    if (!format) format = { type: 'number', post: '.' };

    const formatter = (sec) => {
      const index = sec.numberedSibs.indexOf(sec);
      // If the current section is not numbered, it will not be found in the above filter()
      // This is necessary to avoid child numbered sections of an unnumbered parent from rendering blank formats, e.g., ()4. or .4.
      if (index === -1) return '';
      format = sec.numberFormat;
      //if the document depth goes beyond the style info, just use number and period, i.e., "3."
      if (!format) format = { type: 'number', post: '.' };
      return formatOrder(index, format);
    };

    if ((full || format.full) && !format.docx && format.type !== 'unordered') {
      const pieces = [];
      const master = sec.appendix || this.deal.root;
      while (sec != master) {
        pieces.push(formatter(sec));
        sec = sec.sourceParent;
      }
      return pieces.reverse().join('');
    } else {
      return formatter(this);
    }
  }

  get numberFormat() {
    let numbering;

    // If there's custom numbering defined at the Section level, use that
    // This is currently only used for unordered (bulleted) sections (see SectionToolbar)
    // LISTs use Section.style.numbering to specify *child* (ITEM) formats,
    // which are rendered via the Item.numberFormat override, so we don't need to worry about those cases here
    if (!this.isList) {
      numbering = get(this.style, 'numbering', null);
      if (numbering) {
        if (Array.isArray(numbering)) {
          return numbering[0] || defaultNumberFormat();
        } else {
          return numbering;
        }
      }
    }

    // Next, if this section is on an appendix, look for an appendix-specific numbering style
    const appendix = this.appendix;
    if (appendix) {
      numbering = get(appendix, `style.numbering[${this.indentLevel}]`);
      if (numbering) return numbering;
    }

    // Finally (normal case for most sections), default to contract's numbering style as defined in Deal.style.numbering
    // Note, this also includes lists, meaning a parent LIST container (title/body) can be numbered according to deal/appendix numbering
    // But still define its own internal number format
    // There is also some edge case (occasionally in watcher among others?) where this.deal.style is accessed before style is loaded
    // So when in doubt, fall back to default numbering, which is simple numbering followed by period, i.e., 1. 2. 3.
    return get(this.deal, `style.numbering[${this.indentLevel}]`, defaultNumberFormat());
  }
}
