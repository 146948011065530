import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Dropdown, MenuItem } from '@components/dmp';

@autoBindMethods
export default class FilterList extends Component {
  static defaultProps = {
    title: 'Select...',
    filterGroups: [],
    filterID: null,
  };

  // Selected filter can be (optionally) specified via filterID and (optionally) teamID
  static propTypes = {
    title: PropTypes.string,
    id: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    filterGroups: PropTypes.array,
    filterID: PropTypes.string,
  };

  renderFilterGroup({ title, filters }, idx) {
    const { filterID } = this.props;
    const items = [];

    if (idx !== 0) items.push(<MenuItem divider />);
    items.push(
      <MenuItem header ellipsis>
        {title}
      </MenuItem>
    );

    if (filters.length > 0) {
      filters.map((filter) =>
        items.push(
          <MenuItem eventKey={filter} key={filter.filterID} active={filterID === filter.filterID}>
            {filter.title}
          </MenuItem>
        )
      );
    } else {
      items.push(<MenuItem disabled>No saved filters</MenuItem>);
    }

    return items;
  }

  render() {
    const { id, onSelect, filterGroups, title } = this.props;
    const isEmpty = !filterGroups.length || (filterGroups.length === 1 && !filterGroups[0].filters.length);

    if (isEmpty) {
      return <div className="saved-filter-list empty">No saved filters</div>;
    }

    return (
      <Dropdown
        id={id}
        title={title}
        size="small"
        onSelect={onSelect}
        menuWidth={270}
        block
        data-cy="saved-filter-list"
      >
        {filterGroups.map(this.renderFilterGroup)}
      </Dropdown>
    );
  }
}
