import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DealRole from '@core/enums/DealRole';
import InviteStatus from '@core/enums/InviteStatus';
import Deal from '@core/models/Deal';
import User from '@core/models/User';

import { Dropdown, MenuItem } from '@components/dmp';

export const SignatureActions = {
  ADD: 'addUser',
  DETAILS: 'updateDetails',
  SWAP: 'swapSigner',
  SWAP_NEW: 'swapNew',
  SHARE: 'share',
  CLEAR_SIG: 'clearSig',
  REMOVE_SIGNER: 'removeSigner',
  REMOVE: 'remove',
};

@autoBindMethods
export default class SignatureMenu extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal),
    dealUser: PropTypes.object,
    handleAction: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    partyID: PropTypes.string,
    user: PropTypes.instanceOf(User),
    title: PropTypes.string,
    pullRight: PropTypes.bool,
    dark: PropTypes.bool,
  };

  static defaultProps = {
    title: 'Actions',
    pullRight: true,
    dark: false,
  };

  constructor(props) {
    super(props);

    this.dd = createRef();
  }

  get menu() {
    const { deal, user, dealUser } = this.props;

    const role = _.get(deal, 'currentDealUser.role');
    const isSelf = !!dealUser && _.get(dealUser, 'uid') === _.get(user, 'id');
    const signed = _.get(dealUser, 'startedSigning');
    const canAdmin = role === DealRole.OWNER;
    const canShare = dealUser && user && dealUser.canBeInvitedBy(user);
    const shared = _.get(dealUser, 'inviteStatus') === InviteStatus.INVITED || _.get(dealUser, 'guest') === true;

    const actions = [];
    const key = () => `current-${actions.length + 1}`;

    // If deal is fully fully executed (whether native or 3PP) *nothing* can be done with respect to signatures, period
    if (deal.signed) return null;

    // Only show Add new user option at *root* menu if there's none currently assigned and user can admin
    if (!dealUser) {
      return canAdmin ? this.changeMenu : null;
    }

    if (signed) {
      if (isSelf || canAdmin) {
        actions.push(
          <MenuItem key={key()} eventKey={SignatureActions.CLEAR_SIG}>
            Remove signature
          </MenuItem>
        );
      }
    } else {
      // We only need the user's name in the menu if it's NOT signed
      // Otherwise, in a 3PP context, it repeats the name twice (and on native it's simply unnecessary)
      if (deal.native) {
        actions.push(
          <MenuItem key={key()} header ellipsis>
            {dealUser.fullName || dealUser.email}
          </MenuItem>
        );
      }

      if (canAdmin || isSelf) {
        actions.push(
          <MenuItem key={key()} eventKey={SignatureActions.DETAILS}>
            Update details
          </MenuItem>
        );
      }

      if (!canAdmin && isSelf) {
        // Don't show a non-admin (recipient) the list of other users on the deal
        // because they're just other poeple at the sender's org who this person likely won't recognize
        // so "Change signer" here means "Add a new user"
        actions.push(
          <MenuItem key={key()} eventKey={SignatureActions.SWAP_NEW}>
            Change signer
          </MenuItem>
        );
      }

      if (canShare) {
        const shareCTA = shared ? 'Re-share with user' : 'Share with user';
        actions.push(
          <MenuItem key={key()} eventKey={SignatureActions.SHARE}>
            {shareCTA}
          </MenuItem>
        );
      }

      if (canAdmin) {
        actions.push(
          <MenuItem key={key()} eventKey={SignatureActions.REMOVE_SIGNER}>
            Remove as signer
          </MenuItem>
        );
        if (!isSelf) {
          actions.push(
            <MenuItem key={key()} eventKey={SignatureActions.REMOVE}>
              Delete user
            </MenuItem>
          );
        }
        // Admin can choose another signer from list of non-party users
        actions.push(...this.changeMenu);
      }
    }

    // Don't return an empty menu!
    if (!actions.length) return null;

    return actions;
  }

  get changeMenu() {
    const { deal, dealUser } = this.props;

    const otherSigners = _.filter(deal.users, (du) => du.key !== _.get(dealUser, 'key'));
    // const otherSigners = _.filter(deal.users, {partyID: null});
    const addAction = dealUser ? SignatureActions.SWAP_NEW : SignatureActions.ADD;

    const actions = [];
    const key = () => `change-${actions.length + 1}`;

    if (dealUser) {
      actions.push(<MenuItem divider key={key()} />);
    }

    if (otherSigners.length > 0) {
      actions.push(
        <MenuItem header key={key()}>
          {dealUser ? 'Assign different user' : 'Assign user'}
        </MenuItem>
      );
    }

    _.forEach(otherSigners, (du) => {
      actions.push(
        <MenuItem key={key()} onSelect={() => this.handleAction(SignatureActions.SWAP, du)} info={du.email}>
          {du.fullName}
        </MenuItem>
      );
    });

    if (otherSigners.length > 0) {
      actions.push(<MenuItem key={key()} divider />);
    }

    actions.push(
      <MenuItem key={key()} onSelect={() => this.handleAction(addAction)} icon="plus2">
        Assign new user
      </MenuItem>
    );

    return actions;
  }

  forceClose() {
    const toggle = _.get(this.dd, 'current.handlers.onToggle');
    if (typeof toggle === 'function') toggle();
  }

  // Wrap the handleAction function passed in props,
  // so that we can also send back the swapping DealUser if necessary
  handleAction(action, swapDealUser = null) {
    // Manual submenu actions (onSelect()) still trigger this function
    // so this avoids an unnecessary call
    if (!action) return;

    const { handleAction, dealUser } = this.props;

    if (action === SignatureActions.CLEAR_SIG) {
      handleAction(action, swapDealUser, dealUser.key);
    } else {
      handleAction(action, swapDealUser);
    }
    this.forceClose();
  }

  render() {
    const { deal, id, title, pullRight, dark } = this.props;

    // STILL IN DISCUSSION:
    // For a native deal, nothing is possible if a deal is fully done -- show no actions whatsoever
    // For external/3PP deals, still show menu after signing so that owners can still clear signatures
    // (This will then become impossible again once a new version is created and the deal is finalized, ie pdfElements flushed)
    if (deal.signed /* && !deal.hasVersions*/) return null;

    // Don't render anything if there are no options!
    const menu = this.menu;
    if (!menu) return null;

    return (
      <Dropdown
        ref={this.dd}
        className="signature-menu"
        id={id}
        onClick={(e) => e.stopPropagation()}
        onSelect={this.handleAction}
        pullRight={pullRight}
        dmpStyle={dark ? 'link' : 'link-info'}
        size="small"
        menuWidth={240}
        title={title}
        dark={dark}
        noPadding
        noUnderline
        data-cy="signature-menu"
      >
        {menu}
      </Dropdown>
    );
  }
}
