import React, { createRef } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import DealRole from '@core/enums/DealRole';
import { ReadyLabels } from '@core/models/ReadyCheck';
import { Dt, dt } from '@core/utils';

import { Alert, DropdownDots, MenuItem } from '@components/dmp';

import Dealer from '@root/Dealer';

export const SectionActions = {
  CLEAR_SIG: 'clearSig',
  CLEAR_LIST: 'clearList',
  VERSIONS: 'versions',
  COMMENT: 'comment',

  EDIT: 'edit',
  PROPOSE: 'propose',
  REJECT: 'reject',
  APPROVE: 'approve',
  UNDO: 'undo',
  REVERT: 'revert',

  BEFORE: 'before',
  AFTER: 'after',
  SUB: 'sub',

  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right',

  DELETE: 'delete',
  DELETE_HARD: 'delete-hard',

  NUMBERING: 'numbering',
  PAGE_BREAK: 'page-break',

  RESELECT_LIST: 'reselect-list',
};

const SectionMenu = (props) => {
  const { section, handleAction, user, canReselectListItems } = props;
  const isMobile = Dealer.mobile;

  // SectionMenu is only instantiated once we've confirmed currentDealUser exists,
  // so "me" will always be populated
  const me = section.deal.currentDealUser;
  const isListItem = section.isItem && section.list;
  const isBlock = section.isBlock;

  // Editing menu is still visible when deal is locked or a ReadyCheck is in progress
  const locked = section.deal.locked;
  const rc = section.deal.readyCheck;
  const isCheckpoint = section.deal.currentWorkflowStep?.checkpoint;

  // Now we build out menu item by item depending on deal state, content and user role
  // The helper key() function is just to prevent React errors
  const actions = [];
  const key = () => actions.length + 1;
  const needsLogin = _.get(user, 'anonymous') === true;

  // This is a hack but couldn't find a better way and the Dropdown API is opaque...
  // If we manually trigger an event, the Dropdown Menu doesn't close.
  // But we can use refs to target the Dropdown directly, and onToggle() is exposed and seems to do what we want
  // ...so... <<shrug>> :-)
  const dd = createRef();
  const forceClose = (action) => {
    handleAction(action);
    const toggle = _.get(dd, 'current.handlers.onToggle');
    if (typeof toggle === 'function') toggle();
  };

  // Force guests to login; no other options
  if (needsLogin) {
    const loginPath = `/login/?redirect=${encodeURIComponent(window.location.pathname)}`;
    actions.push(
      <MenuItem key={key()} header className="need-login">
        <Alert icon="nope" inline size="small" title="You are currently a guest" dmpStyle="dark">
          In order to {me.role === DealRole.PROPOSER ? 'propose' : 'make'} changes you need to create a free account on
          Outlaw.
          <br />
          <Link to={loginPath}>Tap here</Link> to continue.
        </Alert>
      </MenuItem>
    );
  } else {
    // If deal is locked (in signing) it's the first (read-only) item in menu
    if (locked && !rc && !isCheckpoint) {
      actions.push(
        <MenuItem key={key()} header className="clear-sig">
          <Alert icon="nope" inline size="small" title={`${Dt} is in signing`} dmpStyle="dark">
            Clear signatures to re-enable {dt} editing.
            <br />
            <a data-cy="clear-signature" onClick={() => forceClose(SectionActions.CLEAR_SIG)}>
              Clear signatures
            </a>
          </Alert>
        </MenuItem>
      );
    }
    // Similar for an active ReadyCheck
    if (rc != null || isCheckpoint)
      actions.push(
        <MenuItem key={key()} header className="ready-check">
          <Alert icon="nope" inline size="small" title={`${ReadyLabels.EVENT} in progress`} dmpStyle="dark">
            {Dt} editing is disabled
          </Alert>
        </MenuItem>
      );

    //now onto the normal editing menu. special case: if role is PROPOSER, they can only edit (Propose changes)
    if (me.role === DealRole.PROPOSER) {
      actions.push(
        <MenuItem key={key()} eventKey={SectionActions.PROPOSE} disabled={locked}>
          Propose changes
        </MenuItem>
      );
      //only show Version History option if there are edits
      if (section.versions.length > 1 && !Dealer.mobile)
        actions.push(
          <MenuItem key={key()} eventKey={SectionActions.VERSIONS}>
            Version history ({section.versions.length})
          </MenuItem>
        );

      actions.push(<MenuItem key={key()} divider />);
      actions.push(
        <MenuItem key={key()} eventKey={SectionActions.BEFORE} disabled={locked || isMobile}>
          Add section before
        </MenuItem>
      );
      actions.push(
        <MenuItem key={key()} eventKey={SectionActions.AFTER} disabled={locked || isMobile}>
          Add section after
        </MenuItem>
      );

      if (!section.sourceChildren.length) {
        actions.push(
          <MenuItem key={key()} eventKey={SectionActions.SUB} disabled={locked || isMobile}>
            Add subsection
          </MenuItem>
        );
      }
    }
    //otherwise add the full editing menu
    else {
      actions.push(
        <MenuItem key={key()} eventKey={SectionActions.EDIT} disabled={locked || isMobile}>
          Edit {isBlock ? 'content' : 'section'}
        </MenuItem>
      );
      //Section deletion for ITEM sections is in menu
      if (section.isItem)
        actions.push(
          <MenuItem key={key()} eventKey={SectionActions.DELETE_HARD} disabled={locked}>
            Delete {isBlock ? 'content' : 'section'}
          </MenuItem>
        );
      //only show Version History option if there are edits
      if (section.versions.length > 1 && !Dealer.mobile)
        actions.push(
          <MenuItem key={key()} eventKey={SectionActions.VERSIONS}>
            Version history ({section.versions.length})
          </MenuItem>
        );

      if (!isBlock) {
        actions.push(<MenuItem key={key()} divider />);
        actions.push(
          <MenuItem key={key()} eventKey={SectionActions.BEFORE} disabled={locked || isMobile}>
            Add section before
          </MenuItem>
        );
        actions.push(
          <MenuItem key={key()} eventKey={SectionActions.AFTER} disabled={locked || isMobile}>
            Add section after
          </MenuItem>
        );
        if (!section.sourceChildren.length && (!isListItem || _.get(section, 'list.nesting'))) {
          actions.push(
            <MenuItem key={key()} eventKey={SectionActions.SUB} disabled={locked || isMobile}>
              Add subsection
            </MenuItem>
          );
        }
        actions.push(<MenuItem key={key()} divider />);
        actions.push(
          <MenuItem
            key={key()}
            eventKey={SectionActions.UP}
            disabled={!section.canMove('up', !section.isItem) || locked}
          >
            Move section up
          </MenuItem>
        );
        actions.push(
          <MenuItem
            key={key()}
            eventKey={SectionActions.DOWN}
            disabled={!section.canMove('down', !section.isItem) || locked}
          >
            Move section down
          </MenuItem>
        );
        actions.push(<MenuItem key={key()} divider />);
      }

      if (!section.isItem) {
        actions.push(
          <MenuItem key={key()} eventKey={SectionActions.NUMBERING} disabled={locked}>
            Toggle numbering
          </MenuItem>
        );
      }

      if (section.canIndent) {
        actions.push(
          <MenuItem
            key={key()}
            eventKey={SectionActions.RIGHT}
            disabled={!section.canMove('right', section.isSource) || locked}
          >
            Indent section
          </MenuItem>
        );
        actions.push(
          <MenuItem
            key={key()}
            eventKey={SectionActions.LEFT}
            disabled={!section.canMove('left', section.isSource) || locked}
          >
            Outdent section
          </MenuItem>
        );
      }

      if (section) {
        actions.push(
          <MenuItem key={key()} eventKey={SectionActions.PAGE_BREAK} disabled={locked || isMobile}>
            {section.pageBreak ? 'Remove page break' : 'Add page break'}
          </MenuItem>
        );
      }

      if (isListItem && _.get(section, 'list.clearing') && !isBlock) {
        actions.push(<MenuItem key={key()} divider />);
        if (canReselectListItems) {
          actions.push(
            <MenuItem key={key()} eventKey={SectionActions.CLEAR_LIST} disabled={locked}>
              Delete all {section?.parent?.dataSource?.displayName} sections
            </MenuItem>
          );
          actions.push(
            <MenuItem key={key()} eventKey={SectionActions.RESELECT_LIST} disabled={locked}>
              Re-select {section?.parent?.dataSource?.displayName} data
            </MenuItem>
          );
        } else {
          actions.push(
            <MenuItem key={key()} eventKey={SectionActions.CLEAR_LIST} disabled={locked}>
              Clear all list items
            </MenuItem>
          );
        }
      }
    }
  }

  const ulClass = cx({ 'ready-check': !!rc }, { 'clear-sig': locked }, { 'need-login': needsLogin });

  return (
    <DropdownDots
      ref={dd}
      className={`section-menu source-menu ${ulClass}`}
      id={`dd-${section.id}`}
      onClick={(e) => e.stopPropagation()}
      onSelect={handleAction}
      pullRight
      data-cy={ulClass}
    >
      {actions}
    </DropdownDots>
  );
};

SectionMenu.propTypes = {
  section: PropTypes.object.isRequired,
  user: PropTypes.object,
  handleAction: PropTypes.func.isRequired,
  canReselectListItems: PropTypes.bool,
};

export default SectionMenu;
