import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Dt, dt } from '@core/utils';

import { Button, Dropdown, Icon, MenuItem } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

import NewDeal, { NEW_DEAL_MODES } from './NewDeal';

@autoBindMethods
export default class NewDealButton extends Component {
  static defaultProps = {
    team: null,
    teams: null,
    onClose: _.noop,
  };

  static propTypes = {
    history: PropTypes.object.isRequired,
    selectTeam: PropTypes.func.isRequired,
    subscription: PropTypes.object,
    team: PropTypes.object,
    teams: PropTypes.array,
    user: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      newDealTab: null,
    };
  }

  get canCreate() {
    return !!this.props.user.team;
  }

  addContract(newDealTab) {
    this.setState({ newDealTab });
  }

  onClose() {
    this.setState({ newDealTab: null });
    this.props.onClose();
  }

  render() {
    const { newDealTab } = this.state;
    const modalProps = _.pick(this.props, ['history', 'selectTeam', 'subscription', 'team', 'teams', 'user']);

    const menuItems = NEW_DEAL_MODES.map((mode) =>
      mode.header ? (
        <MenuItem header key={mode.key}>
          {mode.title}
        </MenuItem>
      ) : (
        <MenuItem key={mode.key} eventKey={mode.key} info={mode.tip} icon={mode.icon}>
          {mode.action}
        </MenuItem>
      )
    );

    menuItems.splice(2, 0, <MenuItem divider key="divider" />);

    const NewContractDropdown = (
      <Dropdown
        id="dd-add-contract"
        className="add-contract"
        onSelect={this.addContract}
        title={`New ${Dt}`}
        dmpStyle="primary"
        pullRight
        menuWidth={280}
        dataCyToggle="btn-new-contract"
      >
        {menuItems}
      </Dropdown>
    );

    return (
      <>
        {this.canCreate ? (
          NewContractDropdown
        ) : (
          <TooltipButton
            tipID="tip-activity"
            tip={`Set up your Team first in order to create ${dt}s`}
            placement="bottom"
          >
            <Button dmpStyle="primary" className="disabled">
              <Icon name="plus2" />
            </Button>
          </TooltipButton>
        )}
        {!!newDealTab && (
          <NewDeal
            {...modalProps}
            show={!!newDealTab}
            mode={newDealTab}
            close={() => {
              this.props.selectTeam(null);
              this.setState({ newDealTab: null });
            }}
          />
        )}
      </>
    );
  }
}
