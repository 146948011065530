import React, { Component, createRef } from 'react';

import autobindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, FormControl, FormGroup, Overlay } from 'react-bootstrap';

import Deal from '@core/models/Deal';
import { getOperators } from '@core/models/Operator';
import VariableFilter from '@core/models/VariableFilter';
import Vital, { CLAUSE_VITAL_PROMPTS, VITAL_TYPES } from '@core/models/Vital';
import { getUniqueKey } from '@core/utils';

import { Button, ButtonIcon, Dropdown, Form, MenuItem, Popover, Validator } from '@components/dmp';

import VariableFilterView from '@components/VariableFilterView';
import Fire from '@root/Fire';

@autobindMethods
class VitalEditor extends Component {
  static defaultProps = {};

  static propTypes = {
    deal: PropTypes.instanceOf(Deal),
    vital: PropTypes.instanceOf(Vital),
    newType: PropTypes.string,

    container: PropTypes.object,
    target: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // This is just for a base id for child controls
    this.id = 'vital-' + getUniqueKey();

    this.state = {
      id: null,
      type: '',
      title: '',
      relatedSections: [],
      relatedVariable: null,
      riskValue: 0,
      valueFilter: null,
      conditions: {},
      tab: 'definition',
      clauseCheck: null,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }

  componentDidUpdate(prevProps) {
    if (!this._isMounted) return;
    if (prevProps.vital !== this.props.vital || prevProps.newType !== this.props.newType) {
      this.populate(this.props);
    }
  }

  get isValid() {
    const { title, type, relatedVariable, riskValue } = this.state;

    if (!title.trim()) return false;

    if (!this.validateRiskValue(riskValue)) return false;

    if (type === 'variable') {
      if (!relatedVariable) return false;
    }
    // Allow initial saving of Clause Vitals without relatedSections
    // because section selection (linking) happens subsequently outside of this component

    return true;
  }

  populate(props) {
    const { vital, newType } = props;

    // All we have for new Vital creation is a type
    if (!vital) {
      this.setState({
        type: newType,
      });
    }

    // Otherwise (for existing Vitals), fully populate
    else {
      this.setState({
        id: vital.id,
        type: vital.type,
        title: vital.title,
        relatedVariable: vital.relatedVariable,
        relatedSections: vital.relatedSections,
        riskValue: vital.riskValue,
        valueFilter: vital.valueFilter,
        conditions: vital.conditions,
        clauseCheck: vital.clauseCheck,
      });
    }
  }

  handleChange(e, prop) {
    const newState = {};
    newState[prop] = e.target.value;
    this.setState(newState);
  }

  validateRiskValue(riskValue) {
    return !isNaN(parseInt(riskValue));
  }

  updateLinkedVariable(variableName) {
    const { deal } = this.props;
    const selectedVariable = deal.variables[variableName];
    this.setState({
      relatedVariable: variableName,
      // Auto-populate title from variable name
      title: selectedVariable?.displayName ? selectedVariable.displayName : variableName,
    });
  }

  addCondition(variableName) {
    const { deal, vital } = this.props;
    const variable = deal.variables[variableName];
    if (!variable) return;

    const operators = getOperators(variable.valueType);
    if (!operators.length) return;

    // Create new VariableFilter with default operator based on value type
    const vf = new VariableFilter(variable.name, variable.valueType, operators[0].key, []);
    vital.conditions[variable.name] = vf;
    Fire.saveTemplateVital(deal.dealID, vital);
  }

  updateCondition(varFilter) {
    const { conditions } = this.state;
    conditions[varFilter.variable] = varFilter;
    this.setState({ conditions });
  }

  removeCondition(varName) {
    const { conditions } = this.state;
    delete conditions[varName];
    this.setState({ conditions });
  }

  cancel(e) {
    if (e) e.stopPropagation();
    this.props.onHide();
  }

  async save(e) {
    const { deal } = this.props;
    const { id, type, title, relatedVariable, riskValue, valueFilter, conditions, clauseCheck } = this.state;

    const relatedSections = this.state.relatedSections.join('|');

    const vital = new Vital({
      id,
      type,
      title,
      relatedVariable,
      relatedSections,
      riskValue,
      valueFilter: _.get(valueFilter, 'json', null),
      conditions: _.keys(conditions).length ? _.mapValues(conditions, 'json') : null,
      clauseCheck,
    });

    await Fire.saveTemplateVital(deal.dealID, vital);

    this.cancel();
  }

  renderCondition(varFilter, varName) {
    const { deal } = this.props;
    const variable = deal.variables[varName];
    return (
      <div className="vital-condition" key={varName}>
        <div className="control-label">{variable.displayName || variable.name}</div>
        <ButtonIcon
          className="remove"
          icon="trash"
          size="default"
          onClick={() => this.removeCondition(variable.name)}
        />
        <VariableFilterView
          configOnly
          variable={variable}
          onChange={this.updateCondition}
          filter={varFilter}
          template={deal}
        />
      </div>
    );
  }

  renderEditor() {
    const { deal, vital } = this.props;
    const { title, type, relatedVariable, relatedSections, id, riskValue, tab, valueFilter, conditions, clauseCheck } =
      this.state;
    const typeDef = _.find(VITAL_TYPES, { key: type });
    const isNew = !id;

    const vars = deal.filterableVariables;
    const selectedVariable = relatedVariable ? deal.variables[relatedVariable] : null;
    const linkedSections = _.map(relatedSections, (id) => _.get(deal, `sections[${id}].displayTOC`, '(untitled)'));

    return (
      <Form>
        {isNew ? (
          <FormGroup className="id">Create new {typeDef.title} Vital</FormGroup>
        ) : (
          <ButtonGroup className="panel-tabs">
            {[
              { key: 'definition', title: 'Definition' },
              { key: 'conditions', title: 'Conditions' },
              { key: 'risk', title: 'Risk Scoring' },
            ].map((t) => (
              <Button
                key={t.key}
                dmpStyle="link"
                active={tab === t.key}
                onClick={() => this.setState({ tab: t.key })}
                data-cy={`${t.key}-tab`}
              >
                {t.title}
              </Button>
            ))}
          </ButtonGroup>
        )}
        {tab === 'definition' && (
          <div>
            {type === 'variable' && (
              <FormGroup className="vital-related-variable">
                <div className="control-label">Related variable</div>
                <Dropdown
                  id={this.id + 'dd-linked-var'}
                  onSelect={this.updateLinkedVariable}
                  title={selectedVariable ? selectedVariable.displayName || selectedVariable.name : 'Select...'}
                  size="small"
                  data-cy="dd-linked-variable"
                  className="dd-linked-variable"
                  block
                >
                  {_.map(vars, (variable) => {
                    return (
                      <MenuItem
                        eventKey={variable.name}
                        key={variable.name}
                        data-cy={variable.name}
                        info={`${variable.type}${variable.name}`}
                      >
                        {variable.displayName || variable.name}
                      </MenuItem>
                    );
                  })}
                </Dropdown>
              </FormGroup>
            )}

            <FormGroup>
              <div className="control-label">Title</div>
              <FormControl
                bsSize="small"
                type="text"
                value={title}
                placeholder="Title"
                onChange={(e) => this.handleChange(e, 'title')}
                data-cy="vital-title"
              />
            </FormGroup>
          </div>
        )}

        {tab === 'conditions' && (
          <div>
            <FormGroup className="vital-variants">
              <div className="vital-conditions-info">
                {_.keys(conditions).length > 0
                  ? 'This Vital will only be included in risk scoring if all conditions below are met.'
                  : 'There are no conditions on this Vital, so it will always be included in risk scoring.'}
              </div>
              {_.map(conditions, this.renderCondition)}
              <div className="control-label">Add a new condition</div>

              <Dropdown
                id={this.id + 'dd-vital-condition'}
                onSelect={this.addCondition}
                title={'Select...'}
                size="small"
                data-cy="dd-vital-condition"
                width="100%"
                className="dd-linked-variable"
              >
                {_.map(vars, (variable) => {
                  return (
                    <MenuItem
                      eventKey={variable.name}
                      key={variable.name}
                      data-cy={variable.name}
                      info={`${variable.type}${variable.name}`}
                    >
                      {variable.displayName || variable.name}
                    </MenuItem>
                  );
                })}
              </Dropdown>
            </FormGroup>
          </div>
        )}

        {tab === 'risk' && (
          <div>
            {type === 'variable' && selectedVariable && vital && (
              <FormGroup className="vital-var-filter">
                <div className="control-label">Target range</div>
                <VariableFilterView
                  configOnly
                  variable={selectedVariable}
                  onChange={(valueFilter) => {
                    this.setState({ valueFilter });
                  }}
                  filter={valueFilter}
                  template={deal}
                />
              </FormGroup>
            )}

            {type === 'clause' && (
              <FormGroup className="display">
                <div className="control-label">Linked sections ({linkedSections.length})</div>
                <FormControl
                  bsSize="small"
                  componentClass="textarea"
                  disabled
                  value={linkedSections.join('\n')}
                  placeholder="Select sections from left-hand side to associate with this Vital"
                />
              </FormGroup>
            )}

            {type === 'clause' && (
              <Dropdown
                id={`dd-clause-check`}
                title={clauseCheck ? _.find(CLAUSE_VITAL_PROMPTS, { key: clauseCheck }).title : 'Select one'}
                size="small"
                onSelect={(clauseCheck) => this.setState({ clauseCheck })}
                block
              >
                {CLAUSE_VITAL_PROMPTS.map((option) => (
                  <MenuItem key={option.key} eventKey={option.key}>
                    {option.title}
                  </MenuItem>
                ))}
              </Dropdown>
            )}

            <div className="control-label">Risk score</div>
            <FormGroup className="risk-value dmp-validator-container">
              <FormControl
                bsSize="small"
                value={riskValue}
                placeholder="Enter an integer"
                onChange={(e) => this.handleChange(e, 'riskValue')}
                data-cy="vital-risk-score"
              />
              <Validator
                validateEmpty
                value={riskValue}
                validate={this.validateRiskValue}
                onResult={_.noop}
                validTip="Valid risk value"
                invalidTip="Must be a valid integer"
              />
            </FormGroup>
          </div>
        )}

        <FormGroup className="actions">
          <Button className="cancel" dmpStyle="link" size="small" onClick={this.cancel} data-cy="btn-cancel-var">
            Cancel
          </Button>

          <Button className="save" disabled={!this.isValid} size="small" onClick={this.save} data-cy={'btn-save-vital'}>
            {isNew ? 'Save' : 'Update'}
          </Button>
        </FormGroup>
      </Form>
    );
  }

  render() {
    const { target, onHide, container, rootClose } = this.props;
    if (!this._isMounted) return null;

    return (
      <Overlay
        container={container}
        onHide={onHide}
        placement="bottom"
        rootClose={rootClose}
        show={true}
        target={target}
      >
        <Popover className="popover-vital-editor" id={`${this.id}-pop`} data-cy="popover-vital-editor">
          {this.renderEditor()}
        </Popover>
      </Overlay>
    );
  }
}

export default VitalEditor;
