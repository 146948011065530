import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { Alert, ControlLabel, FormGroup, Modal } from 'react-bootstrap';

import VitalCheck from './VitalCheck';

@autoBindMethods
export default class VitalInfo extends Component {
  static propTypes = {
    vitalChecks: PropTypes.object,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    errorMsg: PropTypes.string,
  };

  renderBody() {
    const { vitalChecks } = this.props;
    if (!vitalChecks) return null;
    return (
      <Modal.Body>
        <div className="wrapper">
          <FormGroup>
            <ControlLabel>Vitals</ControlLabel>
            <div className="vital-info-body" data-cy="vital-info-body">
              <VitalCheck vitalChecks={vitalChecks} />
            </div>
          </FormGroup>
        </div>
      </Modal.Body>
    );
  }

  renderError() {
    const { errorMsg } = this.props;
    return (
      <Modal.Body>
        <div className="vital-info-error">
          <Alert bsStyle="danger">{errorMsg}</Alert>
        </div>
      </Modal.Body>
    );
  }

  render() {
    const { show, close, vitalChecks } = this.props;
    if (!show) return null;
    return (
      <Modal dialogClassName="vital-info-modal" show={show} onHide={close}>
        <Modal.Header closeButton>
          <span className="headline">Vitals info</span>
        </Modal.Header>
        {vitalChecks ? this.renderBody() : this.renderError()}
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}
