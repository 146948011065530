import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';

import Template from '@core/models/Template';
import { DateFormatter } from '@core/utils';
import { Dt } from '@core/utils';

import { Button } from '@components/dmp';

import API from '@root/ApiClient';

function copyInfo(text) {
  let textarea = document.createElement('textarea');
  textarea.textContent = text;
  document.body.appendChild(textarea);

  var selection = document.getSelection();
  var range = document.createRange();
  range.selectNode(textarea);
  selection.removeAllRanges();
  selection.addRange(range);
  document.execCommand('copy');
  selection.removeAllRanges();
  document.body.removeChild(textarea);
}

@autoBindMethods
export default class ContractInfo extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    deal: PropTypes.object.isRequired,
    team: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isCopied: false,
      templateTitle: null,
      instance: null,
      dateAndTime: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { deal, show, user } = this.props;
    const dealTeamID = deal.team;

    if (show && !prevProps.show) {
      this.setState({ isCopied: false });
      this.getInstance();
      this.getDateAndTime(new Date());
      if (deal && user.teams && user.teams[dealTeamID]) this.dealTemplate(dealTeamID);
    }
  }

  copyToClipBoard() {
    const { deal, user, team } = this.props;
    const { templateTitle, instance, dateAndTime } = this.state;
    const copyContent = [
      `Instance: ${instance}`,
      `Team: ${team ? team.info.name : ''}`,
      `Team ID: ${team ? deal.team : ''}`,
      `Template: ${templateTitle}`,
      `Template ID: ${deal.info.sourceTemplate}`,
      `${Dt} ID: ${deal.dealID}`,
      `User: ${user.email}`,
      `User ID: ${user.id}`,
      `IP Address: ${user.ip || 'n/a'}`,
      `Location: ${user.location || 'n/a'}`,
      `Date and time: ${dateAndTime}`,
    ].join('\n');

    copyInfo(copyContent);
    this.setState({ isCopied: true });
    setTimeout(() => {
      this.setState({ isCopied: false });
    }, 2000);
  }

  async dealTemplate(teamID) {
    const { deal } = this.props;
    API.call('getTeamTemplates', { teamID, activeOnly: true }, async (raw) => {
      const templates = _.map(raw, (json) => new Template(json, json.dealID));
      const matchedTemplate = _.find(templates, { key: deal.info.sourceTemplate });
      await this.setState({ templateTitle: matchedTemplate?.title });
    });
  }

  getInstance = () => {
    if (window.location != window.parent.location) {
      const referrer = document.referrer;
      const url = new URL(referrer);
      this.setState({
        instance: url.host,
      });
    } else {
      this.setState({
        instance: document.location.host,
      });
    }
  };

  getDateAndTime = (date) => {
    const offset = DateFormatter.getOffset(date) / 60000;
    const offsetString = `${offset > 0 ? '+' : '-'}${('0' + Math.abs(offset / 60)).slice(-2)}:${(
      '0' + Math.abs(offset % 60)
    ).slice(-2)}`;
    this.setState({
      dateAndTime: `${DateFormatter.mdy(date)} @ ${DateFormatter.time(date)} (${offsetString} GMT)`,
    });
  };

  render() {
    const { show, close, deal, user, team } = this.props;
    if (!show) return null;
    const { isCopied, templateTitle, instance, dateAndTime } = this.state;

    return (
      <>
        <Modal dialogClassName="deal-info" show={show} onHide={close}>
          <Modal.Header closeButton>
            <span className="headline">{Dt} Info</span>
          </Modal.Header>
          <Modal.Body>
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Instance</ControlLabel>
                <div className="contents" data-cy="deal-info-instance">
                  {instance}
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>Team</ControlLabel>
                <div className="contents" data-cy="deal-info-team">
                  {team ? team.info.name : ''}
                  <small>{team ? deal.team : ''}</small>
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>Template</ControlLabel>
                <div className="contents" data-cy="deal-info-template">
                  {templateTitle}
                  <small>{deal.info.sourceTemplate}</small>
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>{Dt}</ControlLabel>
                <div className="contents" data-cy="deal-info-id">
                  {deal.dealID}
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>User</ControlLabel>
                <div className="contents" data-cy="deal-info-user">
                  {user.email}
                  <small>{user.id}</small>
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>IP address</ControlLabel>
                <div className="contents" data-cy="deal-info-ip">
                  {user.ip || 'n/a'}
                  <small>{user.location || 'n/a'}</small>
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>Date and time</ControlLabel>
                <div className="contents" data-cy="deal-info-date">
                  {dateAndTime}
                </div>
              </FormGroup>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button data-cy="btn-copy-info" disabled={isCopied} onClick={this.copyToClipBoard}>
              {isCopied ? 'Copied' : 'Copy information to clipboard'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
